import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../../../UIComponents/customDropdown";
import { materialTypeOptions } from "../../../../app/optionModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { MaterialModelForCreate } from "../materialModels";
import { handleInputChange } from "../../../../app/common";
import { CreateMaterial, resetStatusCode } from "../materialSlice";
import { useNavigate } from "react-router-dom";
import { NotificationPopup, notify } from "../../../../UIComponents/notification";
import { LoadingPage } from "../../../../UIComponents/loading";

function CreateNewMaterialPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [materialName, setMaterialName] = useState("");
    const [thermalConductivity, setThermalConductivity] = useState("");
    const [specificHeatCapacity, setSpecificHeatCapacity] = useState("");
    const [density, setDensity] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const materialSliceData = useSelector((state: RootState) => state.MaterialSlice.materialSliceData);

    function handleCreateMaterialClick(): void {
        if (materialName && thermalConductivity && specificHeatCapacity && density && selectedCategory) {
            const materialModel: MaterialModelForCreate = {
                name: materialName,
                materialType: selectedCategory,
                thermalConductivity: parseFloat(thermalConductivity),
                specificHeatCapacity: parseFloat(specificHeatCapacity),
                density: parseFloat(density)
            }
            dispatch(CreateMaterial(materialModel));
        }
    }

    useEffect(() => {
        if (materialName === "" || thermalConductivity === "" || parseFloat(thermalConductivity) === 0 || specificHeatCapacity === "" || parseFloat(specificHeatCapacity) === 0 || density === "" || parseFloat(density) === 0 || selectedCategory === "") {
            setIsButtonDisabled(true)
        }
        else {
            setIsButtonDisabled(false)
        }
    }, [materialName, thermalConductivity, specificHeatCapacity, density, selectedCategory])

    useEffect(() => {
        if (materialSliceData.statusValues.statusCode === "") {
            return;
        }
        if (materialSliceData.statusValues.statusCode === "200") {
            navigate("/materials");
        }
        else {
            notify(t(materialSliceData.statusValues.message), true)
            dispatch(resetStatusCode());
        }
    }, [materialSliceData.statusValues])

    return (
        <div>
            <div className={`pt-[15px] pl-[15px] ${materialSliceData.statusValues.isLoading && "blur-5"}`}>
                <div className="bg-#3B4250 w-[563px] md:w-[920px] lg:w-[1040px] h-[511px] px-[28px] rounded flex items-center justify-center">
                    <div className="w-full">
                        <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{t("CreateANewMaterial")}</p>
                        <div className="space-y-[25px] mt-[45px] text-#FFFFFF lg:text-lg xl:text-xl">
                            <div className="flex items-center">
                                <div className="flex">
                                    <p>{t("MaterialName")}:</p>
                                </div>
                                <input id="materialName" type="text" autoComplete="off" placeholder={t("MaterialName")} value={materialName} onChange={(e) => { setMaterialName(e.target.value) }} className={`bg-#2E333E placeholder:text-#545B6A w-[339px] md:w-[635px] lg:w-[702px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto`} />
                            </div>
                            <div className="flex items-center">
                                <div className="flex">
                                    <p>{t("ThermalConductivity")}:</p>
                                </div>
                                <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                                    <input id="thermalConductivity" type="text" autoComplete="off" placeholder="0.000" value={thermalConductivity} onChange={(e) => { handleInputChange(e, setThermalConductivity, 3) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                                    <p className="w-[48px] md:w-[59px]">W/mK</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex">
                                    <p>{t("SpecificHeatCapacity")}:</p>
                                </div>
                                <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                                    <input id="specificHeatCapacity" type="text" autoComplete="off" placeholder="0" value={specificHeatCapacity} onChange={(e) => { handleInputChange(e, setSpecificHeatCapacity, 0) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                                    <p className="w-[48px] md:w-[59px]">J/K</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex">
                                    <p>{t("Density")}:</p>
                                </div>
                                <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                                    <input id="density" type="text" autoComplete="off" placeholder="0" value={density} onChange={(e) => { handleInputChange(e, setDensity, 0) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                                    <p className="w-[48px] md:w-[59px]">kg/m</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex">
                                    <p>{t("Category")}:</p>
                                </div>
                                <CustomDropdown dropdownId="categoryDropdown" options={materialTypeOptions} placeholder={t("SelectCategory")} height={"h-10"} width={"w-[339px] md:w-[635px] lg:w-[702px]"} onSelectClick={setSelectedCategory} />
                            </div>
                            <div className="flex items-center">
                                <button id="createNewMaterialBtn" disabled={isButtonDisabled} onClick={handleCreateMaterialClick} className="w-fit h-10 px-6 bg-#FF9800 text-#272C35 disabled:opacity-50 uppercase font-semibold text-sm rounded-lg justify-center items-center gap-2 inline-flex ml-auto">{t("CreateMaterial")}</button>
                            </div>
                            <p className="text-[12px] text-#CFD8DC leading-[14px] text-right">{"*" + t("ToCreateAMaterialYouMustFillInAllTheFields")}</p>
                        </div>
                    </div>
                </div>
                <NotificationPopup />
            </div>
            {materialSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(materialSliceData.statusValues.isLoading)}</div>}
        </div>
    );
}

export default CreateNewMaterialPage;

