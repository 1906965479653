import { StatusValue } from "../../app/common";

export interface FloorModel {
    floorId: string;
    floorName: string;
    floorLevel: string;
    level: number;
    height: number;
    heightPosition: number;
    zones: AreaModel[];
    spaces: AreaRoomModel[];
}

export interface FloorModelForCreate {
    projectId: string,
    floorName: string;
    floorLevel: string;
    dateCreatedOrModified: string;
    userId: string;
}

export interface FloorModelForUpdate extends FloorModelForCreate {
    floorId: string,
}

export interface FloorModelForDelete {
    projectId: string;
    floorId: string;
    userId: string;
    dateDeleted: string;
}

export interface AreaModel {
    floorId: string;
    zoneId: string;
    zoneNumber: string;
    zoneName: string;
    spaces: AreaRoomModel[];
}

export interface AreaModelForCreate {
    floorId: string,
    zoneNumber: number,
    zoneName: string,
    userId: string,
    projectId: string,
    dateCreatedOrModified: string,
}

export interface AreaModelForUpdate extends AreaModelForCreate {
    zoneId: string,
}

export interface AreaModelForDelete {
    zoneId: string,
    userId: string,
    projectId: string,
    dateDeleted: string,
}

export interface AreaRoomModel {
    spaceId: string,
    zoneId: string,
    floorId: string,
    spaceCode: string,
    name: string,
    temperature: number,
    area: number,
    transmissionLoss: number,
    ventilationLoss: number,
    standardHeatLoss: number,
    heatingInterruptionLoss: number,
    totalLoss: number,
    lossesBySquareMeter: number,
}

export interface RoomModel {
    spaceId: string,
    spaceCode: string,
    zoneId: string,
    spaceNumber: number,
    spaceName: string,
    length: number,
    width: number,
    height: number,
    area: number,
    volume: number,
    n50Value: number,
    temperature: number,
    roomHeight: number,
    ceilingThickness: number,
    heightPosition: number,
    airExchangeRate: number,
    spaceType: string,
    spaceHeatingSurface: string,
    hasHeatingInterruption: boolean,
    heatingInterruptionModel: SpaceHeatingInterruptionModel,
    walls: WallModel[],
}

export interface WallHeightReq {
    projectId: string,
    userId: string,
    spaceId: string,
    newSpaceHeight: number,
    oldSpaceHeight: number,
    dateCreatedOrModified: string,
}

export interface SpaceHeatingInterruptionModel {
    loweringPhase: number,
    reheatingTime: number,
    airExchangeRateDuringLoweringPhase_nabs: number,
    airExchangeRateDuringReheatingTime_nrh: number,
    buildingMass: string,
    effectiveBuildingMass_Cwirk: number,
    temperatureDrop_trh: number,
    reheatingFactor_RH: number,
    heatingInterruptionType: string,
}

export const initialSpaceHeatingInteruptionModel: SpaceHeatingInterruptionModel = {
    loweringPhase: 0,
    reheatingTime: 0,
    airExchangeRateDuringLoweringPhase_nabs: 0,
    airExchangeRateDuringReheatingTime_nrh: 0,
    buildingMass: "LOW",
    effectiveBuildingMass_Cwirk: 15,
    temperatureDrop_trh: 0,
    reheatingFactor_RH: 0,
    heatingInterruptionType: "",
}

export interface RoomModelForCreate {
    zoneId: string,
    spaceName: string,
    spaceNumber: number,
    length: number,
    width: number,
    height: number,
    temperature: number,
    airExchangeRate: number,
    n50Value: number,
    spaceType: string,
    spaceArea: number,
    spaceVolume: number,
    spaceHeatingSurface: string | null,
    userId: string,
    hasHeatingInterruption: boolean,
    projectId: string,
    dateCreatedOrModified: string,
    heightPosition: number,
    ceilingThickness: number,
}

export interface RoomModelForUpdate extends RoomModelForCreate {
    spaceId: string,
}

export interface RoomModelForDelete {
    userId: string,
    spaceId: string,
    projectId: string,
    dateDeleted: string,
}

export interface RoomsByHeatingModel {
    projectId: string,
    spaceType: string,
    spaceId: string
}

export interface BuildingModel {
    buildingId: string;
    buildingName: string;
    length: number;
    width: number;
    buildingArea: number;
    buildingPerimeter: number;
    bottomPlateThickness: number;
    parameterB: number;
    annualAverageTemperature: number;
    outsideTemperature: number;
    geolocationName: string;
    geolocationZipCode: string;
    elevation: number;
    z: number;
    groundWaterDepth: number;
    thermalBridgeValue: number;
    hasHeatingInterruption: boolean;
    buildingCoverage: string;
    airOpeningTightness: string;
    buildingType: string;
    groundType: string;
    buildingMass: string;
    thermalBridge: string;
}

export interface RoomHeatingInteruptionModelForUpdate {
    zoneId: string,
    spaceId: string,
    userId: string,
    loweringPhase: number,
    reheatingTime: number,
    airExchangeRateDuringLoweringPhase_nabs: number,
    airExchangeRateDuringReheatingTime_nrh: number,
    buildingMass: string,
    effectiveBuildingMass_Cwirk: number,
    temperatureDrop_trh: number,
    reheatingFactor_RH: number,
    heatingInterruptionType: string,
    hasHeatingInterruption: boolean,
    projectId: string,
}

export interface WallModel {
    wallId: string,
    pairId: string,
    componentId: string,
    componentCode: string,
    name: string,
    orientation: string,
    environmentType: string,
    length: number,
    widthHeight: number,
    area: number,
    usefulArea: number,
    nextSpaceId: string,
    temperature: number,
    unheatedSpaceProperty: string,
    correctionFactorForTheGround: string,
    bu: number,
    thermalBridge: number,
    thermalTransmittance: number,
    correctedUValue: number,
    heatTransferCoefficient: number,
    transmissionLoss: number,
    dateCreated: string,
    openings: OpeningInWallModel[];
}

export interface WallModelForCreate {
    componentInCollectionId: string | null,
    projectId: string,
    componentId: string,
    spaceId: string,
    userId: string,
    orientation: string,
    environmentType: string,
    length: number,
    widthHeight: number,
    nextSpaceId: string | null,
    temperature: number | null,
    unheatedSpaceProperty: string | null,
    dateCreatedOrModified: string,
    correctionFactorForTheGround: string | null,
};

export interface WallModelForUpdate extends WallModelForCreate {
    wallId: string,
    pairId: string,
}

export interface WallModelForDelete {
    userId: string,
    pairId: string,
    projectId: string,
    dateDeleted: string,
}

export interface OpeningInWallModel {
    openingInWallId: string,
    openingInWallPairId: string,
    wallId: string,
    openingId: string,
    name: string,
    openingCode: string,
    thermalTransmittance: number,
    area: number,
    orientation: string,
    environmentType: string,
    width: number,
    height: number,
    temperature?: number,
    bu: number,
    thermalBridge: number,
    correctedUValue: number,
    heatTransferCoefficient: number,
    transmissionLoss: number,
    number: number,
}

export interface OpeningInWallModelForCreate {
    wallPairId: string,
    userId: string,
    openingId: string,
    width: number,
    height: number,
    number: number,
    dateCreatedOrModified: string,
    projectId: string,
    openingInCollectionId: string | null,
}

export interface OpeningInWallModelForUpdate extends OpeningInWallModelForCreate {
    openingInWallId: string,
    wallId: string
}

export interface OpeningInWallModelForDelete {
    openingPairId: string,
    userId: string,
    wallId: string,
    projectId: string,
    dateDeleted: string,
}

export interface BuildingStructureSliceDataModel {
    floorData: FloorModel,
    allFloorsData: FloorModel[],
    areaData: AreaModel,
    allAreasData: AreaModel[],
    areaRoomData: AreaRoomModel,
    allAreaRoomsData: AreaRoomModel[],
    roomData: RoomModel,
    allRoomsDataInArea: RoomModel[],
    allRoomsDataOnFloor: RoomModel[],
    buildingData: BuildingModel,
    wallData: WallModel,
    allWallsData: WallModel[],
    openingInWallData: OpeningInWallModel,
    allOpeningsInWall: OpeningInWallModel[],
    activeFloorId: string,
    allRoomsByHeatingData: RoomModel[],
}

export interface BuildingStructureSliceData {
    buildingStructureData: BuildingStructureSliceDataModel,
    statusValues: StatusValue,
    isEditRoom: boolean,
}