import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../app/common";

interface BuilidingInfoPopupProps {
    length: string,
    width: string,
    buildingArea: string,
    parameterB: string,
}

function BuildingInfoPopup(props: BuilidingInfoPopupProps) {
    const { t } = useTranslation();

    return (
        <div className="bg-#414959 w-fit h-[240px] rounded-[10px] flex items-start justify-start py-[22px] px-[30px]">
            <div className="text-#F5F5F5 text-[16px] leading-6 font-normal font-inter w-full space-y-5 my-auto">
                <div className="flex justify-center items-end gap-2 text-[18px] leading-[18px] uppercase">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9166 22.917L2.08331 22.917" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                        <path d="M17.7084 22.9163V6.24967C17.7084 4.28549 17.7084 3.3034 17.0982 2.6932C16.488 2.08301 15.5059 2.08301 13.5417 2.08301H11.4584C9.49417 2.08301 8.51208 2.08301 7.90188 2.6932C7.29169 3.3034 7.29169 4.28549 7.29169 6.24967V22.9163" stroke="#FF9E00" strokeWidth="0.964184" />
                        <path d="M21.875 22.9163V11.9788C21.875 10.5159 21.875 9.78437 21.5239 9.2589C21.3719 9.03142 21.1766 8.83611 20.9491 8.68411C20.4237 8.33301 19.6922 8.33301 18.2292 8.33301" stroke="#FF9E00" strokeWidth="0.964184" />
                        <path d="M3.125 22.9163V11.9788C3.125 10.5159 3.125 9.78437 3.47611 9.2589C3.6281 9.03142 3.82342 8.83611 4.0509 8.68411C4.57636 8.33301 5.30785 8.33301 6.77083 8.33301" stroke="#FF9E00" strokeWidth="0.964184" />
                        <path d="M12.5 22.917V19.792" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                        <path d="M10.4167 5.20801H14.5834" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                        <path d="M10.4167 8.33301H14.5834" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                        <path d="M10.4167 11.458H14.5834" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                        <path d="M10.4167 14.583H14.5834" stroke="#FF9E00" strokeWidth="0.964184" strokeLinecap="round" />
                    </svg>
                    <p>{t("BuildingInfo")}</p>
                </div>
                <div>
                    <div className="w-full h-[240px] grid grid-cols-2 grid-rows-6 text-#F5F5F5 text-base">
                        <div className="space-y-5">
                            <p className="h-[22px] flex justify-start items-center text-center">{t("Length")}:</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{t("Width")}:</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{t("Area")}:</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{t("ParameterB")}:</p>
                        </div>
                        <div className="space-y-5 pl-[40px]">
                            <p className="h-[22px] flex justify-start items-center text-center">{formatNumber(parseFloat(props.length), 2)} m</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{formatNumber(parseFloat(props.width), 2)} m</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{formatNumber(parseFloat(props.buildingArea), 2)} m²</p>
                            <p className="h-[22px] flex justify-start items-center text-center">{formatNumber(parseFloat(props.parameterB), 2)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingInfoPopup;