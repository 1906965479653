import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    hello: "Hello",
                    Email: "Email",
                    Password: "Password",
                    CreateAccount: "Create account",
                    ForgotYourPassword: "Forgot your password?",
                    RememberMe: "Remember me",
                    Or: "Or",
                    LogInWithMicrosoft: "Log in with Microsoft",
                    NewToBimagine: "New to Bimagine?",
                    TroubleLoggingIn: "Trouble logging in?",
                    InvalidEmail: "Please insert valid email address.",
                    InsertPassword: "Please insert your password.",
                    EnterYourEmail: "Enter your email and we'll send you a password to get back into your account.",
                    Send: "Send",
                    ReturnTo: "Return to",
                    LogIn: "Log in",
                    Dashboard: "Dashboard",
                    Projects: "Projects",
                    CreateBuilding: "Create building",
                    CreateComponents: "Create components",
                    Materials: "Materials",
                    BuildingStructure: "Building structure",
                    Settings: "Settings",
                    Cancel: "Cancel",
                    Continue: "Continue",
                    EditProject: "Edit project",
                    CreateANewProject: "Create a new project",
                    CreateANewMaterial: "Create a new material",
                    Close: "Close",
                    Save: "Save",
                    Create: "Create",
                    SaveFile: "Save file",
                    Calculate: "Calculate",
                    Standard: "Standard",
                    ProjectCode: "Project code",
                    ProjectName: "Project name",
                    Address: "Address",
                    Country: "Country",
                    ZipCode: "ZIP code",
                    CreateProject: "Create project",
                    CreateANewStakeholder: "Create a new stakeholder",
                    Stakeholders: "Stakeholders",
                    CustomerData: "Customer data",
                    StakeholderData: "Stakeholder Data",
                    StakeholderName: "Stakeholder name",
                    OrganizationName: "Organization name",
                    Department: "Department",
                    Country: "Country",
                    PostalCode: "Postal code",
                    City: "City",
                    Street: "Street",
                    StreetNumber: "Street number",
                    PhoneNumber1: "Phone number 1",
                    PhoneNumber2: "Phone number 2",
                    Fax: "Fax",
                    Email1: "Email 1",
                    Email2: "Email 2",
                    WebSite: "Web site",
                    AreYouSureYouWantToDeleteThisGroup: "Are you sure you want to delete this stakeholder?",
                    AreYouSureYouWantToDeleteThisWall: "Are you sure you want to delete this wall?",
                    AreYouSureYouWantToDeleteThisOpening: "Are you sure you want to delete this opening?",
                    Geometry: "Geometry",
                    Property: "Property",
                    ThermalBridge: "Thermal bridge",
                    Geolocation: "Geolocation",
                    HeatingInterruption: "Heating interruption",
                    Optional: "optional",
                    ToCreateABuildingYouMustFillInAllTheFields: "To create a building, you must fill in all the fields.",
                    ToCreateAMaterialYouMustFillInAllTheFields: "To create a material, you must fill in all the fields.",
                    SaveChanges: "Save changes",
                    TypeABuildingName: "Type a building name",
                    BuildingName: "Building name",
                    BottomPlateThickness: "Bottom plate thickness",
                    ParameterZ: "Parameter Z",
                    BuildingArea: "Building area",
                    BuildingPerimeter: "Building perimeter",
                    GroundWaterDepth: "Ground water depth",
                    ValueOfGroundWaterDepthIsKnown: "Value of ground water depth is known",
                    Login: "Login",
                    BuildingType: "Building type",
                    BuildingMass: "Building mass",
                    BuildingAirTightness: "Building air tightness",
                    GroundType: "Ground type",
                    BuildingCoverage: "Building coverage",
                    SelectBuildingType: "Select building type",
                    SelectBuildingMass: "Select building mass",
                    SelectBuildingAirTightness: "Select building air tightness",
                    SelectGroundType: "Select ground type",
                    SelectBuildingCoverage: "Select building coverage",
                    ThermalBridge001: "without minimizing the heat flow in thermal bridges through thermal insulation (e.g. without thermal insulation, construction until 1990) (0.10)",
                    ThermalBridge005: "with minimizing the heat flow in thermal bridges through thermal insulation according to ÖNORM B 8110 - 1 (construction after 1990) (0.05)",
                    Name: "Name",
                    PostalCode: "Postal code",
                    ClimateRegion: "Climate region",
                    RegionCode: "Region code",
                    MinElevation: "Min elevation",
                    MaxElevation: "Max elevation",
                    MinElevationTemperature: "Min elevation temperature",
                    Elevation: "Elevation",
                    InsertElevationBetween: "Insert elevation between",
                    AnnualAverageTemperature: "Annual average temperature",
                    OutsideTemperature: "Outside temperature",
                    Search: "Search",
                    Material: "Material",
                    Category: "Category",
                    ThermalConductivity: "Thermal conductivity",
                    Edit: "Edit",
                    Delete: "Delete",
                    MaterialName: "Material name",
                    SpecificHeatCapacity: "Specific heat capacity",
                    Density: "Density",
                    CreateMaterial: "Create material",
                    SelectGeolocation: "Select Geolocation",
                    Choose: "Choose",
                    UsableSpace: "Usable space",
                    GeolocationName: "Geolocation name",
                    AnnualTemperature: "Annual temperature",
                    NoRecentProjects: "No recent projects",
                    EmptyProjectList: "Empty project list",
                    HeatedBuildingFloorSpace: "Heated building floor space",
                    HeatedNetBuildingVolume: "Heated net building volume",
                    AgreementOfTheAdditionalHeatingCapacity: "Agreement of the additional heating capacity",
                    AcceptanceOfTheInternalTemperatureDropAfterTheLoweringPhase: "Acceptance of the internal temperature drop after the lowering phase",
                    RoomByRoomCalculationOfTheInternalTemperatureDropAfterTheSinkingPhase: "Room by room calculation of the internal temperature drop after the sinking phase",
                    GlobalCalculationOfTheInternalTemperatureDrop: "Global calculation of the internal temperature drop",
                    TransmissionHeatLossCoefficient: "Transmission heat loss coefficient",
                    AverageInternalTemperatureOfTheBuilding: "Average internal temperature of the building",
                    EffectiveBuildingMass: "Effective building mass",
                    DurationAndAirChange: "Duration and air change",
                    LoweringPhase: "Lowering phase",
                    HeatingTime: "Heating time",
                    DurationOfThePhase: "Duration of the phase",
                    AirExchangeRate: "Air exchange rate",
                    TemperatureDrop: "Temperature drop",
                    SelectComponentType: "Select component type",
                    ComponentCode: "Component code",
                    ComponentName: "Component name",
                    CreateComponent: "Create component",
                    ThermalTransmittance: "Thermal transmittance",
                    CreateOpening: "Create opening",
                    SelectOpeningType: "Select opening type",
                    OpeningName: "Opening name",
                    OpeningCode: "Opening code",
                    SelectTemplate: "Select template",
                    ChooseTemplate: "Choose template",
                    ChooseTemplateToSeeComponents: "Choose template to see components",
                    NoComponentsCreated: "No components created",
                    Project: "Project",
                    Status: "Status",
                    DateCreated: "Date created",
                    LastEdited: "Last edited",
                    ComponentsWithLayeredStructure: "Components with layered structure",
                    ComponentsWithFixedValues: "Components with fixed values",
                    CreateComponentWithLayeredStructure: "Create component with layered structure",
                    CreateComponentWithFixedValues: "Create component with fixed values",
                    Opening: "Opening",
                    Openings: "Openings",
                    Building: "Building",
                    EditOpening: "Edit opening",
                    UpdateOpening: "Update opening",
                    EditComponent: "Edit component",
                    UpdateComponent: "Update component",
                    EditMaterial: "Edit material",
                    EditCompanyInformation: "Edit company information",
                    PhoneNumber: "Phone Number",
                    PlaceOfJurisdiction: "Place of jurisdiction",
                    RegisterNr: "Register nr",
                    CompanyName: "Company name",
                    CompanyInformation: "Company information",
                    StreetAddress: "Street address",
                    CityAndPostalCode: "City and postal code",
                    EmptyRoomList: "Empty room list",
                    RoomNo: "Room No",
                    Description: "Description",
                    Ti: "Ti",
                    phiT: "phiT",
                    phiV: "phiV",
                    phiNetto: "phiNetto",
                    phiRH: "phiRH",
                    phiHL: "phiHL",
                    phiNettoM2: "phiNetto/m²",
                    Temperature: "Temperature",
                    Area: "Area",
                    TransmissionLosses: "Transmission losses",
                    VentilationLosses: "Ventilation losses",
                    StandardHeatingLoad: "Standard heating load",
                    InterruptionHeatingLoss: "Interruption heating loss",
                    TotalHeatLoss: "Total heat loss",
                    LossesBySquareMeter: "Losses by square meter",
                    RseFull: "External surface resistance",
                    RsiFull: "Internal surface resistance",
                    BuFull: "Temperature reduction factor",
                    UFull: "Thermal transmittance",
                    CorrectedUFull: "Corrected thermal transmittance",
                    HTCFull: "Heat transfer coefficient",
                    FTFull: "Transmission heat loss",
                    BuildingInfo: "Building info",
                    Length: "Length",
                    Width: "Width",
                    Height: "Height",
                    Area: "Area",
                    Volume: "Volume",
                    ParameterB: "Parameter B'",
                    AreYouSureYouWantToDeleteThisRoom: "Are you sure you want to delete this room?",
                    FloorName: "Floor name",
                    FloorLevel: "Floor level",
                    SelectFloorLevel: "Select floor level",
                    Floor: "Floor",
                    AddFloor: "Add floor",
                    SaveFloor: "Save floor",
                    Code: "Code",
                    AddArea: "Add area",
                    SaveArea: "Save area",
                    Room: "Room",
                    HeatedUnheated: "Heated/Unheated",
                    AreYouSureYouWantToDeleteThisFloor: "Are you sure you want to delete this floor?",
                    AreYouSureYouWantToDeleteThisArea: "Are you sure you want to delete this area?",
                    BackToRooms: "Back to rooms",
                    AddRoomInformation: "Add room information",
                    Number: "Number",
                    CeilingThickness: "Ceiling thickness",
                    HeightPosition: "Height position",
                    Temperature: "Temperature",
                    U: "U",
                    CorrectedU: "Corrected U",
                    RoomHeatingOperationData: "Room heating interruption data",
                    AddRoom: "Add room",
                    Orientation: "Orientation",
                    Environment: "Environment",
                    WidthHeight: "Width/Height",
                    Areas: "Areas",
                    UsefulArea: "Useful Area",
                    Bu: "Bu",
                    HTC: "HTC",
                    FT: "FT",
                    AddOpening: "Add opening",
                    SpecialCase: "Special Case",
                    Note: "Note",
                    heightPopupDescription: "For the standard case, the heat losses are calculated under the assumption that a heated A room with a height of 5 m or less has a uniform temperature stratification. This assumption is no longer valid if the room height exceeds 5 m.",
                    heightPopupDescription2: "For buildings whose standard heat losses are below 60 W/m heated usable area, t1ct@c?L4manmay be located at",
                    heightPopupDescription3: "The entire standard heat loss can be corrected with a room height factor.",
                    correctionWithRoomHeightFactor: "Correction with room height factor",
                    heatingMethodAndTypeOrArrangement: "Heating method and type or arrangement of space heating surfaces",
                    warmFloor: "Warm floor",
                    warmCeiling: "Warm ceiling (temperature < 40 °C)",
                    mediumHighTemperatureDownwardRadiation: "Medium and high temperature downward radiation from high altitudes",
                    naturalWarmAironvection: "Natural warm air convection",
                    leakageFromLowAltitude: "Leakage from low altitude",
                    downwardFromTheGreatHigh: "Downward from the great high",
                    mediumHighTempLeakFromMedHeight: "Medium and high temperature leakage from medium height",
                    RoomHeightCorrectionFactor: "Room height correction factor",
                    saveChanges: "Save Changes",
                    roomHeatingInterruptionData: "Room heating interruption data",
                    ManualDataEntry: "manual data entry",
                    AddMaterial: "Add material",
                    PleaseSaveToContinue: "Please save to continue",
                    ComponentType: "Component type",
                    Rse: "Rse",
                    Rsi: "Rsi",
                    SeeMaterials: "See Materials",
                    Type: "Type",
                    AreYouSureYouWantToDeleteThisComponent: "Are you sure you want to delete this component?",
                    AreYouSureYouWantToDeleteThisMaterial: "Are you sure you want to delete this material?",
                    ChangeLanguage: "Change language",
                    English: "English",
                    German: "German",
                    Logout: "Logout",
                    InternalSurfaceResistance: "Internal surface resistance",
                    ExternalSurfaceResistance: "External surface resistance",
                    Thickness: "Thickness",
                    SelectMaterial: "Select material",
                    AreYouSureYouWantToDeleteThisMaterialFromComponent: "Are you sure you want to delete this material from component?",
                    SelectCategory: "Select category",
                    FilterBy: "Filter by",
                    Done: "Done",
                    InProgress: "In progress",
                    AreYouSureYouWantToDeleteThisProject: "Are you sure you want to delete this project?",
                    ShowAll: "Show all",
                    Next: "Next",
                    Previous: "Previous",
                    MaterialThickness: "Material thickness",
                    PrintFile: "Print file",
                    FileName: "File name",
                    FileType: "File type",
                    EnterFileName: "Enter file name",
                    SelectFileType: "Select file type",
                    ChooseInformationToDisplay: "Choose information to display",
                    SelectAll: "Select all",
                    RoomComposition: "Room composition",
                    StandardHeatingLoadOfTheBuilding: "Standard heating load of the building",
                    StandardHeatingLoadOfTheRooms: "Standard heating load of the rooms",
                    Components: "Components",
                    BuildingElements: "Building elements",
                    BuildingCharacteristics: "Building characteristics",
                    GeneralProjectData: "General project data",
                    UserhasNoLicense: "User has no license.",
                    NextTo: "Next to",
                    SelectEnvironmentType: "Select environment type",
                    TemperatureOfTheUnheatedRoom: "Temperature of the unheated room",
                    UnheatedRoomIsKnown: "Unheated room is known",
                    UnheatedRoomIsNotKnown: "Unheated room is not known",
                    TemperatureOfTheHeatedRoom: "Temperature of the heated room",
                    ListOfHeatedRooms: "List of heated rooms",
                    ListOfUnheatedRooms: "List of unheated rooms",
                    ListOfUnheatedSpaceProperties: "List of unheated space properties",
                    HeatedRoomIsKnown: "Heated room is known",
                    OnCooledGround: "More than 25% of the area of the heated space borders on a cooled ground",
                    AboveAdjacentLevel: "More than 25% of the earth-touched circumference of the earth-touched floors are above the adjacent level",
                    YouMustSelectARoomToContinue: "You must select a room to continue.",
                    YouMustSelectARoomTypeToContinue: "You must select a room type to continue.",
                    SaveToTemplate: "Save to template",
                    AMemberOfTheTemplates: "A member of the templates",
                    DeleteTheComponentFromATemplate: "Delete the component from a template",
                    SelectATemplateToAdd: "Select template to Add",
                    DeleteTemplate: "Delete template",
                    AddANewTemplate: "Add new template",
                    Upload: "Upload",
                    Templates: "Templates",
                    CurrentTemplates: "Current templates",
                    DeleteTheComponentFromTheTemplate: "Delete the component from the template",
                    SelectATemplateToAddTo: "Select a template to add to",
                    BackToTemplates: "Back to templates",
                    Close: "Close",
                    AddToTemplate: "Add to template",
                    AreYouSureYouWantToDeleteTemplate : "Are you sure you want to delete the template?",
                    AreYouSureYouWantToDeleteComponentFromCollection: "Are you sure you want to delete a component from the collection?",
                    AreYouSureYouWantToDeleteOpeningFromCollection: "Are you sure you want to delete an opening from the collection?",
                    ComponentTemplates: "Component templates",
                    OpeningTemplates: "Opening templates",
                    Jan: "Jan",
                    Feb: "Feb",
                    Mar: "Mar",
                    Apr: "Apr",
                    May: "May",
                    Jun: "Jun",
                    Jul: "Jul",
                    Aug: "Aug",
                    Sep: "Sep",
                    Oct: "Oct",
                    Nov: "Nov",
                    Dec: "Dec",

                    // ----------------------------Enums-------------------------------------

                    PLASTERS: "Plasters, screeds, mortars",
                    LARGEFORMATCOMPONENTS: "Large-format components",
                    CONSTRUCTION: "Construction",
                    MASONRY: "Masonry",
                    INSULATION: "Thermal insulation materials",
                    WOOD: "Wood, wood-based materials",
                    COVERINGS: "Coverings, seals",
                    OTHER: "Other building materials",
                    OUTERWALL: "Outer wall",
                    INNERWALL: "Inner wall",
                    FLOOR: "Base plate",
                    CEILING: "Ceiling",
                    ROOF: "Roof",
                    OUTERDOOR: "Outer door",
                    INNERDOOR: "Inner door",
                    INNERWINDOW: "Inner window",
                    OUTERWINDOW: "Outer window",
                    ROOFWINDOW: "Roof window",
                    FAMILYHOUSE: "Family house",
                    APARTMENTBUILDING: "Apartment building",
                    NONLIVINGBUILDING: "Non-living building",
                    LIGHT: "Light building mass",
                    MEDIUMMASS: "Medium-weight building mass",
                    HEAVY: "Heavy building mass",
                    HIGH: "High",
                    MEDIUM: "Medium",
                    LOW: "Low",
                    SAND_OR_GRAVEL: "Sand or gravel",
                    HOMOGENOUS_ROCK: "Homogenous rock",
                    CLAY_OR_SILT: "Clay or silt",
                    NONCOVERED: "Non-covered",
                    MIDCOVERED: "Mid-covered",
                    HIGHCOVERED: "Well-covered",
                    WITHOUT_MINIMIZING_HEAT_FLOW: "Without minimizing the heat flow in thermal bridges through thermal insulation (e.g. without thermal insulation, construction until 1990) The U-values of the outer components are determinded by a surcharge of 0.1 W/mK",
                    WITH_MINIMIZING_HEAT_FLOW: "With minimization of the heat flow in thermal bridges through thermal insulation according to ÖNORM B 8110-1 (construction after 1990) The U-values of the external components are determinded by a surcharge of 0.05 W/mK",
                    ROOMBYROOM: "ROOMBYROOM",
                    GLOBALLY: "GLOBALLY",
                    MANUALLY: "MANUALLY",
                    GROUNDFLOOR: "Groundfloor",
                    BASEMENT: "Basement",
                    UPPERFLOOR: "Upper floor",
                    HEATED: "Heated",
                    UNHEATED: "Unheated",
                    WARMFLOOR: "Warm floor",
                    WARMCEILING: "Warm ceiling (temperature < 40 °C)",
                    MEDIUMANDHIGHRADIATION: "Medium and high temoerature downward radioation from high altitudes",
                    NATURALAIRCONVECTION: "Natural warm air convection",
                    LEAKAGEFROMLOWALTITUDE: "Leakage from a low altitude",
                    DOWNWARDFROMAGREATHIGHT: "Downward from a great hight",
                    MEDIUMANDHIGHLEAKAGE: "Medium and high temperature leakage from medium height",
                    HEATEDROOM: "Heated room",
                    UNHEATEDROOM: "Unheated room",
                    GROUND: "Ground",
                    OUTSIDE: "Outside",
                    NEIGHBOURSPACE: "Neighbour room",
                    NEIGHBOURBUILDING: "Neighbour building",
                    FROSTFREE_SPACE: "Frost-free room",
                    SPACE_WITH_BUILDING_ENTRANCE_DOOR: "Room with extern openings",
                    SPACE_WITHOUT_BUILDING_ENTRANCE_DOOR: "Room without extern openings",
                    THREE_EXTERNAL_WALLS: "Staircase with 3 external walls",
                    UP_TO_TWO_EXTERNAL_WALLS: "Staircase with up to 2 external walls",
                    WITHOUT_EXTERNAL_WALLS: "Staircase without external walls",
                    OPEN_ROOF_SPACE: "Open roof space",
                    CLOSED_WITH_INSULATION: "Closed roof space with insulation",
                    CLOSED_RI_LESS_THAN_FIVE: "Closed roof space with Ri < 5(m²K)/W to the conditioned building area",
                    CLOSED_RI_GREATER_OR_EQUAL_THAN_FIVE: "Closed roof space with Ri ≥ 5(m²K)/W to the conditioned building area",
                    STANDARDCASE: "Standard",
                    COOLEDGROUND: "More than 25% of the area of the heated space borderes on a cooled ground",
                    ABOVEADJACENTLEVEL: "More than 25% of the earth-touched circumference of the earth-touched floors are above the adjacent level",

                    // ----------------------------Responses from Back-------------------------------------

                    "Project has been created successfully": "Project has been created successfully",
                    "Project has been updated successfully": "Project has been updated successfully",
                    "Project has been deleted successfully": "Project has been deleted successfully",
                    "Project has been closed successfully": "Project has been closed successfully",
                    "Building has been created successfully": "Building has been created successfully",
                    "Building has been updated successfully": "Building has been updated successfully",
                    "Building has been deleted successfully": "Building has been deleted successfully",
                    "Floor has been created successfully": "Floor has been created successfully",
                    "Floor has been updated successfully": "Floor has been updated successfully",
                    "Floor has been deleted successfully": "Floor has been deleted successfully",
                    "Area has been created successfully": "Area has been created successfully",
                    "Area has been updated successfully": "Area has been updated successfully",
                    "Area has been deleted successfully": "Area has been deleted successfully",
                    "Room has been created successfully": "Room has been created successfully",
                    "Room has been updated successfully": "Room has been updated successfully",
                    "Room has been deleted successfully": "Room has been deleted successfully",
                    "Heating interruption data has been updated successfully": "Heating interruption data has been updated successfully",
                    "Wall has been created successfully": "Wall has been created successfully",
                    "Wall has been updated successfully": "Wall has been updated successfully",
                    "Wall has been deleted successfully": "Wall has been deleted successfully",
                    "Opening in wall has been created successfully": "Opening in wall has been created successfully",
                    "Opening in wall has been updated successfully": "Opening in wall has been updated successfully",
                    "Opening in wall has been deleted successfully": "Opening in wall has been deleted successfully",
                    "Component has been created successfully": "Component has been created successfully",
                    "Component has been updated successfully": "Component has been updated successfully",
                    "Component has been deleted successfully": "Component has been deleted successfully",
                    "Material has been added to component": "Material has been added to component",
                    "Material has been removed": "Material has been removed",
                    "Opening has been created successfully": "Opening has been created successfully",
                    "Opening has been updated successfully": "Opening has been updated successfully",
                    "Opening has been deleted successfully": "Opening has been deleted successfully",
                    "Collection has been deleted successfully": "Collection has been deleted successfully",
                    "Component has been removed": "Component has been removed",
                    "Component has been updated": "Component has been updated",
                    "Component has been saved to the Collection": "Component has been saved to the Collection",
                    "Opening has been removed": "Opening has been removed",
                    "Opening has been updated": "Opening has been updated",
                    "Opening has been saved to the Collection": "Opening has been saved to the Collection",
                    "Calculation is done": "Calculation is done",
                    "Annual average temperature, outside temperature and elevetion cannot be empty": "Annual average temperature, outside temperature and elevetion cannot be empty",
                    "Annual average temperature, outside temperature and elevetion cannot be null": "Annual average temperature, outside temperature and elevetion cannot be null",
                    "Average temperature can be set only if you set heating type to globally": "Average temperature can be set only if you set heating type to globally",
                    "Building already exist": "Building already exist",
                    "Building cannot be created": "Building cannot be created",
                    "Building created successful": "Building created successful",
                    "Building deleted successful": "Building deleted successful",
                    "Building loaded successful": "Building loaded successful",
                    "Building not found": "Building not found",
                    "Building updated successful": "Building updated successful",
                    "Calculations cannot be done because building structure is empty": "Calculations cannot be done because building structure is empty",
                    "Calculations done successful": "Calculations done successful",
                    "Cannot add material to this type of component": "Cannot add material to this type of component",
                    "Cannot choose both, temperature and unheated space property": "Cannot choose both, temperature and unheated space property",
                    "Choose next space or temperature of heated space": "Choose next space or temperature of heated space",
                    "Company cannot be created": "Company cannot be created",
                    "Company created successful": "Company created successful",
                    "Company loaded successful": "Company loaded successful",
                    "Company not found": "Company not found",
                    "Component code cannot be null or white space": "Component code cannot be null or white space",
                    "Component code or component name cannot be null or white space": "Component code or component name cannot be null or white space",
                    "Component created successful": "Component created successful",
                    "Component deleted successful": "Component deleted successful",
                    "Component does not exist": "Component does not exist",
                    "Component loaded successful": "Component loaded successful",
                    "Component name cannot be null or white space": "Component name cannot be null or white space",
                    "Component not found": "Component not found",
                    "Component updated successful": "Component updated successful",
                    "Components loaded successful": "Components loaded successful",
                    "Create groundfloor first": "Create groundfloor first",
                    "Database error": "Database error",
                    "Dimensions cannot be equal or less than 0": "Dimensions cannot be equal or less than 0",
                    "Email or password is incorrect": "Email or password is incorrect",
                    "Enter space dimensions": "Enter space dimensions",
                    "Enter space dimensions. Enter length and width or space area": "Enter space dimensions. Enter length and width or space area",
                    "Entity not found": "Entity not found",
                    "Error": "Error",
                    "Floor created successful": "Floor created successful",
                    "Floor deleted successful": "Floor deleted successful",
                    "Floor height cannot be equal or less than 0": "Floor height cannot be equal or less than 0",
                    "Floor loaded successful": "Floor loaded successful",
                    "Floor name cannot be empty string or null": "Floor name cannot be empty string or null",
                    "Floor not found": "Floor not found",
                    "Floor updated successful": "Floor updated successful",
                    //"Floor with id: {floorId} does not exist..." : "Floor with id: {floorId} does not exist...",
                    "Floors loaded successful": "Floors loaded successful",
                    "Geolocation loaded successful": "Geolocation loaded successful",
                    "Geolocation not found": "Geolocation not found",
                    "Geolocation(s) load successful": "Geolocation(s) load successful",
                    "Geolocations loaded successful": "Geolocations loaded successful",
                    "Ground floor already exist, create upper floor or basement": "Ground floor already exist, create upper floor or basement",
                    "Groundfloor cannot be deleted. Delete all upper floors and basements first": "Groundfloor cannot be deleted. Delete all upper floors and basements first",
                    "Heat transfer coefficient can be set only if you set heating type to globally": "Heat transfer coefficient can be set only if you set heating type to globally",
                    "If choose heating interruption type to gloabal, you cannot choose temperature drop. It will be calculated": "If choose heating interruption type to gloabal, you cannot choose temperature drop. It will be calculated",
                    "If environemnt type is outside, you cannot pick bordering space": "If environemnt type is outside, you cannot pick bordering space",
                    "If environment type is ground, you have to select correction factor for the ground": "If environment type is ground, you have to select correction factor for the ground",
                    "If room height is greater than 5m, you have to choose space heating surface": "If room height is greater than 5m, you have to choose space heating surface",
                    "If set heating interruption type to manually, you must enter temperature drop": "If set heating interruption type to manually, you must enter temperature drop",
                    "Internal and external surface resistances cannot be less than 0": "Internal and external surface resistances cannot be less than 0",
                    "Invalid environment type": "Invalid environment type",
                    "Invalid opening Id": "Invalid opening Id",
                    "Length, width, height, space area and space volume cannot be equal or less than 0. Air exchange rate and n50 cannot be less than 0": "Length, width, height, space area and space volume cannot be equal or less than 0. Air exchange rate and n50 cannot be less than 0",
                    "List is empty": "List is empty",
                    "List loaded successful": "List loaded successful",
                    "Login successful": "Login successful",
                    "Material added successful": "Material added successful",
                    "Material created successful": "Material created successful",
                    "Material deleted successful": "Material deleted successful",
                    "Material loaded successful": "Material loaded successful",
                    "Material not found": "Material not found",
                    "Material removed successful": "Material removed successful",
                    "Material type cannot be null": "Material type cannot be null",
                    "Material updated successful": "Material updated successful",
                    "Material(s) loaded successful": "Material(s) loaded successful",
                    "Materials loaded successful": "Materials loaded successful",
                    "Not allowed. Choose different value from natural warm air convection": "Not allowed. Choose different value from natural warm air convection",
                    "Not allowed. Choose different value from warm ceiling": "Not allowed. Choose different value from warm ceiling",
                    "Opening created successful": "Opening created successful",
                    "Opening deleted successful": "Opening deleted successful",
                    "Opening in wall deleted successful": "Opening in wall deleted successful",
                    "Opening in wall loaded successful": "Opening in wall loaded successful",
                    "Opening in wall not found": "Opening in wall not found",
                    "Opening loaded successful": "Opening loaded successful",
                    "Opening name cannot be null or white space": "Opening name cannot be null or white space",
                    "Opening not found": "Opening not found",
                    "Opening type cannot be null or white space": "Opening type cannot be null or white space",
                    "Opening updated successful": "Opening updated successful",
                    "Opening(s) created successful": "Opening(s) created successful",
                    "Opening(s) updated successful": "Opening(s) updated successful",
                    "Openings in wall loaded successful": "Openings in wall loaded successful",
                    "Project created successful": "Project created successful",
                    "Project deleted successful": "Project deleted successful",
                    "Project loaded successful": "Project loaded successful",
                    "Project not found": "Project not found",
                    "Project status changed successful": "Project status changed successful",
                    "Project updated successful": "Project updated successful",
                    "Projects loaded successful": "Projects loaded successful",
                    "Report created successful": "Report created successful",
                    "Room height must be grater than 5m if you want to choose space heating surface": "Room height must be grater than 5m if you want to choose space heating surface",
                    "Something went wrong": "Something went wrong",
                    "Space created successful": "Space created successful",
                    "Space deleted successful": "Space deleted successful",
                    "Space dimensions cannot be equal or less than 0": "Space dimensions cannot be equal or less than 0",
                    "Space does not exist": "Space does not exist",
                    "Space heating interruption data updated successful": "Space heating interruption data updated successful",
                    "Space loaded successful": "Space loaded successful",
                    "Space name cannot be null or white space": "Space name cannot be null or white space",
                    "Space not found": "Space not found",
                    "Space number already exists": "Space number already exists",
                    "Space number cannot be greater than 999": "Space number cannot be greater than 999",
                    "Space number cannot be less than 1": "Space number cannot be less than 1",
                    "Space updated successful": "Space updated successful",
                    "SpaceId cannot be null": "SpaceId cannot be null",
                    "Spaces loaded successful": "Spaces loaded successful",
                    "Temperature drop cannot be less than 1 and greater than 7": "Temperature drop cannot be less than 1 and greater than 7",
                    "The total number of geolocaitons is: ": "The total number of geolocaitons is: ",
                    "Thermal transmittance cannot be equeal or less than zero": "Thermal transmittance cannot be equeal or less than zero",
                    "Thermal transmittance cannot be less or equal than zero": "Thermal transmittance cannot be less or equal than zero",
                    "Total thermal resistance cannot be equal or less than zero": "Total thermal resistance cannot be equal or less than zero",
                    "Wall not found": "Wall not found",
                    "Wall(s) created successful": "Wall(s) created successful",
                    "Wall(s) removed successful": "Wall(s) removed successful",
                    "Wall(s) updated successful": "Wall(s) updated successful",
                    "Walls loaded successful": "Walls loaded successful",
                    "Width and height cannot be equal or less than 0": "Width and height cannot be equal or less than 0",
                    "Wrong wallId": "Wrong wallId",
                    "You must choose space type, heated or unheated space": "You must choose space type, heated or unheated space",
                    "Zone code cannot be less than 1": "Zone code cannot be less than 1",
                    "Zone created successful": "Zone created successful",
                    "Zone deleted successful": "Zone deleted successful",
                    "Zone loaded successful": "Zone loaded successful",
                    "Zone name cannot be null or white space": "Zone name cannot be null or white space",
                    "Zone not found": "Zone not found",
                    "Zone number already exist": "Zone number already exist",
                    "Zone updated successful": "Zone updated successful",
                    "Project status changed": "Project status changed",
                    "Reheat time cannot be greater than 4 or less than 0.5": "Reheat time cannot be greater than 4 or less than 0.5",
                    //"Opening with id: {model.OpeningId} does not exist":"Opening with id: {model.OpeningId} does not exist",
                    //"Component with id: {componentId} not found":"Component with id: {componentId} not found",
                    "BuildingModelForCreate cannot be null": "BuildingModelForCreate cannot be null",
                    "If you have heating interruption, heatingInterruptionModel cannot be null": "If you have heating interruption, heatingInterruptionModel cannot be null",
                    "Building name cannot be white space or empty": "Building name cannot be white space or empty",
                    "Building coverage must be selected": "Building coverage must be selected",
                    "Building mass must be selected": "Building mass must be selected",
                    "Air tightness must be selected": "Air tightness must be selected",
                    "Building type must be selected": "Building type must be selected",
                    "Ground type must be selected": "Ground type must be selected",
                    "Cannot select thermal bridge and manually input thermal bridge value": "Cannot select thermal bridge and manually input thermal bridge value",
                    "You must select thermal bridge": "You must select thermal bridge",
                    //"Object with id: {zoneId} not found":"Object with id: {zoneId} not found",
                    //"Wall with pair id: {model.PairId} does not exist":"Wall with pair id: {model.PairId} does not exist",
                    //"Opening with Id:{openingId} does not exist":"Opening with Id:{openingId} does not exist",
                    //"Opening with id: {nextOpening.OpeningInWallId} does not exist":"Opening with id: {nextOpening.OpeningInWallId} does not exist",
                    "Internal, external surface resistances and thermal transmittance cannot be less than 0": "Internal, external surface resistances and thermal transmittance cannot be less than 0",
                    "Thermal transmittance (U value) cannot be less than zero": "Thermal transmittance (U value) cannot be less than zero",
                    					"Error in application" : "Error in application",
                    "Building created successfully" : "Building created successfully",
                    "Building updated successfully" : "Building updated successfully",
                    "Building deleted successfully" : "Building deleted successfully",
                    "Floor created successfully" : "Floor created successfully",
                    "Floor updated successfully" : "Floor updated successfully",
                    "Floor deleted successfully" : "Floor deleted successfully",
                    "Area created successfully" : "Area created successfully",
                    "Area updated successfully" : "Area updated successfully",
                    "Area deleted successfully" : "Area deleted successfully",
                    "Room created successfully" : "Room created successfully",
                    "Room updated successfully" : "Room updated successfully",
                    "Room deleted successfully" : "Room deleted successfully",
                    "Heating interruption data updated successfully" : "Heating interruption data updated successfully",
                    "Wall created successfully" : "Wall created successfully",
                    "Wall updated successfully" : "Wall updated successfully",
                    "Wall deleted successfully" : "Wall deleted successfully",
                    "Opening in wall created successfully" : "Opening in wall created successfully",
                    "Opening in wall updated successfully" : "Opening in wall updated successfully",
                    "Opening in wall deleted successfully" : "Opening in wall deleted successfully",
                    "Walls have been updated successfully" : "Walls have been updated successfully",
                    "Component created successfully" : "Component created successfully",
                    "Component updated successfully" : "Component updated successfully",
                    "Material added to component" : "Material added to component",
                    "The component material has been updated" : "The component material has been updated",
                    "Material removed" : "Material removed",
                    "Component deleted successfully" : "Component deleted successfully",
                    "Component created successfully" : "Component created successfully",
                    "Component updated successfully" : "Component updated successfully",
                    "Opening created successfully" : "Opening created successfully",
                    "Opening updated successfully" : "Opening updated successfully",
                    "Opening deleted successfully" : "Opening deleted successfully",
                    "Template deleted successfully" : "Template deleted successfully",
                    "Component removed" : "Component removed",
                    "Component updated" : "Component updated",
                    "Template added successfully" : "Template added successfully",
                    "Opening removed" : "Opening removed",
                    "Opening updated" : "Opening updated",
                    "Opening saved to the Collection" : "Opening saved to the Collection",
                    "Calculation is done" : "Calculation is done",
                    "Project created successfully" : "Project created successfully",
                    "Project updated successfully" : "Project updated successfully",
                    "Project deleted successfully" : "Project deleted successfully",
                    "Project closed successfully" : "Project closed successfully",
                    "Project information added successfully" : "Project information added successfully",
                    "Project information updated successfully" : "Project information updated successfully",
                    "Project information removed successfully" : "Project information removed successfully",
                    "Component added successfully to the template" : "Component added successfully to the template",
                    "Opening added successfully to the template" : "Opening added successfully to the template",
                    "Company Information is added successufully" : "Company Information is added successufully",
                    "Company Information is updated successufully" : "Company Information is updated successufully",
                    "Cannot create project with same project name or project code" : "Cannot create project with same project name or project code",
                    "Template added successfully" : "Template added successfully",
                },
            },
            de: {
                translation: {
                    hello: "Hallo",
                    German: "Deutsch",
                    Email: "E-Mail",
                    Password: "Passwort",
                    CreateAccount: "Konto erstellen",
                    ForgotYourPassword: "Passwort vergessen?",
                    RememberMe: "Anmeldung speichern",
                    Or: "Oder",
                    LogInWithMicrosoft: "Anmelden mit Microsoft",
                    NewToBimagine: "Neu bei Bimagine?",
                    TroubleLoggingIn: "Probleme beim Anmelden?",
                    InvalidEmail: "Bitte geben Sie eine gültige E-Mail Adresse ein",
                    InsertPassword: "Bitte geben Sie Ihr Passwort ein",
                    EnterYourEmail: "Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen ein Passwort zu, mit dem Sie sich wieder in Ihr Konto einloggen können.",
                    Send: "Senden",
                    ReturnTo: "Zurück zu",
                    LogIn: "Anmeldung",
                    Dashboard: "Dashboard",
                    Projects: "Projekte",
                    CreateBuilding: "Gebäude erstellen",
                    CreateComponents: "Komponenten erstellen",
                    Materials: "Materialien",
                    BuildingStructure: "Gebäudestruktur",
                    Settings: "Einstellungen",
                    Cancel: "Abbrechen",
                    Continue: "Fortfahren",
                    EditProject: "Projekt bearbeiten",
                    CreateANewProject: "Neues Projekt anlegen",
                    CreateANewMaterial: "Neues Material anlegen",
                    Close: "Schließen",
                    Save: "Speichern",
                    Create: "Erstellen",
                    SaveFile: "Datei speichern",
                    Calculate: "Berechnen",
                    Standard: "Norm",
                    ProjectCode: "Projektnummer",
                    ProjectName: "Projektname",
                    Address: "Adresse",
                    Country: "Land",
                    ZipCode: "Postleitzahl",
                    CreateProject: "Projekt anlegen",
                    CreateANewStakeholder: "Erstellen Sie einen neuen Stakeholder",
                    Stakeholders: "Stakeholder",
                    CustomerData: "Kundendaten",
                    StakeholderData: "Stakeholderdaten",
                    StakeholderName: "Name des Stakeholders",
                    OrganizationName: "Organisationsname",
                    Department: "Abteilung",
                    Country: "Land",
                    PostalCode: "Postleitzahl",
                    City: "Stadt",
                    Street: "Straße",
                    StreetNumber: "Straßennummer",
                    PhoneNumber1: "Telefonnummer 1",
                    PhoneNumber2: "Telefonnummer 2",
                    Fax: "Fax",
                    Email1: "E-Mail 1",
                    Email2: "E-Mail 2",
                    WebSite: "Website",
                    AreYouSureYouWantToDeleteThisGroup: "Sind Sie sicher dass Sie diese Interessengruppen löschen möchten?",
                    AreYouSureYouWantToDeleteThisWall: "Sind Sie sicher dass Sie diese Wand löschen möchten?",
                    AreYouSureYouWantToDeleteThisOpening: "Sind Sie sicher dass Sie diese Öffnung löschen möchten?",
                    Geometry: "Geometrie",
                    Property: "Immobilie",
                    ThermalBridge: "Wärmebrücke",
                    Geolocation: "Geolokalisierung",
                    HeatingInterruption: "Heizungsunterbrechung",
                    Optional: "Optional",
                    ToCreateABuildingYouMustFillInAllTheFields: "Um ein Gebäude zu erstellen, müssen Sie alle Felder ausfüllen.",
                    ToCreateAMaterialYouMustFillInAllTheFields: "Um ein Material zu erstellen, müssen Sie alle Felder ausfüllen.",
                    SaveChanges: "Änderungen speichern",
                    TypeABuildingName: "Gebäudenamen eingeben",
                    BuildingName: "Gebäudename",
                    BottomPlateThickness: "Dicke der Bodenplatte",
                    ParameterZ: "Parameter Z",
                    BuildingArea: "Fläche des Gebäudes",
                    BuildingPerimeter: "Gebäudeumfang",
                    GroundWaterDepth: "Grundwasserspiegel",
                    ValueOfGroundWaterDepthIsKnown: "Wert des Grundwasserspiegels ist bekannt",
                    Login: "Anmeldung",
                    BuildingType: "Gebäudetyp",
                    BuildingMass: "Gebäudemasse",
                    BuildingAirTightness: "Luftdichtheit des Gebäudes",
                    GroundType: "Erdreichtype",
                    BuildingCoverage: "Abschirmungsklasse",
                    SelectBuildingType: "Gebäudetyp auswählen",
                    SelectBuildingMass: "Gebäudemasse auswählen",
                    SelectBuildingAirTightness: "Luftdichtheit des Gebäudes auswählen",
                    SelectGroundType: "Erdreichtype auswählen",
                    SelectBuildingCoverage: "Abschirmungsklasse auswählen",
                    ThermalBridge001: "ohne Minimierung des Wärmestroms bei Wärmebrücken durch Wärmedämmung (zB ohne Wärmedämmung, Bauweise bis 1990) [0,10]",
                    ThermalBridge005: "mit Minimierung des Wärmestroms bei Wärmebrücken durch Wärmedämmung gemäß ÖNORM B 8110-1 (Bauweise nach 1990) [0,05]",
                    Name: "Name",
                    PostalCode: "Postleitzahl",
                    ClimateRegion: "Klimaregion",
                    RegionCode: "Postleitzahl",
                    MinElevation: "Min. Höhe",
                    MaxElevation: "Maximale Höhe",
                    MinElevationTemperature: "Min. Höhentemperatur",
                    Elevation: "Höhe",
                    InsertElevationBetween: "Fügen Sie die Höhe ein",
                    AnnualAverageTemperature: "Durchschnittliche Jahrestemperatur",
                    OutsideTemperature: "Außentemperatur",
                    Search: "Suchen",
                    Material: "Material",
                    Category: "Kategorie",
                    ThermalConductivity: "Wärmeleitfähigkeit",
                    Edit: "Bearbeiten",
                    Delete: "Löschen",
                    MaterialName: "Materialbezeichnung",
                    SpecificHeatCapacity: "Spezifische Wärmekapazität",
                    Density: "Dichte",
                    CreateMaterial: "Material erstellen",
                    SelectGeolocation: "Geolokalisierung auswählen",
                    Choose: "Wählen",
                    UsableSpace: "Nutzbarer Raum",
                    GeolocationName: "Name des Geostandorts",
                    AnnualTemperature: "Jahrestemperatur",
                    NoRecentProjects: "Keine aktuellen Projekte",
                    EmptyProjectList: "leere Projektliste",
                    HeatedBuildingFloorSpace: "Beheizte Gebäudegrundfläche",
                    HeatedNetBuildingVolume: "Beheiztes Netto-Gebäudevolumen",
                    AgreementOfTheAdditionalHeatingCapacity: "Vereinbarung der zusätzlichen Heizleistung",
                    AcceptanceOfTheInternalTemperatureDropAfterTheLoweringPhase: "Akzeptanz des internen Temperaturabfalls nach der Absenkphase",
                    RoomByRoomCalculationOfTheInternalTemperatureDropAfterTheSinkingPhase: "Raumweise Berechnung des internen Temperaturabfalls nach der Absenkungsphase",
                    GlobalCalculationOfTheInternalTemperatureDrop: "Globale Berechnung des internen Temperaturabfalls",
                    TransmissionHeatLossCoefficient: "Transmissionswärmeverlust-Koeffizient",
                    AverageInternalTemperatureOfTheBuilding: "Durchschnittliche Innentemperatur des Gebäudes",
                    EffectiveBuildingMass: "Effektive Gebäudemasse",
                    DurationAndAirChange: "Dauer und Luftaustausch",
                    LoweringPhase: "Absenkungsphase",
                    HeatingTime: "Aufheizzeit",
                    DurationOfThePhase: "Dauer der Phase",
                    AirExchangeRate: "Luftaustauschrate",
                    TemperatureDrop: "Temperaturabfall",
                    SelectComponentType: "Bauteiltyp auswählen",
                    ComponentCode: "Bauteil-Code",
                    ComponentName: "Bezeichnung der Komponente",
                    CreateComponent: "Komponente erstellen",
                    ThermalTransmittance: "Wärmeübertragung",
                    CreateOpening: "Öffnung erstellen",
                    SelectOpeningType: "Öffnungsart wählen",
                    OpeningName: "Name der Öffnung",
                    OpeningCode: "Öffnungscode",
                    SelectTemplate: "Markieren Sie die Vorlage",
                    ChooseTemplate: "Wählen Sie die Vorlage aus",
                    ChooseTemplateToSeeComponents: "Wählen Sie die Vorlage aus, um die Komponenten anzuzeigen",
                    NoComponentsCreated: "Keine Komponenten wurden erstellt",
                    Project: "Projekt",
                    Status: "Status",
                    DateCreated: "Datum der Erstellung",
                    LastEdited: "Letzte Bearbeitung",
                    ComponentsWithLayeredStructure: "Komponenten mit Schichtaufbau",
                    ComponentsWithFixedValues: "Komponenten mit Festwert",
                    CreateComponentWithLayeredStructure: "Komponenten mit Schichtaufbau erstellen",
                    CreateComponentWithFixedValues: "Komponente mit Festwert erstellen",
                    Opening: "Öffnung",
                    Openings: "Öffnungen",
                    Building: "Gebäude",
                    EditOpening: "Öffnung bearbeiten",
                    UpdateOpening: "Öffnung aktualisieren",
                    EditComponent: "Komponente bearbeiten",
                    UpdateComponent: "Komponente aktualisieren",
                    EditMaterial: "Material bearbeiten",
                    EditCompanyInformation: "Firmeninformationen bearbeiten",
                    PhoneNumber: "Telefonnummer",
                    PlaceOfJurisdiction: "Gerichtsstand",
                    RegisterNr: "Handelsregisternr.",
                    CompanyName: "Firmenname",
                    CompanyInformation: "Firmeninformationen",
                    StreetAddress: "Straße und Hausnummer",
                    CityAndPostalCode: "Stadt und Postleitzahl",
                    EmptyRoomList: "leere Raumliste",
                    RoomNo: "Raum Nr.",
                    Description: "Bezeichnung",
                    Ti: "Ti",
                    phiT: "phiT",
                    phiV: "phiV",
                    phiNetto: "phiNetto",
                    phiRH: "phiRH",
                    phiHL: "phiHL",
                    phiNettoM2: "phiNetto/m²",
                    Temperature: "Temperatur",
                    Area: "Fläche",
                    TransmissionLosses: "Übertragungsverluste",
                    VentilationLosses: "Lüftungsverluste",
                    StandardHeatingLoad: "Standard-Heizlast",
                    InterruptionHeatingLoss: "Unterbrechungsheizverlust",
                    TotalHeatLoss: "Gesamt-Wärmeverlust",
                    LossesBySquareMeter: "Verluste pro Quadratmeter",
                    RseFull: "Externer Oberflächenwiderstand",
                    RsiFull: "Interner Oberflächenwiderstand",
                    BuFull: "Temperaturminderungsfaktor",
                    UFull: "Wärmedurchgangskoeffizient",
                    CorrectedUFull: "korrigierter Wärmedurchgangskoeffizient",
                    HTCFull: "Wärmeübertragungskoeffizient",
                    FTFull: "Übertragungs-Wärmeverlust",
                    BuildingInfo: "Gebäudeinformationen",
                    Length: "Länge",
                    Width: "Breite",
                    Height: "Höhe",
                    Area: "Fläche",
                    Volume: "Volumen",
                    ParameterB: "Parameter B",
                    AreYouSureYouWantToDeleteThisRoom: "Sind Sie sicher dass Sie diesen Raum löschen möchten?",
                    FloorName: "Etagenbezeichnung",
                    FloorLevel: "Etagenebene",
                    SelectFloorLevel: "Etage auswählen",
                    Floor: "Etage",
                    AddFloor: "Etage hinzufügen",
                    SaveFloor: "Etage speichern",
                    Code: "Code",
                    AddArea: "Fläche hinzufügen",
                    SaveArea: "Fläche speichern",
                    Room: "Raum",
                    HeatedUnheated: "Beheizt/Unbeheizt",
                    AreYouSureYouWantToDeleteThisFloor: "Sind Sie sicher dass Sie diese Etage löschen möchten?",
                    AreYouSureYouWantToDeleteThisArea: "Sind Sie sicher, dass Sie diese Fläche löschen möchten?",
                    BackToRooms: "Zurück zu den Räumen",
                    AddRoomInformation: "Rauminformationen hinzufügen",
                    Number: "Nummer",
                    CeilingThickness: "Deckenstärke",
                    HeightPosition: "Höhenposition",
                    Temperature: "Temperatur",
                    U: "U",
                    CorrectedU: "Korrigiert U",
                    RoomHeatingOperationData: "Daten zur Unterbrechung der Raumheizung",
                    AddRoom: "Raum hinzufügen",
                    Orientation: "Ausrichtung",
                    Environment: "Umgebung",
                    WidthHeight: "Breite/Höhe",
                    Areas: "Bereiche",
                    UsefulArea: "Nutzfläche",
                    Bu: "Bu",
                    HTC: "HTC",
                    FT: "FT",
                    AddOpening: "Öffnung hinzufügen",
                    SpecialCase: "Sonderfall",
                    Note: "Hinweis",
                    heightPopupDescription: "Für den Standardfall werden die Wärmeverluste unter der Annahme berechnet, dass ein beheizter A-Raum mit einer Höhe von 5 m oder weniger eine gleichmäßige Temperaturschichtung aufweist. Diese Annahme ist nicht mehr gültig, wenn die Raumhöhe mehr als 5 m beträgt",
                    heightPopupDescription2: "Für Gebäude, deren Norm-Wärmeverluste unter 60 W/m² beheizter Nutzfläche liegen, darf bei hohen Räumen der gesamte Norm-Wärmeverlust mit einem Raumhöhenfaktor korrigiert werden.",
                    heightPopupDescription3: "Der gesamte Standardwärmeverlust kann mit einem Raumhöhenfaktor korrigiert werden.",
                    correctionWithRoomHeightFactor: "Korrektur mit Raumhöhenfaktor",
                    heatingMethodAndTypeOrArrangement: "Heizmethode und Art bzw. Anordnung von Raumheizflächen",
                    warmFloor: "warmer Fußboden",
                    warmCeiling: "Warme Decke (Temperatur < 40 °C)",
                    mediumHighTemperatureDownwardRadiation: "abwärts gerichtete Strahlung mittlerer und hoher Temperatur aus großer Höhe",
                    naturalWarmAironvection: "natürliche Warmluftkonvektion",
                    leakageFromLowAltitude: "Austritt aus geringer Höhe",
                    downwardFromTheGreatHigh: "Aus großer Höhe abwärts",
                    mediumHighTempLeakFromMedHeight: "Austritt bei mittleren und hohen Temperaturen aus mittlerer Höhe",
                    RoomHeightCorrectionFactor: "Korrekturfaktor der Raumhöhe",
                    saveChanges: "Änderungen speichern",
                    roomHeatingInterruptionData: "Daten zur Unterbrechung der Raumheizung",
                    ManualDataEntry: "Manuelle Datenerfassung",
                    AddMaterial: "Material hinzufügen",
                    PleaseSaveToContinue: "Speichern Sie bitte, um fortzufahren",
                    ComponentType: "Komponententyp",
                    Rse: "Rse",
                    Rsi: "Rsi",
                    SeeMaterials: "siehe Materialien",
                    Type: "Typ",
                    AreYouSureYouWantToDeleteThisComponent: "Sind Sie sicher, dass Sie diese Komponente löschen möchten?",
                    AreYouSureYouWantToDeleteThisMaterial: "Sind Sie sicher, dass Sie dieses Material löschen möchten?",
                    ChangeLanguage: "Sprache ändern",
                    English: "Englisch",
                    Logout: "Abmelden",
                    InternalSurfaceResistance: "Innerer Oberflächenwiderstand",
                    ExternalSurfaceResistance: "Äußerer Oberflächenwiderstand",
                    Thickness: "Stärke",
                    SelectMaterial: "Material auswählen",
                    AreYouSureYouWantToDeleteThisMaterialFromComponent: "Sind Sie sicher, dass Sie dieses Material aus der Komponente löschen möchten?",
                    SelectCategory: "Kategorie auswählen",
                    FilterBy: "Filtern nach",
                    Done: "Fertig",
                    DeleteTemplate: "Vorlage löschen",
                    InProgress: "In Bearbeitung",
                    AreYouSureYouWantToDeleteThisProject: "Sind Sie sicher, dass Sie dieses Projekt löschen möchten?",
                    ShowAll: "Alle anzeigen",
                    Next: "Nächste",
                    Previous: "Vorherige",
                    MaterialThickness: "Materialstärke",
                    PrintFile: "Datei drucken",
                    FileName: "Dateiname",
                    FileType: "Dateityp",
                    EnterFileName: "Geben Sie den Dateinamen ein",
                    SelectFileType: "Wählen Sie den Dateityp aus",
                    ChooseInformationToDisplay: "Anzuzeigende Informationen auswählen",
                    SelectAll: "Alle auswählen",
                    RoomComposition: "Zusammensetzung der Räume",
                    StandardHeatingLoadOfTheBuilding: "Standard-Heizlast des Gebäudes",
                    StandardHeatingLoadOfTheRooms: "Standard-Heizlast der Räume",
                    Components: "Komponenten",
                    BuildingElements: "Bauelemente",
                    BuildingCharacteristics: "Gebäudeeigenschaften",
                    GeneralProjectData: "allgemeine Projektdaten",
                    UserhasNoLicense: "Der Benutzer hat keine Lizenz",
                    NextTo: "Neben",
                    SelectEnvironmentType: "Umgebungstyp auswählen",
                    TemperatureOfTheUnheatedRoom: "Temperatur des unbeheizten Raums",
                    UnheatedRoomIsKnown: "Unbeheizter Raum ist bekannt",
                    UnheatedRoomIsNotKnown: "Unbeheizter Raum ist nicht bekannt",
                    TemperatureOfTheHeatedRoom: "Temperatur des beheizten Raums",
                    ListOfHeatedRooms: "Liste der beheizten Räume",
                    ListOfUnheatedRooms: "Liste der unbeheizten Räume",
                    ListOfUnheatedSpaceProperties: "Liste unbeheizter Räume",
                    HeatedRoomIsKnown: "Beheizter Raum ist bekannt",
                    OnCooledGround: "Mehr als 25 % des erdberührten Umfangs des beheizten Raumes grenzen an ein gekühltes Erdreich",
                    AboveAdjacentLevel: "Mehr als 25 % des erdberührten Umfangs des erdberührten Fußbodens sind über dem angrenzenden Niveau.",
                    YouMustSelectARoomToContinue: "Sie müssen einen Raum auswählen, um fortzufahren.",
                    YouMustSelectARoomTypeToContinue: "Sie müssen einen Raumtyp auswählen, um fortzufahren.",
                    SaveToTemplate: "Als Vorlage speichern",
                    AMemberOfTheTemplates: "Ein Mitglied der Vorlagen",
                    DeleteTheComponentFromATemplate: "Komponente aus einer Vorlage löschen",
                    SelectATemplateToAdd: "Vorlage zum Hinzufügen auswählen",
                    DeleteTemplates: "Vorlage löschen",
                    AddANewTemplate: "Neue Vorlage hinzufügen",
                    Upload: "Hochladen",
                    Templates: "Vorlagen",
                    CurrentTemplates: "Aktuelle Vorlagen",
                    DeleteTheComponentFromTheTemplate: "Komponente aus der Vorlage löschen",
                    SelectATemplateToAddTo: "Vorlage zum Hinzufügen auswählen",
                    BackToTemplates: "Zurück zu Vorlagen",
                    Close: "Schließen",
                    AddToTemplate: "Zur Vorlage hinzufügen",
                    AreYouSureYouWantToDeleteTemplate : "Sind Sie sicher, dass Sie die Vorlage löschen möchten?",
                    AreYouSureYouWantToDeleteComponentFromCollection: "Sind Sie sicher, dass Sie ein Element aus der Sammlung löschen möchten?",
                    AreYouSureYouWantToDeleteOpeningFromCollection: "Sind Sie sicher, dass Sie eine Öffnung aus der Sammlung löschen möchten?",
                    Jan: "Jan",
                    Feb: "Feb",
                    Mar: "Mär",
                    Apr: "Apr",
                    May: "Mai",
                    Jun: "Jun",
                    Jul: "Jul",
                    Aug: "Aug",
                    Set: "Sep",
                    Oct: "Okt",
                    Nov: "Nov",
                    Dec: "Dez",

                    // ----------------------------Enums-------------------------------------
                    PLASTERS: "Putze, Estriche, Mörtel",
                    LARGEFORMATCOMPONENTS: "Großformatige Bauteile",
                    CONSTRUCTION: "Bauplatten",
                    MASONRY: "Mauerwerk",
                    INSULATION: "Wärmedämmstoffe",
                    WOOD: "Holz, Holzwerkstoffe",
                    COVERINGS: "Beläge, Abdichtungen",
                    OTHER: "Sonstige Baustoffe",
                    OUTERWALL: "Außenwand",
                    INNERWALL: "Innenwand",
                    FLOOR: "Fußboden",
                    CEILING: "Decke",
                    ROOF: "Dach",
                    OUTERDOOR: "Außentür",
                    INNERDOOR: "Innentür",
                    INNERWINDOW: "Innenfenster",
                    OUTERWINDOW: "Außenfenster",
                    ROOFWINDOW: "Dachfenster",
                    FAMILYHOUSE: "Einfamilienhaus ",
                    APARTMENTBUILDING: "Mehrfamilienhaus",
                    NONLIVINGBUILDING: "Nicht-Wohngebäude",
                    LIGHT: "leichte Gebäudemasse",
                    MEDIUMMASS: "mittelschwere Gebäudemasse",
                    HEAVY: "schwere Gebäudemasse",
                    HIGH: "sehr dicht",
                    MEDIUM: "dicht",
                    LOW: "weniger dicht",
                    SAND_OR_GRAVEL: "Sand ored Kies",
                    HOMOGENOUS_ROCK: "homogener Felsen",
                    CLAY_OR_SILT: "Ton oder Schluff",
                    NONCOVERED: "keine Abschirmung",
                    MIDCOVERED: "moderate Abschirmung",
                    HIGHCOVERED: "gute Abschirmung",
                    WITHOUT_MINIMIZING_HEAT_FLOW: "ohne Minimierung des Wärmestroms bei Wärmebrücken durch Wärmedämmung (z.B. ohne Wärmedämmung. Bauweise bis 1990) (Die U-Werte der Außenbauteile werden durch einen Zuschlag von 0,1 W/m2K korrigiert - die Engabe der Wärmebrücken ist nicht erforderlich)",
                    WITH_MINIMIZING_HEAT_FLOW: "mit Minimierung des Wärmestroms bei Wärmebrücken durch Wärmedämmung gemäß ONORM B 8110-1 (Bauweise nach 1990) (Die U-Werte der Außenbauteile werden durch einen Zuschlag von 0.05 W/m2K korrigiert - die Engabe der Wärmebrücken ist nicht erforderlich)",
                    ROOMBYROOM: "raumweise Berechnung des Innentemperaturabfalls nach der Absenkphase",
                    GLOBALLY: "globale Berechnung des Innentemperaturabfalls nach der Absenkphase",
                    MANUALLY: "Annahme (manuelle Auswahl) des Innentemperaturabfalls nach der Absenkphase",
                    GROUNDFLOOR: "Erdgeschoss",
                    BASEMENT: "Keller",
                    UPPERFLOOR: "Obergeschoss",
                    HEATED: "beheizter",
                    UNHEATED: "unbeheizter",
                    WARMFLOOR: "warme Fußboden",
                    WARMCEILING: "warme Decke (Temperaturniveau < 40°C)",
                    MEDIUMANDHIGHRADIATION: "abwärts Gerichtete Strahlung mittlerer und hoher Temperatur aus großer Höhe",
                    NATURALAIRCONVECTION: "natürliche Warmluftkonvektion",
                    LEAKAGEFROMLOWALTITUDE: "Querstrom aus niedriger Höhe",
                    DOWNWARDFROMAGREATHIGHT: "abwärts gerichtet aus großer Höhe",
                    MEDIUMANDHIGHLEAKAGE: "Querstrom mittlerer und hoher Temperatur aus mittlerer Höhe",
                    HEATEDROOM: "beheizten Raum",
                    UNHEATEDROOM: "unbeheizten Raum",
                    GROUND: "grenzt an Erdreich",
                    OUTSIDE: "grenzt an Außenluft",
                    NEIGHBOURSPACE: "Raum einer anderen Wohneinheit",
                    NEIGHBOURBUILDING: "Raum eines separaten Gebäudes",
                    FROSTFREE_SPACE: "frostfrei zu haltende Räume",
                    SPACE_WITH_BUILDING_ENTRANCE_DOOR: "mit Gebäude.Eingangstüren",
                    SPACE_WITHOUT_BUILDING_ENTRANCE_DOOR: "ohne Gebäude.Eingangstüren",
                    THREE_EXTERNAL_WALLS: "angebaut (3 Außenwände)",
                    UP_TO_TWO_EXTERNAL_WALLS: "eingebaut (bis zu 2 Außenwände)",
                    WITHOUT_EXTERNAL_WALLS: "eingebaut (ohne Außenwänd)",
                    OPEN_ROOF_SPACE: "offen",
                    CLOSED_WITH_INSULATION: "geschlossen; mit Wärmedämmung nach außen",
                    CLOSED_RI_LESS_THAN_FIVE: "geschlossen; Ri<5(m2K)/W zum kond. Raum",
                    CLOSED_RI_GREATER_OR_EQUAL_THAN_FIVE: "geschlossen; Ri>=5(m2K)/W zum kond. Raum",
                    STANDARDCASE: "Standardgehäuse",
                    COOLEDGROUND: "Mehr als 25 % der Fläche des beheizten Raumes grenzt an einen gekühlten Erdreich",
                    ABOVEADJACENTLEVEL: "Mehr als 25 % des erdberührten Umfangs der erdberührten Fußbodens liegen über dem angrenzenden Niveau.",

                    // ----------------------------Responses from Back-------------------------------------

                    "Project has been created successfully": "Das Projekt wurde erfolgreich erstellt",
                    "Project has been updated successfully": "Das Projekt wurde erfolgreich aktualisiert",
                    "Project has been deleted successfully": "Das Projekt wurde erfolgreich gelöscht",
                    "Project has been closed successfully": "Das Projekt wurde erfolgreich geschlossen",
                    "Building has been created successfully": "Das Gebäude wurde erfolgreich erstellt",
                    "Building has been updated successfully": "Das Gebäude wurde erfolgreich aktualisiert",
                    "Building has been deleted successfully": "Das Gebäude wurde erfolgreich gelöscht",
                    "Floor has been created successfully": "Die Etage wurde erfolgreich erstellt",
                    "Floor has been updated successfully": "Die Etage wurde erfolgreich aktualisiert",
                    "Floor has been deleted successfully": "Die Etage wurde erfolgreich gelöscht",
                    "Area has been created successfully": "Der Bereich wurde erfolgreich erstellt",
                    "Area has been updated successfully": "Der Bereich wurde erfolgreich aktualisiert",
                    "Area has been deleted successfully": "Der Bereich wurde erfolgreich gelöscht",
                    "Room has been created successfully": "Der Raum wurde erfolgreich erstellt",
                    "Room has been updated successfully": "Der Raum wurde erfolgreich aktualisiert",
                    "Room has been deleted successfully": "Der Raum wurde erfolgreich gelöscht",
                    "Heating interruption data has been updated successfully": "Die Unterbrechungsdaten für die Heizung wurden erfolgreich aktualisiert",
                    "Wall has been created successfully": "Die Wand wurde erfolgreich erstellt",
                    "Wall has been updated successfully": "Die Wand wurde erfolgreich aktualisiert",
                    "Wall has been deleted successfully": "Die Wand wurde erfolgreich gelöscht",
                    "Opening in wall has been created successfully": "Die Öffnung in der Wand wurde erfolgreich erstellt",
                    "Opening in wall has been updated successfully": "Die Öffnung in der Wand wurde erfolgreich aktualisiert",
                    "Opening in wall has been deleted successfully": "Die Öffnung in der Wand wurde erfolgreich gelöscht",
                    "Component has been created successfully": "Die Komponente wurde erfolgreich erstellt",
                    "Component has been updated successfully": "Die Komponente wurde erfolgreich aktualisiert",
                    "Component has been deleted successfully": "Die Komponente wurde erfolgreich gelöscht",
                    "Material has been added to component": "Material wurde zur Komponente hinzugefügt",
                    "Material has been removed": "Material wurde entfernt",
                    "Opening has been created successfully": "Die Öffnung wurde erfolgreich erstellt",
                    "Opening has been updated successfully": "Die Öffnung wurde erfolgreich aktualisiert",
                    "Opening has been deleted successfully": "Die Öffnung wurde erfolgreich gelöscht",
                    "Collection has been deleted successfully": "Die Sammlung wurde erfolgreich gelöscht",
                    "Component has been removed": "Die Komponente wurde entfernt",
                    "Component has been updated": "Die Komponente wurde aktualisiert",
                    "Component has been saved to the Collection": "Die Komponente wurde zur Sammlung gespeichert",
                    "Opening has been removed": "Die Öffnung wurde entfernt",
                    "Opening has been updated": "Die Öffnung wurde aktualisiert",
                    "Opening has been saved to the Collection": "Die Öffnung wurde zur Sammlung gespeichert",
                    "Calculation is done": "Die Berechnung ist abgeschlossen",
                    "Annual average temperature, outside temperature and elevetion cannot be empty": "Jahresdurchschnittstemperatur, Außentemperatur und Höhe können nicht leer sein",
                    "Annual average temperature, outside temperature and elevetion cannot be null": "Jahresdurchschnittstemperatur, Außentemperatur und Höhe können nicht null sein",
                    "Average temperature can be set only if you set heating type to globally": `Die durchschnittliche Temperatur kann nur eingestellt werden, wenn Sie die Heizungsart auf "global" einstellen.`,
                    "Building already exist": "Gebäude bereits vorhanden",
                    "Building cannot be created": "Gebäude kann nicht erstellt werden",
                    "Building created successful": "Gebäude erfolgreich erstellt",
                    "Building deleted successful": "Gebäude erfolgreich gelöscht",
                    "Building loaded successful": "Gebäude erfolgreich geladen",
                    "Building not found": "Gebäude nicht gefunden",
                    "Building updated successful": "Gebäude erfolgreich aktualisiert ",
                    "Calculations cannot be done because building structure is empty": "Es können keine Berechnungen durchgeführt werden, da die Gebäudestruktur leer ist",
                    "Calculations done successful": "Berechnungen erfolgreich durchgeführt",
                    "Cannot add material to this type of component": "Zu dieser Art von Komponente kann kein Material hinzugefügt werden",
                    "Cannot choose both, temperature and unheated space property": "Temperatur und unbeheizter Raum können nicht gleichzeitig ausgewählt werden",
                    "Choose next space or temperature of heated space": "Wählen Sie den nächsten Raum aus oder die Temperatur des beheizten Raums",
                    "Company cannot be created": "Firma kann nicht erstellt werden",
                    "Company created successful": "Firma erfolgreich erstellt",
                    "Company loaded successful": "Firma erfolgreich geladen",
                    "Company not found": "Firma nicht gefunden",
                    "Component code cannot be null or white space": "Der Komponentencode kann weder null noch ein Leerzeichen sein",
                    "Component code or component name cannot be null or white space": "Der Komponentencode oder Komponentenname kann weder null noch ein Leerzeichen sein",
                    "Component created successful": "Komponente erfolgreich erstellt",
                    "Component deleted successful": "Komponente erfolgreich gelöscht",
                    "Component does not exist": "Komponente existiert nicht",
                    "Component loaded successful": "Komponente erfolgreich geladen",
                    "Component name cannot be null or white space": "Der Komponentenname kann weder null noch ein Leerzeichen sein",
                    "Component not found": "Komponente nicht gefunden",
                    "Component updated successful": "Komponente erfolgreich aktualisiert",
                    "Components loaded successful": "Komponenten erfolgreich geladen",
                    "Create groundfloor first": "Erstellen Sie zuerst das Erdgeschoss",
                    "Database error": "Datenbankfehler",
                    "Dimensions cannot be equal or less than 0": "Die Abmessungen können nicht gleich oder kleiner als 0 sein",
                    "Email or password is incorrect": "E-Mail oder Passwort ist falsch",
                    "Enter space dimensions": "Geben Sie die Raummaße ein",
                    "Enter space dimensions. Enter length and width or space area": "Geben Sie die Raummaße ein. Geben Sie Länge und Breite bzw. die Raumfläche ein",
                    "Entity not found": "Einheit nicht gefunden",
                    "Error": "Fehler",
                    "Floor created successful": "Etage erfolgreich erstellt",
                    "Floor deleted successful": "Etage erfolgreich gelöscht",
                    "Floor height cannot be equal or less than 0": "Etage kann nicht gleich oder kleiner als 0 sein",
                    "Floor loaded successful": "Etage erfolgreich geladen",
                    "Floor name cannot be empty string or null": "Der Etagenname kann keine leere Zeichenfolge oder null sein",
                    "Floor not found": "Etage nicht gefunden",
                    "Floor updated successful": "Etage erfolgreich aktualisiert",
                    //"Floor with id: {floorId} does not exist..." : "",
                    "Floors loaded successful": "Etagen erfolgreich geladen",
                    "Geolocation loaded successful": "Geolokalisierung erfolgreich geladen",
                    "Geolocation not found": "Geolokalisierung nicht gefunden",
                    "Geolocation(s) load successful": "Geolokalisierung(en) laden erfolgreich",
                    "Geolocations loaded successful": "Geolocations erfolgreich geladen",
                    "Ground floor already exist, create upper floor or basement": "Erdgeschoss ist bereits vorhanden, Obergeschoss bzw. Keller erstellen",
                    "Groundfloor cannot be deleted. Delete all upper floors and basements first": "Das Erdgeschoss kann nicht gelöscht werden. Löschen Sie zunächst alle Obergeschosse und den Keller",
                    "Heat transfer coefficient can be set only if you set heating type to globally": "Der Wärmeübertragungskoeffizient kann nur eingestellt werden, wenn Sie die Heizungsart auf global einstellen.",
                    "If choose heating interruption type to gloabal, you cannot choose temperature drop. It will be calculated": "Wenn Sie die Heizunterbrechungsart „Global“ einstellen, können Sie keinen Temperaturabfall wählen. Es wird berechnet",
                    "If environemnt type is outside, you cannot pick bordering space": "Wenn der Umgebungstyp Außen ist, können Sie keinen angrenzenden Raum auswählen.",
                    "If environment type is ground, you have to select correction factor for the ground": "Wenn der Umgebungstyp Boden ist, müssen Sie einen Korrekturfaktor für den Boden auswählen",
                    "If room height is greater than 5m, you have to choose space heating surface": "Wenn die Raumhöhe mehr als 5 m beträgt, müssen Sie eine Raumheizfläche wählen.",
                    "If set heating interruption type to manually, you must enter temperature drop": "Wenn Sie die Art der Heizungsunterbrechung auf manuell einstellen, müssen Sie den Temperaturabfall eingeben",
                    "Internal and external surface resistances cannot be less than 0": "Der innere und äußere Oberflächenwiderstand kann nicht kleiner als 0 sein.",
                    "Invalid environment type": "Ungültiger Umgebungstyp",
                    "Invalid opening Id": "Ungültige Öffnungs-ID",
                    "Length, width, height, space area and space volume cannot be equal or less than 0. Air exchange rate and n50 cannot be less than 0": "Länge, Breite, Höhe, Raumfläche und Raumvolumen können nicht gleich oder kleiner als 0 sein. Die Luftaustauschrate und n50 können nicht kleiner als 0 sein",
                    "List is empty": "Die Liste ist leer",
                    "List loaded successful": "Liste erfolgreich geladen",
                    "Login successful": "Anmeldung erfolgreich",
                    "Material added successful": "Material erfolgreich hinzugefügt",
                    "Material created successful": "Material erfolgreich erstellt",
                    "Material deleted successful": "Material erfolgreich gelöscht",
                    "Material loaded successful": "Material erfolgreich geladen",
                    "Material not found": "Material nicht gefunden",
                    "Material removed successful": "Material erfolgreich entfernt",
                    "Material type cannot be null": "Materialtyp kann nicht null sein",
                    "Material updated successful": "Material erfolgreich aktualisiert",
                    "Material(s) loaded successful": "Material(ien) erfolgreich geladen",
                    "Materials loaded successful": "Materialien erfolgreich geladen",
                    "Not allowed. Choose different value from natural warm air convection": "Nicht erlaubt. Wählen Sie einen anderen Wert für die natürliche Warmluftkonvektion",
                    "Not allowed. Choose different value from warm ceiling": "Nicht erlaubt. Wählen Sie einen anderen Wert für die warme Decke",
                    "Opening created successful": "Öffnung erfolgreich erstellt",
                    "Opening deleted successful": "Öffnung erfolgreich gelöscht",
                    "Opening in wall deleted successful": "Öffnung in der Wand erfolgreich gelöscht",
                    "Opening in wall loaded successful": "Öffnung in der Wand erfolgreich geladen",
                    "Opening in wall not found": "Öffnung in der Wand nicht gefunden",
                    "Opening loaded successful": "Öffnung erfolgreich geladen",
                    "Opening name cannot be null or white space": "Der Öffnungsname kann weder Null noch ein Leerzeichen sein",
                    "Opening not found": "Öffnung nicht gefunden",
                    "Opening type cannot be null or white space": "Der Öffnungstyp kann weder Null noch Leerzeichen sein",
                    "Opening updated successful": "Öffnung erfolgreich aktualisiert",
                    "Opening(s) created successful": "Öffnung(en) erfolgreich erstellt",
                    "Opening(s) updated successful": "Öffnung(en) erfolgreich aktualisiert",
                    "Openings in wall loaded successful": "Öffnungen in der Wand erfolgreich geladen",
                    "Project created successful": "Projekt erfolgreich erstellt",
                    "Project deleted successful": "Projekt erfolgreich gelöscht",
                    "Project loaded successful": "Projekt erfolgreich geladen",
                    "Project not found": "Projekt nicht gefunden",
                    "Project status changed": "Projektstatus geändert",
                    "Project status changed successful": "Projektstatus erfolgreich geändert",
                    "Project updated successful": "Projekt erfolgreich aktualisiert",
                    "Projects loaded successful": "Projekte erfolgreich geladen",
                    "Report created successful": "Bericht erfolgreich erstellt",
                    "Room height must be grater than 5m if you want to choose space heating surface": "Die Raumhöhe muss größer als 5 m sein, wenn Sie die Raumheizfläche wählen möchten",
                    "Something went wrong": "Etwas ist schiefgelaufen",
                    "Space created successful": "Raum erfolgreich erstellt",
                    "Space deleted successful": "Raum erfolgreich gelöscht",
                    "Space dimensions cannot be equal or less than 0": "Die Raumabmessungen können nicht gleich oder kleiner als 0 sein",
                    "Space does not exist": "Der Raum existiert nicht",
                    "Space heating interruption data updated successful": "Daten zur Raumheizungsunterbrechung erfolgreich aktualisiert",
                    "Space loaded successful": "Raum erfolgreich geladen",
                    "Space name cannot be null or white space": "Der Raumname kann weder Null noch ein Leerzeichen sein",
                    "Space not found": "Raum nicht gefunden",
                    "Space number already exists": "Die Raum-Nummer ist bereits vorhanden",
                    "Space number cannot be greater than 999": "Die Raum-Nummer kann nicht größer als 999 sein",
                    "Space number cannot be less than 1": "Die Raum-Nummer kann nicht kleiner als 1 sein",
                    "Space updated successful": "Raum erfolgreich aktualisiert",
                    "SpaceId cannot be null": "Die Raum ID kann nicht null sein",
                    "Spaces loaded successful": "Räume erfolgreich geladen",
                    "Temperature drop cannot be less than 1 and greater than 7": "Der Temperaturabfall kann nicht kleiner als 1 und größer als 7 sein",
                    "The total number of geolocaitons is: ": "Die Gesamtzahl der Geolokationen beträgt:",
                    "Thermal transmittance cannot be equeal or less than zero": "Der Wärmedurchlasskoeffizient kann nicht gleich oder kleiner als null sein",
                    "Thermal transmittance cannot be less or equal than zero": "Der Wärmedurchlasskoeffizient kann nicht kleiner oder gleich null sein",
                    "Total thermal resistance cannot be equal or less than zero": "Der Gesamtwärmewiderstand kann nicht gleich oder kleiner als null sein",
                    "Wall not found": "Wand nicht gefunden",
                    "Wall(s) created successful": "Wand (Wände) erfolgreich erstellt",
                    "Wall(s) removed successful": "Wand (Wände) erfolgreich entfernt",
                    "Wall(s) updated successful": "Wand (Wände) erfolgreich aktualisiert",
                    "Walls loaded successful": "Wände erfolgreich geladen",
                    "Width and height cannot be equal or less than 0": "Breite und Höhe können nicht gleich oder kleiner als 0 sein",
                    "Wrong wallId": "Falsche Wand ID",
                    "You must choose space type, heated or unheated space": "Sie müssen den Raumtyp auswählen: beheizter oder unbeheizter Raum",
                    "Zone code cannot be less than 1": "Der Zonencode kann nicht kleiner als 1 sein",
                    "Zone created successful": "Zone erfolgreich erstellt",
                    "Zone deleted successful": "Zone erfolgreich gelöscht",
                    "Zone loaded successful": "Zone erfolgreich geladen",
                    "Zone name cannot be null or white space": "Der Zonenname kann weder null noch ein Leerzeichen sein",
                    "Zone not found": "Zone nicht gefunden",
                    "Zone number already exist": "Zonennummer bereits vorhanden",
                    "Zone updated successful": "Zone erfolgreich aktualisiert",
                    "Reheat time cannot be greater than 4 or less than 0.5": "Die Aufwärmzeit kann nicht größer als 4 oder kleiner als 0,5 sein",
                    //"Opening with id: {model.OpeningId} does not exist":"",
                    //"Component with id: {componentId} not found":"",
                    "BuildingModelForCreate cannot be null": "BuildingModelForCreate kann nicht null sein",
                    "If you have heating interruption, heatingInterruptionModel cannot be null": "Wenn eine Heizunterbrechung vorliegt, kann „heatingInterruptionModel“ nicht null sein",
                    "Building name cannot be white space or empty": "Der Gebäudename kann kein Leerzeichen oder leer sein",
                    "Building coverage must be selected": "Die Gebäudeabdeckung muss gewählt werden",
                    "Building mass must be selected": "Die Gebäudemasse muss ausgewählt werden",
                    "Air tightness must be selected": "Luftdichtheit muss gewählt werden",
                    "Building type must be selected": "Die Gebäudeart muss ausgewählt werden",
                    "Ground type must be selected": "Die Bodenart muss ausgewählt werden",
                    "Cannot select thermal bridge and manually input thermal bridge value": "Die Wärmebrücke kann nicht ausgewählt und der Wärmebrückenwert manuell eingegeben werden",
                    "You must select thermal bridge": "Sie müssen die Wärmebrücke auswählen",
                    //"Object with id: {zoneId} not found":"",
                    //"Wall with pair id: {model.PairId} does not exist":"",
                    //"Opening with Id:{openingId} does not exist":"",
                    //"Opening with id: {nextOpening.OpeningInWallId} does not exist":"",
                    "Internal, external surface resistances and thermal transmittance cannot be less than 0": "Die inneren und äußeren Oberflächenwiderstände sowie der Wärmedurchlasskoeffizient dürfen nicht kleiner als 0 sein",
                    "Thermal transmittance (U value) cannot be less than zero": "Der Wärmedurchlasskoeffizient (U-Wert) darf nicht kleiner als Null sein",
                    					"Error in application" : "Fehler in der Programm",
                    "Building created successfully" : "Gebäude erfolgreich erstellt",
                    "Building updated successfully" : "Gebäude erfolgreich aktualisiert",
                    "Building deleted successfully" : "Gebäude erfolgreich gelöscht",
                    "Floor created successfully" : "Etage erfolgreich erstellt",
                    "Floor updated successfully" : "Etage erfolgreich aktualisiert",
                    "Floor deleted successfully" : "Etage erfolgreich gelöscht",
                    "Area created successfully" : "Bereich erfolgreich erstellt",
                    "Area updated successfully" : "Bereich erfolgreich aktualisiert",
                    "Area deleted successfully" : "Bereich erfolgreich gelöscht",
                    "Room created successfully" : "Raum erfolgreich erstellt",
                    "Room updated successfully" : "Raum erfolgreich aktualisiert",
                    "Room deleted successfully" : "Raum erfolgreich gelöscht",
                    "Heating interruption data updated successfully" : "Heizungsunterbrechungsdaten erfolgreich aktualisiert",
                    "Wall created successfully" : "Wand erfolgreich erstellt",
                    "Wall updated successfully" : "Wand erfolgreich aktualisiert",
                    "Wall deleted successfully" : "Wand erfolgreich gelöscht",
                    "Opening in wall created successfully" : "Öffnung in der Wand erfolgreich erstellt",
                    "Opening in wall updated successfully" : "Öffnung in der Wand erfolgreich aktualisiert",
                    "Opening in wall deleted successfully" : "Öffnung in der Wand erfolgreich gelöscht",
                    "Walls have been updated successfully" : "Wände wurden erfolgreich aktualisiert",
                    "Component created successfully" : "Komponente erfolgreich erstellt",
                    "Component updated successfully" : "Komponente erfolgreich aktualisiert",
                    "Material added to component" : "Material zur Komponente hinzugefügt",
                    "Material updated in component" : "Material in der Komponente aktualisiert",
                    "Material removed" : "Material entfernt",
                    "Component deleted successfully" : "Komponente erfolgreich gelöscht",
                    "Component created successfully" : "Komponente erfolgreich erstellt",
                    "Component updated successfully" : "Komponente erfolgreich aktualisiert",
                    "Opening created successfully" : "Öffnung erfolgreich erstellt",
                    "Opening updated successfully" : "Öffnung erfolgreich aktualisiert",
                    "Opening deleted successfully" : "Öffnung erfolgreich gelöscht",
                    "Component removed" : "Komponente entfernt",
                    "Component updated" : "Komponente aktualisiert",
                    "Template added successfully" : "Vorlage erfolgreich hinzugefügt",
                    "Opening removed" : "Öffnung entfernt",
                    "Opening updated" : "Öffnung aktualisiert",
                    "Template deleted successfully" : "Sammlung erfolgreich gelöscht",
                    "Opening saved to the Collection" : "Öffnung in der Sammlung gespeichert",
                    "Calculation is done" : "Berechnung abgeschlossen",
                    "Project created successfully" : "Projekt erfolgreich erstellt",
                    "Project updated successfully" : "Projekt erfolgreich aktualisiert",
                    "Project deleted successfully" : "Projekt erfolgreich gelöscht",
                    "Project closed successfully" : "Projekt erfolgreich geschlossen",
                    "Project information added successfully" : "Projektinformation erfolgreich hinzugefügt",
                    "Project information updated successfully" : "Projektinformation erfolgreich aktualisiert",
                    "Project information removed successfully" : "Projektinformation erfolgreich entfernt",
                    "Component added successfully to the template" : "Komponente erfolgreich zur Vorlage hinzugefügt",
                    "Opening added successfully to the template" : "Öffnung erfolgreich zur Vorlage hinzugefügt",
                    "Company Information is added successufully" : "Firmeninformationen wurden erfolgreich hinzugefügt",
                    "Company Information is updated successufully" : "Firmeninformationen wurden erfolgreich aktualisiert",
                    "Cannot create project with same project name or project code" : "Kann kein Projekt mit demselben Projektnamen oder Projektcode erstellen",
                    "Template added successfully" : "Vorlage erfolgreich hinzugefügt",
                },
            },
            srb: {
                translation: {
                    hello: "Zdravo",
                },
            },
        },
    });

export default i18n;
