import { OpeningInWallModel, OpeningInWallModelForDelete } from "../buildingStructureModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DeleteOpeningInWall } from "../buildingStructureSlice";
import { formatDateToDateTimeString, formatNumber, getEnumValueByKey } from "../../../app/common";
import { EnvironmentType } from "../../../app/enums";
import NewRow from "./newRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteCard from "../../../UIComponents/deleteCard";

interface OpeningInWallProps {
    spaceId: string
    opening: OpeningInWallModel
}

function OpeningInWall({ spaceId, opening }: OpeningInWallProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const currentDate = formatDateToDateTimeString(new Date());

    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    function onEditOpeningClick() {
        setIsEdit(!isEdit);
    }

    function handleDelete() {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisOpening"));
        setIsDelete(true);
    }

    function deleteOpening() {
        const openingForDelete: OpeningInWallModelForDelete = {
            userId: localStorage.getItem("userId")!,
            openingPairId: opening.openingInWallPairId,
            wallId: opening.wallId,
            projectId: projectId,
            dateDeleted: currentDate
        }
        dispatch(DeleteOpeningInWall(openingForDelete));
        setIsDelete(false);
    }

    useEffect(() => {
        setIsEdit(false)
    }, [buildingStructureSliceData.buildingStructureData.openingInWallData])

    return (
        <div className="min-w-fit">
            {isEdit ? <NewRow opening spaceId={spaceId} isOpeningEdit openingData={opening} wallEnvironment={opening.environmentType} wallOrientation={opening.orientation} wallPairId={opening.openingInWallPairId} onDoubleClick={onEditOpeningClick} /> :
                <div id={"opening-" + opening.openingInWallId} className="bg-#545B6A flex text-xs xl:text-lg font-inter justify-between border-b border-b-#3B4250 h-[45px]" onDoubleClick={onEditOpeningClick}>
                    <p className="text-left px-4 py-3 min-w-[120px] xl:min-w-[170px]">{opening.name}</p>
                    <p className="flex items-center justify-center min-w-[120px] xl:min-w-[146px]">{opening.orientation}</p>
                    <p className="flex items-center justify-center min-w-[170px] xl:min-w-[210px]">{t(getEnumValueByKey(EnvironmentType, opening.environmentType))}</p>
                    <p className="flex items-center justify-center min-w-[85px] xl:min-w-[99px]">{formatNumber(opening.width, 1)}</p>
                    <p className="flex items-center justify-center min-w-[135px] xl:min-w-[158px]">{formatNumber(opening.height, 1)}</p>
                    <p className="flex items-center justify-center min-w-[92px] xl:min-w-[113px]">{formatNumber(opening.number, 0)}</p>
                    <p className="flex items-center justify-center min-w-[95px] xl:min-w-[115px]">{formatNumber(opening.area, 1)}</p>
                    <p className="flex items-center justify-center min-w-[119px] xl:min-w-[142px]">-</p>
                    <p className="flex items-center justify-center min-w-[128px] xl:min-w-[153px]">{opening.temperature && formatNumber(opening.temperature, 1)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(opening.bu, 2)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(opening.thermalTransmittance, 3)}</p>
                    <p className="flex items-center justify-center min-w-[146px] xl:min-w-[176px]">{formatNumber(opening.thermalBridge, 3)}</p>
                    <p className="flex items-center justify-center min-w-[124px] xl:min-w-[148px]">{formatNumber(opening.correctedUValue, 3)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(opening.heatTransferCoefficient, 2)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(opening.transmissionLoss, 0)}</p>
                    <div className="min-w-[146px] xl:min-w-[220px]" />
                    <p className="flex items-center justify-center min-w-[75px] xl:min-w-[85px]">
                        <button id="deleteBtn" className="mx-auto" onClick={handleDelete}>
                            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </button>
                    </p>
                </div>
            }
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteOpening} /> : <></>}
        </div>
    );
}
export default OpeningInWall;