import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dialog, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import CustomDropdown from "../../../UIComponents/customDropdown";
import { environmentTypeOptions } from "../../../app/optionModels";
import { CorrectionFactorForTheGround, EnvironmentType, SpaceType, UnheatedSpaceProperty } from "../../../app/enums";
import { GetAllRoomsByHeating } from "../buildingStructureSlice";
import { getEnumKeyByValue, handleInputChange } from "../../../app/common";
import { RoomsByHeatingModel } from "../buildingStructureModels";

interface EnvironmentProps {
    spaceId: string;
    environment?: string;
    nextSpaceId?: string | null;
    temperature?: string | null;
    unheatedSpaceProperty?: string | null;
    correctionFactorForTheGround?: string | null;
    onCancelClickFunction: () => void,
    onContinueClickFunction: (environment: string, nextSpaceId: string | null, temperature: string | null, unheatedSpaceProperty: string | null, correctionFactorForTheGround: string | null) => void,
}

function Environment(props: EnvironmentProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const allRoomsByHeatingData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice.buildingStructureData.allRoomsByHeatingData);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    const unheatedSpaceProperties = Object.entries(UnheatedSpaceProperty);

    const [selectedEnvironmentType, setSelectedEnvironmentType] = useState("")

    const [heatedRoomIsKnown, setHeatedRoomIsKnown] = useState(false)
    const [unheatedRoomIsKnown, setUnheatedRoomIsKnown] = useState(false)
    const [unheatedRoomIsNotKnown, setUnheatedRoomIsNotKnown] = useState(false)

    const [temperatureOfHeatedRoom, setTemperatureOfHeatedRoom] = useState("")
    const [temperatureOfHeatedRoomErrorStatus, setTemperatureOfHeatedRoomErrorStatus] = useState(false)
    const [heatedRoomErrorStatus, setHeatedRoomErrorStatus] = useState(false)
    const [temperatureOfUnheatedRoom, setTemperatureOfUnheatedRoom] = useState("")
    const [temperatureOfUnheatedRoomErrorStatus, setTemperatureOfUnheatedRoomErrorStatus] = useState(false)
    const [unheatedRoomErrorStatus, setUnheatedRoomErrorStatus] = useState(false)
    const [standard, setStandard] = useState(true)
    const [onCooledGround, setOnCooledGround] = useState(false)
    const [aboveAdjacentLevel, setAboveAdjacentLevel] = useState(false)

    const [selectedHeatedRoom, setSelectedHeatedRoom] = useState("")
    const [selectedUnheatedRoom, setSelectedUnheatedRoom] = useState("")
    const [selectedUnheatedSpaceProperty, setSelectedUnheatedSpaceProperty] = useState("")

    const [errorMessage, setErrorMessage] = useState("")

    function onCancelClick(): void {
        props.onCancelClickFunction();
        handleDialog();
    }

    useEffect(() => {
        if (props.environment) {
            setSelectedEnvironmentType(props.environment)
            if (props.environment === getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM)) {
                if (props.nextSpaceId) {
                    handleHeatedRoomIsKnownChecked(true)
                    handleSelectHeatedRoom(props.nextSpaceId)
                }
                else if (props.temperature) {
                    setTemperatureOfHeatedRoom(props.temperature);
                }
            }
            else if (props.environment === getEnumKeyByValue(EnvironmentType, EnvironmentType.UNHEATEDROOM)) {
                if (props.temperature) {
                    setTemperatureOfUnheatedRoom(props.temperature);
                }
                else if (props.nextSpaceId) {
                    handleUnheatedRoomIsKnownChecked(true)
                    handleSelectUnheatedRoom(props.nextSpaceId)
                }
                else if (props.unheatedSpaceProperty) {
                    handleUnheatedRoomIsNotKnownChecked(true)
                    handleUnheatedSpaceProperty(props.unheatedSpaceProperty)
                }
            }
            else if (props.environment === getEnumKeyByValue(EnvironmentType, EnvironmentType.GROUND)) {
                if (props.correctionFactorForTheGround === getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.STANDARDCASE)) {
                    handleStandardChecked(true)
                }
                else if (props.correctionFactorForTheGround === getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.COOLEDGROUND)) {
                    handleOnCooledGroundChecked(true)
                }
                else if (props.correctionFactorForTheGround === getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.ABOVEADJACENTLEVEL)) {
                    handleAboveAdjacentLevelChecked(true)
                }
            }
        }
        else {
            setSelectedEnvironmentType(getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM))
        }
    }, [])


    function handleSelectEnvironmentType(value: string): void {
        setSelectedEnvironmentType(value)
        setHeatedRoomErrorStatus(false);
        setUnheatedRoomErrorStatus(false)
        setTemperatureOfHeatedRoomErrorStatus(false);
        setTemperatureOfUnheatedRoomErrorStatus(false);
    }

    function handleHeatedRoomIsKnownChecked(checked: boolean): void {
        setHeatedRoomIsKnown(checked)
        if (checked) {
            const roomsByHeatingModel: RoomsByHeatingModel = {
                projectId: projectId,
                spaceType: getEnumKeyByValue(SpaceType, SpaceType.HEATED),
                spaceId: props.spaceId
            }
            dispatch(GetAllRoomsByHeating(roomsByHeatingModel))
        }
    }

    function handleSelectHeatedRoom(spaceId: string): void {
        if (selectedHeatedRoom === spaceId) {
            setSelectedHeatedRoom("")
        }
        else {
            setSelectedHeatedRoom(spaceId)
        }
    }

    function handleSelectUnheatedRoom(spaceId: string): void {
        if (selectedUnheatedRoom === spaceId) {
            setSelectedUnheatedRoom("")
        }
        else {
            setSelectedUnheatedRoom(spaceId)
        }
    }

    function handleUnheatedSpaceProperty(spaceKey: string): void {
        if (selectedUnheatedSpaceProperty === spaceKey) {
            setSelectedUnheatedSpaceProperty("")
        }
        else {
            setSelectedUnheatedSpaceProperty(spaceKey)
        }
    }

    function handleUnheatedRoomIsKnownChecked(checked: boolean): void {
        setUnheatedRoomIsKnown(checked)
        if (checked) {
            const roomsByHeatingModel: RoomsByHeatingModel = {
                projectId: projectId,
                spaceType: getEnumKeyByValue(SpaceType, SpaceType.UNHEATED),
                spaceId: props.spaceId
            }
            dispatch(GetAllRoomsByHeating(roomsByHeatingModel))
        }
        if (unheatedRoomIsNotKnown) {
            setUnheatedRoomIsNotKnown(!checked)
        }
    }

    function handleUnheatedRoomIsNotKnownChecked(checked: boolean): void {
        setUnheatedRoomIsNotKnown(checked)
        if (unheatedRoomIsKnown) {
            setUnheatedRoomIsKnown(!checked)
        }
    }

    function handleStandardChecked(checked: boolean): void {
        if (checked) {
            setStandard(checked)
            setOnCooledGround(!checked)
            setAboveAdjacentLevel(!checked)
        }
    }

    function handleOnCooledGroundChecked(checked: boolean): void {
        if (checked) {
            setOnCooledGround(checked)
            setStandard(!checked)
            setAboveAdjacentLevel(!checked)
        }
    }

    function handleAboveAdjacentLevelChecked(checked: boolean): void {
        if (checked) {
            setAboveAdjacentLevel(checked)
            setOnCooledGround(!checked)
            setStandard(!checked)
        }
    }

    function handleContinueClick(): void {
        if (selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM)) {
            if (!heatedRoomIsKnown && temperatureOfHeatedRoom === "") {
                setTemperatureOfHeatedRoomErrorStatus(true);
            }
            else if (!heatedRoomIsKnown && temperatureOfHeatedRoom !== "") {
                props.onContinueClickFunction(selectedEnvironmentType, null, temperatureOfHeatedRoom, null, null);
                props.onCancelClickFunction();
                handleDialog();
            }
            if (heatedRoomIsKnown && selectedHeatedRoom === "") {
                setHeatedRoomErrorStatus(true);
                setErrorMessage(t("YouMustSelectARoomToContinue"))
            }
            else if (heatedRoomIsKnown && selectedHeatedRoom !== "") {
                props.onContinueClickFunction(selectedEnvironmentType, selectedHeatedRoom, null, null, null);
                props.onCancelClickFunction();
                handleDialog();
            }
        }
        else if (selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.UNHEATEDROOM)) {
            if (!(unheatedRoomIsKnown || unheatedRoomIsNotKnown) && temperatureOfUnheatedRoom === "") {
                setTemperatureOfUnheatedRoomErrorStatus(true);
            }
            else if (!(unheatedRoomIsKnown || unheatedRoomIsNotKnown) && temperatureOfUnheatedRoom !== "") {
                props.onContinueClickFunction(selectedEnvironmentType, null, temperatureOfUnheatedRoom, null, null);
                props.onCancelClickFunction();
                handleDialog();
            }
            if (unheatedRoomIsKnown && selectedUnheatedRoom === "") {
                setUnheatedRoomErrorStatus(true);
                setErrorMessage(t("YouMustSelectARoomToContinue"))
            }
            else if (unheatedRoomIsKnown && selectedUnheatedRoom !== "") {
                props.onContinueClickFunction(selectedEnvironmentType, selectedUnheatedRoom, null, null, null);
                props.onCancelClickFunction();
                handleDialog();
            }
            if (unheatedRoomIsNotKnown && selectedUnheatedSpaceProperty === "") {
                setUnheatedRoomErrorStatus(true);
                setErrorMessage(t("YouMustSelectARoomTypeToContinue"))
            }
            else if (unheatedRoomIsNotKnown && selectedUnheatedSpaceProperty !== "") {
                props.onContinueClickFunction(selectedEnvironmentType, null, null, selectedUnheatedSpaceProperty, null);
                props.onCancelClickFunction();
                handleDialog();
            }
        }
        else if (selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.GROUND)) {
            if (standard) {
                props.onContinueClickFunction(selectedEnvironmentType, null, null, null, getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.STANDARDCASE));
                props.onCancelClickFunction();
                handleDialog();
            }
            else if (onCooledGround) {
                props.onContinueClickFunction(selectedEnvironmentType, null, null, null, getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.COOLEDGROUND));
                props.onCancelClickFunction();
                handleDialog();
            }
            else if (aboveAdjacentLevel) {
                props.onContinueClickFunction(selectedEnvironmentType, null, null, null, getEnumKeyByValue(CorrectionFactorForTheGround, CorrectionFactorForTheGround.ABOVEADJACENTLEVEL));
                props.onCancelClickFunction();
                handleDialog();
            }
        }
        else if (selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.OUTSIDE) || selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.NEIGHBOURSPACE) || selectedEnvironmentType === getEnumKeyByValue(EnvironmentType, EnvironmentType.NEIGHBOURBUILDING)) {
            props.onContinueClickFunction(selectedEnvironmentType, null, null, null, null);
            props.onCancelClickFunction();
            handleDialog();
        }
    }

    function LeftSide() {
        switch (selectedEnvironmentType) {
            case getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM):
                return (
                    <div>
                        <p>{t("TemperatureOfTheHeatedRoom")}:</p>
                        <div className="flex items-center gap-3 pt-[10px] pb-[23px]">
                            <input id="temperatureOfHeatedRoom" type="text" autoComplete="off" disabled={heatedRoomIsKnown} placeholder={t("TemperatureOfTheHeatedRoom")} value={temperatureOfHeatedRoom} onChange={(e) => { handleInputChange(e, setTemperatureOfHeatedRoom, 1); setTemperatureOfHeatedRoomErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${(temperatureOfHeatedRoomErrorStatus && !heatedRoomIsKnown) ? "border-[1px] border-#EF5350" : ""} h-10 w-full pl-[12px] rounded-[5px] disabled:opacity-50 outline-none shadow-inner ml-auto`} />
                            <p className="text-lg">°C</p>
                        </div>
                        <Checkbox id="heatedRoomIsKnown" crossOrigin={""} ripple={false} onChange={(e) => handleHeatedRoomIsKnownChecked(e.target.checked)} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={heatedRoomIsKnown}
                            label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("HeatedRoomIsKnown")}</Typography>}
                            containerProps={{ className: "-ml-2.5" }} />
                    </div>
                );
            case getEnumKeyByValue(EnvironmentType, EnvironmentType.UNHEATEDROOM):
                return (
                    <div>
                        <p>{t("TemperatureOfTheUnheatedRoom")}:</p>
                        <div className="flex items-center gap-3 pt-[10px] pb-[23px]">
                            <input id="temperatureOfUnheatedRoom" type="text" autoComplete="off" disabled={unheatedRoomIsKnown || unheatedRoomIsNotKnown} placeholder={t("TemperatureOfTheUnheatedRoom")} value={temperatureOfUnheatedRoom} onChange={(e) => { handleInputChange(e, setTemperatureOfUnheatedRoom, 1); setTemperatureOfUnheatedRoomErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${(temperatureOfUnheatedRoomErrorStatus && !(unheatedRoomIsKnown || unheatedRoomIsNotKnown)) ? "border-[1px] border-#EF5350" : ""} h-10 w-full pl-[12px] rounded-[5px] disabled:opacity-50 outline-none shadow-inner ml-auto`} />
                            <p className="text-lg">°C</p>
                        </div>
                        <div className="flex flex-col">
                            <Checkbox id="unheatedRoomIsKnown" crossOrigin={""} ripple={false} onChange={(e) => handleUnheatedRoomIsKnownChecked(e.target.checked)} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={unheatedRoomIsKnown}
                                label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("UnheatedRoomIsKnown")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="unheatedRoomIsNotKnown" crossOrigin={""} ripple={false} onChange={(e) => handleUnheatedRoomIsNotKnownChecked(e.target.checked)} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={unheatedRoomIsNotKnown}
                                label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("UnheatedRoomIsNotKnown")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                        </div>

                    </div>
                );
            case getEnumKeyByValue(EnvironmentType, EnvironmentType.GROUND):
                return (
                    <div>
                        <div className="flex flex-col">
                            <Checkbox id="standard" crossOrigin={""} ripple={false} onChange={(e) => handleStandardChecked(e.target.checked)} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={standard}
                                label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("Standard")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="onCooledGround" crossOrigin={""} ripple={false} onChange={(e) => handleOnCooledGroundChecked(e.target.checked)} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={onCooledGround}
                                label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("OnCooledGround")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="aboveAdjacentLevel" crossOrigin={""} ripple={false} onChange={(e) => handleAboveAdjacentLevelChecked(e.target.checked)} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={aboveAdjacentLevel}
                                label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 font-normal" >{t("AboveAdjacentLevel")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                        </div>
                    </div>
                );
            default:
                return;
        }
    }

    function RightSide() {
        switch (selectedEnvironmentType) {
            case getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM):
                return (
                    <div>
                        {heatedRoomIsKnown && <div>
                            <p className="pl-[15px]">{t("ListOfHeatedRooms")}:</p>
                            {allRoomsByHeatingData.map((room) =>
                                <div id={"Room" + room.spaceId} onClick={() => handleSelectHeatedRoom(room.spaceId)} className={`flex flex-row gap-2 hover:bg-#D79F451A pl-[15px] cursor-pointer rounded ${(selectedHeatedRoom === room.spaceId) ? "bg-#D79F451A" : ""}`}>
                                    <p className="w-fit">{room.spaceCode}</p>
                                    <p className="w-fit truncate">{room.spaceName}</p>
                                    <p className="w-fit">{room.temperature}°C</p>
                                </div>
                            )}
                        </div>}
                    </div>
                );
            case getEnumKeyByValue(EnvironmentType, EnvironmentType.UNHEATEDROOM):
                return (
                    <div>
                        {unheatedRoomIsKnown && <div>
                            <p>{t("ListOfUnheatedRooms")}:</p>
                            {allRoomsByHeatingData.map((room) =>
                                <div id={"Room" + room.spaceId} onClick={() => handleSelectUnheatedRoom(room.spaceId)} className={`flex flex-row gap-2 hover:bg-#D79F451A pl-[15px] cursor-pointer rounded ${(selectedUnheatedRoom === room.spaceId) ? "bg-#D79F451A" : ""}`}>
                                    <p className="w-fit">{room.spaceCode}</p>
                                    <p className="w-fit truncate">{room.spaceName}</p>
                                    <p className="w-fit">{room.temperature}°C</p>
                                </div>
                            )}
                        </div>}
                        {unheatedRoomIsNotKnown && <div>
                            <p>{t("ListOfUnheatedSpaceProperties")}:</p>
                            {unheatedSpaceProperties.map(([key, value]) =>
                                <div onClick={() => handleUnheatedSpaceProperty(key)} className={`flex flex-row gap-2 hover:bg-#D79F451A pl-[15px] cursor-pointer rounded ${(selectedUnheatedSpaceProperty === key) ? "bg-#D79F451A" : ""}`}>
                                    <p>{t(value)}</p>
                                </div>
                            )}
                        </div>}
                    </div>
                );
            default:
                return;
        }
    }

    return (
        <Dialog open={openDialog} size="xl" handler={() => { props.onCancelClickFunction(); handleDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="justify-center bg-transparent shadow-none outline-none">
            <div className="text-#F5F5F5 bg-#3B4250 p-[40px] rounded-[5px] ">
                <div className="flex flex-row justify-between min-h-[400px]">
                    <div className="w-3/5 pr-[40px]">
                        <p className="pb-[10px]">{t("NextTo")}:</p>
                        <CustomDropdown dropdownId="selectEnvironmentTypeDropdown" previousSelectedOption={selectedEnvironmentType} options={environmentTypeOptions} placeholder={t("SelectEnvironmentType")} height={"h-10"} width={"w-full"} onSelectClick={handleSelectEnvironmentType} />
                        <div className="pt-[23px]">
                            {LeftSide()}
                        </div>
                    </div>
                    <div className="w-2/5 pl-[25px] border-l-[1px] border-#D9D9D9">
                        {RightSide()}
                    </div>
                </div>
                <div className="flex items-center flex-col">
                    <div className="flex w-full space-x-[30px]">
                        <Button id="cancelBtn" onClick={onCancelClick} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</Button>
                        <Button id="continueBtn" onClick={handleContinueClick} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Continue")}</Button>
                    </div>
                    <p className="ml-auto mt-1 h-4 text-#EF5350">{(heatedRoomErrorStatus || unheatedRoomErrorStatus) ? errorMessage : ""}</p>
                </div>
            </div>
        </Dialog>
    );
}

export default Environment;