import { useTranslation } from "react-i18next";
import CustomDropdown, { CustomDropdownMethods } from "../../../../UIComponents/customDropdown";
import { useEffect, useRef, useState } from "react";
import { componentTypeOptions } from "../../../../app/optionModels";
import { formatDateToDateTimeString, getEnumKeyByValue, handleInputChange, handleSpaceInInput } from "../../../../app/common";
import { ComponentModelForCreate } from "../componentsModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { CreateComponentWithLayeredStructure, resetStatusCode } from "../componentsSlice";
import { notify } from "../../../../UIComponents/notification";
import { ComponentType } from "../../../../app/enums";

function ComponentWithLayeredStructure() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const customDropdownRef = useRef<CustomDropdownMethods>(null);
    const [selectedComponentType, setSelectedComponentType] = useState("");
    const [componentCode, setComponentCode] = useState("");
    const [componentName, setComponentName] = useState("");
    const [internalSurfaceResistance, setInternalSurfaceResistance] = useState("");
    const [externalSurfaceResistance, setExternalSurfaceResistance] = useState("");
    const [selectedComponentTypeErrorStatus, setSelectedComponentTypeErrorStatus] = useState(false);
    const [componentCodeErrorStatus, setComponentCodeErrorStatus] = useState(false);
    const [componentNameErrorStatus, setComponentNameErrorStatus] = useState(false);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const componentSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);

    function handleCreateComponentClick(): void {
        if (selectedComponentType === "") { setSelectedComponentTypeErrorStatus(true); }
        if (componentCode === "") { setComponentCodeErrorStatus(true); }
        if (componentName === "") { setComponentNameErrorStatus(true); }

        const currentDate = formatDateToDateTimeString(new Date());

        if (selectedComponentType !== "" && componentCode !== "" && componentName !== "") {
            const componentModel: ComponentModelForCreate = {
                projectId: projectId,
                userId: localStorage.getItem("userId")!,
                componentCode: componentCode,
                componentName: componentName,
                componentType: selectedComponentType,
                internalSurfaceResistance: parseFloat(internalSurfaceResistance),
                externalSurfaceResistance: parseFloat(externalSurfaceResistance),
                thermalTransmittance: 0,
                dateCreatedOrModified: currentDate
            }
            dispatch(CreateComponentWithLayeredStructure(componentModel))
        }
    }

    function resetState(): void {
        customDropdownRef.current && customDropdownRef.current.resetDropdown();
        setSelectedComponentType("");
        setComponentCode("");
        setComponentName("");
        setInternalSurfaceResistance("");
        setExternalSurfaceResistance("");
    }

    useEffect(() => {
        if (componentSliceData.statusValues.statusCode === "") {
            return;
        }
        if (componentSliceData.statusValues.statusCode === "200" && componentSliceData.statusValues.showNotification) {
            notify(t(componentSliceData.statusValues.message))
            dispatch(resetStatusCode());
            resetState();
        }
        else if (componentSliceData.statusValues.showNotification) {
            notify(t(componentSliceData.statusValues.message), true)
            dispatch(resetStatusCode());
        }
    }, [componentSliceData.statusValues])

    useEffect(() => {
        if (selectedComponentType === getEnumKeyByValue(ComponentType, ComponentType.OUTERWALL)) {
            setInternalSurfaceResistance("0.13");
            setExternalSurfaceResistance("0.04");
        }
        else if (selectedComponentType === getEnumKeyByValue(ComponentType, ComponentType.INNERWALL)) {
            setInternalSurfaceResistance("0.13");
            setExternalSurfaceResistance("0.13");
        }
        else if (selectedComponentType === getEnumKeyByValue(ComponentType, ComponentType.FLOOR)) {
            setInternalSurfaceResistance("0.17");
            setExternalSurfaceResistance("0");
        }
        else if (selectedComponentType === getEnumKeyByValue(ComponentType, ComponentType.CEILING)) {
            setInternalSurfaceResistance("0.13");
            setExternalSurfaceResistance("0.17");
        }
        else if (selectedComponentType === getEnumKeyByValue(ComponentType, ComponentType.ROOF)) {
            setInternalSurfaceResistance("0.1");
            setExternalSurfaceResistance("0.04");
        }
    }, [selectedComponentType])

    return (
        <div className="w-[531px] md:w-[829px] lg:w-[899px] xl:w-[904px] mx-auto sm:ml-[30px] mt-[27px] md:mt-[40px]">
            <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{t("CreateComponentWithLayeredStructure")}</p>
            <div className="space-y-[25px] mt-[45px] text-#F5F5F5 lg:text-lg xl:text-xl">
                <div className="flex items-center">
                    <p>{t("SelectComponentType")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <CustomDropdown ref={customDropdownRef} dropdownId="selectComponentLSTypeDropdown" options={componentTypeOptions} placeholder={t("SelectComponentType")} height={"h-10"} width={"w-[333px] md:w-[583px] lg:w-[627px]"} onSelectClick={(e) => { setSelectedComponentType(e); setSelectedComponentTypeErrorStatus(false) }} isEmpty={selectedComponentTypeErrorStatus} />
                </div>
                <div className="flex items-center">
                    <p>{t("ComponentCode")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <input id="componentLSCode" type="text" maxLength={5} autoComplete="off" placeholder={t("ComponentCode")} value={componentCode} onChange={(e) => { handleSpaceInInput(e, setComponentCode); setComponentCodeErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${componentCodeErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] outline-none rounded-[5px] shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center">
                    <p>{t("ComponentName")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <input id="componentLSName" type="text" autoComplete="off" placeholder={t("ComponentName")} value={componentName} onChange={(e) => { handleSpaceInInput(e, setComponentName); setComponentNameErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${componentNameErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] outline-none rounded-[5px] shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center">
                    <p className="max-w-[190px] lg:max-w-[400px]">{t("InternalSurfaceResistance")}:</p>
                    <input id="internalSurfaceResistanceLS" type="text" autoComplete="off" placeholder={"0.00"} value={internalSurfaceResistance} onChange={(e) => handleInputChange(e, setInternalSurfaceResistance, 2)} className="bg-#2E333E placeholder:text-#545B6A w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] outline-none rounded-[5px] shadow-inner ml-auto" />
                </div>
                <div className="flex items-center">
                    <p className="max-w-[190px] lg:max-w-[400px]">{t("ExternalSurfaceResistance")}:</p>
                    <input id="externalSurfaceResistanceLS" type="text" autoComplete="off" placeholder={"0.00"} value={externalSurfaceResistance} onChange={(e) => handleInputChange(e, setExternalSurfaceResistance, 2)} className="bg-#2E333E placeholder:text-#545B6A w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] outline-none rounded-[5px] shadow-inner ml-auto" />
                </div>
                <div className="flex items-center">
                    <button id="createComponentLSBtn" onClick={handleCreateComponentClick} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("CreateComponent")}</button>
                </div>
            </div>
        </div>


    );
}

export default ComponentWithLayeredStructure;