import { Button, Dialog } from "@material-tailwind/react";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GeolocationPopup from "./geolocationPopup";
import { handleInputChangeForObjects } from "../../../app/common";
import { GeolocationModel } from "../buildingModels";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { GeolocationDataInput } from "../buildingModels";

interface GeolocationProps {
    isEdit: boolean,
    setGeolocationDataInput: Dispatch<SetStateAction<GeolocationDataInput>>,
    geolocationDataInput: GeolocationDataInput,
}

function Geolocation(props: GeolocationProps) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const handleDeleteDialog = () => setOpenDialog(!openDialog);
    const geolocationData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData.geolocation);

    function handleInputGeolocation(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        if (!value.startsWith(' '))
            props.setGeolocationDataInput({
                ...props.geolocationDataInput,
                [name]: value
            })
    }

    const onChooseClick = () => {
        setOpenDialog(!openDialog);
    }

    const onGeolocationSelect = (data: GeolocationModel) => {
        onChooseClick();
    }

    return (
        <div className="bg-transparent w-full h-fit flex items-center justify-start py-[30px] pl-[30px] pr-[80px]">
            <div className="space-y-[15px] text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] leading-6 w-full">
                <div className="flex items-center pb-[20px]">
                    <p className="pr-[40px]">{t("SelectGeolocation")}</p>
                    < Button id="chooseBtn" className="w-fit h-[30px] px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D" onClick={onChooseClick}>
                        {t("Choose")}
                    </Button>
                </div>
                <div className="flex items-center">
                    <p>{t("Name") + ":"}</p>
                    <input id="name" autoComplete="off" name="name" type="text" placeholder={t("Name")} value={props.geolocationDataInput.name} onChange={(e) => handleInputGeolocation(e)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[284px] md:w-[606px] lg:w-[800px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                </div>
                <div className="flex items-center">
                    <p>{t("PostalCode") + ":"}</p>
                    <input id="postalCode" autoComplete="off" name="postalCode" type="text" placeholder={t("PostalCode")} value={props.geolocationDataInput.postalCode} onChange={(e) => handleInputGeolocation(e)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[284px] md:w-[606px] lg:w-[800px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                </div>
                <div className="flex items-center">
                    <p>{t("Elevation") + ":"}</p>
                    <input id="elevation" autoComplete="off" name="elevation" type="number" placeholder={"0"} value={props.geolocationDataInput.elevation} onChange={(e) => handleInputChangeForObjects(e, props.geolocationDataInput, props.setGeolocationDataInput, 2, undefined, true)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[262px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("AnnualAverageTemperature") + ":"}</p>
                    <input id="annualAverageTemperature" autoComplete="off" name="annualAverageTemperature" type="number" placeholder={"0.00"} value={props.geolocationDataInput.annualAverageTemperature} onChange={(e) => handleInputChangeForObjects(e, props.geolocationDataInput, props.setGeolocationDataInput, 2, undefined, true)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[324px] md:w-[640px] lg:w-[764px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"°C"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("OutsideTemperature") + ":"}</p>
                    <input id="outsideTemperature" autoComplete="off" name="outsideTemperature" type="number" placeholder={"0.00"} value={props.geolocationDataInput.outsideTemperature} onChange={(e) => handleInputChangeForObjects(e, props.geolocationDataInput, props.setGeolocationDataInput, 2, undefined, true)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[258px] md:w-[570px] lg:w-[764px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"°C"}</p>
                </div>
            </div>
            <Dialog open={openDialog} handler={handleDeleteDialog} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex justify-center bg-transparent shadow-none outline-none">
                <GeolocationPopup onGeolocationSelect={onGeolocationSelect} />
            </Dialog>
        </div>
    );
}

export default Geolocation;