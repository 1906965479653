import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidateEmail } from "../../../app/validation";
import { Alert } from "@material-tailwind/react";

function TroubleLoggingInPage() {
    const { t } = useTranslation();
    const [errorMesssage, seterrorMesssage] = useState("");
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");

    function handleSendClick(): void {
        if (email && ValidateEmail(email)) {
            //backend call for Send
            setEmail('');
        } else {
            seterrorMesssage(t("InvalidEmail"));
            setOpen(true);
        }
    }

    function XIcon() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="7.5" stroke="#272C35" />
                <path d="M4.40002 4.3999L11.6 11.5999" stroke="#272C35" strokeLinecap="square" />
                <path d="M4.40015 11.5999L11.6001 4.39988" stroke="#272C35" strokeLinecap="square" />
            </svg>
        );
    }

    return (
        <div className="pt-[5%] w-fit mx-auto">
            <div className="py-[45px] w-[512px]">
                <svg width="321" height="43" viewBox="0 0 321 43" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto">
                    <path d="M32.209 7.86648L24.2301 15.7329V9.55577H19.3846V15.7329L10.8557 7.32544L7.43005 10.7028L15.9577 19.1114H9.69227V23.8887H15.9577L7.97883 31.7551L11.4057 35.1337L19.3846 27.2673V33.4444H24.2301V27.2673L32.209 35.1337L35.6359 31.7551L27.657 23.8887H33.9224V19.1114H27.657L35.6359 11.2451L32.209 7.86648Z" fill="#F7632F" />
                    <path d="M43.6147 19.1113H38.7692V23.8886H43.6147V19.1113Z" fill="#F7632F" />
                    <path d="M24.2302 0H19.3846V4.77725H24.2302V0Z" fill="#F7632F" />
                    <path d="M4.84555 19.1113H0V23.8886H4.84555V19.1113Z" fill="#F7632F" />
                    <path d="M24.2302 38.2229H19.3846V43.0001H24.2302V38.2229Z" fill="#F7632F" />
                    <path d="M72.6678 24.8808V27.0816C71.3749 29.5611 68.0607 31.3686 64.7476 31.3686C59.4599 31.3686 55.6053 27.6604 55.6053 22.5406C55.6053 17.4207 59.4599 13.689 64.7476 13.689C68.0619 13.689 71.3761 15.5425 72.6678 18.0917V20.2701H69.2601C68.8611 18.439 67.0506 17.2577 64.889 17.2341C61.8803 17.2105 59.8122 19.3888 59.8122 22.5406C59.8122 25.6923 61.9043 27.8234 64.865 27.8234C67.0506 27.8234 68.8611 26.6185 69.2601 24.8808H72.6678Z" fill="white" />
                    <path d="M84.7719 14.2681L89.5899 28.5419V30.79H85.8995L85.0296 28.1249H78.4011L77.5312 30.79H73.8407V28.5419L78.6587 14.2681H84.7695H84.7719ZM79.2254 25.1823H84.2316L81.7165 17.7908L79.2254 25.1823Z" fill="white" />
                    <path d="M96.3107 14.2681V27.2448H103.127V30.79H92.1038V14.2681H96.3107Z" fill="white" />
                    <path d="M120.896 24.8808V27.0816C119.603 29.5611 116.288 31.3686 112.975 31.3686C107.688 31.3686 103.833 27.6604 103.833 22.5406C103.833 17.4207 107.688 13.689 112.975 13.689C116.29 13.689 119.604 15.5425 120.896 18.0917V20.2701H117.488C117.089 18.439 115.278 17.2577 113.117 17.2341C110.108 17.2105 108.04 19.3888 108.04 22.5406C108.04 25.6923 110.132 27.8234 113.093 27.8234C115.278 27.8234 117.089 26.6185 117.488 24.8808H120.896Z" fill="white" />
                    <path d="M138.829 14.2681V23.9312C138.829 28.334 135.727 31.37 131.237 31.37C126.748 31.37 123.646 28.334 123.646 23.9312V14.2681H127.852V23.7919C127.852 25.8308 129.075 27.7776 131.237 27.8237C133.283 27.8237 134.645 26.132 134.645 23.7919V14.2681H138.829Z" fill="white" />
                    <path d="M146.42 14.2681V27.2448H153.236V30.79H142.213V14.2681H146.42Z" fill="white" />
                    <path d="M165.222 14.2681L170.04 28.5419V30.79H166.35L165.48 28.1249H158.851L157.981 30.79H154.291V28.5419L159.109 14.2681H165.22H165.222ZM159.676 25.1823H164.682L162.167 17.7908L159.676 25.1823Z" fill="white" />
                    <path d="M183.696 14.2681V17.8132H178.737V30.79H174.53V17.8132H169.57V14.2681H183.696Z" fill="white" />
                    <path d="M185.623 14.2681H189.83V30.79H185.623V14.2681Z" fill="white" />
                    <path d="M201.887 13.688C207.411 13.688 211.195 17.3028 211.195 22.5396C211.195 27.7764 207.411 31.3676 201.887 31.3676C196.364 31.3676 192.58 27.7764 192.58 22.5396C192.58 17.3028 196.364 13.688 201.887 13.688ZM201.887 27.8236C204.896 27.8236 206.988 25.6453 206.988 22.5408C206.988 19.4363 204.896 17.2343 201.887 17.2343C198.879 17.2343 196.787 19.4127 196.787 22.5408C196.787 25.6689 198.879 27.8236 201.887 27.8236Z" fill="white" />
                    <path d="M229.456 30.79H225.083L217.844 19.2969V30.79H213.942V14.2681H218.337L225.577 25.7847V14.2681H229.456V30.79Z" fill="white" />
                    <path d="M252.559 14.2681V17.8132H247.6V30.79H243.393V17.8132H238.434V14.2681H252.559Z" fill="white" />
                    <path d="M262.454 13.688C267.978 13.688 271.762 17.3028 271.762 22.5396C271.762 27.7764 267.978 31.3676 262.454 31.3676C256.931 31.3676 253.147 27.7764 253.147 22.5396C253.147 17.3028 256.931 13.688 262.454 13.688ZM262.454 27.8236C265.463 27.8236 267.555 25.6453 267.555 22.5408C267.555 19.4363 265.463 17.2343 262.454 17.2343C259.446 17.2343 257.353 19.4127 257.353 22.5408C257.353 25.6689 259.446 27.8236 262.454 27.8236Z" fill="white" />
                    <path d="M282.948 13.688C288.472 13.688 292.256 17.3028 292.256 22.5396C292.256 27.7764 288.472 31.3676 282.948 31.3676C277.425 31.3676 273.641 27.7764 273.641 22.5396C273.641 17.3028 277.425 13.688 282.948 13.688ZM282.948 27.8236C285.957 27.8236 288.049 25.6453 288.049 22.5408C288.049 19.4363 285.957 17.2343 282.948 17.2343C279.94 17.2343 277.848 19.4127 277.848 22.5408C277.848 25.6689 279.94 27.8236 282.948 27.8236Z" fill="white" />
                    <path d="M299.212 14.2681V27.2448H306.027V30.79H295.004V14.2681H299.21H299.212Z" fill="white" />
                    <path d="M306.804 27.7539V25.9465H310.188C310.353 27.3133 311.834 28.2867 313.762 28.2867C315.736 28.3103 317.169 27.383 317.169 26.1084C317.169 22.5632 306.498 26.1544 306.498 19.1799C306.498 15.936 309.319 13.688 313.408 13.688C316.793 13.688 319.614 15.0548 320.294 17.0016V18.809H316.933C316.792 17.6041 315.381 16.77 313.525 16.77C311.669 16.77 310.353 17.6041 310.353 18.7866C310.353 22.262 321 18.6944 321 25.5992C321.024 29.005 317.991 31.3688 313.62 31.3688C310.377 31.3688 307.58 29.8862 306.804 27.7539Z" fill="white" />
                </svg>
            </div>
            <div className="w-[512px] mb-1">
                {open &&
                    <Alert icon={<XIcon />} open={open}
                        className=" p-1 pl-3 rounded-none border-none shadow-none text-center bg-[#EF5350] font-light text-xs text-#272C35">
                        {errorMesssage}
                    </Alert>}
            </div>
            <div className="bg-#3B4250 w-[512px] h-[384px] px-[64px] rounded flex flex-col place-content-center ">
                <p className="uppercase text-center text-#E3E7F0 text-[22px] font-normal">{t("TroubleLoggingIn")}</p>
                <p className="mt-[27px] text-center text-#8D929B font-light mb-[32px]">{t("EnterYourEmail")}</p>
                <input id="loginEmail" type="email" name="email" placeholder={t("Email")} value={email} onChange={(e) => setEmail(e.target.value)} className="w-96 h-10 bg-#2E333E placeholder:text-#545B6A text-#F5F5F5 font-light pl-[12px] rounded shadow-inner" />
                <div className="flex place-content-center mt-[30px]">
                    <div className="inline-flex items-center gap-3">
                        <button id="sendBtn" onClick={handleSendClick} className="w-[140px] h-10 bg-#FF9800 rounded shadow-inner uppercase font-semibold">{t("Send")}</button>
                        <div className="w-[17px] h-10 relative">
                            <div className="left-0 top-[13px] absolute text-center text-#8D929B text-xs font-light uppercase">{t("Or")}</div>
                            <div className="w-2.5 h-[0px] left-[9px] top-[10px] absolute origin-top-left -rotate-90 border border-#8D929B"></div>
                            <div className="w-2.5 h-[0px] left-[9px] top-[40px] absolute origin-top-left -rotate-90 border border-#8D929B"></div>
                        </div>
                        <a href="https://www.bimagine.com/register" className="uppercase underline text-#FF9800 font-medium">{t("CreateAccount")}</a>
                    </div>
                </div>

                <div className="mt-[20px]">
                    <hr className="w-72 mx-auto border border-#8D929B" />
                </div>
                <div className="flex place-content-center mt-[20px]">
                    <p className="uppercase text-#8D929B text-sm font-light">{t("ReturnTo")}</p>
                    <a href="/login" className="underline uppercase text-#FF9800 pl-1 text-sm font-semibold">{t("LogIn")}</a>
                </div>
            </div>
        </div >
    );
}

export default TroubleLoggingInPage;
