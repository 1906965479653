import { useTranslation } from "react-i18next";
import CustomDropdown, { CustomDropdownMethods } from "../../../../UIComponents/customDropdown";
import { useEffect, useRef, useState } from "react";
import { formatDateToDateTimeString, handleInputChange, handleSpaceInInput } from "../../../../app/common";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { OpeningModelForCreate } from "../componentsModels";
import { CreateOpening, resetStatusCode } from "../componentsSlice";
import { notify } from "../../../../UIComponents/notification";
import { openingTypeOptions } from "../../../../app/optionModels";

function Opening() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const customDropdownRef = useRef<CustomDropdownMethods>(null);
    const [selectedOpeningType, setSelectedOpeningType] = useState("");
    const [openingName, setOpeningName] = useState("");
    const [openingCode, setOpeningCode] = useState("");
    const [thermalTransmittance, setThermalTransmittance] = useState("")
    const [selectedOpeningTypeErrorStatus, setSelectedOpeningTypeErrorStatus] = useState(false);
    const [openingNameErrorStatus, setOpeningNameErrorStatus] = useState(false);
    const [openingCodeErrorStatus, setOpeningCodeErrorStatus] = useState(false);
    const [thermalTransmittanceErrorStatus, setThermalTransmittanceErrorStatus] = useState(false)
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const componentSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);

    function handleCreateOpeningClick(): void {
        if (selectedOpeningType === "") { setSelectedOpeningTypeErrorStatus(true); }
        if (openingCode === "") { setOpeningCodeErrorStatus(true); }
        if (openingName === "") { setOpeningNameErrorStatus(true); }
        if (thermalTransmittance === "") { setThermalTransmittanceErrorStatus(true); }

        const currentDate = formatDateToDateTimeString(new Date());

        if (selectedOpeningType !== "" && openingName !== "" && openingCode !== "" && thermalTransmittance !== "") {
            const openingModel: OpeningModelForCreate = {
                projectId: projectId,
                userId: localStorage.getItem("userId")!,
                openingName: openingName,
                openingCode: openingCode,
                thermalTransmittance: parseFloat(thermalTransmittance),
                openingType: selectedOpeningType,
                dateCreatedOrModified: currentDate
            }
            dispatch(CreateOpening(openingModel))
        }
    }

    function resetState(): void {
        customDropdownRef.current && customDropdownRef.current.resetDropdown();
        setSelectedOpeningType("");
        setSelectedOpeningType("");
        setOpeningName("");
        setOpeningCode("");

        setThermalTransmittance("")
    }

    useEffect(() => {
        if (componentSliceData.statusValues.statusCode === "") {
            return;
        }
        if (componentSliceData.statusValues.statusCode === "200" && componentSliceData.statusValues.showNotification) {
            notify(t(componentSliceData.statusValues.message))
            dispatch(resetStatusCode());
            resetState();
        }
        else if (componentSliceData.statusValues.showNotification) {
            notify(t(componentSliceData.statusValues.message), true)
            dispatch(resetStatusCode());
        }
    }, [componentSliceData.statusValues])

    return (
        <div className="w-[531px] md:w-[829px] lg:w-[899px] xl:w-[904px] mx-auto sm:ml-[30px] mt-[27px] md:mt-[40px]">
            <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{t("CreateOpening")}</p>
            <div className="space-y-[25px] mt-[45px] text-#F5F5F5 lg:text-lg xl:text-xl">
                <div className="flex items-center gap-8">
                    <p>{t("SelectOpeningType")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <CustomDropdown ref={customDropdownRef} dropdownId="selectOpeningTypeDropdown" options={openingTypeOptions} placeholder={t("SelectOpeningType")} height={"h-10"} width={"w-[333px] md:w-[583px] lg:w-[627px]"} onSelectClick={(e) => { setSelectedOpeningType(e); setSelectedOpeningTypeErrorStatus(false) }} isEmpty={selectedOpeningTypeErrorStatus} />
                </div>
                <div className="flex items-center">
                    <p>{t("OpeningCode")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <input id="openingCode" type="text" autoComplete="off" maxLength={5} placeholder={t("OpeningCode")} value={openingCode} onChange={(e) => { handleSpaceInInput(e, setOpeningCode); setOpeningCodeErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${openingCodeErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center gap-8">
                    <p>{t("OpeningName")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <input id="openingName" type="text" autoComplete="off" placeholder={t("OpeningName")} value={openingName} onChange={(e) => { handleSpaceInInput(e, setOpeningName); setOpeningNameErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${openingNameErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[333px] md:w-[583px] lg:w-[627px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center">
                    <p>{t("ThermalTransmittance")}:<span className="text-#EF5350 pl-1">*</span></p>
                    <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                        <input id="openingThermalTransmittance" type="text" autoComplete="off" placeholder={"0.000"} value={thermalTransmittance} onChange={(e) => { handleInputChange(e, setThermalTransmittance, 3); setThermalTransmittanceErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${thermalTransmittanceErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[270px] md:w-[515px] lg:w-[548px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                        <p className="w-[55px] md:w-[60px]">W/m<sup>2</sup>K</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <button id="createOpeningBtn" onClick={handleCreateOpeningClick} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("CreateOpening")}</button>
                </div>
            </div>
        </div>
    );
}

export default Opening;