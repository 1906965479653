import { Middleware } from 'redux';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { CalculationToolsAccessToken } from './app/common';
import { RefreshAccessToken } from './elements/Auth/AuthSlice';

export const tokenExpirationMiddleware: Middleware = (storeAPI) => (next) => (action) => {
    //None of the AuthentificationSlice should refresh token,
    //Persist are the ones for persisting data in redux
    //no_ are the methods in slices that don't need to be refreshed, so the name has to match
    if (action.type.startsWith('AuthSlicer') || action.type.startsWith('persist')) {
        return next(action)
    }

    const state = storeAPI.getState();
    const isRefreshingToken = state.AuthSlice.isRefreshingToken

    if (isRefreshingToken === true) {
        return next(action);
    }
    // Check if the action requires a token and if the token is expired
    let token = localStorage.getItem(CalculationToolsAccessToken);

    if (token) {
        const tokenExpired = isTokenExpired(token); // You need to implement isTokenExpired
        if (tokenExpired === true) {
            //It's our token:

            // Dispatch an action to refresh the token
            storeAPI.dispatch(RefreshAccessToken() as any).then((result: any) => {
                if (result.payload === 200 || result.payload === "200")
                    return next(action);
                else {
                    localStorage.clear();
                    window.location.replace("/*")
                }
            })
        }
        else {
            return next(action)
        }
    }
    else {
        // If the action does not require a token or the token is not expired, proceed as usual
        return next(action);
    }
};

function isTokenExpired(token: string | null): boolean {
    if (token !== null && token !== undefined) {
        const decoded = jwtDecode<JwtPayload>(token);
        let dateTime = Math.floor(Date.now() / 1000);
        if (decoded.exp && decoded.exp < dateTime) {
            return true;
        }
    }

    return false;
}
