import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { orientationOptions } from "../../../app/optionModels";
import { GetAllComponentsWithFixedValues, GetAllComponentsWithLayeredStructure, GetAllOpenings } from "../../Components/Component/componentsSlice";
import { formatDateToDateTimeString, formatNumber, getEnumValueByKey, handleInputChange } from "../../../app/common";
import { EnvironmentType } from "../../../app/enums";
import Environment from "./environmentPopup";
import { CreateOpeningInWall, CreateWall, UpdateOpeningInWall, UpdateWall } from "../buildingStructureSlice";
import { OpeningInWallModel, OpeningInWallModelForCreate, OpeningInWallModelForUpdate, WallModel, WallModelForCreate, WallModelForUpdate } from "../buildingStructureModels";
import { Menu, MenuHandler, MenuItem, MenuList } from "@material-tailwind/react";
import ComponentOpeningPopup from "./componentOpeningPopup";
import { useTranslation } from "react-i18next";

type NewRowProps =
    | {
        opening: true;
        spaceId: string;
        wallOrientation: string;
        wallEnvironment: string;
        wallPairId: string;
        isWallEdit?: boolean;
        wallData?: WallModel;
        isOpeningEdit?: boolean;
        openingData?: OpeningInWallModel;
        onDoubleClick?: () => void;
    }
    | {
        opening?: false;
        spaceId: string;
        wallOrientation?: string;
        wallEnvironment?: string;
        wallPairId?: string;
        isWallEdit?: boolean;
        wallData?: WallModel;
        isOpeningEdit?: boolean;
        openingData?: OpeningInWallModel;
        onDoubleClick?: () => void;
    };

function NewRow(props: NewRowProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [codeId, setCodeId] = useState("");
    const [code, setCode] = useState("");
    const [orientation, setOrientation] = useState("");
    const [environment, setEnvironment] = useState("");
    const [length, setLength] = useState("");
    const [widthHeight, setWidthHeight] = useState("");
    const [number, setNumber] = useState("");
    const [areas, setAreas] = useState("");
    const [usefulArea, setUsefulArea] = useState("");
    const [temperature, setTemperature] = useState<string | null>(null);
    const [bu, setBu] = useState("");
    const [uValue, setUValue] = useState("");
    const [thermalBridge, setThermalBridge] = useState("");
    const [correctedUValue, setCorrectedUValue] = useState("");
    const [htc, setHtc] = useState("");
    const [ft, setFt] = useState("");

    const [isEnviromentOpen, setIsEnviromentOpen] = useState<boolean>(false);
    const [isComponentOpeningOpen, setIsComponentOpeningOpen] = useState<boolean>(false);

    const [nextSpaceId, setNextSpaceId] = useState<string | null>(null);
    const [unheatedSpaceProperty, setUnheatedSpaceProperty] = useState<string | null>(null);
    const [correctionFactorForTheGround, setCorrectionFactorForTheGround] = useState<string | null>(null);

    const [itemInCollectionId, setItemInCollectionId] = useState<string | null>(null);

    const [checkError, setCheckError] = useState<boolean>(false);

    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    function resetState(): void {
        setCode("");
        setOrientation("");
        setEnvironment("");
        setNextSpaceId("");
        setUnheatedSpaceProperty("");
        setCorrectionFactorForTheGround("");
        setLength("");
        setWidthHeight("");
        setNumber("");
        setAreas("");
        setUsefulArea("");
        setTemperature("");
        setBu("");
        setUValue("");
        setThermalBridge("");
        setCorrectedUValue("");
        setHtc("");
        setFt("");
    }

    function setEnvironmentProps(environment: string, nextSpaceId: string | null, temperature: string | null, unheatedSpaceProperty: string | null, correctionFactorForTheGround: string | null) {
        setEnvironment(environment);
        setNextSpaceId(nextSpaceId);
        setUnheatedSpaceProperty(unheatedSpaceProperty);
        setCorrectionFactorForTheGround(correctionFactorForTheGround);
        setIsEnviromentOpen(false);
        setTemperature(temperature)
    }

    function handleSaveChanges(): void {
        setCheckError(true)
        const currentDate = formatDateToDateTimeString(new Date());
        if (props.opening || props.isOpeningEdit) {
            if (code && length && widthHeight && number) {
                if (props.isOpeningEdit) {
                    const openingToUpdate: OpeningInWallModelForUpdate = {
                        wallId: props.openingData?.wallId!,
                        openingInWallId: props.openingData?.openingInWallId!,
                        wallPairId: props.openingData?.openingInWallPairId!,
                        userId: localStorage.getItem("userId")!,
                        openingId: codeId,
                        width: parseFloat(length),
                        height: parseFloat(widthHeight),
                        number: parseFloat(number),
                        dateCreatedOrModified: currentDate,
                        projectId: projectId,
                        openingInCollectionId: itemInCollectionId
                    }
                    dispatch(UpdateOpeningInWall(openingToUpdate));
                }
                else {
                    const opening: OpeningInWallModelForCreate = {
                        wallPairId: props.wallPairId!,
                        userId: localStorage.getItem("userId")!,
                        openingId: codeId,
                        width: parseFloat(length),
                        height: parseFloat(widthHeight),
                        number: parseFloat(number),
                        dateCreatedOrModified: currentDate,
                        projectId: projectId,
                        openingInCollectionId: itemInCollectionId
                    }
                    dispatch(CreateOpeningInWall(opening));
                }
            }
        }
        else {
            if (code && orientation && environment && length && widthHeight) {
                if (props.isWallEdit) {
                    const wallToUpdate: WallModelForUpdate = {
                        wallId: props.wallData?.wallId!,
                        pairId: props.wallData?.pairId!,
                        componentId: codeId,
                        spaceId: buildingStructureSliceData.buildingStructureData.roomData.spaceId,
                        userId: localStorage.getItem("userId")!,
                        orientation: orientation,
                        environmentType: environment,
                        length: parseFloat(length),
                        widthHeight: parseFloat(widthHeight),
                        nextSpaceId: nextSpaceId,
                        temperature: nextSpaceId === null || nextSpaceId === "" ? (temperature ? parseFloat(temperature) : null) : null,
                        unheatedSpaceProperty: unheatedSpaceProperty,
                        dateCreatedOrModified: currentDate,
                        correctionFactorForTheGround: correctionFactorForTheGround,
                        projectId: projectId,
                        componentInCollectionId: itemInCollectionId
                    }
                    dispatch(UpdateWall(wallToUpdate));
                    setCheckError(false)
                }
                else {
                    const wall: WallModelForCreate = {
                        componentId: codeId,
                        spaceId: buildingStructureSliceData.buildingStructureData.roomData.spaceId,
                        userId: localStorage.getItem("userId")!,
                        orientation: orientation,
                        environmentType: environment,
                        length: parseFloat(length),
                        widthHeight: parseFloat(widthHeight),
                        nextSpaceId: nextSpaceId,
                        temperature: temperature ? parseFloat(temperature) : null,
                        unheatedSpaceProperty: unheatedSpaceProperty,
                        dateCreatedOrModified: currentDate,
                        correctionFactorForTheGround: correctionFactorForTheGround,
                        projectId: projectId,
                        componentInCollectionId: itemInCollectionId
                    }
                    dispatch(CreateWall(wall));
                    setCheckError(false)
                }
            }
        }
    }

    function onCodeSelect(code: string, id: string, itemInCollectionId: string | null) {
        setCode(code);
        setCodeId(id);
        setItemInCollectionId(itemInCollectionId);
        setIsComponentOpeningOpen(false);
    }

    function onCodeClick(): void {
        setIsComponentOpeningOpen(true);
    }

    function onEnviromentClick(): void {
        setIsEnviromentOpen(true);
    }

    useEffect(() => {
        if (props.opening) {
            dispatch(GetAllOpenings(projectId))
        }
        else {
            dispatch(GetAllComponentsWithFixedValues(projectId))
            dispatch(GetAllComponentsWithLayeredStructure(projectId))
        }
        if (props.isWallEdit && props.wallData) {
            setCodeId(props.wallData.componentId);
            setCode(props.wallData.name);
            setOrientation(props.wallData.orientation);
            setEnvironment(props.wallData.environmentType);
            setLength(props.wallData.length?.toString());
            setWidthHeight(props.wallData.widthHeight?.toString());
            setNumber("1");
            setAreas(props.wallData.area?.toString());
            setUsefulArea(props.wallData.usefulArea?.toString());
            setNextSpaceId(props.wallData.nextSpaceId);
            setTemperature(props.wallData.temperature?.toString());
            setUnheatedSpaceProperty(props.wallData.unheatedSpaceProperty);
            setCorrectionFactorForTheGround(props.wallData.correctionFactorForTheGround);
            setBu(props.wallData.bu?.toString());
            setUValue(props.wallData.thermalTransmittance?.toString());
            setThermalBridge(props.wallData.thermalBridge?.toString());
            setCorrectedUValue(props.wallData.correctedUValue?.toString());
            setHtc(props.wallData.heatTransferCoefficient?.toString());
            setFt(props.wallData.transmissionLoss?.toString());
        }
        else if (props.isOpeningEdit && props.openingData) {
            setCodeId(props.openingData.openingId);
            setCode(props.openingData.name);
            setOrientation(props.openingData.orientation);
            setEnvironment(props.openingData.environmentType);
            setLength(props.openingData.width?.toString());
            setWidthHeight(props.openingData.height?.toString());
            setNumber(props.openingData.number.toString());
            setAreas(props.openingData.area?.toString());
            setUsefulArea("-");
            setTemperature(props.openingData.temperature ? props.openingData.temperature?.toString() : "");
            setBu(props.openingData.bu?.toString());
            setUValue(props.openingData.thermalTransmittance?.toString());
            setThermalBridge(props.openingData.thermalBridge?.toString());
            setCorrectedUValue(props.openingData.correctedUValue?.toString());
            setHtc(props.openingData.heatTransferCoefficient?.toString());
            setFt(props.openingData.transmissionLoss?.toString());
        }
    }, [])

    useEffect(() => {
        if (buildingStructureSliceData.statusValues.statusCode === "200" && buildingStructureSliceData.statusValues.showNotification) {
            resetState()
        }
    }, [buildingStructureSliceData.statusValues])

    useEffect(() => {
        if (orientation !== 'H' && orientation !== '' && !number && !widthHeight) {
            setWidthHeight(buildingStructureSliceData.buildingStructureData.roomData.roomHeight.toString());
        } else if (orientation === 'H' && !number) {
            setWidthHeight('');
        }
    }, [orientation])

    function OrientationDropdown() {
        const [openMenu, setOpenMenu] = useState(false);

        return (
            <div className={`w-[96px] xl:w-[122px] h-[33px] bg-#414959 shadow-inner px-4 ${!orientation && checkError && "border-[1px] border-#EF5350"}`}>
                <Menu open={openMenu} handler={setOpenMenu}>
                    <MenuHandler id="orientationDropdown">
                        <div className="w-full h-full inline-flex items-center cursor-pointer">
                            <p>{orientation ? orientation : ""}</p>
                            <svg className="ml-auto" xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none" transform={openMenu ? "rotate(180)" : ""}>
                                <path d="M15 1L8 7L1 1" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </MenuHandler>
                    <MenuList className="max-h-[150px] min-w-[105px] w-[105px] xl:w-[127px] rounded-[5px] text-#F5F5F5 bg-#414959 border-none p-0 m-0">
                        {orientationOptions.map((orientation) => (
                            <MenuItem id={orientation.key} key={orientation.key} value={orientation.key} onClick={() => setOrientation(orientation.key)} className="px-4 py-2 hover:bg-#D79F451A hover:bg-opacity-80 focus:bg-#D79F451A focus:bg-opacity-100 active:bg-#D79F451A active:bg-opacity-100 hover:text-#F5F5F5 focus:text-#F5F5F5 active:text-#F5F5F5">
                                {orientation.value}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </div>
        );
    }

    return (
        <div className="min-w-fit text-xs xl:text-lg">
            <div id={props.isWallEdit ? props.wallData?.wallId : props.openingData?.openingInWallId} className={`${props.opening ? "bg-#545B6A" : "bg-#676D7A"} h-[45px] border-b border-b-#3B4250 text-#F5F5F5 flex justify-between`} onDoubleClick={props.onDoubleClick}>
                <div className="flex justify-center px-2">
                    <input id="code" onChange={() => { }} type="text" value={code} name="code" autoComplete="off" onClick={onCodeClick} className={`text-center w-[104px] xl:w-[154px] bg-#414959 h-[33px] my-auto outline-none cursor-pointer caret-transparent ${!code && checkError && "border-[1px] border-#EF5350"}`} />
                </div>
                {
                    !props.opening ?
                        <div className={`flex items-center px-3`}>
                            <OrientationDropdown />
                        </div>
                        : <p className="flex items-center justify-center w-[120px] xl:w-[146px]">{props.wallOrientation}</p>
                }
                {
                    !props.opening ?
                        <input id="enviroment" onChange={() => { }} type="text" value={environment ? t(getEnumValueByKey(EnvironmentType, environment)) : ""} name="enviroment" autoComplete="off" onClick={onEnviromentClick} className={`px-4 py-3 m-[10px] text-center w-[150px] xl:w-[190px] bg-#414959 h-[33px] my-auto outline-none cursor-pointer caret-transparent ${!environment && checkError && "border-[1px] border-#EF5350"}`} />
                        : <p className="flex items-center justify-center w-[170px] xl:w-[210px]">{t(getEnumValueByKey(EnvironmentType, props.wallEnvironment!))}</p>
                }
                <input id="lengthInput" onChange={(e) => handleInputChange(e, setLength, 2)} type="text" value={length} name="length" autoComplete="off" className={`px-4 py-3 text-center m-[10px] w-[65px] xl:w-[79px] bg-#414959 h-[33px] my-auto outline-none ${!length && checkError && "border-[1px] border-#EF5350"}`} />
                <input id="heightInput" onChange={(e) => handleInputChange(e, setWidthHeight, 2)} type="text" value={widthHeight} name="widthHeight" autoComplete="off" className={`px-4 py-3 text-center m-[10px] w-[115px] xl:w-[138px] bg-#414959 h-[33px] my-auto outline-none ${!widthHeight && checkError && "border-[1px] border-#EF5350"}`} />
                {
                    props.opening ?
                        <input id="number" onChange={(e) => handleInputChange(e, setNumber, 0)} type="text" value={number} name="number" autoComplete="off" className={`px-4 py-3 text-center w-[92px] xl:w-[113px] bg-#414959 h-[33px] my-auto outline-none ${!number && checkError && " border-[1px] border-#EF5350"}`} />
                        : <p className="flex items-center justify-center w-[92px] xl:w-[113px]">{formatNumber(parseFloat(number), 2)}</p>
                }
                <p id="area" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[95px] xl:w-[115px]">{formatNumber(parseFloat(areas), 2)}</p>
                <p id="usefulArea" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[119px] xl:w-[142px]">{formatNumber(parseFloat(usefulArea), 2)}</p>
                <p id="temperature" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[128px] xl:w-[153px]">{formatNumber(parseFloat(temperature !== null ? temperature : ""), 2)}</p>
                <p id="bu" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[70px] xl:w-[80px]">{formatNumber(parseFloat(bu), 2)}</p>
                <p id="uValue" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[70px] xl:w-[80px]">{formatNumber(parseFloat(uValue), 2)}</p>
                <p id="thermalBridge" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[146px] xl:w-[176px]">{formatNumber(parseFloat(thermalBridge), 2)}</p>
                <p id="correctedUValue" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[124px] xl:w-[148px]">{formatNumber(parseFloat(correctedUValue), 2)}</p>
                <p id="htc" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[70px] xl:w-[80px]">{formatNumber(parseFloat(htc), 2)}</p>
                <p id="ft" className="flex items-center justify-center bg-transparent h-[33px] my-auto outline-none w-[70px] xl:w-[80px]">{formatNumber(parseFloat(ft), 2)}</p>
                <div className="w-[146px] xl:w-[220px]" />
                <div className="flex items-center justify-center bg-transparent w-[75px] xl:w-[85px] text-center">
                    <button id="saveChangesBtn" type="submit" onClick={handleSaveChanges}>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10.5" cy="10.5" r="10.5" fill="#3EAD43" className="hover:fill-#7EB608" />
                            <path d="M14.6355 7.94036C14.7306 8.04048 14.7817 8.17391 14.7775 8.31132C14.7733 8.44873 14.7142 8.57887 14.6131 8.67312L9.07842 13.8397C8.96559 13.945 8.81566 14.0023 8.66071 13.9995C8.50575 13.9966 8.35807 13.9338 8.24925 13.8245L6.37876 11.9443C6.32923 11.896 6.28987 11.8386 6.26299 11.7752C6.2361 11.7117 6.22223 11.6437 6.22217 11.5749C6.22211 11.5061 6.23587 11.438 6.26264 11.3745C6.28941 11.3111 6.32866 11.2535 6.37811 11.2052C6.42756 11.157 6.48622 11.1189 6.55066 11.0933C6.6151 11.0677 6.68405 11.055 6.75348 11.056C6.82292 11.0571 6.89145 11.0718 6.95509 11.0993C7.01873 11.1268 7.0762 11.1666 7.12417 11.2163L8.68338 12.7835L13.8956 7.91824C13.9967 7.82403 14.1314 7.77343 14.2702 7.77758C14.4089 7.78173 14.5403 7.84028 14.6355 7.94036Z" className="stroke-#F5F5F5" />
                        </svg>
                    </button>
                </div>
                {isEnviromentOpen && <Environment spaceId={props.spaceId} environment={environment} nextSpaceId={nextSpaceId} temperature={temperature} unheatedSpaceProperty={unheatedSpaceProperty} correctionFactorForTheGround={correctionFactorForTheGround} onCancelClickFunction={() => setIsEnviromentOpen(false)} onContinueClickFunction={setEnvironmentProps} />}
                {isComponentOpeningOpen && <ComponentOpeningPopup onCodeSelect={onCodeSelect} projectId={projectId} opening={props.opening!} onClose={() => setIsComponentOpeningOpen(false)} />}
            </div>
        </div>
    );
}

export default NewRow;