import { act } from "@testing-library/react";
import { useTranslation } from "react-i18next";

export function extractErrorMessageFromException(exceptionString: string): string {
    const newRegex = /Validation failed:[^:]+[\s\S]([\s\S]+?)\sSeverity:/;
    const newMatch = newRegex.exec(exceptionString);
    if (newMatch && newMatch.length > 1) {
        return newMatch[1];
    }

    const oldRegex = /System\.Exception: ([\s\S]+?) -/;
    const oldMatch = oldRegex.exec(exceptionString);
    if (oldMatch && oldMatch.length > 1) {
        return oldMatch[1];
    }

    return 'Error in Application';
}

export function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, setStateFunction: React.Dispatch<React.SetStateAction<string>>, lengthAfterDot: number, lengthBeforeDot?: number, allowNegative?: boolean) {
    const newValue = event.target.value;
    const regexPattern = new RegExp(
        allowNegative ?
            (lengthBeforeDot ? `^-?\\d{0,${lengthBeforeDot}}(?:\\.\\d{0,${lengthAfterDot}})?$` : `^-?\\d*\\.?\\d{0,${lengthAfterDot}}$`)
            :
            (lengthBeforeDot ? `^\\d{0,${lengthBeforeDot}}(?:\\.\\d{0,${lengthAfterDot}})?$` : `^\\d*\\.?\\d{0,${lengthAfterDot}}$`)
    );
    if (regexPattern.test(newValue)) {
        setStateFunction(newValue);
    }
}

export function handleInputChangeForObjects(e: React.ChangeEvent<HTMLInputElement>, state: Object, setStateFunction: React.Dispatch<React.SetStateAction<any>>, lengthAfterDot: number, lengthBeforeDot?: number, allowNegative?: boolean) {
    const { name, value } = e.target;
    const regexPattern = new RegExp(
        allowNegative ?
            (lengthBeforeDot ? `^-?\\d{0,${lengthBeforeDot}}(?:\\.\\d{0,${lengthAfterDot}})?$` : `^-?\\d*\\.?\\d{0,${lengthAfterDot}}$`)
            :
            (lengthBeforeDot ? `^\\d{0,${lengthBeforeDot}}(?:\\.\\d{0,${lengthAfterDot}})?$` : `^\\d*\\.?\\d{0,${lengthAfterDot}}$`)
    );
    if (regexPattern.test(value)) {
        setStateFunction({ ...state, [name]: value });
    }
}

export function handleSpaceInInput(event: React.ChangeEvent<HTMLInputElement>, setStateFunction: React.Dispatch<React.SetStateAction<string>>) {
    const inputValue = event.target.value;
    const regexPattern = new RegExp(/^[^ ].*$/);
    if (regexPattern.test(inputValue) || inputValue === "") {
        setStateFunction(inputValue);
    }
}

export function handleSpaceInInputForObjects(event: React.ChangeEvent<HTMLInputElement>, state: Object, setStateFunction: React.Dispatch<React.SetStateAction<any>>) {
    const { name, value } = event.target;
    const regexPattern = new RegExp(/^[^ ].*$/);
    if (regexPattern.test(value) || value === "") {
        setStateFunction({ ...state, [name]: value });
    }
}

export function formatNumber(value: number, lengthAfterDot: number): string {
    if (value === null) return value;
    if (isNaN(value)) return "";
    let formattedNumber = value.toFixed(lengthAfterDot);
    if (lengthAfterDot > 0 && formattedNumber.includes('.') && formattedNumber.endsWith('0')) {
        while (formattedNumber.endsWith('0')) {
            formattedNumber = formattedNumber.slice(0, -1);
        }
        if (formattedNumber.endsWith('.')) {
            formattedNumber = formattedNumber.slice(0, -1);
        }
    }
    return formattedNumber;
};

export function trimNumber(value: number, lengthAfterDot: number): string {
    if (value === null) return value;
    if (isNaN(value)) return "";
    let formattedNumber = value.toFixed(lengthAfterDot);
    return formattedNumber;
};

export function getEnumKeyByValue(enumType: any, enumValue: any): string {
    const keys = Object.keys(enumType).filter((key) => isNaN(Number(key)));
    const foundKey = keys.find((key) => enumType[key] === enumValue);
    if (foundKey)
        return foundKey;
    else
        return enumValue;
}

export const getEnumValueByKey = (enumType: any, key: string): any | undefined => {
    const enumKeys = Object.keys(enumType).filter(k => typeof enumType[k as keyof typeof enumType] === 'string');
    if (enumKeys.includes(key)) {
        return enumType[key as keyof typeof enumType];
    }
    return undefined;
};

export function truncateString(inputString: string, maxLength: number): string {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.substring(0, maxLength) + '...';
    }
}

export const formatDateToDateTimeString = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const FormatUTCDateTimeToDateString = (utcDateTime: string): string => {
    const { t } = useTranslation();
    if (utcDateTime === "") return "";

    const utcDate = new Date(utcDateTime);
    const day = utcDate.getUTCDate().toString();
    const month = (utcDate.getUTCMonth() + 1).toString();
    const year = utcDate.getUTCFullYear().toString();

    const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(utcDate);

    return `${day} ${t(monthName)} ${year}`;
};

export const FormatDateTimeToDateString = (fullDateTime: string): string => {
    const { t } = useTranslation();
    if (fullDateTime === "") return "";
    const [datePart, timePart] = fullDateTime.split(" ");
    const [day, month, year] = datePart.split(".");
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));

    return `${day} ${t(monthName)} ${year}`;
};

export function ConvertDateToString(convertDate: Date, separator: string = '-') {
    let date = convertDate.getDate();
    let month = convertDate.getMonth();
    let year = convertDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
}

export function GetCurrentDate() {

    return new Date();
}

export function RemoveItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export async function HandleErrorsFromBack(response: Response) {
    const errorResponse = (await response.json()) as ErrorResponse;
    let properties: string[];
    let errorTitle: string;
    if ('errors' in errorResponse && Object.keys(errorResponse.errors).length > 0) {
        properties = Object.keys(errorResponse.errors);
        errorTitle = errorResponse.errors[properties[0]];
        return { message: errorTitle, status: response.status.toString() };
    } else {
        return { message: errorResponse.detail, status: response.status.toString() };
    }
}

export interface ValueResponse<T> {
    value: T;
    status: string;
    message: string;
}

export interface RejectedValue {
    message: string;
    status: string;
}

export interface StatusValue {
    statusCode: string,
    isLoading: boolean,
    showNotification: boolean,
    message: string,
}

export interface ErrorResponse {
    type: string,
    title: string,
    status: number,
    detail: string,
    instance: string,
    errors: any,
}

//Slice:
export function handleFulfilled(state: StatusValue, actionData: ValueResponse<any>): any {
    state.statusCode = actionData.status.toString() ?? 'Code is not valid';
    state.isLoading = false;
    state.message = actionData.message ?? 'Error in application';

    return actionData.value;
}

export function handleRejected(state: StatusValue, actionData: RejectedValue) {
    state.statusCode = actionData.status.toString() ?? 'Code is not valid';
    state.isLoading = false;
    state.message = actionData.message ?? 'Error in application';
}

export function handlePending(state: StatusValue) {
    state.isLoading = true;
}

export const CalculationToolsRefreshToken = "calculationToolsRefreshToken";
export const CalculationToolsAccessToken = "calculationToolsTAccessToken";
