import { useTranslation } from "react-i18next";
import MaterialComponent from "./materialComponent";
import { useEffect, useState } from "react";
import CustomDropdown, { OptionModel } from "../../../../UIComponents/customDropdown";
import { MaterialModel } from "../materialModels";
import DeleteCard from "../../../../UIComponents/deleteCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { DeleteMaterial, GetAllMaterials, resetStatusCode } from "../materialSlice";
import { getEnumValueByKey } from "../../../../app/common";
import { MaterialType } from "../../../../app/enums";
import { NotificationPopup, notify } from "../../../../UIComponents/notification";
import { LoadingPage } from "../../../../UIComponents/loading";
import { resetArea, resetFloor } from "../../../BuildingStructure/buildingStructureSlice";

function MaterialsPage() {
    const { t } = useTranslation();
    const [materials, setMaterials] = useState<MaterialModel[]>([]);
    const [searchValue, setSearchValue] = useState("")
    const [filterValue, setFilterValue] = useState("")
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [selectedMaterialId, setSelectedMaterialId] = useState("");
    const [options] = useState<OptionModel[]>([])
    const dispatch = useDispatch<AppDispatch>();
    const materialSliceData = useSelector((state: RootState) => state.MaterialSlice.materialSliceData);

    useEffect(() => {
        dispatch(resetArea());
        dispatch(resetFloor());
        dispatch(GetAllMaterials());
    }, [])

    useEffect(() => {
        setMaterials(materialSliceData.materialData.allMaterialsData);
        dispatch(resetStatusCode())
    }, [materialSliceData.materialData.allMaterialsData])

    useEffect(() => {
        options.splice(0, options.length)
        if (options.length === 0) {
            options.push({ key: "-1", value: t("ShowAll") })
        }
        materials.map(material => {
            if (!options.map(cat => cat.key).includes(material.materialType)) {
                options.push({ key: material.materialType, value: getEnumValueByKey(MaterialType, material.materialType) })
            }
        })
    }, [materials])

    useEffect(() => {
        if (materialSliceData.statusValues.statusCode === "") {
            return
        }
        if (materialSliceData.statusValues.statusCode === "200" && materialSliceData.statusValues.showNotification) {
            notify(t(materialSliceData.statusValues.message))
            dispatch(GetAllMaterials());
            dispatch(resetStatusCode())
        }
        else if (materialSliceData.statusValues.statusCode !== "200" && materialSliceData.statusValues.showNotification) {
            notify(t(materialSliceData.statusValues.message), true)
            dispatch(resetStatusCode())
        }

    }, [materialSliceData.statusValues])

    function handleDeleteClick(materialId: string): void {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisMaterial"));
        setIsDelete(true);
        setSelectedMaterialId(materialId)
    }

    function deleteSelected(): void {
        dispatch(DeleteMaterial(selectedMaterialId))
        setIsDelete(false)
    }

    return (
        <div>
            {materials.length > 0 &&
                <div className={`${materialSliceData.statusValues.isLoading && "blur-5"}`}>
                    <div className="mt-[19px] mx-3 flex">
                        <div className="relative">
                            <input id="searchMaterials" type="text" autoComplete="off" placeholder={t("Search")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="relative bg-#2E333E placeholder:text-#545B6A text-#F5F5F5 w-[321px] md:w-[473px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto" />
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute translate-x-[280px] md:translate-x-[435px] -translate-y-[32px]">
                                <circle cx="11.5" cy="11.5" r="9.5" stroke="white" strokeWidth="1.5" />
                                <path d="M18.5 18.5L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </div>
                        <div className="ml-auto">
                            <CustomDropdown dropdownId="filterByDropdown" options={options} placeholder={t("FilterBy")} height={"h-10"} width={"w-[300px]"} onSelectClick={setFilterValue} />
                        </div>
                    </div>
                    <div className="w-[586px] m-[12px] sm:w-[calc(100vw-70px-24px)] px-[20px] py-[15px] grid grid-cols-3 text-#F5F5F5 text-sm md:text-base rounded-[10px] place-content-center">
                        <p className="col-span-1 my-auto">{t("Material")}</p>
                        <p className="my-auto px-2">{t("Category")}</p>
                        <p className="text-center my-auto">{t("ThermalConductivity")}</p>
                        {/* <p className="text-center my-auto">{t("Edit")}</p>
                        <p className="text-center my-auto">{t("Delete")}</p> */}
                    </div>
                    {materials.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()) && (filterValue === "-1" ? item : item.materialType.includes(filterValue))).map(material => (
                        <MaterialComponent key={material.materialId} material={material} onDeleteClick={handleDeleteClick} />
                    ))}
                </div>}
            <NotificationPopup />
            {materialSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(materialSliceData.statusValues.isLoading)}</div>}
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} /> : <></>}
        </div>
    );
}

export default MaterialsPage;
