import { Dialog, Tooltip } from "@material-tailwind/react";
import { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown, { CustomDropdownMethods, OptionModel } from "../../../../UIComponents/customDropdown";
import DeleteCard from "../../../../UIComponents/deleteCard";
import { ComponentModel, MaterialInComponentModel, MaterialInComponentModelForCreate, MaterialInComponentModelForDelete, MaterialInComponentModelForUpdate } from "../componentsModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { AddMaterialToComponent, GetAllComponentsWithLayeredStructure, RemoveMaterialFromComponent, UpdateMaterialInComponent, resetStatusCode } from "../componentsSlice";
import { GetAllMaterials } from "../../Material/materialSlice";
import { formatDateToDateTimeString, trimNumber, getEnumValueByKey, handleInputChange } from "../../../../app/common";
import { notify } from "../../../../UIComponents/notification";
import AddMaterialComponent from "./addMaterialComponent";
import { MaterialType } from "../../../../app/enums";
import { MaterialModel } from "../../Material/materialModels";

interface AddMaterialsCardProps {
    component: ComponentModel;
    materials: MaterialInComponentModel[];
    componentId: string;
    onCancelClickFunction: () => void,
    onAddMaterialClickFunction: (material: MaterialInComponentModelForCreate) => void,
}

function AddMaterialsCard(props: AddMaterialsCardProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const customDropdownRef = useRef<CustomDropdownMethods>(null);
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [materialToDelete, setMaterialToDelete] = useState<MaterialInComponentModel>(props.materials[0])
    const [selectedMaterials, setSelectedMaterials] = useState<MaterialSelectInfo[]>([]);
    const [selectedMaterial, setSelectedMaterial] = useState<MaterialSelectInfo>();
    const [materialThickness, setMaterialThickness] = useState("");
    const [thickness, setThickness] = useState("");
    const [thermalConductivity, setThermalConductivity] = useState("");
    const [thicknessErrorStatus, setThicknessErrorStatus] = useState(false);
    const [thermalConductivityErrorStatus, setThermalConductivityErrorStatus] = useState(false);
    const [options, setOptions] = useState<OptionModel[]>([])
    const currentDate = formatDateToDateTimeString(new Date());
    const materialSliceData = useSelector((state: RootState) => state.MaterialSlice.materialSliceData);
    const componentSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const [searchValue, setSearchValue] = useState("")
    const [filterValue, setFilterValue] = useState("")
    const emptyGuid = '00000000-0000-0000-0000-000000000000'

    useEffect(() => {
        // dispatch(GetAllComponentsWithLayeredStructure(projectId));
        dispatch(GetAllMaterials());
        let materials: MaterialSelectInfo[] = [];
        props.component.materials.map((material) => {
            materials.push({ materialData: material, isSelected: false });
        })
        setSelectedMaterials(materials);
    }, [componentSliceData.componentsData.allComponentsWithLayeredStructureData])

    useEffect(() => {
        options.splice(0, options.length)
        if (options.length === 0) {
            options.push({ key: "-1", value: t("ShowAll") })
        }
        materialSliceData.materialData.allMaterialsData.map(material => {
            if (!options.map(cat => cat.key).includes(material.materialType)) {
                options.push({ key: material.materialType, value: getEnumValueByKey(MaterialType, material.materialType) })
            }
        })
    }, [materialSliceData.materialData.allMaterialsData])

    interface MaterialDeleteInfo {
        material: MaterialInComponentModel,
        index: number
    }

    interface MaterialSelectInfo {
        materialData: MaterialInComponentModel,
        isSelected: boolean
    }

    function handleDeleteMaterialFromComponentClick(materialInfo: MaterialDeleteInfo): void {
        if (materialInfo.material.materialComponentId === emptyGuid) {
            setSelectedMaterials(selectedMaterials?.filter((_, index) => index !== materialInfo.index));
        } else {
            setDeleteMessage(t("AreYouSureYouWantToDeleteThisMaterialFromComponent"));
            setMaterialToDelete(materialInfo.material);
            setIsDelete(true);
        }
    }

    function deleteSelected(): void {
        if (materialToDelete) {
            const materialToRemove: MaterialInComponentModelForDelete = {
                userId: localStorage.getItem("userId")!,
                materialComponentId: materialToDelete.materialComponentId,
                componentId: props.componentId,
                projectId: projectId,
                dateRemovedMaterial: currentDate
            }
            dispatch(RemoveMaterialFromComponent(materialToRemove))
            setIsDelete(false);
            //setMaterialToDelete(undefined);
        }
    }

    function handleSelectMaterial(material: MaterialModel, isEdit: boolean) {
        if (selectedMaterial) {
            notify(t("PleaseSaveToContinue"), true)
            return;
        }
        setSelectedMaterial({ materialData: material, isSelected: true });
        setThickness(material.thickness.toString());
        setThermalConductivity(material.thermalConductivity.toString());
        if (isEdit) {
            const clickedMaterial = selectedMaterials.find(m => m.materialData === material);
            if (clickedMaterial) {
                clickedMaterial.isSelected = true;
                setSelectedMaterial(clickedMaterial);
            }
        }
        if (!isEdit && selectedMaterials) {
            setSelectedMaterials([{ materialData: material, isSelected: true }, ...selectedMaterials]);
        }
    }

    function handleDeselectMaterial() {
        if (selectedMaterial) {
            selectedMaterial.isSelected = false;
            if (selectedMaterial.materialData.materialComponentId === emptyGuid) {
                selectedMaterials.shift();
            }
            setSelectedMaterial(undefined);
        }
    }

    function handleCancelBtn(): void {
        props.onCancelClickFunction();
        handleDialog();
        resetState();
    }

    function handleSaveBtn(material: MaterialModel): void {
        if (thickness === "" || thermalConductivity === "" || parseFloat(thickness) === 0 || parseFloat(thermalConductivity) === 0) {
            if (thickness === "" || parseFloat(thickness) === 0) { setThicknessErrorStatus(true); }
            if (thermalConductivity === "" || parseFloat(thermalConductivity) === 0) { setThermalConductivityErrorStatus(true); }
            return;
        }
        if (material.materialId !== '' && material.materialComponentId === emptyGuid) {
            addMaterialToComponent(material);
        }
        else if (material.materialId !== '' && material.materialComponentId !== emptyGuid) {
            updateMaterialInComponent(material);
        }
    }

    function addMaterialToComponent(material: MaterialModel): void {
        const materialModel: MaterialInComponentModelForCreate = {
            userId: localStorage.getItem("userId")!,
            componentId: props.componentId,
            materialId: material.materialId,
            projectId: projectId,
            thermalConductivity: parseFloat(thermalConductivity),
            specificHeatCapacity: material.specificHeatCapacity,
            density: material.density,
            thickness: parseFloat(thickness),
            thermalResistance: 1,
            dateMaterialAdded: currentDate
        }
        dispatch(AddMaterialToComponent(materialModel))
        setSelectedMaterial({ materialData: material, isSelected: true });
    }

    function updateMaterialInComponent(material: MaterialModel): void {
        const materialModel: MaterialInComponentModelForUpdate = {
            materialInComponentId: material.materialComponentId,
            userId: localStorage.getItem("userId")!,
            componentId: props.componentId,
            materialId: material.materialId,
            projectId: projectId,
            thermalConductivity: parseFloat(thermalConductivity),
            specificHeatCapacity: material.specificHeatCapacity,
            density: material.density,
            thickness: parseFloat(thickness),
            thermalResistance: 1,
            dateMaterialAdded: currentDate
        }
        dispatch(UpdateMaterialInComponent(materialModel))
        setSelectedMaterial({ materialData: material, isSelected: true });
    }

    function resetState(): void {
        customDropdownRef.current && customDropdownRef.current.resetDropdown();
        setMaterialThickness("");
        setSelectedMaterial(undefined);
    }

    useEffect(() => {
        if (componentSliceData.statusValues.statusCode === "") {
            return;
        }
        if (componentSliceData.statusValues.statusCode === "200" && componentSliceData.statusValues.showNotification) {
            if (selectedMaterial) {
                selectedMaterial.isSelected = false;
            }
            notify(t(componentSliceData.statusValues.message))
            dispatch(GetAllComponentsWithLayeredStructure(projectId))
            dispatch(resetStatusCode());
            resetState();
        }
        else if (componentSliceData.statusValues.showNotification) {
            notify(t(componentSliceData.statusValues.message), true)
            dispatch(resetStatusCode());
        }
    }, [componentSliceData.statusValues])

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex justify-center bg-transparent shadow-none outline-none">
            <div className="h-fit bg-#3B4250 px-[30px]">
                <div className="mx-auto mt-[15px] md:mt-[30px] w-[937px] lg:w-[1321px] xl:w-[1528px]">
                    <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{t("AddMaterial")}</p>
                    {materialSliceData.materialData.allMaterialsData.length > 0 &&
                        <div className={`${materialSliceData.statusValues.isLoading && "blur-5"}`}>
                            <div className="mt-[19px] flex">
                                <div className="relative">
                                    <input id="searchMaterials" type="text" autoComplete="off" placeholder={t("Search")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="relative bg-#2E333E placeholder:text-#545B6A text-#F5F5F5 w-[321px] md:w-[473px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto" />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute translate-x-[280px] md:translate-x-[435px] -translate-y-[32px]">
                                        <circle cx="11.5" cy="11.5" r="9.5" stroke="white" strokeWidth="1.5" />
                                        <path d="M18.5 18.5L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </div>
                                <div className="ml-auto">
                                    <CustomDropdown dropdownId="filterByDropdown" options={options} placeholder={t("FilterBy")} height={"h-10"} width={"w-[300px]"} onSelectClick={setFilterValue} />
                                </div>
                            </div>
                            <div className="w-[937px] lg:w-[1321px] xl:w-[1528px] my-[12px] px-[20px] py-[15px] grid grid-cols-5 text-#F5F5F5 text-sm md:text-base rounded-[10px] place-content-center">
                                <p className="col-span-3 my-auto">{t("Material")}</p>
                                <p className="text-center my-auto">{t("Category")}</p>
                                <p className="text-center my-auto">{t("ThermalConductivity")}</p>
                            </div>
                            <div className="flex flex-col gap-4 overflow-auto max-h-[200px]">
                                {materialSliceData.materialData.allMaterialsData.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()) && (filterValue === "-1" ? item : item.materialType.includes(filterValue))).map(material => (
                                    <AddMaterialComponent key={material.materialId} material={material} onSeleckClick={() => handleSelectMaterial(material, false)} />
                                ))}
                            </div>
                        </div>}
                </div>
                <div className="w-full h-px bg-#F5F5F5 mt-[15px] md:mt-[30px]" />
                <div className="mx-auto my-[15px] md:my-[30px] w-[937px] lg:w-[1321px] xl:w-[1528px]">
                    <div>
                        <div className="mt-[15px] md:mt-[30px] text-#F5F5F5">
                            <div className="h-[50px] flex flex-row bg-#343A47 text-base font-normal px-5 py-2 gap-[100px]">
                                <div className="flex space-x-1"><p className="my-auto text-#B5B5B5">{t("ComponentCode")}:</p><p className="my-auto">{props.component.componentCode}</p></div>
                                <div className="flex space-x-1"><p className="my-auto text-#B5B5B5">{t("ComponentName")}:</p><p className="my-auto">{props.component.componentName}</p></div>
                                <div className="flex space-x-1"><Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0 z-[10000]" content={t("RseFull")} placement="top"><p className="my-auto text-#B5B5B5">{t("Rse")}:</p></Tooltip><p className="my-auto">{props.component.externalSurfaceResistance}</p></div>
                                <div className="flex space-x-1"><Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0 z-[10000]" content={t("RsiFull")} placement="top"><p className="my-auto text-#B5B5B5">{t("Rsi")}:</p></Tooltip><p className="my-auto">{props.component.internalSurfaceResistance}</p></div>
                                <div className="flex space-x-1"><Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0 z-[10000]" content={t("UFull")} placement="top"><p className="my-auto text-#B5B5B5">{t("U")}:</p></Tooltip><p className="my-auto">{trimNumber(props.component.thermalTransmittance, 3)}</p></div>
                            </div>
                            <div className="h-[32px] grid grid-cols-12 bg-#414959 border-b-[1px] border-#E9E8E7 text-sm font-medium px-5 py-2">
                                <p className="my-auto col-span-3 text-start">{t("Material")}</p>
                                <p className="my-auto col-span-2 text-center">{t("Thickness") + " (mm)"}</p>
                                <p className="my-auto col-span-2 text-center">{t("Density")}</p>
                                <p className="my-auto col-span-2 text-center">{t("ThermalConductivity")}</p>
                                <p className="my-auto col-span-2 text-center">{t("SpecificHeatCapacity")}</p>
                            </div>
                            {selectedMaterials &&
                                <div className="overflow-auto max-h-[200px]">
                                    {selectedMaterials.map((material, index) => (
                                        <div key={index} id={"materialOfComponent-" + material.materialData.materialId} className="h-[65px] grid items-center overflow-auto grid-cols-12 text-base font-normal px-5 border-b-[1px] border-#E9E8E7" onDoubleClick={() => handleSelectMaterial(material.materialData, true)}>
                                            <p className="col-span-3">{material.materialData.name}</p>
                                            {!material.isSelected ?
                                                <p className="col-span-2 text-center">{trimNumber(material.materialData.thickness, 1)}</p> :
                                                <input id="thicknessInput" type="text" autoComplete="off" placeholder={"0.0"} value={thickness} onChange={(e) => { handleInputChange(e, setThickness, 3, 4); setThicknessErrorStatus(false) }} onDoubleClick={(e) => e.stopPropagation()} className={`bg-#2E333E placeholder:text-#545B6A ${thicknessErrorStatus ? "border-[1px] border-#EF5350" : ""} col-span-2 text-center h-[33px] w-[120px] lg:w-[140px] mx-auto  rounded-[5px] outline-none shadow-inner`} />
                                            }
                                            <p className="col-span-2 text-center">{material.materialData.density}</p>
                                            {!material.isSelected ?
                                                <p className="col-span-2 text-center">{trimNumber(material.materialData.thermalConductivity, 3)}</p> :
                                                <input id="thermalConductivityInput" type="text" autoComplete="off" placeholder={"0.000"} value={thermalConductivity} onChange={(e) => { handleInputChange(e, setThermalConductivity, 3, 2); setThermalConductivityErrorStatus(false) }} onDoubleClick={(e) => e.stopPropagation()} className={`bg-#2E333E placeholder:text-#545B6A ${thermalConductivityErrorStatus ? "border-[1px] border-#EF5350" : ""} col-span-2 text-center h-[33px] w-[120px] lg:w-[140px] mx-auto rounded-[5px] outline-none shadow-inner`} />
                                            }
                                            <p className="col-span-2 text-center">{material.materialData.specificHeatCapacity}</p>
                                            {!material.isSelected ?
                                                <svg id="deleteComponentWithLayeredStructure" className="m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" onClick={() => handleDeleteMaterialFromComponentClick(
                                                    {
                                                        material: {
                                                            materialId: material.materialData.materialId,
                                                            name: material.materialData.name,
                                                            thickness: material.materialData.thickness,
                                                            materialType: material.materialData.materialType,
                                                            thermalConductivity: material.materialData.thermalConductivity,
                                                            specificHeatCapacity: material.materialData.specificHeatCapacity,
                                                            density: material.materialData.density,
                                                            materialComponentId: material.materialData.materialComponentId
                                                        },
                                                        index: index
                                                    }
                                                )} width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                                    <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                                </svg>
                                                : <div className="flex">
                                                    <button id="confirmBtn" type="submit" className="w-[21px] h-[21px] m-auto">
                                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleSaveBtn(material.materialData)}>
                                                            <circle cx="10.5" cy="10.5" r="10.5" fill="#3EAD43" className="hover:fill-#7EB608" />
                                                            <path d="M14.6355 7.94036C14.7306 8.04048 14.7817 8.17391 14.7775 8.31132C14.7733 8.44873 14.7142 8.57887 14.6131 8.67312L9.07842 13.8397C8.96559 13.945 8.81566 14.0023 8.66071 13.9995C8.50575 13.9966 8.35807 13.9338 8.24925 13.8245L6.37876 11.9443C6.32923 11.896 6.28987 11.8386 6.26299 11.7752C6.2361 11.7117 6.22223 11.6437 6.22217 11.5749C6.22211 11.5061 6.23587 11.438 6.26264 11.3745C6.28941 11.3111 6.32866 11.2535 6.37811 11.2052C6.42756 11.157 6.48622 11.1189 6.55066 11.0933C6.6151 11.0677 6.68405 11.055 6.75348 11.056C6.82292 11.0571 6.89145 11.0718 6.95509 11.0993C7.01873 11.1268 7.0762 11.1666 7.12417 11.2163L8.68338 12.7835L13.8956 7.91824C13.9967 7.82403 14.1314 7.77343 14.2702 7.77758C14.4089 7.78173 14.5403 7.84028 14.6355 7.94036Z" className="stroke-#F5F5F5" />
                                                        </svg>
                                                    </button>
                                                    <button id="closeEditBtn" type="submit" className="w-[14px] h-[14px] m-auto">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleDeselectMaterial}>
                                                            <path d="M13 1L1 13M1 1L13 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>}
                        </div>
                        <div className="flex items-center space-x-[30px] my-[15px] md:my-[30px]">
                            <button id="cancelBtn" onClick={() => { handleCancelBtn() }} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
            {isDelete && <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} />}
        </Dialog>);
}

export default AddMaterialsCard;