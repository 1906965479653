import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateToDateTimeString, handleSpaceInInput } from "../../../app/common";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CreateProject, UpdateProject } from "../projectSlice";
import { ProjectModelForCreate, ProjectModelForUpdate } from "../projectModels";
import { useNavigate } from "react-router-dom";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import { LoadingPage } from "../../../UIComponents/loading";
import { resetBuilding } from "../../Building/buildingSlice";
import { Button } from "@material-tailwind/react";
import ProjectAdress from "./projectAddressComponent";

function CreateNewProjectPage() {
    const { t } = useTranslation();
    const settingsSliceData = useSelector((state: RootState) => state.SettingsSlice.settingsSliceData);
    const [projectCode, setProjectCode] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectCodeErrorStatus, setProjectCodeErrorStatus] = useState(false);
    const [projectNameErrorStatus, setProjectNameErrorStatus] = useState(false);
    const [hasSelectedProject, setHasSelectedProject] = useState<boolean>(false);
    const [showProjectAddressDialog, setShowProjectAddressDialog] = useState<boolean>(false);
    const [navigateTo, setNavigateTo] = useState("");
    const austrianStandard = { value: "ÖNORM H 7500-1", label: "ÖNORM H 7500-1" };
    const directoryId = settingsSliceData.CompanyData.companyId;
    const dispatch = useDispatch<AppDispatch>();
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const navigate = useNavigate();

    const onCreateUpdateProject = () => {
        if (projectCode === "") { setProjectCodeErrorStatus(true); }
        if (projectName === "") { setProjectNameErrorStatus(true); }
        const currentDate = formatDateToDateTimeString(new Date());
        if (projectCode && projectName) {
            if (hasSelectedProject) {
                const projectModelForUpdate: ProjectModelForUpdate = {
                    projectId: projectSliceData.projectData.projectData.projectId,
                    standard: austrianStandard.value,
                    projectCode: projectCode,
                    projectName: projectName,
                    dateCreated: hasSelectedProject ? projectSliceData.projectData.projectData.dateCreated : currentDate,
                    dateModified: currentDate,
                    directoryId: directoryId,
                    userId: localStorage.getItem("userId")!
                }
                dispatch(UpdateProject(projectModelForUpdate));
                setNavigateTo("/dashboard");
            }
            else {
                const projectModel: ProjectModelForCreate = {
                    standard: austrianStandard.value,
                    projectCode: projectCode,
                    projectName: projectName,
                    dateCreated: hasSelectedProject ? projectSliceData.projectData.projectData.dateCreated : currentDate,
                    dateModified: currentDate,
                    directoryId: directoryId,
                    userId: localStorage.getItem("userId")!
                }
                dispatch(resetBuilding());
                dispatch(CreateProject(projectModel));
                setNavigateTo("/createBuilding");
            }
        }
    }

    const onCustomerData = () => {
        setShowProjectAddressDialog(!showProjectAddressDialog);
    }

    useEffect(() => {
        if (projectSliceData.projectData.isEdit) {
            setHasSelectedProject(true);
        }
    }, [])

    useEffect(() => {
        if (projectSliceData.projectData.isEdit) {
            setProjectCode(projectSliceData.projectData.projectData.projectCode);
            setProjectName(projectSliceData.projectData.projectData.projectName);
        }
    }, [projectSliceData.projectData.projectData])

    useEffect(() => {
        if (projectSliceData.statusValues.statusCode === "") {
            return
        }
        if (projectSliceData.statusValues.statusCode === "200" && projectSliceData.statusValues.showNotification) {
            notify(t(projectSliceData.statusValues.message))
            navigate(navigateTo);
            setNavigateTo("");
        }
        else if (projectSliceData.statusValues.statusCode !== "200" && projectSliceData.statusValues.showNotification) {
            notify(t(projectSliceData.statusValues.message), true)
        }
    }, [projectSliceData.statusValues])

    return (
        <div className="pt-[15px] pl-[15px]">
            <div className="bg-#3B4250 w-[563px] md:w-[936px] h-[374px] px-[28px] rounded flex items-center justify-center">
                <div>
                    <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{hasSelectedProject ? t("EditProject") : t("CreateANewProject")}</p>
                    <div className="space-y-[25px] mt-[45px] text-#FFFFFF lg:text-lg xl:text-xl">
                        <div className="flex items-center gap-[30px]">
                            <p>{t("Standard")}:</p>
                            <input id="projectStandard" type="text" disabled={true} autoComplete="off" value={austrianStandard.label} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[356px] md:w-[702px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                        </div>
                        <div className="flex items-center gap-[30px]">
                            <div className="flex space-x-1">
                                <p>{t("ProjectCode")}:<span className="text-#EF5350 pl-1">*</span></p>
                            </div>
                            <input id="projectCode" type="text" autoComplete="off" placeholder={t("ProjectCode")} value={projectCode} onChange={(e) => { handleSpaceInInput(e, setProjectCode); setProjectCodeErrorStatus(false); }} className={`bg-#2E333E placeholder:text-#545B6A ${projectCodeErrorStatus ? "border-[1px] border-#EF5350" : ""} outline-none w-[356px] md:w-[702px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />
                        </div>
                        <div className="flex items-center gap-[30px]">
                            <div className="flex space-x-1">
                                <p>{t("ProjectName")}:<span className="text-#EF5350 pl-1">*</span></p>
                            </div>
                            <input id="projectName" type="text" autoComplete="off" placeholder={t("ProjectName")} value={projectName} onChange={(e) => { handleSpaceInInput(e, setProjectName); setProjectNameErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${projectNameErrorStatus ? "border-[1px] border-#EF5350" : ""} outline-none w-[356px] md:w-[702px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />
                        </div>
                        <div className="flex items-center space-x-[30px]">
                            <Button id="stakeholderDataBtn" disabled={!hasSelectedProject} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg justify-center items-center gap-2 inline-flex ml-auto" onClick={onCustomerData}>{t("StakeholderData")}</Button>
                            <Button id="createNewProjectBtn" className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg justify-center items-center gap-2 inline-flex ml-auto" onClick={onCreateUpdateProject}>{hasSelectedProject ? t("SaveChanges") : t("CreateProject")}</Button>
                        </div>
                    </div>
                </div>
            </div>
            {projectSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(projectSliceData.statusValues.isLoading)}</div>}
            {showProjectAddressDialog && <ProjectAdress onCancelClickFunction={onCustomerData} />}
            <NotificationPopup />
        </div>
    );
}

export default CreateNewProjectPage;