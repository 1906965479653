import { getEnumKeyByValue } from "./common";
import { BuildingAirTightness, BuildingCoverage, BuildingMass, BuildingType, ComponentType, EnvironmentType, FloorLevel, GroundType, MaterialType, OpeningType, Orientation, SpaceType } from "./enums";

export const materialTypeOptions = [
    { key: getEnumKeyByValue(MaterialType, MaterialType.PLASTERS), value: MaterialType.PLASTERS },
    { key: getEnumKeyByValue(MaterialType, MaterialType.LARGEFORMATCOMPONENTS), value: MaterialType.LARGEFORMATCOMPONENTS },
    { key: getEnumKeyByValue(MaterialType, MaterialType.CONSTRUCTION), value: MaterialType.CONSTRUCTION },
    { key: getEnumKeyByValue(MaterialType, MaterialType.MASONRY), value: MaterialType.MASONRY },
    { key: getEnumKeyByValue(MaterialType, MaterialType.INSULATION), value: MaterialType.INSULATION },
    { key: getEnumKeyByValue(MaterialType, MaterialType.WOOD), value: MaterialType.WOOD },
    { key: getEnumKeyByValue(MaterialType, MaterialType.COVERINGS), value: MaterialType.COVERINGS },
    { key: getEnumKeyByValue(MaterialType, MaterialType.OTHER), value: MaterialType.OTHER },
];

export const componentTypeOptions = [
    { key: getEnumKeyByValue(ComponentType, ComponentType.OUTERWALL), value: ComponentType.OUTERWALL },
    { key: getEnumKeyByValue(ComponentType, ComponentType.INNERWALL), value: ComponentType.INNERWALL },
    { key: getEnumKeyByValue(ComponentType, ComponentType.FLOOR), value: ComponentType.FLOOR },
    { key: getEnumKeyByValue(ComponentType, ComponentType.CEILING), value: ComponentType.CEILING },
    { key: getEnumKeyByValue(ComponentType, ComponentType.ROOF), value: ComponentType.ROOF },
];

export const openingTypeOptions = [
    { key: getEnumKeyByValue(OpeningType, OpeningType.OUTERDOOR), value: OpeningType.OUTERDOOR },
    { key: getEnumKeyByValue(OpeningType, OpeningType.INNERDOOR), value: OpeningType.INNERDOOR },
    { key: getEnumKeyByValue(OpeningType, OpeningType.INNERWINDOW), value: OpeningType.INNERWINDOW },
    { key: getEnumKeyByValue(OpeningType, OpeningType.OUTERWINDOW), value: OpeningType.OUTERWINDOW },
    { key: getEnumKeyByValue(OpeningType, OpeningType.ROOFWINDOW), value: OpeningType.ROOFWINDOW },
];

export const environmentTypeOptions = [
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.HEATEDROOM), value: EnvironmentType.HEATEDROOM },
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.UNHEATEDROOM), value: EnvironmentType.UNHEATEDROOM },
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.GROUND), value: EnvironmentType.GROUND },
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.OUTSIDE), value: EnvironmentType.OUTSIDE },
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.NEIGHBOURSPACE), value: EnvironmentType.NEIGHBOURSPACE },
    { key: getEnumKeyByValue(EnvironmentType, EnvironmentType.NEIGHBOURBUILDING), value: EnvironmentType.NEIGHBOURBUILDING },
]

export const orientationOptions = [
    { key: getEnumKeyByValue(Orientation, Orientation.H), value: Orientation.H },
    { key: getEnumKeyByValue(Orientation, Orientation.N), value: Orientation.N },
    { key: getEnumKeyByValue(Orientation, Orientation.NE), value: Orientation.NE },
    { key: getEnumKeyByValue(Orientation, Orientation.E), value: Orientation.E },
    { key: getEnumKeyByValue(Orientation, Orientation.SE), value: Orientation.SE },
    { key: getEnumKeyByValue(Orientation, Orientation.S), value: Orientation.S },
    { key: getEnumKeyByValue(Orientation, Orientation.SW), value: Orientation.SW },
    { key: getEnumKeyByValue(Orientation, Orientation.W), value: Orientation.W },
    { key: getEnumKeyByValue(Orientation, Orientation.NW), value: Orientation.NW },
];

export const buildingTypeOptions = [
    { key: getEnumKeyByValue(BuildingType, BuildingType.FAMILYHOUSE), value: BuildingType.FAMILYHOUSE },
    { key: getEnumKeyByValue(BuildingType, BuildingType.APARTMENTBUILDING), value: BuildingType.APARTMENTBUILDING },
    { key: getEnumKeyByValue(BuildingType, BuildingType.NONLIVINGBUILDING), value: BuildingType.NONLIVINGBUILDING },
];

export const buildingMassOptions = [
    { key: getEnumKeyByValue(BuildingMass, BuildingMass.LIGHT), value: BuildingMass.LIGHT },
    { key: getEnumKeyByValue(BuildingMass, BuildingMass.MEDIUMMASS), value: BuildingMass.MEDIUMMASS },
    { key: getEnumKeyByValue(BuildingMass, BuildingMass.HEAVY), value: BuildingMass.HEAVY },
];

export const buildingAirTightnessOptions = [
    { key: getEnumKeyByValue(BuildingAirTightness, BuildingAirTightness.HIGH), value: BuildingAirTightness.HIGH },
    { key: getEnumKeyByValue(BuildingAirTightness, BuildingAirTightness.MEDIUM), value: BuildingAirTightness.MEDIUM },
    { key: getEnumKeyByValue(BuildingAirTightness, BuildingAirTightness.LOW), value: BuildingAirTightness.LOW },
];

export const groundTypeOptions = [
    { key: getEnumKeyByValue(GroundType, GroundType.SAND_OR_GRAVEL), value: GroundType.SAND_OR_GRAVEL },
    { key: getEnumKeyByValue(GroundType, GroundType.HOMOGENOUS_ROCK), value: GroundType.HOMOGENOUS_ROCK },
    { key: getEnumKeyByValue(GroundType, GroundType.CLAY_OR_SILT), value: GroundType.CLAY_OR_SILT },
];

export const buildingCoverageOptions = [
    { key: getEnumKeyByValue(BuildingCoverage, BuildingCoverage.NONCOVERED), value: BuildingCoverage.NONCOVERED },
    { key: getEnumKeyByValue(BuildingCoverage, BuildingCoverage.MIDCOVERED), value: BuildingCoverage.MIDCOVERED },
    { key: getEnumKeyByValue(BuildingCoverage, BuildingCoverage.HIGHCOVERED), value: BuildingCoverage.HIGHCOVERED },
];

export const floorLevelOptions = [
    { key: getEnumKeyByValue(FloorLevel, FloorLevel.GROUNDFLOOR), value: FloorLevel.GROUNDFLOOR },
    { key: getEnumKeyByValue(FloorLevel, FloorLevel.BASEMENT), value: FloorLevel.BASEMENT },
    { key: getEnumKeyByValue(FloorLevel, FloorLevel.UPPERFLOOR), value: FloorLevel.UPPERFLOOR },
];

export const selectBuildingTypes = [
    { key: getEnumKeyByValue(SpaceType, SpaceType.HEATED), value: SpaceType.HEATED },
    { key: getEnumKeyByValue(SpaceType, SpaceType.UNHEATED), value: SpaceType.UNHEATED },
];