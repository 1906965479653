import { Button, Typography, List, Accordion, AccordionBody } from "@material-tailwind/react";
import { AreaModel, FloorModel } from "../buildingStructureModels";
import { useTranslation } from "react-i18next";

interface BuildingStructureTreeModel {
    floors: FloorModel[],
    openFloors: string[],
    areas: AreaModel[],
    selectedFloor: FloorModel,
    handleOpen: (floorId: string) => void,
    onFloorSelect: (floorId: string) => void,
    removeFloor: (floorId: string) => void,
    selectedArea: AreaModel,
    onAreaSelect: (areaId: string) => void,
    removeArea: (areaId: string) => void,
    setIsAddArea: (isAdd: boolean, floorId: string) => void,
    setIsAddFloor: (isAdd: boolean) => void,
}

export function BuildingStructure(props: BuildingStructureTreeModel) {
    const { t } = useTranslation();

    return (
        <div className="pt-5 w-full h-fit">
            <List className="p-0 space-y-1 min-w-fit max-w-full w-full">
                {props.floors.map((floor, index) => (
                    <div key={floor.floorId}>
                        <Accordion open={props.openFloors.includes(floor.floorId)} className="w-full pb-2">
                            <Button id={"Floor-" + floor.floorId} key={floor.floorId} ripple={false} className="p-0 m-0 flex flex-row justify-start items-center space-x-2 w-fit h-[25px] shadow-none hover:shadow-none bg-transparent hover:bg-transparent active:bg-transparent normal-case outline-none">
                                {props.openFloors.includes(floor.floorId) ?
                                    <p className="w-[15px] h-[15px] flex flex-row justify-center items-center" onClick={() => props.handleOpen(floor.floorId)}>
                                        <svg id="showBtn" width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 4L5.5 1L1 4" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </p>
                                    :
                                    <p className="w-[15px] h-[15px] flex flex-row justify-center items-center" onClick={() => props.handleOpen(floor.floorId)}>
                                        <svg id="colapseBtn" width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5.5 4L10 1" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </p>
                                }
                                <div onClick={() => props.onFloorSelect(floor.floorId)} className={`flex flex-row justify-start items-center space-x-2 ${floor.floorId === props.selectedFloor?.floorId ? "rounded-2xl border-[1px] px-2 border-#785C34" : "hover:rounded-2xl hover:border-[1px] hover:px-2 hover:border-#785C34"}`}>
                                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.91969 4.66372C1.6399 4.1518 1 3.89584 1 3.57778C1 3.25971 1.6399 3.00375 2.91969 2.49184L4.72959 1.76788C6.00939 1.25596 6.64928 1 7.44444 1C8.23961 1 8.8795 1.25596 10.1593 1.76788L11.9692 2.49184C13.249 3.00375 13.8889 3.25971 13.8889 3.57778C13.8889 3.89584 13.249 4.1518 11.9692 4.66372L10.1593 5.38768C8.8795 5.8996 8.23961 6.15556 7.44444 6.15556C6.64928 6.15556 6.00939 5.8996 4.72959 5.38768L2.91969 4.66372Z" stroke="#F5F5F5" strokeWidth="0.8" />
                                        <path d="M3.42706 4.86719L2.91969 5.07014C1.6399 5.58205 1 5.83801 1 6.15608C1 6.47414 1.6399 6.7301 2.91969 7.24202L4.72959 7.96598C6.00939 8.4779 6.64928 8.73385 7.44444 8.73385C8.23961 8.73385 8.8795 8.4779 10.1593 7.96598L11.9692 7.24202C13.249 6.7301 13.8889 6.47414 13.8889 6.15608C13.8889 5.83801 13.249 5.58205 11.9692 5.07014L11.4618 4.86719" stroke="#F5F5F5" strokeWidth="0.8" />
                                        <path d="M3.42706 7.44531L2.91969 7.64826C1.6399 8.16018 1 8.41614 1 8.7342C1 9.05227 1.6399 9.30822 2.91969 9.82014L4.72959 10.5441C6.00939 11.056 6.64928 11.312 7.44444 11.312C8.23961 11.312 8.8795 11.056 10.1593 10.5441L11.9692 9.82014C13.249 9.30822 13.8889 9.05227 13.8889 8.7342C13.8889 8.41614 13.249 8.16018 11.9692 7.64826L11.4618 7.44531" stroke="#F5F5F5" strokeWidth="0.8" />
                                    </svg>
                                    <Typography className="font-normal text-#F5F5F5">{floor.floorName.replace(/ /g, '_')}</Typography>
                                    <svg id="removeFloorBtn" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(e) => { e.stopPropagation(); props.removeFloor(floor.floorId); }}>
                                        <path d="M0.353554 1.06058C0.158291 0.865323 0.158291 0.54874 0.353553 0.353478C0.548815 0.158216 0.865398 0.158216 1.06066 0.353478L6.71751 6.01033C6.91278 6.20559 6.91278 6.52218 6.71751 6.71744C6.52225 6.9127 6.20567 6.9127 6.01041 6.71744L0.353554 1.06058Z" fill="#F5F5F5" />
                                        <path d="M6.0104 0.353554C6.20566 0.158291 6.52224 0.158291 6.71751 0.353553C6.91277 0.548815 6.91277 0.865398 6.71751 1.06066L1.06065 6.71751C0.86539 6.91278 0.548807 6.91278 0.353545 6.71751C0.158283 6.52225 0.158283 6.20567 0.353545 6.01041L6.0104 0.353554Z" fill="#F5F5F5" />
                                    </svg>
                                </div>
                            </Button>
                            <AccordionBody className="py-1 pl-[22px] w-full">
                                <List className="py-0 gap-0 min-w-fit max-w-fit">
                                    {floor.zones.map((area, index) => (
                                        <div key={area.zoneId} className="w-fit flex flex-row justify-start items-center gap-1">
                                            {index < floor.zones.length - 1 ?
                                                <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="1" height="25" fill="#F5F5F5" />
                                                    <path d="M1 13L1 12L12.5 12C12.7761 12 13 12.2239 13 12.5C13 12.7761 12.7761 13 12.5 13L1 13Z" fill="#F5F5F5" />
                                                </svg>
                                                :
                                                <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="1" height="13" fill="#F5F5F5" />
                                                    <path d="M1 13L1 12L12.5 12C12.7761 12 13 12.2239 13 12.5C13 12.7761 12.7761 13 12.5 13L1 13Z" fill="#F5F5F5" />
                                                </svg>
                                            }
                                            <Button id={"Area-" + area.zoneId} key={area.zoneId} onClick={() => props.onAreaSelect(area.zoneId)} ripple={false} className={`p-0 m-0 flex flex-row border-transparent ${area.zoneId === props.selectedArea?.zoneId ? "rounded-2xl border-[1px] px-2 border-#785C34" : "hover:rounded-2xl hover:border-[1px] hover:px-2 hover:border-#785C34"} justify-start items-center space-x-2 w-fit h-[25px] shadow-none hover:shadow-none bg-transparent hover:bg-transparent active:bg-transparent normal-case text-#F5F5F5 hover:text-#F5F5F5 active:text-#F5F5F5 outline-none`}>
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 11L0.970588 11C0.436765 11 4.93134e-07 10.5359 5.17926e-07 9.96875L6.38133e-07 7.21875L9.08597e-07 1.03125C9.33389e-07 0.464062 0.436766 -4.61734e-07 0.970589 -4.38399e-07L10.0294 -4.24258e-08C10.5632 -1.90916e-08 11 0.464063 11 1.03125L11 6.53125C11 7.09844 10.5632 7.5625 10.0294 7.5625L6.47059 7.5625L6.47059 9.96875C6.47059 10.5359 6.03382 11 5.5 11ZM3.39706 0.6875L3.39706 6.875L6.14706 6.875L6.95588 6.875L6.95588 0.6875L3.39706 0.6875ZM2.75 4.125L0.647059 4.125L0.647059 6.875L2.75 6.875L2.75 4.125ZM10.3529 6.53125L10.3529 5.84375L7.60294 5.84375L7.60294 6.875L10.0294 6.875C10.2074 6.875 10.3529 6.72031 10.3529 6.53125ZM10.0294 0.6875L7.60294 0.6875L7.60294 5.15625L10.3529 5.15625L10.3529 1.03125C10.3529 0.842188 10.2074 0.6875 10.0294 0.6875ZM0.647059 1.03125L0.647059 3.4375L2.75 3.4375L2.75 0.6875L0.970589 0.6875C0.792647 0.6875 0.647059 0.842187 0.647059 1.03125ZM5.5 10.3125C5.67794 10.3125 5.82353 10.1578 5.82353 9.96875L5.82353 7.5625L0.647059 7.5625L0.647059 9.96875C0.647059 10.1578 0.792647 10.3125 0.970588 10.3125L5.5 10.3125Z" fill="#F5F5F5" />
                                                </svg>
                                                <Typography className="font-normal text-#F5F5F5">{area.zoneName.replace(/ /g, '_')}</Typography>
                                                <svg id="removeAreaBtn" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => props.removeArea(area.zoneId)}>
                                                    <path d="M0.353554 1.06058C0.158291 0.865323 0.158291 0.54874 0.353553 0.353478C0.548815 0.158216 0.865398 0.158216 1.06066 0.353478L6.71751 6.01033C6.91278 6.20559 6.91278 6.52218 6.71751 6.71744C6.52225 6.9127 6.20567 6.9127 6.01041 6.71744L0.353554 1.06058Z" fill="#F5F5F5" />
                                                    <path d="M6.0104 0.353554C6.20566 0.158291 6.52224 0.158291 6.71751 0.353553C6.91277 0.548815 6.91277 0.865398 6.71751 1.06066L1.06065 6.71751C0.86539 6.91278 0.548807 6.91278 0.353545 6.71751C0.158283 6.52225 0.158283 6.20567 0.353545 6.01041L6.0104 0.353554Z" fill="#F5F5F5" />
                                                </svg>
                                            </Button>
                                        </div>
                                    ))}
                                    <div className="pt-1">
                                        <Button id="addAreaBtn" onClick={() => props.setIsAddArea(true, floor.floorId)} size="sm" ripple={false} className={`px-3 m-0 flex flex-row border-transparent justify-start items-center space-x-2 w-fit h-[20px] shadow-none hover:shadow-none rounded-[10px] bg-#785C34 normal-case text-#F5F5F5 outline-none`}>
                                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.5 5C0.223858 5 -9.78512e-09 4.77614 -2.18557e-08 4.5C-3.39262e-08 4.22386 0.223858 4 0.5 4L8.5 4C8.77614 4 9 4.22386 9 4.5C9 4.77614 8.77614 5 8.5 5L0.5 5Z" fill="#F5F5F5" />
                                                <path d="M4 0.5C4 0.223858 4.22386 0 4.5 0C4.77614 0 5 0.223858 5 0.5L5 8.5C5 8.77614 4.77614 9 4.5 9C4.22386 9 4 8.77614 4 8.5L4 0.5Z" fill="#F5F5F5" />
                                            </svg>
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 11L0.970588 11C0.436765 11 4.93134e-07 10.5359 5.17926e-07 9.96875L6.38133e-07 7.21875L9.08597e-07 1.03125C9.33389e-07 0.464062 0.436766 -4.61734e-07 0.970589 -4.38399e-07L10.0294 -4.24258e-08C10.5632 -1.90916e-08 11 0.464063 11 1.03125L11 6.53125C11 7.09844 10.5632 7.5625 10.0294 7.5625L6.47059 7.5625L6.47059 9.96875C6.47059 10.5359 6.03382 11 5.5 11ZM3.39706 0.6875L3.39706 6.875L6.14706 6.875L6.95588 6.875L6.95588 0.6875L3.39706 0.6875ZM2.75 4.125L0.647059 4.125L0.647059 6.875L2.75 6.875L2.75 4.125ZM10.3529 6.53125L10.3529 5.84375L7.60294 5.84375L7.60294 6.875L10.0294 6.875C10.2074 6.875 10.3529 6.72031 10.3529 6.53125ZM10.0294 0.6875L7.60294 0.6875L7.60294 5.15625L10.3529 5.15625L10.3529 1.03125C10.3529 0.842188 10.2074 0.6875 10.0294 0.6875ZM0.647059 1.03125L0.647059 3.4375L2.75 3.4375L2.75 0.6875L0.970589 0.6875C0.792647 0.6875 0.647059 0.842187 0.647059 1.03125ZM5.5 10.3125C5.67794 10.3125 5.82353 10.1578 5.82353 9.96875L5.82353 7.5625L0.647059 7.5625L0.647059 9.96875C0.647059 10.1578 0.792647 10.3125 0.970588 10.3125L5.5 10.3125Z" fill="#F5F5F5" />
                                            </svg>
                                            <Typography className="font-normal text-#F5F5F5">{t("Area")}</Typography>
                                        </Button>
                                    </div>
                                </List>
                            </AccordionBody>
                        </Accordion>
                        <div className="p-0 m-0 w-full h-[1px] bg-#414959"></div>
                    </div>
                ))}
                <div className="px-6 pt-1">
                    <Button id="addFloorBtn" onClick={() => props.setIsAddFloor(true)} size="sm" ripple={false} className={`px-3 m-0 flex flex-row border-transparent justify-start items-center space-x-2 w-fit h-[20px] shadow-none hover:shadow-none rounded-[10px] bg-#785C34 normal-case text-#F5F5F5 outline-none`}>
                        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 5C0.223858 5 -9.78512e-09 4.77614 -2.18557e-08 4.5C-3.39262e-08 4.22386 0.223858 4 0.5 4L8.5 4C8.77614 4 9 4.22386 9 4.5C9 4.77614 8.77614 5 8.5 5L0.5 5Z" fill="#F5F5F5" />
                            <path d="M4 0.5C4 0.223858 4.22386 0 4.5 0C4.77614 0 5 0.223858 5 0.5L5 8.5C5 8.77614 4.77614 9 4.5 9C4.22386 9 4 8.77614 4 8.5L4 0.5Z" fill="#F5F5F5" />
                        </svg>
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.91969 4.66372C1.6399 4.1518 1 3.89584 1 3.57778C1 3.25971 1.6399 3.00375 2.91969 2.49184L4.72959 1.76788C6.00939 1.25596 6.64928 1 7.44444 1C8.23961 1 8.8795 1.25596 10.1593 1.76788L11.9692 2.49184C13.249 3.00375 13.8889 3.25971 13.8889 3.57778C13.8889 3.89584 13.249 4.1518 11.9692 4.66372L10.1593 5.38768C8.8795 5.8996 8.23961 6.15556 7.44444 6.15556C6.64928 6.15556 6.00939 5.8996 4.72959 5.38768L2.91969 4.66372Z" stroke="#F5F5F5" strokeWidth="0.8" />
                            <path d="M3.42706 4.86719L2.91969 5.07014C1.6399 5.58205 1 5.83801 1 6.15608C1 6.47414 1.6399 6.7301 2.91969 7.24202L4.72959 7.96598C6.00939 8.4779 6.64928 8.73385 7.44444 8.73385C8.23961 8.73385 8.8795 8.4779 10.1593 7.96598L11.9692 7.24202C13.249 6.7301 13.8889 6.47414 13.8889 6.15608C13.8889 5.83801 13.249 5.58205 11.9692 5.07014L11.4618 4.86719" stroke="#F5F5F5" strokeWidth="0.8" />
                            <path d="M3.42706 7.44531L2.91969 7.64826C1.6399 8.16018 1 8.41614 1 8.7342C1 9.05227 1.6399 9.30822 2.91969 9.82014L4.72959 10.5441C6.00939 11.056 6.64928 11.312 7.44444 11.312C8.23961 11.312 8.8795 11.056 10.1593 10.5441L11.9692 9.82014C13.249 9.30822 13.8889 9.05227 13.8889 8.7342C13.8889 8.41614 13.249 8.16018 11.9692 7.64826L11.4618 7.44531" stroke="#F5F5F5" strokeWidth="0.8" />
                        </svg>
                        <Typography className="font-normal text-#F5F5F5">{t("Floor")}</Typography>
                    </Button>
                </div>
            </List>
        </div>
    );
}
