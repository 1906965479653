import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

export interface OptionModel {
    key: string;
    value: string;
}

interface CustomDropdownProps {
    dropdownId: string;
    previousSelectedOption?: string;
    options: OptionModel[];
    placeholder: string;
    height: string;
    innerHeight?: string;
    width: string;
    search?: true;
    isEmpty?: boolean;
    disabled?: boolean;
    bg?: string;
    onSelectClick: (selectedKey: string) => void;
}

export interface CustomDropdownMethods {
    resetDropdown: () => void;
}

const CustomDropdown = forwardRef<CustomDropdownMethods, CustomDropdownProps>((props, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptionKey, setSelectedOptionKey] = useState<string>("");
    const [selectedOptionValue, setSelectedOptionValue] = useState<string>("");
    const [searchValue, setSearchValue] = useState<string>("");
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const previus: OptionModel | undefined = props.options.find(option => option.key === props.previousSelectedOption)
        if (previus !== undefined) {
            setSelectedOptionKey(previus.key)
            setSelectedOptionValue(previus.value)
        }
    }, [props.previousSelectedOption])

    const handleOptionClick = (option: OptionModel) => {
        setSelectedOptionKey(option.key);
        setSelectedOptionValue(option.value);
        props.onSelectClick(option.key);
        setSearchValue("");
        setIsOpen(false);
    };

    const handleClickOutside = (event: Event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const resetDropdown = () => {
        setSelectedOptionKey("");
        setSelectedOptionValue("");
    };

    useImperativeHandle(ref, () => ({
        resetDropdown,
    }), []);

    return (
        <div id={props.dropdownId} ref={dropdownRef} className={`${props.width === "w-full" ? props.width : ""} ${props.disabled ? "pointer-events-none opacity-50" : ""} relative inline-block text-base text-left ml-auto`}>
            <div className="relative">
                {props.search ? <div>
                    {isOpen && <input type="text" autoComplete="off" autoFocus placeholder={t("Search")} className={`h-full w-fit border-none outline-none bg-transparent text-#F5F5F5 placeholder:text-#545B6A absolute pl-3 tracking-wide`} onChange={(e) => setSearchValue(e.target.value)} />}
                    <span onClick={() => setIsOpen(!isOpen)} className={`${props.height} ${props.width} cursor-pointer inline-flex items-center px-3 py-2 rounded ${selectedOptionKey ? "text-#F5F5F5" : "text-#545B6A"} ${props.bg ? "bg-" + props.bg : "bg-#2E333E"} focus:outline-none shadow-inner ${props.isEmpty ? "border-[1px] border-#EF5350" : ""}`}>
                        {isOpen ? searchValue : (selectedOptionKey ? t(selectedOptionValue) : props.placeholder)}
                        <svg className="ml-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none" aria-hidden="true" transform={isOpen ? "rotate(180)" : ""}>
                            <path d="M19 9L12 15L5 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div> : <div>
                    <span onClick={() => setIsOpen(!isOpen)} className={`${props.height} ${props.width} cursor-pointer inline-flex items-center px-3 py-2 rounded ${selectedOptionKey ? "text-#F5F5F5" : "text-#545B6A"} ${props.bg ? "bg-" + props.bg : "bg-#2E333E"} focus:outline-none shadow-inner ${props.isEmpty ? "border-[1px] border-#EF5350" : ""}`}>
                        {selectedOptionKey ? t(selectedOptionValue) : props.placeholder}
                        <svg className="ml-auto h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none" aria-hidden="true" transform={isOpen ? "rotate(180)" : ""}>
                            <path d="M19 9L12 15L5 9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>}

                {isOpen && (
                    <div className={`${props.height} ${props.width} origin-top-right absolute right-0 shadow-lg z-10`}>
                        <div className={`rounded-b-[5px] ${props.bg ? "bg-" + props.bg : "bg-#2E333E"} ${props.innerHeight} overflow-auto`}>
                            <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {searchValue ?
                                    props.options.filter(item => item.value.toLowerCase().includes(searchValue.toLowerCase())).map((option) => (
                                        <div id={option.key} key={option.key} onClick={() => handleOptionClick(option)} className="block px-4 py-2 text-#F5F5F5 hover:bg-#D79F451A cursor-pointer" role="menuitem">
                                            {t(option.value)}
                                        </div>
                                    )) :
                                    props.options.map((option) => (
                                        <div id={option.key} key={option.key} onClick={() => handleOptionClick(option)} className="block px-4 py-2 text-#F5F5F5 hover:bg-#D79F451A cursor-pointer" role="menuitem">
                                            {t(option.value)}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default CustomDropdown;
