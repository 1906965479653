import { Button, Checkbox, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { HeightPopupProps } from "../roomDetailsModels";
import { SpaceHeatingSurface } from "../../../app/enums";
import { getEnumKeyByValue } from "../../../app/common";

const HeightPopup: React.FC<HeightPopupProps> = ({ openHeightPopup, setOpenHeightPopup, height, spaceHeatingSurface, setSpaceHeatingSurface }) => {
    const { t } = useTranslation();
    const disableCheckbox: boolean = height > 10 ? true : false;
    const [spaceHeatingSurfaceDraft, setSpaceHeatingSurfaceDraft] = useState<string>(spaceHeatingSurface)
    const [correctionFactorValue, setCorrectionFactorValue] = useState<string>("")

    const [warmFloor, setWarmFloor] = useState<boolean>(false);
    const [warmCeiling, setWarmCeiling] = useState<boolean>(false);
    const [medHighTemp, setMedHighTemp] = useState<boolean>(false);
    const [naturalWarm, setNaturalWarm] = useState<boolean>(false);
    const [leakage, setLeakage] = useState<boolean>(false);
    const [downward, setDownward] = useState<boolean>(false);
    const [medHighLeak, setMedHighLeak] = useState<boolean>(false);

    const onWarmFloor = () => {
        setWarmFloor(true)
        setWarmCeiling(false)
        setMedHighTemp(false)
        setNaturalWarm(false)
        setLeakage(false)
        setDownward(false)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.WARMFLOOR))
    }

    const onWarmCeiling = () => {
        setWarmFloor(false)
        setWarmCeiling(true)
        setMedHighTemp(false)
        setNaturalWarm(false)
        setLeakage(false)
        setDownward(false)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.WARMCEILING))
    }

    const onMedHighTemp = () => {
        setWarmFloor(false)
        setWarmCeiling(false)
        setMedHighTemp(true)
        setNaturalWarm(false)
        setLeakage(false)
        setDownward(false)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.MEDIUMANDHIGHRADIATION))
    }

    const onNaturalWarm = () => {
        setWarmFloor(false)
        setWarmCeiling(false)
        setMedHighTemp(false)
        setNaturalWarm(true)
        setLeakage(false)
        setDownward(false)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.NATURALAIRCONVECTION))
    }

    const onLeakage = () => {
        setWarmFloor(false)
        setWarmCeiling(false)
        setMedHighTemp(false)
        setNaturalWarm(false)
        setLeakage(true)
        setDownward(false)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.LEAKAGEFROMLOWALTITUDE))
    }

    const onDownward = () => {
        setWarmFloor(false)
        setWarmCeiling(false)
        setMedHighTemp(false)
        setNaturalWarm(false)
        setLeakage(false)
        setDownward(true)
        setMedHighLeak(false)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.DOWNWARDFROMAGREATHIGHT))
    }

    const onMedHighLeak = () => {
        setWarmFloor(false)
        setWarmCeiling(false)
        setMedHighTemp(false)
        setNaturalWarm(false)
        setLeakage(false)
        setDownward(false)
        setMedHighLeak(true)
        setSpaceHeatingSurfaceDraft(getEnumKeyByValue(SpaceHeatingSurface, SpaceHeatingSurface.MEDIUMANDHIGHLEAKAGE))
    }

    function handleOpen() {
        setOpenHeightPopup((prevOpenHeightPopup: any) => !prevOpenHeightPopup);
    }

    function handleSaveChanges() {
        if (spaceHeatingSurfaceDraft !== "") {
            setSpaceHeatingSurface(spaceHeatingSurfaceDraft)
            setOpenHeightPopup((prevOpenHeightPopup: any) => !prevOpenHeightPopup);
        }
    }

    useEffect(() => {
        if (!disableCheckbox) {
            switch (spaceHeatingSurfaceDraft) {
                case "WARMFLOOR": setCorrectionFactorValue("1")
                    break;
                case "WARMCEILING": setCorrectionFactorValue("1.15")
                    break;
                case "MEDIUMANDHIGHRADIATION": setCorrectionFactorValue("1")
                    break;
                case "NATURALAIRCONVECTION": setCorrectionFactorValue("1.15")
                    break;
                case "LEAKAGEFROMLOWALTITUDE": setCorrectionFactorValue("1.3")
                    break;
                case "DOWNWARDFROMAGREATHIGHT": setCorrectionFactorValue("1.2")
                    break;
                case "MEDIUMANDHIGHLEAKAGE": setCorrectionFactorValue("1.15")
                    break;
            }
        } else {
            switch (spaceHeatingSurfaceDraft) {
                case "WARMFLOOR": setCorrectionFactorValue("1")
                    break;
                case "MEDIUMANDHIGHRADIATION": setCorrectionFactorValue("1.15")
                    break;
                case "LEAKAGEFROMLOWALTITUDE": setCorrectionFactorValue("1.6")
                    break;
                case "DOWNWARDFROMAGREATHIGHT": setCorrectionFactorValue("1.45")
                    break;
                case "MEDIUMANDHIGHLEAKAGE": setCorrectionFactorValue("1.3")
                    break;
            }
        }
    }, [spaceHeatingSurfaceDraft])

    useEffect(() => {
        if (openHeightPopup && (spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setWarmFloor(false)
            setWarmCeiling(false)
            setMedHighTemp(false)
            setNaturalWarm(false)
            setLeakage(false)
            setDownward(false)
            setMedHighLeak(false)
        }
    }, [openHeightPopup])

    return (
        <Dialog className="flex font-inter bg-transparent shadow-none justify-center" size="md" open={openHeightPopup} handler={handleOpen}>
            <div className="bg-[#343A47] w-[753px] h-[603px] m-0 pt-[22px] pl-[37px] pb-[0px]">
                <DialogHeader className="flex flex-col items-start p-0">
                    <div className="flex flex-row w-[100%] justify-between items-center pr-[10px] pb-[16px]">
                        <Typography className="text-[#F5F5F5] uppercaser leading-none">{t("SpecialCase")}</Typography>
                    </div>
                    <Typography className="leading-none text-[#EF5350] mb-[10px]">{t("Note")}</Typography>
                    <div className="mb-[21px] h-[152px] w-[682px] space-y-[16px]">
                        <Typography className="leading-none pl-[2px] text-[#8D929B]">{t("heightPopupDescription")}</Typography>
                        <Typography className="leading-none pl-[2px] text-[#8D929B]">{t("heightPopupDescription2")}</Typography>
                        <Typography className="leading-none pl-[2px] text-[#8D929B]">{t("heightPopupDescription3")}</Typography>
                    </div>
                </DialogHeader>
                <div className="h-[1px] bg-[#272C35] mr-[23px] mb-[19px]" />
                <DialogBody className="text-[#F5F5F5] p-0 mb-[19px]">
                    <Typography className="leading-none uppercase mb-[18px]">{t("correctionWithRoomHeightFactor")}</Typography>
                    <Typography className="leading-none">{t("heatingMethodAndTypeOrArrangement")}</Typography>
                    <div className="flex mt-[5px] py-auto h-[184px] justify-start">
                        <div className="flex flex-col">
                            <Checkbox id="checkWarmFloor" crossOrigin={""} onChange={onWarmFloor} defaultChecked={warmFloor} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={warmFloor}
                                label={<Typography id="labelWarmFloor" className="w-[356px]  leading-none text-[#F5F5F5]">{t("warmFloor")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="checkWarmCeiling" crossOrigin={""} disabled={disableCheckbox} onChange={onWarmCeiling} defaultChecked={warmCeiling} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={warmCeiling}
                                label={<Typography id="labelWarmCeiling" className="w-[356px] leading-none text-[#F5F5F5]">{t("warmCeiling")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="checkMedHighTemp" crossOrigin={""} onChange={onMedHighTemp} defaultChecked={medHighTemp} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={medHighTemp}
                                label={<Typography id="labelMedHighTemp" className="w-[356px] leading-none text-[#F5F5F5]">{t("mediumHighTemperatureDownwardRadiation")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="checkNaturalWarmAironvection" crossOrigin={""} disabled={disableCheckbox} onChange={onNaturalWarm} defaultChecked={naturalWarm} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={naturalWarm}
                                label={<Typography id="labelNaturalWarmAironvection" className="w-[356px] leading-none text-[#F5F5F5]">{t("naturalWarmAironvection")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                        </div>
                        <div className="flex flex-col w-[50%]">
                            <Checkbox id="checkLeakage" crossOrigin={""} onChange={onLeakage} defaultChecked={leakage} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={leakage}
                                label={<Typography id="labelLeakage" variant="small" className="w-[241px] leading-none text-[#F5F5F5]">{t("leakageFromLowAltitude")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="checkDownward" crossOrigin={""} onChange={onDownward} defaultChecked={downward} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={downward}
                                label={<Typography id="labelDownward" variant="small" className="w-[241px] leading-none text-[#F5F5F5]">{t("downwardFromTheGreatHigh")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                            <Checkbox id="checkMediumHighTempLeak" crossOrigin={""} onChange={onMedHighLeak} defaultChecked={medHighLeak} className="w-[16px] h-[16px] rounded-full hover:before:opacity-0 shrink-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={medHighLeak}
                                label={<Typography id="labelMediumHighTempLeak" variant="small" leading-none className="w-[241px] text-[#F5F5F5]">{t("mediumHighTempLeakFromMedHeight")}</Typography>}
                                containerProps={{ className: "-ml-2.5" }} />
                        </div>
                    </div>
                </DialogBody>
                <div className="h-[1px] bg-[#272C35] mb-[13px]"></div>
                <DialogFooter className="flex flex-row justify-between p-0 mr-[30px]">
                    <div className="flex items-center">
                        <Typography className="leading-none text-[#F5F5F5] mr-[27px]">{t("RoomHeightCorrectionFactor") + ":"}</Typography>
                        <input id="inputRoomHeight" type="text" disabled={true} className="bg-[#2E333E] text-[#F5F5F5] text-center w-[66px] h-[40px] rounded-md disabled:text-opacity-50" value={correctionFactorValue} />
                    </div>
                    <div className="flex items-center space-x-[30px]">
                        <Button id="cancelBtn" onClick={handleOpen} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</Button>
                        <Button id="saveBtn" type="submit" onClick={handleSaveChanges} className="float-right w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                            {t("Save")}
                        </Button>
                    </div>
                </DialogFooter>
            </div>
        </Dialog>
    );
}

export default HeightPopup;