import { useState, ChangeEvent, useEffect, useRef } from "react";
import { Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { CompanyModel, initialCompanyModel } from "../settingsModels";
import { AddCompany, UpdateCompany, resetCompanyInformation, resetSettingsStatusValues } from "../settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { NotificationPopup, notify } from "../../../UIComponents/notification";

export function CompanyInformationPage() {
    const { t } = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);

    const [companyInformation, setCompanyInformation] = useState<CompanyModel>(initialCompanyModel);
    const settingsSliceData = useSelector((state: RootState) => state.SettingsSlice.settingsSliceData);
    const emptyId = '00000000-0000-0000-0000-000000000000'
    const dispatch = useDispatch<AppDispatch>();
    const userId = localStorage.getItem('userId')!
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [inputError, setInputError] = useState<boolean>(false);
    const [currentPhoto, setCurrentPhoto] = useState<string | null>(settingsSliceData.CompanyData?.companyPicture);

    useEffect(() => {
        dispatch(resetSettingsStatusValues());
        setCompanyInformation(settingsSliceData.CompanyData);
        settingsSliceData.CompanyData?.companyId === emptyId && setEditMode(true);
    }, [])

    function triggerEditMode() {
        setEditMode(prevEditMode => !prevEditMode)
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        if (!e.target.value.startsWith(' ')) {
            setCompanyInformation({
                ...companyInformation,
                [name]: value
            })
        }
    }

    const handleSave = () => {
        setInputError(true);
        let addUpdateModel = replaceNull(companyInformation);
        setCompanyInformation(addUpdateModel);
        if (addUpdateModel.name !== '' && addUpdateModel.city !== '' && addUpdateModel.country !== '' && addUpdateModel.emailAddress_1 !== '' && addUpdateModel.phoneNumber_1 !== '' && addUpdateModel.postalCode !== '' && addUpdateModel.street !== '') {
            if (settingsSliceData.CompanyData?.companyId !== emptyId) {
                dispatch(UpdateCompany({ ...addUpdateModel, userId: userId, companyId: settingsSliceData.CompanyData?.companyId }));
                setInputError(false);
                setEditMode(prevEditMode => !prevEditMode);
            } else {
                dispatch(AddCompany({ ...addUpdateModel, userId: userId }));
                setInputError(false);
                setEditMode(prevEditMode => !prevEditMode);
            }
        }
    };

    async function handlePhotoChange(e: ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader();
        const target = e.target as HTMLInputElement & {
            files: FileList
        }
        const photoUploaded = target.files[0];
        if (photoUploaded) {
            reader.readAsDataURL(photoUploaded);
            reader.onload = () => {
                const base64String = reader.result?.toString().split(",")[1];
                if (base64String) {
                    setCompanyInformation({ ...companyInformation, companyPicture: base64String });
                    setCurrentPhoto(base64String);
                }
            }
        }
    }

    function stringMaxLength(stringValue: string) {
        if (stringValue?.length > 40) {
            let newString = stringValue.substring(0, 40) + '...';
            return newString
        }
        return stringValue;
    }

    function handleClick() {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }

    function replaceNull(companyInformation: CompanyModel) {
        let replacedState = { ...companyInformation };
        for (let key in replacedState) {
            if (replacedState.hasOwnProperty(key) && replacedState[key as keyof CompanyModel] === null && key !== 'companyPicture') {
                replacedState[key as keyof CompanyModel] = '';
            }
        }
        return replacedState;
    }

    useEffect(() => {
        setCompanyInformation(settingsSliceData.CompanyData);
    }, [editMode])

    useEffect(() => {
        if (settingsSliceData.statusValues.statusCode === "") {
            return
        }
        if (settingsSliceData.statusValues.statusCode === "200" && settingsSliceData.statusValues.showNotification) {
            setCompanyInformation(settingsSliceData.CompanyData);
            notify(t(settingsSliceData.statusValues.message));
            dispatch(resetSettingsStatusValues());
            dispatch(resetCompanyInformation());

        } else if (settingsSliceData.statusValues.statusCode !== "200" && settingsSliceData.statusValues.showNotification) {
            notify(t(settingsSliceData.statusValues.message), true);
            dispatch(resetSettingsStatusValues());
        }
    }, [settingsSliceData.statusValues])

    return (
        <div className="bg-#343A47 h-[929px] w-[566px] md:w-[796px] lg:w-[796px] m-[12px] lg:m-[30px] pt-[20px] pl-[37px] rounded-[10px] font-inter text-[#F5F5F5]">
            <h1 className="title border-red-500 text-[20px] mb-[46px]">{editMode ? `${t("EditCompanyInformation")}` : `${t("CompanyInformation")}`}</h1>
            <div className={`profile-container flex`}>
                <div className="flex flex-col items-center ml-[45px] mr-[75px]">
                    <div className="flex bg-#3B4250 w-[162px] h-[162px] rounded-[100%] overflow-hidden justify-center items-center">
                        {
                            //(settingsSliceData.CompanyData?.companyPicture !== null && settingsSliceData.CompanyData?.companyPicture !== undefined && settingsSliceData.CompanyData?.companyPicture !== "") ?
                            companyInformation.companyPicture ? <img src={`data:image/jpg;base64,${companyInformation.companyPicture}`} /> : <Typography>Company Photo</Typography>//:
                            //<Typography>Company Photo</Typography>
                        }
                    </div>
                    {editMode &&
                        <div className="flex flex-col w-auto mt-[30px] items-center">
                            <button className="hover:text-[#FF9800]" onClick={handleClick}>{t("Upload")}</button>
                            <input
                                type="file"
                                onChange={handlePhotoChange}
                                style={{ display: 'none' }}
                                ref={hiddenFileInput}
                                accept="image/*"
                            />
                        </div>}
                </div>
                <div id="formContainer">
                    <div className="flex flex-col">
                        <div className={`flex flex-col ${editMode ? "mb-[22px]" : "mb-[27px]"} `}>
                            <label htmlFor="companyName" className={`${editMode ? "mb-[5px]" : "mb-[12px]"} text-[16px]`}>{t("CompanyName") + ":"}</label>
                            {editMode ? <input id="companyName" value={companyInformation?.name} name="name" type="text" onChange={handleInputChange} placeholder={t("CompanyName")} className={` outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] shadow-inner ${inputError && companyInformation?.name === "" ? "border-[1px] border-#EF5350" : ""}`} /> : <p className="w-[10ch] text-[14px] opacity-80 font-light">{companyInformation?.name !== null ? stringMaxLength(companyInformation?.name) : ''}</p>}
                        </div>
                        <div className={`flex flex-col ${editMode ? "mb-[12px]" : "mb-[27px]"}`}>
                            <label htmlFor="streetAddress" className={`${editMode ? "mb-[5px]" : "mb-[12px]"} text-[16px]`}>{t("Address") + ":"}</label>
                            <div className="flex flex-col">
                                {editMode ? <input id="addressStreet" value={companyInformation?.street} name="street" type="text" onChange={handleInputChange} placeholder={t("StreetAddress")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] shadow-inner ${inputError && companyInformation?.street === "" ? "border-[1px] border-#EF5350" : ""}`} /> : <p className="text-[14px] opacity-80 mb-[5px] font-light">{companyInformation?.street !== null ? stringMaxLength(companyInformation?.street) : ''}</p>}
                                {editMode ? <input id="addressCity" value={companyInformation?.city} name="city" type="text" onChange={handleInputChange} placeholder={t("City")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] shadow-inner mt-[5px] mb-[5px] ${inputError && companyInformation?.city === "" ? "border-[1px] border-#EF5350" : ""}`} /> : <p className="text-[14px] opacity-80 mb-[5px] font-light">{companyInformation?.city !== null ? stringMaxLength(companyInformation?.city) : ''}</p>}
                                {editMode ? <input id="addressCountry" type="text" value={companyInformation?.country} name="country" placeholder={t("Country")} onChange={handleInputChange} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] ${inputError && companyInformation?.country === "" ? "border-[1px] border-#EF5350" : ""}`} shadow-inner /> : <p className="text-[14px] opacity-80 font-light">{companyInformation?.country !== null ? stringMaxLength(companyInformation?.country) : ''}</p>}
                            </div>
                        </div>
                        <div className={`flex flex-col ${editMode ? "mb-[23px]" : "mb-[27px]"}`}>
                            <label htmlFor="registerNr" className={`${editMode ? "mb-[5px]" : "mb-[12px]"} text-[16px]`}>{t("PostalCode") + ":"}</label>
                            {editMode ? <input id="registerNum" value={companyInformation?.postalCode} name="postalCode" type="text" onChange={handleInputChange} placeholder={t("PostalCode")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] shadow-inner ${inputError && companyInformation?.postalCode === "" ? "border-[1px] border-#EF5350" : ""}`} /> : <p className="text-[14px] opacity-80 font-light">{companyInformation?.postalCode !== null ? stringMaxLength(companyInformation?.postalCode) : ''}</p>}
                        </div>

                        <div className={`flex flex-col ${editMode ? "mb-[23px]" : "mb-[27px]"}`}>
                            <label htmlFor="phoneNumber" className={`${editMode ? "mb-[5px]" : "mb-[12px]"} text-[16px]`}>{t("PhoneNumber") + ":"}</label>
                            {editMode ? <input id="phoneNum" value={companyInformation?.phoneNumber_1} name="phoneNumber_1" type="text" onChange={handleInputChange} placeholder={t("PhoneNumber1")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] mb-[5px] shadow-inner ${inputError && companyInformation?.phoneNumber_1 === "" ? "border-[1px] border-#EF5350" : ""}`} /> : <p className="text-[14px] opacity-80 mb-[5px] font-light ">{companyInformation?.phoneNumber_1 !== null ? stringMaxLength(companyInformation?.phoneNumber_1) : ''}</p>}
                            {editMode ? <input id="phoneNum" value={companyInformation?.phoneNumber_2} name="phoneNumber_2" type="text" onChange={handleInputChange} placeholder={t("PhoneNumber2")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] mb-[5px]`} shadow-inner /> : <p className="text-[14px] opacity-80 font-light">{companyInformation?.phoneNumber_2 !== null ? stringMaxLength(companyInformation?.phoneNumber_2) : ''}</p>}
                        </div>
                        <div className={`flex flex-col mb-[27px]`}>
                            <label htmlFor="email" className={`${editMode ? "mb-[5px]" : "mb-[12px]"} text-[16px]`}>{t("Email") + ":"}</label>
                            {editMode ? <input id="email" value={companyInformation?.emailAddress_1} name="emailAddress_1" type="email" onChange={handleInputChange} placeholder={t("Email1")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] mb-[5px] ${inputError && companyInformation.emailAddress_1 === "" ? "border-[1px] border-#EF5350" : ""}`} shadow-inner /> : <p className="text-[14px] opacity-80 mb-[5px] font-light">{companyInformation?.emailAddress_1 !== null ? stringMaxLength(companyInformation?.emailAddress_1) : ''}</p>}
                            {editMode ? <input id="email" value={companyInformation?.emailAddress_2} name="emailAddress_2" type="email" onChange={handleInputChange} placeholder={t("Email2")} className={`outline-none bg-#2E333E placeholder:text-#545B6A w-[222px] h-8 pl-[12px] rounded-[5px] mb-[5px] shadow-inner`} /> : <p className="text-[14px] opacity-80 font-light">{companyInformation?.emailAddress_2 !== null ? stringMaxLength(companyInformation?.emailAddress_2) : ''}</p>}
                        </div>
                        {editMode &&
                            <div className="ml-auto">
                                < Button id="saveMyProfileBtn" onClick={handleSave} className="h-10 bg-#FF9800 items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                    {t("Save")}
                                </Button>
                                < Button id="cancelBtn" onClick={() => setEditMode(false)} className="h-10 bg-#FF9800 ml-3 items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                    {t("Cancel")}
                                </Button>
                            </div>
                        }
                    </div>
                    {!editMode &&
                        <Button id="editCompanyInfoBtn" variant="text" size="sm" className="flex flex-row items-center p-0 text-[14px] ml-auto text-#FF9800 font-medium normal-case shadow-none hover:shadow-none hover:bg-transparent" onClick={triggerEditMode}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#FF9800 mr-[12px]">
                                <path d="M9.57403 2.71878L10.1919 2.10086C11.2157 1.07706 12.8757 1.07706 13.8995 2.10086C14.9233 3.12466 14.9233 4.78458 13.8995 5.80838L13.2815 6.4263M9.57403 2.71878C9.57403 2.71878 9.65127 4.03186 10.8099 5.19046C11.9685 6.34906 13.2815 6.4263 13.2815 6.4263M9.57403 2.71878L3.8932 8.39961C3.50843 8.78438 3.31604 8.97677 3.15058 9.1889C2.95541 9.43913 2.78807 9.70988 2.65155 9.99636C2.53581 10.2392 2.44977 10.4973 2.27769 11.0136L1.54852 13.2011M13.2815 6.4263L7.60072 12.1071C7.21594 12.4919 7.02356 12.6843 6.81143 12.8497C6.5612 13.0449 6.29045 13.2123 6.00397 13.3488C5.76111 13.4645 5.503 13.5506 4.98677 13.7226L2.79926 14.4518M2.79926 14.4518L2.26455 14.63C2.0105 14.7147 1.73042 14.6486 1.54107 14.4593C1.35172 14.2699 1.2856 13.9898 1.37028 13.7358L1.54852 13.2011M2.79926 14.4518L1.54852 13.2011" />
                            </svg>
                            {t("EditCompanyInformation")}
                        </Button>
                    }
                </div>
            </div>
            <NotificationPopup />
        </div>
    );
}
