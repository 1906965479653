import { Checkbox, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleInputChange, handleSpaceInInput } from "../../../app/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

interface GeometryProps {
    isEdit: boolean,
    buildingNameOnChange: (name: string) => void,
    lengthOnChange: (length: string) => void,
    widthOnChange: (width: string) => void,
    bottomPlateThicknessOnChange: (bpt: string) => void,
    parameterZOnChange: (z: string) => void,
    buildingAreaOnChange: (area: string) => void,
    buildingPerimeterOnChange: (parimeter: string) => void,
    groundWaterDepthOnChange: (wd: string) => void,
}

function Geometry(props: GeometryProps) {
    const { t } = useTranslation();
    const [buildingName, setBuildingName] = useState("");
    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [bottomPlateThickness, setBottomPlateThickness] = useState("");
    const [parameterZ, setParameterZ] = useState("");
    const [buildingArea, setBuildingArea] = useState("");
    const [buildingPerimeter, setBuildingPerimeter] = useState("");
    const [isKnownWaterDepth, setIsKnownWaterDepth] = useState(false);
    const [groundWaterDepth, setGroundWaterDepth] = useState("");
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData.buildingData.buildingData);

    const onNameChange = (buildingName: string) => {
        props.buildingNameOnChange(buildingName);
    }
    const onLengthChange = (length: string) => {
        props.lengthOnChange(length);
    }
    const onWidthChange = (width: string) => {
        props.widthOnChange(width);
    }
    const onBottomPlateThicknessChange = (bottomPlateThickness: string) => {
        props.bottomPlateThicknessOnChange(bottomPlateThickness);
    }
    const onZChange = (parameterZ: string) => {
        props.parameterZOnChange(parameterZ);
    }
    const onAreaChange = (buildingArea: string) => {
        props.buildingAreaOnChange(buildingArea);
    }
    const onPerimeterChange = (buildingPerimeter: string) => {
        props.buildingPerimeterOnChange(buildingPerimeter);
    }
    const onIsKnownGroundWaterDepthChange = (isKnownWD: boolean) => {
        setIsKnownWaterDepth(isKnownWD);
        if (!isKnownWD) {
            setGroundWaterDepth("");
            props.groundWaterDepthOnChange("");
        }
    }
    const onGroundWaterDepthChange = (groundWaterDepth: string) => {
        props.groundWaterDepthOnChange(groundWaterDepth);
    }

    useEffect(() => {
        if (props.isEdit) {
            setBuildingName(buildingSliceData.buildingName);
            setLength(buildingSliceData.length?.toString());
            setWidth(buildingSliceData.width?.toString());
            setBottomPlateThickness(buildingSliceData.bottomPlateThickness?.toString());
            setParameterZ(buildingSliceData.z?.toString());
            setBuildingArea(buildingSliceData.buildingArea?.toString());
            setBuildingPerimeter(buildingSliceData.buildingPerimeter?.toString());
            if (buildingSliceData.groundWaterDepth === null || buildingSliceData.groundWaterDepth?.toString() === '') {
                setIsKnownWaterDepth(false);
            }
            else {
                setIsKnownWaterDepth(true);
            }
            setGroundWaterDepth(buildingSliceData.groundWaterDepth?.toString());
            onNameChange(buildingSliceData.buildingName);
            onLengthChange(buildingSliceData.length?.toString());
            onWidthChange(buildingSliceData.width?.toString());
            onBottomPlateThicknessChange(buildingSliceData.bottomPlateThickness?.toString());
            onZChange(buildingSliceData.z?.toString());
            onAreaChange(buildingSliceData.buildingArea?.toString());
            onPerimeterChange(buildingSliceData.buildingPerimeter?.toString());
            onGroundWaterDepthChange(buildingSliceData.groundWaterDepth?.toString());
        }
    }, [buildingSliceData])

    return (
        <div className="bg-transparent w-full h-fit flex items-center justify-start py-[30px] pl-[30px] pr-[80px]">
            <div className="space-y-[15px] text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] leading-6 w-full">
                <div className="flex items-center">
                    <p>{t("BuildingName") + ":"}</p>
                    <input id="buildingName" autoComplete="off" type="text" placeholder={t("TypeABuildingName")} value={buildingName} onChange={(e) => handleSpaceInInput(e, setBuildingName)} onBlur={(e) => onNameChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[284px] md:w-[606px] lg:w-[800px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                </div>
                <div className="flex items-center">
                    <p>{t("Length") + ":"}</p>
                    <input id="length" autoComplete="off" type="text" placeholder={"0.00"} value={length} onChange={(e) => handleInputChange(e, setLength, 2)} onBlur={(e) => onLengthChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("Width") + ":"}</p>
                    <input id="width" autoComplete="off" type="text" placeholder={"0.00"} value={width} onChange={(e) => handleInputChange(e, setWidth, 2)} onBlur={(e) => onWidthChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("BottomPlateThickness") + ":"}</p>
                    <input id="bottomPlateThickness" autoComplete="off" type="text" placeholder={"0.00"} value={bottomPlateThickness} onChange={(e) => handleInputChange(e, setBottomPlateThickness, 2)} onBlur={(e) => onBottomPlateThicknessChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("ParameterZ") + ":"}</p>
                    <input id="parameterZ" autoComplete="off" type="text" placeholder={"0.00"} value={parameterZ} onChange={(e) => handleInputChange(e, setParameterZ, 2)} onBlur={(e) => onZChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("BuildingArea") + " (" + t("Optional") + ")" + ":"}</p>
                    <input id="buildingArea" autoComplete="off" type="text" placeholder={"0.00"} value={buildingArea} onChange={(e) => handleInputChange(e, setBuildingArea, 2)} onBlur={(e) => onAreaChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[256px] md:w-[571px] lg:w-[764px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m²"}</p>
                </div>
                <div className="flex items-center">
                    <p>{t("BuildingPerimeter") + " (" + t("Optional") + ")" + ":"}</p>
                    <input id="buildingPerimeter" autoComplete="off" type="text" placeholder={"0.00"} value={buildingPerimeter} onChange={(e) => handleInputChange(e, setBuildingPerimeter, 2)} onBlur={(e) => onPerimeterChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m"}</p>
                </div>
                <Checkbox id="depthToggle" crossOrigin={""} ripple={false} onChange={() => onIsKnownGroundWaterDepthChange(!isKnownWaterDepth)} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={isKnownWaterDepth}
                    label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal lowercase" >{t("ValueOfGroundWaterDepthIsKnown")}</Typography>}
                    containerProps={{ className: "-ml-2.5" }} />
                <div className="flex items-center">
                    <p className={`${isKnownWaterDepth ? "" : "text-#545B6A"}`}>{t("GroundWaterDepth") + ":"}</p>
                    <input id="groundWaterDepth" autoComplete="off" type="text" disabled={!isKnownWaterDepth} placeholder={"0"} value={groundWaterDepth} onChange={(e) => handleInputChange(e, setGroundWaterDepth, 0)} onBlur={(e) => onGroundWaterDepthChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[261px] md:w-[578px] lg:w-[770px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className={`pl-[8px] md:pl-[12px] ${isKnownWaterDepth ? "" : "text-#545B6A"}`}>{"m"}</p>
                </div>
            </div>
        </div>
    );
}

export default Geometry;