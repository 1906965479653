import { getEnumValueByKey } from "../../../../app/common";
import { MaterialType } from "../../../../app/enums";
import { useTranslation } from "react-i18next";
import { MaterialModel } from "../../Material/materialModels";

interface AddMaterialComponentProps {
    material: MaterialModel;
    onSeleckClick: (material: MaterialModel) => void;
}

function AddMaterialComponent(props: AddMaterialComponentProps) {
    const { t } = useTranslation();

    return (
        <div id={"Material-" + props.material.name} key={props.material.materialId} onClick={() => props.onSeleckClick(props.material)} className="bg-#343A47 min-h-[60px] w-[937px] lg:w-[1321px] xl:w-[1528px] p-[20px] grid grid-cols-5 text-#F5F5F5 text-sm md:text-base rounded-[10px] shadow place-content-center cursor-pointer border-#343A47 border-[1px] hover:shadow-projectOrange hover:border-#FF980080">
            <p className="col-span-3 my-auto pr-2">{props.material.name}</p>
            <p className="text-center my-auto">{t(getEnumValueByKey(MaterialType, props.material.materialType))}</p>
            <p className="text-center my-auto">{props.material.thermalConductivity}</p>
        </div>
    );
}

export default AddMaterialComponent;