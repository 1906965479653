import { useState } from 'react';

interface Notification {
    error?: boolean;
    message: string;
}

let setNotification: React.Dispatch<React.SetStateAction<Notification | null>>;

export const notify = (message: string, error?: boolean, duration?: number) => {
    setNotification({ error, message });

    setTimeout(() => {
        setNotification(null);
    }, duration ? duration : 3000);
};

export const NotificationPopup = () => {
    const [notificationState, setNotificationState] = useState<Notification | null>(null);
    setNotification = setNotificationState;

    return (notificationState &&
        <div className="absolute right-0 bottom-0 m-10 z-[10000]">
            <div className={`${notificationState.error ? "bg-#EF5350" : "bg-#66BB6A"} p-[22px] rounded-md flex items-center justify-center`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={`${notificationState.error ? "rotate-180" : ""}`}>
                    <rect x="24" y="24" width="24" height="24" />
                    <path d="M11 17L13 17L13 11L11 11L11 17ZM12 9C12.2833 9 12.521 8.904 12.713 8.712C12.9043 8.52067 13 8.28334 13 8C13 7.71667 12.9043 7.479 12.713 7.287C12.521 7.09567 12.2833 7 12 7C11.7167 7 11.4793 7.09567 11.288 7.287C11.096 7.479 11 7.71667 11 8C11 8.28333 11.096 8.52067 11.288 8.712C11.4793 8.904 11.7167 9 12 9ZM12 22C10.6167 22 9.31666 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31266 17.1167 2.788 15.9C2.26266 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26234 15.9 2.787C17.1167 3.31234 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88334 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76666 20 12 20Z" fill="#272C35" />
                </svg>
                <p className="pl-[12px] text-center text-#272C35 font-bold">{notificationState.message}</p>
            </div>
        </div>)
};
