import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { GetAllComponentCollections, GetAllComponentsWithFixedValues, GetAllComponentsWithLayeredStructure, GetAllOpeningCollections, GetAllOpenings } from "../../Components/Component/componentsSlice";
import { ComponentInCollectionModel, ComponentModel, OpeningInCollectionModel, OpeningModel } from "../../Components/Component/componentsModels";
import { Dialog } from "@material-tailwind/react";
import { formatNumber, getEnumValueByKey } from "../../../app/common";
import { ComponentType, OpeningType } from "../../../app/enums";
import CustomDropdown, { OptionModel } from "../../../UIComponents/customDropdown";

interface CodeProps {
    onCodeSelect: (code: string, id: string, itemInCollectionId: string | null) => void,
    projectId: string,
    opening: boolean,
    onClose: () => void
}

function ComponentOpeningPopup(props: CodeProps) {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [components] = useState<ComponentModel[]>([]);
    const [componentsCollections] = useState<OptionModel[]>([]);
    const [componentsOfSelectedCollections, setComponentsOfSelectedCollections] = useState<ComponentInCollectionModel[]>([]);
    const [openings] = useState<OpeningModel[]>([]);
    const [openingsCollections] = useState<OptionModel[]>([]);
    const [openingsOfSelectedCollections, setOpeningsOfSelectedCollections] = useState<OpeningInCollectionModel[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const componentSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    let toScrollTableFrom: number = 5;

    useEffect(() => {
        if (props.opening) {
            dispatch(GetAllOpenings(props.projectId))
            dispatch(GetAllOpeningCollections(localStorage.getItem('userId')!))
        }
        else {
            dispatch(GetAllComponentsWithFixedValues(props.projectId))
            dispatch(GetAllComponentsWithLayeredStructure(props.projectId))
            dispatch(GetAllComponentCollections(localStorage.getItem('userId')!))
        }
    }, [])

    useEffect(() => {
        components.splice(0, components.length);
        componentSliceData.componentsData.allComponentsWithLayeredStructureData.forEach(comp => {
            components.push(comp);
        });
        componentSliceData.componentsData.allComponentsWithFixedValuesData.forEach(comp => {
            components.push(comp);
        });
    }, [componentSliceData.componentsData.allComponentsWithLayeredStructureData, componentSliceData.componentsData.allComponentsWithFixedValuesData])

    useEffect(() => {
        componentsCollections.splice(0, componentsCollections.length);
        componentSliceData.componentsData.allComponentInCollectionData.forEach(coll => {
            const option: OptionModel = {
                key: coll.collectionId,
                value: coll.collectionName,
            }
            componentsCollections.push(option);
        });
    }, [componentSliceData.componentsData.allComponentInCollectionData])

    useEffect(() => {
        openings.splice(0, openings.length);
        componentSliceData.openingsData.allOpeningsData.forEach(open => {
            openings.push(open);
        });
    }, [componentSliceData.openingsData.allOpeningsData])

    useEffect(() => {
        openingsCollections.splice(0, openingsCollections.length);
        componentSliceData.openingsData.allOpeningInCollectionData.forEach(coll => {
            const option: OptionModel = {
                key: coll.collectionId,
                value: coll.collectionName,
            }
            openingsCollections.push(option);
        });
    }, [componentSliceData.openingsData.allOpeningInCollectionData])

    const onTemplateSelect = (templateId: string) => {
        setSelectedTemplate(templateId);
        if (props.opening) {
            const openings = componentSliceData.openingsData.allOpeningInCollectionData
                .filter(open => open.collectionId === templateId)
                .flatMap(open => open.openings);
            setOpeningsOfSelectedCollections(openings);
        }
        else {
            const components = componentSliceData.componentsData.allComponentInCollectionData
                .filter(comp => comp.collectionId === templateId)
                .flatMap(comp => comp.components);
            setComponentsOfSelectedCollections(components);
        }
    }

    return (
        <Dialog open={openDialog} handler={() => { props.onClose(); handleDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} size="lg" className="flex justify-center bg-transparent shadow-none outline-none">
            <div className=" bg-#2E333E w-[812px] h-[700px] rounded-[5px] flex flex-col justify-between items-start py-[22px] px-[20px]">
                <div className=" space-y-5 text-#F5F5F5 text-[20px] leading-6 w-full">
                    <div className="flex flex-col items-start gap-8">
                        <p>{props.opening ? t("Openings") : t("Components")}</p>
                        <div className="flex flex-row items-center space-x-5">
                            <p className="text-base">{t("ChooseTemplate")}:</p>
                            <CustomDropdown dropdownId="chooseTemplateDropdown" isEmpty={false} disabled={false} options={props.opening ? openingsCollections : componentsCollections} placeholder={t("SelectTemplate")} height={"h-[30px]"} width={"w-[233px]"} bg="#343A47" onSelectClick={onTemplateSelect} />
                            <div className="relative flex items-center justify-end">
                                <input id="search" type="text" placeholder={t("Search")} value={searchValue} autoComplete="off" autoFocus={false} onChange={(e) => setSearchValue(e.target.value)} className="relative bg-#343A47 placeholder:text-#545B6A outline-none w-[233px] h-[30px] text-[16px] pl-[12px] pr-[48px] rounded-[5px] shadow-inner" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-7">
                        <div>
                            <div className={`bg-#3B4250 w-full grid grid-cols-8 text-#F5F5F5 text-base leading-4 ${(props.opening && openingsOfSelectedCollections.length > toScrollTableFrom) || componentsOfSelectedCollections.length > toScrollTableFrom ? "pr-[5px]" : ""}`}>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Code")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Name")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Type")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{t("ThermalTransmittance")}</p>
                            </div>
                            <div className="overflow-auto max-h-[200px]">
                                {
                                    !selectedTemplate && <p className="flex justify-center items-center h-[50px] w-full text-base font-light text-#545B6A border-b-[1px]">{t("ChooseTemplateToSeeComponents")}</p>
                                }
                                {props.opening ?
                                    openingsOfSelectedCollections.filter(o => o.openingCode.toLowerCase().includes(searchValue.toLowerCase())).map(opening => (
                                        <div id={"Opening-" + opening.openingId} key={opening.openingId} className="w-full grid grid-cols-8 text-#F5F5F5 text-base hover:bg-#D79F451A cursor-pointer" onClick={() => { props.onCodeSelect(opening.openingCode, opening.openingId, null); handleDialog() }}>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{opening.openingCode}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{opening.openingName}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t(getEnumValueByKey(OpeningType, opening.openingType))}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{formatNumber(opening.thermalTransmittance, 3)}</p>
                                        </div>
                                    ))
                                    :
                                    componentsOfSelectedCollections.filter(c => c.componentCode.toLowerCase().includes(searchValue.toLowerCase())).map(component => (
                                        <div id={"Component-" + component.componentId} key={component.componentId} className="w-full grid grid-cols-8 text-#F5F5F5 text-base hover:bg-#D79F451A cursor-pointer" onClick={() => { props.onCodeSelect(component.componentCode, component.componentId, null); handleDialog() }}>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{component.componentCode}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{component.componentName}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t(getEnumValueByKey(ComponentType, component.componentType))}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{formatNumber(component.thermalTransmittance, 3)}</p>
                                        </div>))
                                }
                            </div>
                        </div>
                        <div>
                            <div className={`bg-#3B4250 w-full grid grid-cols-8 text-#F5F5F5 text-base leading-4 ${(props.opening && openings.length > toScrollTableFrom) || components.length > toScrollTableFrom ? "pr-[5px]" : ""}`}>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Code")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Name")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("Type")}</p>
                                <p className="w-full h-[32px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{t("ThermalTransmittance")}</p>
                            </div>
                            <div className="overflow-auto max-h-[200px]">
                                {
                                    (openings.length < 1 && components.length < 1) && <p className="flex justify-center items-center h-[50px] w-full text-base font-light text-#545B6A border-b-[1px]">{t("NoComponentsCreated")}</p>
                                }
                                {props.opening ?
                                    openings.filter(o => o.openingCode.toLowerCase().includes(searchValue.toLowerCase())).map(opening => (
                                        <div id={"Opening-" + opening.openingId} key={opening.openingId} className="w-full grid grid-cols-8 text-#F5F5F5 text-base hover:bg-#D79F451A cursor-pointer" onClick={() => { props.onCodeSelect(opening.openingCode, opening.openingId, null); handleDialog() }}>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{opening.openingCode}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{opening.openingName}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t(getEnumValueByKey(OpeningType, opening.openingType))}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{formatNumber(opening.thermalTransmittance, 3)}</p>
                                        </div>
                                    ))
                                    :
                                    components.filter(c => c.componentCode.toLowerCase().includes(searchValue.toLowerCase())).map(component => (
                                        <div id={"Component-" + component.componentId} key={component.componentId} className="w-full grid grid-cols-8 text-#F5F5F5 text-base hover:bg-#D79F451A cursor-pointer" onClick={() => { props.onCodeSelect(component.componentCode, component.componentId, null); handleDialog() }}>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{component.componentCode}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{component.componentName}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t(getEnumValueByKey(ComponentType, component.componentType))}</p>
                                            <p className="w-full h-[50px] col-span-2 flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{formatNumber(component.thermalTransmittance, 3)}</p>
                                        </div>))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <button id="cancelBtn" onClick={() => { props.onClose(); handleDialog() }} className="ml-auto w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg">{t('Cancel')}</button>
            </div>
        </Dialog>
    );
}

export default ComponentOpeningPopup;