import { useTranslation } from "react-i18next";
import { FormatUTCDateTimeToDateString } from "../../../app/common";

interface ProjectComponentProps {
    project: string;
    status: boolean;
    dateCreated: string;
    lastEdited: string;
    isSelected: boolean;
    onSeleckClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

function ProjectComponent(props: ProjectComponentProps) {
    const { t } = useTranslation();

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        props.onSeleckClick(event);
    };

    const handleAuxClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.button === 1) {
            event.stopPropagation();
            props.onSeleckClick(event);
        }
    };

    return (
        <div id={props.project} onClick={handleMouseDown} className="cursor-pointer w-full">
            <div className={`bg-#343A47 min-h-[60px] p-[20px] grid grid-cols-7 md:grid-cols-8 xl:grid-cols-8 text-#F5F5F5 text-sm md:text-base rounded-[10px] shadow-project place-content-center border-solid border-[1px] hover:shadow-projectOrange hover:border-#FF980080 ${props.isSelected ? "shadow-projectOrange border-#FF9800" : "border-#343A47"}`}>
                <p className="col-span-3 my-auto pr-2">{props.project}</p>
                <p className="my-auto px-2">{props.status ? t("Done") : t("InProgress")}</p>
                <p className="my-auto px-2 hidden md:block">{FormatUTCDateTimeToDateString(props.dateCreated)}</p>
                <p className="my-auto px-2">{FormatUTCDateTimeToDateString(props.lastEdited)}</p>
                <button id="editProjectBtn" onClick={(e) => { e.stopPropagation(); props.onEditClick(); }} className="mx-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                        <path d="M14.3601 4.07866L15.2869 3.15178C16.8226 1.61607 19.3125 1.61607 20.8482 3.15178C22.3839 4.68748 22.3839 7.17735 20.8482 8.71306L19.9213 9.63993M14.3601 4.07866C14.3601 4.07866 14.4759 6.04828 16.2138 7.78618C17.9517 9.52407 19.9213 9.63993 19.9213 9.63993M14.3601 4.07866L5.83882 12.5999C5.26166 13.1771 4.97308 13.4656 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.32181 19.8021M19.9213 9.63993L11.4001 18.1612C10.8229 18.7383 10.5344 19.0269 10.2162 19.2751C9.84082 19.5679 9.43469 19.8189 9.00498 20.0237C8.6407 20.1973 8.25352 20.3263 7.47918 20.5844L4.19792 21.6782M4.19792 21.6782L3.39584 21.9456C3.01478 22.0726 2.59466 21.9734 2.31063 21.6894C2.0266 21.4053 1.92743 20.9852 2.05445 20.6042L2.32181 19.8021M4.19792 21.6782L2.32181 19.8021" strokeWidth="1.5" />
                    </svg>
                </button>
                <button id="deleteProjectBtn" onClick={(e) => { e.stopPropagation(); props.onDeleteClick(); }} className="mx-auto">
                    <svg className="m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </button>
            </div>
        </div >
    );
}

export default ProjectComponent;