import React, { useEffect } from "react";
import { TableProps } from "../roomDetailsModels";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { GetAllWalls, resetStatusValues } from "../buildingStructureSlice";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import WallInRoom from "./wallInRoomRow";
import NewRow from "./newRow";
import { Tooltip } from "@material-tailwind/react";

const RoomDetailsTable: React.FC<TableProps> = ({ handleSave }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>();

    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);

    useEffect(() => {
        if (buildingStructureSliceData.buildingStructureData.roomData.spaceId !== '') {
            dispatch(GetAllWalls(buildingStructureSliceData.buildingStructureData.roomData.spaceId))
        }
    }, [])

    useEffect(() => {
        if (buildingStructureSliceData.statusValues.statusCode === "") {
            return
        }
        if (buildingStructureSliceData.statusValues.statusCode === "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message));
            dispatch(resetStatusValues());
            if (buildingStructureSliceData.buildingStructureData.roomData.spaceId !== '') {
                dispatch(GetAllWalls(buildingStructureSliceData.buildingStructureData.roomData.spaceId))
            }
        }
        else if (buildingStructureSliceData.statusValues.statusCode !== "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message), true);
            dispatch(resetStatusValues());
        }
    }, [buildingStructureSliceData.statusValues])

    return (
        <div className="overflow-auto">
            <div className="bg-#3B4250 min-w-fit text-#F5F5F5 flex justify-between text-sm xl:text-xl">
                <p className="text-left font-inter font-light py-2 px-4 min-w-[120px] xl:min-w-[170px]">{t("Code")}</p>
                <p className="text-center py-2 px-4 min-w-[120px] xl:min-w-[146px]">{t("Orientation")}</p>
                <p className="text-center py-2 px-4 min-w-[170px] xl:min-w-[210px]">{t("Environment")}</p>
                <p className="text-center py-2 px-4 min-w-[85px] xl:min-w-[99px]">{t("Length")}</p>
                <p className="text-center py-2 px-4 min-w-[135px] xl:min-w-[158px]">{t("WidthHeight")}</p>
                <p className="text-center py-2 px-4 min-w-[92px] xl:min-w-[113px]">{t("Number")}</p>
                <p className="text-center py-2 px-4 min-w-[95px] xl:min-w-[115px]">{t("Areas")}</p>
                <p className="text-center py-2 px-4 min-w-[119px] xl:min-w-[142px]">{t("UsefulArea")}</p>
                <p className="text-center py-2 px-4 min-w-[128px] xl:min-w-[153px]">{t("Temperature")}</p>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("BuFull")} placement="top"><p className="text-center py-2 px-4 min-w-[70px] xl:min-w-[80px]">{t("Bu")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("UFull")} placement="top"><p className="text-center py-2 px-4 min-w-[70px] xl:min-w-[80px]">{t("U")}</p></Tooltip>
                <p className="text-center py-2 px-4 min-w-[146px] xl:min-w-[176px]">{t("ThermalBridge")}</p>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("CorrectedUFull")} placement="top"><p className="text-center py-2 px-4 min-w-[124px] xl:min-w-[148px]">{t("CorrectedU")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("HTCFull")} placement="top"><p className="text-center py-2 px-4 min-w-[70px] xl:min-w-[80px]">{t("HTC")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("FTFull")} placement="top"><p className="text-center py-2 px-4 min-w-[70px] xl:min-w-[80px]">{t("FT")}</p></Tooltip>
                <p className="text-center py-2 px-4 min-w-[146px] xl:min-w-[220px]">{t("AddOpening")}</p>
                <p className="text-center py-2 px-4 min-w-[75px] xl:min-w-[85px]"></p>
            </div>
            {buildingStructureSliceData.buildingStructureData.allWallsData.length > 0 &&
                buildingStructureSliceData.buildingStructureData.allWallsData.map((wall) => (
                    <WallInRoom spaceId={buildingStructureSliceData.buildingStructureData.roomData.spaceId} wall={wall} key={wall.wallId} />
                ))}
            <NewRow spaceId={buildingStructureSliceData.buildingStructureData.roomData.spaceId} />
            <div className="h-[44px] border-b border-b-#3B4250" />
            <div className="h-[44px] border-b border-b-#3B4250" />
            <div className="h-[44px] border-b border-b-#3B4250" />
            <NotificationPopup />
        </div>
    )
}

export default RoomDetailsTable;