import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import CustomDropdown from "../../../UIComponents/customDropdown";
import { floorLevelOptions } from "../../../app/optionModels";
import { handleSpaceInInput } from "../../../app/common";

interface FloorProps {
    floorName: string,
    selectFloorLevel: string,
    addFloorClickFunction: (floorName: string, floorLevel: string) => void,
    onCancelClickFunction: () => void,
    isEdit: boolean,
}

function FloorPopup(props: FloorProps) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const [floorName, setFloorName] = useState("");
    const [floorLevel, setFloorLevel] = useState("");
    const [floorNameErrorStatus, setFloorNameErrorStatus] = useState(false);
    const [floorLevelErrorStatus, setFloorLevelErrorStatus] = useState(false);

    function onAddClick() {
        if (floorName === "") { setFloorNameErrorStatus(true); } else { setFloorNameErrorStatus(false); }
        if (floorLevel === "") { setFloorLevelErrorStatus(true); } else { setFloorLevelErrorStatus(false); }

        if (floorName && floorLevel) {
            props.addFloorClickFunction(floorName, floorLevel);
            handleDialog();
        }
    }

    function onCancelClick(): void {
        props.onCancelClickFunction();
        handleDialog();
    }

    useEffect(() => {
        if (props.isEdit) {
            setFloorName(props.floorName);
            setFloorLevel(props.selectFloorLevel);
        }
    }, [])

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex flex-row justify-center bg-transparent shadow-none outline-none">
            <div className="flex flex-col justify-center space-y-3 bg-#343A47 w-[521px] h-[226px] md:w-[711px] md:h-[239px] rounded-[5px] text-[16px] font-normal font-inter text-#F5F5F5 px-6">
                <div className="flex justify-start items-center uppercase">
                    <p>{t("Floor")}</p>
                </div>
                <div className="flex items-center justify-between">
                    <p className="w-[150px]">{t("FloorLevel")}</p>
                    <CustomDropdown dropdownId="floorLevelDropdown" isEmpty={floorLevelErrorStatus} disabled={props.isEdit} previousSelectedOption={props.isEdit ? props.selectFloorLevel : ""} options={floorLevelOptions} placeholder={t("SelectFloorLevel")} height={"h-10"} width={"w-[324px] md:w-[508px]"} onSelectClick={setFloorLevel} />
                </div>
                <div className="flex items-center justify-between">
                    <p className="w-[150px]">{t("FloorName")}</p>
                    <input id="floorNameInput" type="text" autoComplete="off" placeholder={t("FloorName")} value={floorName} onChange={(e) => handleSpaceInInput(e, setFloorName)} className={`${floorNameErrorStatus ? "border-[1px] border-#EF5350" : ""} bg-#2E333E placeholder:text-#545B6A outline-none w-[324px] md:w-[508px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center space-x-[30px] my-[15px] md:my-[30px]">
                    <Button id="cancelBtn" onClick={() => onCancelClick()} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</Button>
                    <Button id="confirmationBtn" disabled={false} onClick={() => onAddClick()} className="bg-#FF9800 w-fit h-10 px-6 justify-center items-center inline-flex rounded-lg text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                        {props.isEdit ? t("SaveFloor") : t("AddFloor")}
                    </Button>
                </div>
            </div>
        </Dialog >
    );
}

export default FloorPopup;
