import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../../UIComponents/customDropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { buildingAirTightnessOptions, buildingCoverageOptions, buildingMassOptions, buildingTypeOptions, groundTypeOptions } from "../../../app/optionModels";

interface PropertyProps {
    isEdit: boolean,
    buildingTypeOnChange: (buildingType: string) => void,
    buildingMassOnChange: (buildingMass: string) => void,
    buildingAirTightnessOnChange: (buildingAirTightness: string) => void,
    groundTypeOnChange: (groundType: string) => void,
    buildingCoverageOnChange: (buildingCoverage: string) => void,
}

function Property(props: PropertyProps) {
    const { t } = useTranslation();
    const [buildingType, setBuildingType] = useState("");
    const [buildingMass, setBuildingMass] = useState("");
    const [buildingAirTightness, setBuildingAirTightness] = useState("");
    const [groundType, setGroundType] = useState("");
    const [buildingCoverage, setBuildingCoverage] = useState("");
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData.buildingData.buildingData);

    const buildingTypeOnChange = (type: string) => {
        setBuildingType(type);
        props.buildingTypeOnChange(type);
    }
    const buildingMassOnChange = (mass: string) => {
        setBuildingMass(mass);
        props.buildingMassOnChange(mass);
    }
    const buildingAirTightnessOnChange = (airTightness: string) => {
        setBuildingAirTightness(airTightness);
        props.buildingAirTightnessOnChange(airTightness);
    }
    const groundTypeOnChange = (groundType: string) => {
        setGroundType(groundType);
        props.groundTypeOnChange(groundType);
    }
    const buildingCoverageOnChange = (coverage: string) => {
        setBuildingCoverage(coverage);
        props.buildingCoverageOnChange(coverage);
    }

    useEffect(() => {
        if (props.isEdit) {
            buildingTypeOnChange(buildingSliceData.buildingType);
            buildingMassOnChange(buildingSliceData.buildingMass);
            buildingAirTightnessOnChange(buildingSliceData.airOpeningTightness);
            groundTypeOnChange(buildingSliceData.groundType);
            buildingCoverageOnChange(buildingSliceData.buildingCoverage);
        }
    }, [buildingSliceData])

    return (
        <div className="bg-transparent w-full h-[420px] flex items-start justify-start py-[30px] pl-[30px] pr-[80px]">
            <div className="space-y-[15px] text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] leading-6 w-full">
                <div className="flex items-center">
                    <p>{t("BuildingType") + ":"}</p>
                    <CustomDropdown dropdownId="buildingTypeDropdown" previousSelectedOption={buildingType} options={buildingTypeOptions} placeholder={t("SelectBuildingType")} height={"h-10"} width={"w-[284px] md:w-[606px] lg:w-[800px]"} onSelectClick={buildingTypeOnChange} />
                </div>
                <div className="flex items-center">
                    <p>{t("BuildingMass") + ":"}</p>
                    <CustomDropdown dropdownId="buildingMassDropdown" previousSelectedOption={buildingMass} options={buildingMassOptions} placeholder={t("SelectBuildingMass")} height={"h-10"} width={"w-[284px] md:w-[606px] lg:w-[800px]"} onSelectClick={buildingMassOnChange} />
                </div>
                <div className="flex items-center">
                    <p>{t("BuildingAirTightness") + ":"}</p>
                    <CustomDropdown dropdownId="buildingAirTightnessDropdown" previousSelectedOption={buildingAirTightness} options={buildingAirTightnessOptions} placeholder={t("SelectBuildingAirTightness")} height={"h-10"} width={"w-[284px] md:w-[606px] lg:w-[800px]"} onSelectClick={buildingAirTightnessOnChange} />
                </div>
                <div className="flex items-center">
                    <p>{t("GroundType") + ":"}</p>
                    <CustomDropdown dropdownId="groundTypeDropdown" previousSelectedOption={groundType} options={groundTypeOptions} placeholder={t("SelectGroundType")} height={"h-10"} width={"w-[284px] md:w-[606px] lg:w-[800px]"} onSelectClick={groundTypeOnChange} />
                </div>
                <div className="flex items-center">
                    <p>{t("BuildingCoverage") + ":"}</p>
                    <CustomDropdown dropdownId="buildingCoverageDropdown" previousSelectedOption={buildingCoverage} options={buildingCoverageOptions} placeholder={t("SelectBuildingCoverage")} height={"h-10"} width={"w-[284px] md:w-[606px] lg:w-[800px]"} onSelectClick={buildingCoverageOnChange} />
                </div>
            </div>
        </div>
    );
}

export default Property;
