import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { Checkbox, Dialog } from "@material-tailwind/react";
import { ExcelReportModel, ReportModel } from "../reportsModels";
import { GetExcelReport, GetPdfReport, resetReportsSlice } from "../reportsSlice";
import { LoadingPage } from "../../../UIComponents/loading";
import CustomDropdown from "../../../UIComponents/customDropdown";
import { handleSpaceInInput } from "../../../app/common";

interface SaveFileProps {
    onCancelClickFunction: () => void
}

const fileTypeOptions = [
    { key: ".pdf", value: "PDF" },
    { key: ".xlsx", value: "Excel" },
];

function SaveFile(props: SaveFileProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDeleteDialog = () => setOpenDialog(!openDialog);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const reportsSliceData = useSelector((state: RootState) => state.ReportsSlice.reportsSliceData);

    const [fileType, setFileType] = useState("");
    const fileTypeMIME = fileType === '.pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    const [fileName, setFileName] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [roomComposition, setRoomComposition] = useState(false);
    const [standardHeatingLoadOfTheBuilding, setStandardHeatingLoadOfTheBuilding] = useState(false);
    const [standardHeatingLoadOfTheRooms, setStandardHeatingLoadOfTheRooms] = useState(false);
    const [components, setComponents] = useState(false);
    const [buildingElements, setBuilidingElements] = useState(false);
    const [buildingCharacteristics, setBuildingCharacteristics] = useState(false);
    const [generalProjectData, setGeneralProjectData] = useState(false);
    const [fileNameErrorStatus, setFileNameErrorStatus] = useState(false);
    const [fileTypeErrorStatus, setFileTypeErrorStatus] = useState(false);

    useEffect(() => {
        if (selectAll) {
            setRoomComposition(true);
            setStandardHeatingLoadOfTheBuilding(true);
            setStandardHeatingLoadOfTheRooms(true);
            setComponents(true);
            setBuilidingElements(true);
            setBuildingCharacteristics(true);
            setGeneralProjectData(true);
        }
        else {
            setRoomComposition(false);
            setStandardHeatingLoadOfTheBuilding(false);
            setStandardHeatingLoadOfTheRooms(false);
            setComponents(false);
            setBuilidingElements(false);
            setBuildingCharacteristics(false);
            setGeneralProjectData(false);
        }
    }, [selectAll])

    function handleSaveClick(): void {
        if (fileName === "" || fileType === "") {
            if (fileName === "") { setFileNameErrorStatus(true); }
            if (fileType === "") { setFileTypeErrorStatus(true); }
            return;
        }
        if (fileType === fileTypeOptions[0].key) {
            handlePDFSave();
        }
        else if (fileType === fileTypeOptions[1].key) {
            handleExcelSave();
        }
    }

    const handlePDFSave = () => {
        if (fileName !== "") {
            const reportModel: ReportModel = {
                projectId: projectId,
                userId: localStorage.getItem("userId")!,
                name: fileName,
                generalProjectData: generalProjectData,
                components: components,
                buildingElements: buildingElements,
                buildingCharacteristics: buildingCharacteristics,
                standardHeatLoadForTheBuilding: standardHeatingLoadOfTheBuilding,
                standardHeatLoadForTheRooms: standardHeatingLoadOfTheRooms,
                roomComposition: roomComposition
            }
            dispatch(GetPdfReport(reportModel))
        }
    }

    const handleExcelSave = () => {
        if (fileName !== "" && fileType !== "") {
            const reportModel: ExcelReportModel = {
                projectId: projectId,
                userId: localStorage.getItem("userId")!,
                fileName: fileName,
                fileType: fileType
            }
            dispatch(GetExcelReport(reportModel))
        }
    }

    const downloadFile = () => {
        if (reportsSliceData.reportsData.file === null) return;
        const byteCharacters = atob(reportsSliceData.reportsData.file.document);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileTypeMIME });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        dispatch(resetReportsSlice());
    };

    useEffect(() => {
        if (reportsSliceData.statusValues.statusCode === "200") {
            downloadFile()
            props.onCancelClickFunction()
            handleDeleteDialog()
        }
    }, [reportsSliceData.statusValues])

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDeleteDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="justify-center bg-#3B4250 text-#F5F5F5 shadow-none p-[23px] outline-none">
            <div id="closeIcon" onClick={() => { props.onCancelClickFunction(); handleDeleteDialog() }} className="absolute right-0 top-[5px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="30" viewBox="0 0 44 30" fill="none" className="hover:cursor-pointer fill-#8D929B hover:fill-#272C35">
                    <path d="M27 11.007L25.993 10L22 13.993L18.007 10L17 11.007L20.993 15L17 18.993L18.007 20L22 16.007L25.993 20L27 18.993L23.007 15L27 11.007Z" fill="" />
                </svg>
            </div>
            <p className="uppercase">{t("SaveFile")}</p>
            <p className="mt-[23px] mb-[13px]">{t("FileName")}:</p>
            <input id="fileName" type="text" autoComplete="off" placeholder={t("EnterFileName")} value={fileName} onChange={(e) => { handleSpaceInInput(e, setFileName); setFileNameErrorStatus(false) }} className={`bg-#2E333E placeholder:text-#545B6A ${fileNameErrorStatus ? "border-[1px] border-#EF5350" : ""} w-full h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
            <p className="mt-[23px] mb-[13px]">{t("FileType") + ":"}</p>
            <CustomDropdown dropdownId="fileTypeDropdown" isEmpty={fileTypeErrorStatus} previousSelectedOption={fileType} options={fileTypeOptions} placeholder={t("SelectFileType")} height={"h-10"} width={"w-full"} onSelectClick={(e) => { setFileType(e); setFileTypeErrorStatus(false); }} />
            <div className="w-full mt-[36px] mb-[28px] h-px bg-#272C35" />
            <p className="mt-[23px] mb-[13px]">{t("ChooseInformationToDisplay")}:</p>
            <div className="grid grid-cols-2 gap-7">
                <div className="flex flex-col">
                    <Checkbox id="selectAll" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setSelectAll(e.target.checked)} checked={selectAll} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("SelectAll")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="roomComposition" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setRoomComposition(e.target.checked)} checked={roomComposition} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("RoomComposition")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="standardHeatingLoadOfTheBuilding" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setStandardHeatingLoadOfTheBuilding(e.target.checked)} checked={standardHeatingLoadOfTheBuilding} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("StandardHeatingLoadOfTheBuilding")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="standardHeatingLoadOfTheRooms" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setStandardHeatingLoadOfTheRooms(e.target.checked)} checked={standardHeatingLoadOfTheRooms} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("StandardHeatingLoadOfTheRooms")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                </div>
                <div className="flex flex-col">
                    <Checkbox id="components" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setComponents(e.target.checked)} checked={components} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("Components")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="buildingElements" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setBuilidingElements(e.target.checked)} checked={buildingElements} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("BuildingElements")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="buildingCharacteristics" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setBuildingCharacteristics(e.target.checked)} checked={buildingCharacteristics} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("BuildingCharacteristics")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="generalProjectData" crossOrigin={""} ripple={false} disabled={fileType !== '.pdf'} onChange={(e) => setGeneralProjectData(e.target.checked)} checked={generalProjectData} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"
                        label={<p className="flex items-center font-normal text-#F5F5F5" >{t("GeneralProjectData")}</p>}
                        containerProps={{ className: "-ml-2.5" }} />
                </div>
            </div>
            <div className="flex w-fit ml-auto gap-[25px]">
                <button id="saveFileFromBtn" onClick={handleSaveClick} className="w-fit h-10 px-6 mt-[19px] bg-#FF9800 text-#272C35 uppercase font-semibold text-sm rounded-lg">{t("SaveFile")}</button>
            </div>
            {reportsSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(reportsSliceData.statusValues.isLoading)}</div>}
        </Dialog >
    );
}

export default SaveFile;