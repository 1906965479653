import { Tooltip } from "@material-tailwind/react";
import { formatNumber } from "../../../app/common";
import { AreaRoomModel } from "../buildingStructureModels";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

interface RoomComponentProps {
    room: AreaRoomModel;
    isCalculated: boolean;
    onEditClick: (roomId: string) => void;
    onDeleteClick: (roomId: string) => void;
}

function RoomComponent(props: RoomComponentProps) {
    const [textColor, setTextColor] = useState("");
    const isCalculated = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData);

    useEffect(() => {
        if ((props.room.lossesBySquareMeter >= 60 && props.room.lossesBySquareMeter <= 20) || props.room.lossesBySquareMeter === 0) {
            setTextColor("text-#FFFFFF");
        }
        else if (props.room.lossesBySquareMeter > 60) {
            setTextColor("text-#EF5350");
        }
        else if (props.room.lossesBySquareMeter < 20) {
            setTextColor("text-cyan-400");
        }
    }, [isCalculated])

    return (
        <div id={props.room.spaceId} onClick={() => props.onEditClick(props.room.spaceId)} className="cursor-pointer">
            <div className={`bg-#343A47 h-[45px] w-full p-[20px] grid grid-cols-12 space-x-2 text-#F5F5F5 text-sm lg:text-base shadow-project place-content-center border-solid border-[1px] hover:shadow-projectOrange border-#343A47 hover:border-#FF980080`}>
                <p className="text-left my-auto">{props.room.spaceCode}</p>
                <p className="col-span-2 text-left my-auto truncate">{props.room.name}</p>
                <p className="text-right my-auto">{formatNumber(props.room.temperature, 0)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.area, 1)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.transmissionLoss, 1)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.ventilationLoss, 1)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.standardHeatLoss, 1)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.heatingInterruptionLoss, 1)}</p>
                <p className="text-right my-auto">{formatNumber(props.room.totalLoss, 1)}</p>
                {props.room.lossesBySquareMeter <= 20 || props.room.lossesBySquareMeter >= 60 ? <Tooltip key={"phiNettoNotification"} className="rounded-[8px] bg-#2E333E text-#FFFFFF font-[600] px-2 py-1 m-0" content={"Results outside the recommended range (20-60W/m2)."} >
                    <p className={`text-right my-auto ${isCalculated ? textColor : ''}`}>{formatNumber(props.room.lossesBySquareMeter, 1)}</p>
                </Tooltip> : <p className={`text-right my-auto ${isCalculated ? textColor : ''}`}>{formatNumber(props.room.lossesBySquareMeter, 1)}</p>}
                <button id="deleteRoomBtn" className="flex justify-center mx-auto">
                    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800" onClick={(e) => { e.stopPropagation(); props.onDeleteClick(props.room.spaceId); }}>
                        <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </button>
            </div>
            <div className="p-0 m-0 w-full h-[1px] bg-#414959"></div>
        </div>
    );
}

export default RoomComponent;