export enum MaterialType {
    PLASTERS = "PLASTERS",
    LARGEFORMATCOMPONENTS = "LARGEFORMATCOMPONENTS",
    CONSTRUCTION = "CONSTRUCTION",
    MASONRY = "MASONRY",
    INSULATION = "INSULATION",
    WOOD = "WOOD",
    COVERINGS = "COVERINGS",
    OTHER = "OTHER",
}

export enum ComponentType {
    OUTERWALL = "OUTERWALL",
    INNERWALL = "INNERWALL",
    FLOOR = "FLOOR",
    CEILING = "CEILING",
    ROOF = "ROOF",
}

export enum OpeningType {
    OUTERDOOR = "OUTERDOOR",
    INNERDOOR = "INNERDOOR",
    INNERWINDOW = "INNERWINDOW",
    OUTERWINDOW = "OUTERWINDOW",
    ROOFWINDOW = "ROOFWINDOW",
}

export enum BuildingType {
    FAMILYHOUSE = "FAMILYHOUSE",
    APARTMENTBUILDING = "APARTMENTBUILDING",
    NONLIVINGBUILDING = "NONLIVINGBUILDING",
};

export enum BuildingMass {
    LIGHT = "LIGHT",
    MEDIUMMASS = "MEDIUMMASS",
    HEAVY = "HEAVY",
};

export enum BuildingAirTightness {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW",
};

export enum GroundType {
    SAND_OR_GRAVEL = "SAND_OR_GRAVEL",
    HOMOGENOUS_ROCK = "HOMOGENOUS_ROCK",
    CLAY_OR_SILT = "CLAY_OR_SILT",
};

export enum BuildingCoverage {
    NONCOVERED = "NONCOVERED",
    MIDCOVERED = "MIDCOVERED",
    HIGHCOVERED = "HIGHCOVERED",
};

export enum BuildingMassValues {
    LIGHT = "15",
    MEDIUMMASS = "35",
    HEAVY = "50",
};

export enum ThermalBrigde {
    WITHOUT_MINIMIZING_HEAT_FLOW = "WITHOUT_MINIMIZING_HEAT_FLOW",
    WITH_MINIMIZING_HEAT_FLOW = "WITH_MINIMIZING_HEAT_FLOW",
}

export enum HeatingInterruptionType {
    ROOMBYROOM = "ROOMBYROOM",
    GLOBALLY = "GLOBALLY",
    MANUALLY = "MANUALLY",
}

export enum FloorLevel {
    GROUNDFLOOR = "GROUNDFLOOR",
    BASEMENT = "BASEMENT",
    UPPERFLOOR = "UPPERFLOOR"
}

export enum SpaceType {
    HEATED = "HEATED",
    UNHEATED = "UNHEATED",
}

export enum SpaceHeatingSurface {
    WARMFLOOR = "WARMFLOOR",
    WARMCEILING = "WARMCEILING",
    MEDIUMANDHIGHRADIATION = "MEDIUMANDHIGHRADIATION",
    NATURALAIRCONVECTION = "NATURALAIRCONVECTION",
    LEAKAGEFROMLOWALTITUDE = "LEAKAGEFROMLOWALTITUDE",
    DOWNWARDFROMAGREATHIGHT = "DOWNWARDFROMAGREATHIGHT",
    MEDIUMANDHIGHLEAKAGE = "MEDIUMANDHIGHLEAKAGE",
}

export enum Orientation {
    H = "H",
    N = "N",
    NE = "NE",
    E = "E",
    SE = "SE",
    S = "S",
    SW = "SW",
    W = "W",
    NW = "NW",
}

export enum EnvironmentType {
    HEATEDROOM = "HEATEDROOM",
    UNHEATEDROOM = "UNHEATEDROOM",
    GROUND = "GROUND",
    OUTSIDE = "OUTSIDE",
    NEIGHBOURSPACE = "NEIGHBOURSPACE",
    NEIGHBOURBUILDING = "NEIGHBOURBUILDING",
}

export enum UnheatedSpaceProperty {
    FROSTFREE_SPACE = "FROSTFREE_SPACE",
    SPACE_WITH_BUILDING_ENTRANCE_DOOR = "SPACE_WITH_BUILDING_ENTRANCE_DOOR",
    SPACE_WITHOUT_BUILDING_ENTRANCE_DOOR = "SPACE_WITHOUT_BUILDING_ENTRANCE_DOOR",
    THREE_EXTERNAL_WALLS = "THREE_EXTERNAL_WALLS",
    UP_TO_TWO_EXTERNAL_WALLS = "UP_TO_TWO_EXTERNAL_WALLS",
    WITHOUT_EXTERNAL_WALLS = "WITHOUT_EXTERNAL_WALLS",
    OPEN_ROOF_SPACE = "OPEN_ROOF_SPACE",
    CLOSED_WITH_INSULATION = "CLOSED_WITH_INSULATION",
    CLOSED_RI_LESS_THAN_FIVE = "CLOSED_RI_LESS_THAN_FIVE",
    CLOSED_RI_GREATER_OR_EQUAL_THAN_FIVE = "CLOSED_RI_GREATER_OR_EQUAL_THAN_FIVE",
}

export enum CorrectionFactorForTheGround {
    STANDARDCASE = "STANDARDCASE",
    COOLEDGROUND = "COOLEDGROUND",
    ABOVEADJACENTLEVEL = "ABOVEADJACENTLEVEL",

}