import { useState } from "react";
import DeleteCard from "../../../../UIComponents/deleteCard";
import { useTranslation } from "react-i18next";
import EditOpening from "../Pages/editOpening";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOpening } from "../componentsSlice";
import { OpeningModelForDelete } from "../componentsModels";
import { formatDateToDateTimeString, getEnumValueByKey } from "../../../../app/common";
import { OpeningType } from "../../../../app/enums";
import SaveTemplate from "../Pages/saveTemplate";

interface OpeningRowProps {
    openingId: string;
    name: string;
    openingCode: string;
    openingType: string;
    thermalTransmittance: number;
}

function OpeningRow(props: OpeningRowProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    function handleDeleteOpeningClick(): void {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisOpening"));
        setIsDelete(true);
    }

    function deleteSelected(): void {
        const currentDate = formatDateToDateTimeString(new Date());

        const componentForDelete: OpeningModelForDelete = {
            openingId: props.openingId,
            userId: localStorage.getItem("userId")!,
            projectId: projectId,
            dateDeleted: currentDate
        }
        dispatch(DeleteOpening(componentForDelete))
        setIsDelete(false)
    }

    return (
        <div className="text-#F5F5F5">
            <div className="grid grid-cols-4 md:grid-cols-12 text-base font-medium pl-[35px] pr-[15px] py-[15px] h-[62px] border-b border-b-#F5F5F5">
                <p className="my-auto md:col-span-1">{props.openingCode}</p>
                <p className="my-auto md:col-span-1 truncate">{props.name}</p>
                <p className="my-auto text-center md:text-left md:col-span-1">{t(getEnumValueByKey(OpeningType, props.openingType))}</p>
                <p className="my-auto text-center md:text-right md:col-span-1">{props.thermalTransmittance}</p>
                <div className="col-span-5" />
                <svg id="editOpening" onClick={() => setIsEdit(true)} className="hidden md:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14.3601 4.07866L15.2869 3.15178C16.8226 1.61607 19.3125 1.61607 20.8482 3.15178C22.3839 4.68748 22.3839 7.17735 20.8482 8.71306L19.9213 9.63993M14.3601 4.07866C14.3601 4.07866 14.4759 6.04828 16.2138 7.78618C17.9517 9.52407 19.9213 9.63993 19.9213 9.63993M14.3601 4.07866L5.83882 12.5999C5.26166 13.1771 4.97308 13.4656 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.32181 19.8021M19.9213 9.63993L11.4001 18.1612C10.8229 18.7383 10.5344 19.0269 10.2162 19.2751C9.84082 19.5679 9.43469 19.8189 9.00498 20.0237C8.6407 20.1973 8.25352 20.3263 7.47918 20.5844L4.19792 21.6782M4.19792 21.6782L3.39584 21.9456C3.01478 22.0726 2.59466 21.9734 2.31063 21.6894C2.0266 21.4053 1.92743 20.9852 2.05445 20.6042L2.32181 19.8021M4.19792 21.6782L2.32181 19.8021" />
                </svg>
                <svg id="saveToTemplateOpening" onClick={() => setSaveTemplate(true)} className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 16.0909V11.0975C21 6.80891 21 4.6646 19.682 3.3323C18.364 2 16.2426 2 12 2C7.75736 2 5.63604 2 4.31802 3.3323C3 4.6646 3 6.80891 3 11.0975V16.0909C3 19.1875 3 20.7358 3.73411 21.4123C4.08421 21.735 4.52615 21.9377 4.99692 21.9915C5.98402 22.1045 7.13673 21.0849 9.44216 19.0458C10.4612 18.1445 10.9708 17.6938 11.5603 17.5751C11.8506 17.5166 12.1494 17.5166 12.4397 17.5751C13.0292 17.6938 13.5388 18.1445 14.5578 19.0458C16.8633 21.0849 18.016 22.1045 19.0031 21.9915C19.4739 21.9377 19.9158 21.735 20.2659 21.4123C21 20.7358 21 19.1875 21 16.0909Z" strokeWidth="1.5" />
                    <path d="M15 6H9" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <svg id="deleteOpening" onClick={handleDeleteOpeningClick} className="hidden md:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            </div>
            {isDelete && <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} />}
            {isEdit && <EditOpening onCancelClickFunction={() => setIsEdit(false)} onContinueClickFunction={() => setIsEdit(false)} opening={{ openingId: props.openingId, openingCode: props.openingCode, openingType: props.openingType, openingName: props.name, thermalTransmittance: props.thermalTransmittance }} />
            }
            {saveTemplate && <SaveTemplate onCancelClickFunction={() => setSaveTemplate(false)} setSaveTemplate={() => setSaveTemplate(false)} isComponent={false} item={{ itemId: props.openingId, itemName: props.name }} />}
        </div>
    );
}

export default OpeningRow;