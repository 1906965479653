import { useTranslation } from "react-i18next";
import { ProjectModel } from "../../Project/projectModels";
import ProjectsComponent from "../../Project/Pages/projectsComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { GetLastModifiedProjects, resetStatusValues } from "../../Project/projectSlice";
import { LoadingPage } from "../../../UIComponents/loading";
import { resetArea, resetFloor } from "../../BuildingStructure/buildingStructureSlice";
import { GetCompany } from "../../Settings/settingsSlice";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const { t } = useTranslation();
    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [projectsFiltered, setProjectsFiltered] = useState<ProjectModel[]>([]);
    const [filteredList, setFilteredList] = useState<boolean>(false);
    const userId = localStorage.getItem("userId")!;
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const settingsSliceData = useSelector((state: RootState) => state.SettingsSlice.settingsSliceData);
    const emptyId = '00000000-0000-0000-0000-000000000000'

    useEffect(() => {
        dispatch(resetStatusValues());
        dispatch(resetArea());
        dispatch(resetFloor());
        dispatch(GetLastModifiedProjects(userId));
        dispatch(GetCompany({ userId: userId }))
    }, [])

    useEffect(() => {
        if (settingsSliceData.CompanyData.companyId === emptyId) {
            navigate("/CompanyInformation");
        }
    }, [settingsSliceData.CompanyData])

    useEffect(() => {
        setProjects(projectSliceData.projectData.lastOpenedProjectsData);
    }, [projectSliceData.projectData.lastOpenedProjectsData]);

    useEffect(() => {
        if (filteredList) {
            setProjectsFiltered(projectSliceData.projectData.allProjectsData);
        }
    }, [projectSliceData.projectData.allProjectsData])

    useEffect(() => {
        if (projectSliceData.statusValues.statusCode === "") {
            return
        }
        if (projectSliceData.statusValues.statusCode === "200" && projectSliceData.statusValues.showNotification) {
            dispatch(GetLastModifiedProjects(userId));
        }
    }, [projectSliceData.statusValues])

    return (
        <div className="w-screenWithoutSidebar">
            {projects.length > 0 ? <ProjectsComponent projects={filteredList ? projectsFiltered : projects} setFilteredList={setFilteredList} /> :
                <div className="w-[610px] sm:w-screenWithoutSidebar mt-[38px]">
                    <p className="text-center text-#F5F5F5 text-xl">{t("NoRecentProjects")}</p>
                </div>}
            {projectSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(projectSliceData.statusValues.isLoading)}</div>}
        </div >
    );
}

export default Dashboard;
