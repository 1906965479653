import { Checkbox, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleInputChange } from "../../../app/common";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { ThermalBrigde } from "../../../app/enums";

interface ThermalBridgeProps {
    isEdit: boolean,
    thermalBridgeValueOnChange: (thermalBridgeValue: string) => void,
}

function ThermalBridge(props: ThermalBridgeProps) {
    const { t } = useTranslation();
    const [thermalBridgeTo005, setThermalBridgeTo005] = useState(true);
    const [thermalBridgeTo001, setThermalBridgeTo001] = useState(false);
    const [thermalBridgeManually, setThermalBridgeManually] = useState(false);
    const [thermalBridgeError, setThermalBridgeError] = useState(false);
    const [thermalBridgeManuallyValue, setThermalBridgeManuallyValue] = useState("");
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData.buildingData.buildingData);

    const onThermalBridgeTo005Change = () => {
        setThermalBridgeTo001(false);
        setThermalBridgeTo005(true);
        setThermalBridgeManually(false);
        setThermalBridgeError(false);
        setThermalBridgeManuallyValue("");
        props.thermalBridgeValueOnChange(ThermalBrigde.WITH_MINIMIZING_HEAT_FLOW);
    }
    const onThermalBridgeTo001Change = () => {
        setThermalBridgeTo001(true);
        setThermalBridgeTo005(false);
        setThermalBridgeManually(false);
        setThermalBridgeError(false);
        setThermalBridgeManuallyValue("");
        props.thermalBridgeValueOnChange(ThermalBrigde.WITHOUT_MINIMIZING_HEAT_FLOW);
    }
    const onThermalBridgeToManuallyChange = () => {
        setThermalBridgeTo001(false);
        setThermalBridgeTo005(false);
        setThermalBridgeManually(true);
        props.thermalBridgeValueOnChange(thermalBridgeManuallyValue);
    }
    const thermalBridgeToManuallyValueOnChange = (value: string) => {
        let minValue = 0.01;
        let maxValue = 1;
        const trimmedValue = parseFloat(value).toString();
        if (parseFloat(trimmedValue) >= minValue && parseFloat(trimmedValue) <= maxValue) {
            setThermalBridgeError(false);
            props.thermalBridgeValueOnChange(trimmedValue);
            setThermalBridgeManuallyValue(trimmedValue);
        }
        else {
            setThermalBridgeError(true);
            props.thermalBridgeValueOnChange("");
        }
    }

    useEffect(() => {
        onThermalBridgeTo005Change();
        if (props.isEdit) {
            if (buildingSliceData.thermalBridge === ThermalBrigde.WITH_MINIMIZING_HEAT_FLOW) {
                onThermalBridgeTo005Change();
            }
            else if (buildingSliceData.thermalBridge === ThermalBrigde.WITHOUT_MINIMIZING_HEAT_FLOW) {
                onThermalBridgeTo001Change();
            }
            else {
                setThermalBridgeManuallyValue(buildingSliceData.thermalBridgeValue.toString());
                onThermalBridgeToManuallyChange();
                thermalBridgeToManuallyValueOnChange(buildingSliceData.thermalBridgeValue.toString());
            }
        }
    }, [buildingSliceData])

    return (
        <div className="bg-transparent w-full h-fit flex items-center justify-start py-[30px] pl-[30px] pr-[80px]">
            <div className="space-y-[30px] md:space-y-[15px] text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] leading-6 w-full">
                <p>{t("ThermalBridge") + ":"}</p>
                <Checkbox id="005Toggle" crossOrigin={""} ripple={false} onChange={onThermalBridgeTo005Change} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={thermalBridgeTo005}
                    label={<Typography variant="small" color="gray" className="w-[458px] md:w-[754px] h-[48px] flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal normal-case" >{t("ThermalBridge005")}</Typography>}
                    containerProps={{ className: "-ml-2.5" }} />
                <Checkbox id="001Toggle" crossOrigin={""} ripple={false} onChange={onThermalBridgeTo001Change} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={thermalBridgeTo001}
                    label={<Typography variant="small" color="gray" className="w-[458px] md:w-[754px] h-[48px] flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal normal-case" >{t("ThermalBridge001")}</Typography>}
                    containerProps={{ className: "-ml-2.5" }} />
                <Checkbox id="manuallyToggle" crossOrigin={""} ripple={false} onChange={onThermalBridgeToManuallyChange} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={thermalBridgeManually}
                    label={<input id="manuallyInput" autoComplete="off" type="text" disabled={!thermalBridgeManually} placeholder={"0.00 (0.01 > 1)"} value={thermalBridgeManuallyValue} onChange={(e) => handleInputChange(e, setThermalBridgeManuallyValue, 2)} onBlur={(e) => thermalBridgeToManuallyValueOnChange(e.target.value)} className={`${thermalBridgeError ? "border-[1px] border-#EF5350" : ""} bg-#2E333E text-#F5F5F5 placeholder:text-#545B6A outline-none w-[284px] md:w-[606px] lg:w-[800px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />}
                    containerProps={{ className: "-ml-2.5" }} />
            </div>
        </div>
    );
}

export default ThermalBridge;