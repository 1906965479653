import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MaterialData, MaterialModel, MaterialModelForCreate, MaterialModelForUpdate, MaterialSliceData } from "./materialModels";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, handleFulfilled, handlePending, handleRejected } from "../../../app/common";
import { apiAddress } from "../../../app/apiModel";

export const GetAllMaterials = createAsyncThunk<ValueResponse<MaterialModel[]>, void, { rejectValue: RejectedValue }>(
    'MaterialSlicer/GetAllMaterials',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("calculationToolsTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        const response = await fetch(apiAddress + 'GetAllMaterials', {
            method: "GET",
            headers: requestHeaders,
        })
        const dataResponse: ValueResponse<MaterialModel[]> = {
            value: (await response.json()) as MaterialModel[],
            status: response.status.toString(),
            message: response.statusText
        }
        if (response.status.toString() === "200") {
            return dataResponse;
        }
        else {
            return rejectWithValue(await HandleErrorsFromBack(response));
        }
    }
);

export const CreateMaterial = createAsyncThunk<ValueResponse<MaterialModel>, MaterialModelForCreate, { rejectValue: RejectedValue }>(
    'MaterialSlicer/CreateMaterial',
    async (material: MaterialModelForCreate, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("calculationToolsTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        requestHeaders.set("Content-Type", "application/json");
        const response = await fetch(apiAddress + 'CreateMaterial', {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(material),
        })
        const dataResponse: ValueResponse<MaterialModel> = {
            value: (await response.json()) as MaterialModel,
            status: response.status.toString(),
            message: response.statusText
        }
        if (response.status.toString() === "200") {
            dataResponse.message = "";
            return dataResponse;
        }
        else {
            return rejectWithValue(await HandleErrorsFromBack(response));
        }
    }
);

export const UpdateMaterial = createAsyncThunk<ValueResponse<MaterialModel>, MaterialModelForUpdate, { rejectValue: RejectedValue }>(
    'MaterialSlicer/UpdateMaterial',
    async (material: MaterialModelForUpdate, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("calculationToolsTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        requestHeaders.set('Content-Type', 'application/json');
        const response = await fetch(apiAddress + 'UpdateMaterial', {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(material)
        })
        const dataResponse: ValueResponse<MaterialModel> = {
            value: (await response.json()) as MaterialModel,
            status: response.status.toString(),
            message: response.statusText
        }
        if (response.status.toString() === "200") {
            dataResponse.message = "";
            return dataResponse;
        }
        else {
            return rejectWithValue(await HandleErrorsFromBack(response));
        }
    }
);

export const DeleteMaterial = createAsyncThunk<ValueResponse<string>, string, { rejectValue: RejectedValue }>(
    'MaterialSlicer/DeleteMaterial',
    async (materialId: string, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("calculationToolsTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        const response = await fetch(apiAddress + 'DeleteMaterial?materialId=' + materialId, {
            method: 'DELETE',
            headers: requestHeaders,
        })
        const dataResponse: ValueResponse<string> = {
            value: (await response.json()) as string,
            status: response.status.toString(),
            message: response.statusText
        }
        if (response.status.toString() === "200") {
            dataResponse.message = "";
            return dataResponse;
        }
        else {
            return rejectWithValue(await HandleErrorsFromBack(response));
        }
    }
);

const initialMaterialData: MaterialModel = {
    materialComponentId: '',
    materialId: '',
    name: '',
    density: 0,
    thermalConductivity: 0,
    specificHeatCapacity: 0,
    materialType: '',
    thickness: 0
}

const initialData: MaterialData = {
    materialData: initialMaterialData,
    allMaterialsData: []
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",

}

const initialMaterialSliceData: MaterialSliceData = {
    materialData: initialData,
    statusValues: initialStatusValues
}

export const MaterialSlice = createSlice({
    name: "MaterialSlicer",
    initialState: {
        materialSliceData: initialMaterialSliceData
    },
    reducers: {
        resetMaterialSlice: (state) => {
            state.materialSliceData.statusValues.statusCode = '';
            state.materialSliceData.statusValues.isLoading = false;
            state.materialSliceData.statusValues.message = '';
            state.materialSliceData.statusValues.showNotification = false;
            state.materialSliceData.materialData = initialData;
        },
        resetStatusCode: (state) => {
            state.materialSliceData.statusValues.statusCode = '';
            state.materialSliceData.statusValues.message = '';
            state.materialSliceData.statusValues.showNotification = false;
        },
    },
    extraReducers(builder) {
        //GetAllMaterials
        builder
            .addCase(GetAllMaterials.fulfilled, (state, action) => {
                state.materialSliceData.materialData.allMaterialsData = handleFulfilled(state.materialSliceData.statusValues, action.payload);
                state.materialSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllMaterials.rejected, (state, action) => {
                handleRejected(state.materialSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(GetAllMaterials.pending, (state) => {
                handlePending(state.materialSliceData.statusValues);
            });
        //CreateMaterial
        builder
            .addCase(CreateMaterial.fulfilled, (state, action) => {
                handleFulfilled(state.materialSliceData.statusValues, action.payload);
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateMaterial.rejected, (state, action) => {
                handleRejected(state.materialSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateMaterial.pending, (state) => {
                handlePending(state.materialSliceData.statusValues);
            });
        //UpdateMaterial
        builder
            .addCase(UpdateMaterial.fulfilled, (state, action) => {
                handleFulfilled(state.materialSliceData.statusValues, action.payload);
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateMaterial.rejected, (state, action) => {
                handleRejected(state.materialSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateMaterial.pending, (state) => {
                handlePending(state.materialSliceData.statusValues);
            })
        //DeleteMaterial
        builder
            .addCase(DeleteMaterial.fulfilled, (state, action) => {
                handleFulfilled(state.materialSliceData.statusValues, action.payload);
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteMaterial.rejected, (state, action) => {
                handleRejected(state.materialSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.materialSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteMaterial.pending, (state) => {
                handlePending(state.materialSliceData.statusValues);
            })
    }
})

export const { resetMaterialSlice, resetStatusCode } = MaterialSlice.actions;
export default MaterialSlice.reducer;