import { StatusValue } from "../../app/common";

export interface CompanyModel {
    name: string,
    street: string,
    country: string,
    city: string,
    postalCode: string,
    phoneNumber_1: string,
    phoneNumber_2: string,
    emailAddress_1: string,
    emailAddress_2: string,
    companyPicture: string | null,
}

export interface AddCompanyModel extends CompanyModel {
    userId: string,
}

export interface GetUpdateResultCompanyModel extends AddCompanyModel {
    companyId: string,
}

export interface CheckCompanyUserDataRequest {
    userId: string
}

export interface SettingsSliceData {
    CompanyData: GetUpdateResultCompanyModel,
    statusValues: StatusValue
}

export const initialCompanyModel: CompanyModel = {
    name: '',
    street: '',
    country: '',
    city: '',
    postalCode: '',
    phoneNumber_1: '',
    phoneNumber_2: '',
    emailAddress_1: '',
    emailAddress_2: '',
    companyPicture: null,
}

export const initialAddCompanyModel: AddCompanyModel = {...initialCompanyModel, userId: '' }

export const initialGetUpdateResultCompanyModel : GetUpdateResultCompanyModel = {...initialAddCompanyModel, companyId: ''}