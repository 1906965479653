import { Button, Typography, IconButton } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RoomsComponent from "./roomsComponent";
import { AreaRoomModel, FloorModel, AreaModel, FloorModelForDelete, AreaModelForDelete, RoomModelForDelete, AreaModelForUpdate, AreaModelForCreate, FloorModelForUpdate, FloorModelForCreate } from "../buildingStructureModels";
import BuildingInfoPopup from "./buildingInfoPopup";
import DeleteCard from "../../../UIComponents/deleteCard";
import FloorPopup from "./floorPopup";
import AreaPopup from "./areaPopup";
import { BuildingStructure } from "./buildingStructureTree";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { GetBuilding } from "../../Building/buildingSlice";
import { CreateArea, CreateFloor, DeleteArea, DeleteFloor, DeleteRoom, GetAllFloors, GetRoom, UpdateArea, UpdateFloor, initialAllAreaRoomsData, initialAllAreasData, initialAllFloorsData, initialAreaData, initialFloorData, resetIsEditRoom, resetRoom, resetStatusValues, resetWalls, setArea, setFloor } from "../buildingStructureSlice";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import { LoadingPage } from "../../../UIComponents/loading";
import { resetStatusCode } from "../../Dashboard/reportsSlice";
import { resetCalculationsStatusCode } from "../../Dashboard/calculationsSlice";
import { formatDateToDateTimeString } from "../../../app/common";
import { GetProject } from "../../Project/projectSlice";

function BuildingStructurePage() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData);
    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);
    const reportsSliceData = useSelector((state: RootState) => state.ReportsSlice.reportsSliceData);
    const calculationsSliceData = useSelector((state: RootState) => state.CalculationsSlice.calculationsSliceData);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);
    const [buildingInfoOpen, setBuildingInfoOpen] = useState(false);
    const openBuildingInfo = (isActive: boolean) => setBuildingInfoOpen(isActive);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isEditRoom, setIsEditRoom] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [selectedRoom, setSelectedRoom] = useState("");
    const [currentFloorId, setCurrentFloorId] = useState("");
    const [isEditFloor, setIsEditFloor] = useState(false);
    const [isEditArea, setIsEditArea] = useState(false);
    const [isAddFloor, setIsAddFloor] = useState(false);
    const [isAddArea, setIsAddArea] = useState(false);
    const [openFloors, setOpenFloors] = useState<string[]>([]);
    const [areaModelToDelete, setAreaModelToDelete] = useState<AreaModelForDelete>();
    const [areaRoomToDelete, setAreaRoomToDelete] = useState<RoomModelForDelete>();
    const [selectedFloor, setSelectedFloor] = useState<FloorModel>(initialFloorData);
    const [selectedArea, setSelectedArea] = useState<AreaModel>(initialAreaData);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [floorModelToDelete, setFloorModelToDelete] = useState<FloorModelForDelete>();
    const currentDate = formatDateToDateTimeString(new Date());

    const [floors, setFloors] = useState<FloorModel[]>(initialAllFloorsData);
    const [areas, setAreas] = useState<AreaModel[]>(initialAllAreasData);
    const [rooms, setRooms] = useState<AreaRoomModel[]>(initialAllAreaRoomsData);
    const [roomsToShow, setRoomstoShow] = useState<AreaRoomModel[]>(initialAllAreaRoomsData);

    const handleOpen = (value: any) => {
        if (openFloors.includes(value)) {
            setOpenFloors(openFloors.filter((id) => id !== value));
        } else {
            setOpenFloors([...openFloors, value]);
        }
    };

    const closeFloorAndAreaPopup = () => {
        setIdToDelete("");
        setIsEditFloor(false);
        setIsAddFloor(false);
        setIsEditArea(false);
        setIsAddArea(false);
    }

    const onEditRoomClick = (roomId: string) => {
        if (rooms.find(room => room.spaceId === roomId)) {
            setSelectedRoom(roomId);
            setIsEditRoom(true);
            dispatch(GetRoom(roomId));
            navigate("/roomDetails");
        }
    }

    const onDeleteRoomClick = (roomId: string) => {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisRoom"));
        setSelectedRoom(roomId);
        setIdToDelete(roomId);
        setIsDelete(true);
    }

    const deleteSelected = () => {
        if (idToDelete !== "") {
            let floorToDelete = floors.find(f => f.floorId === idToDelete);
            let areaToDelete = areas.find(a => a.zoneId === idToDelete);
            let roomToDelete = rooms.find(r => r.spaceId === idToDelete);
            if (floorToDelete) {
                let floorModelForDelete: FloorModelForDelete = {
                    projectId: projectSliceData.projectData.projectData.projectId,
                    floorId: floorToDelete.floorId,
                    userId: localStorage.getItem("userId")!,
                    dateDeleted: currentDate
                }
                setFloorModelToDelete(floorModelForDelete);
            }
            else if (areaToDelete) {
                let areaModelForDelete: AreaModelForDelete = {
                    zoneId: areaToDelete.zoneId,
                    userId: localStorage.getItem("userId")!,
                    projectId: projectSliceData.projectData.projectData.projectId,
                    dateDeleted: currentDate
                }
                setAreaModelToDelete(areaModelForDelete);

            } else if (roomToDelete) {
                let roomModelForDelete: RoomModelForDelete = {
                    spaceId: roomToDelete.spaceId,
                    userId: localStorage.getItem("userId")!,
                    projectId: projectSliceData.projectData.projectData.projectId,
                    dateDeleted: currentDate
                }
                setAreaRoomToDelete(roomModelForDelete);
            }
            setIsDelete(false);
            setSelectedRoom("");
            if (selectedArea.zoneId === areaToDelete?.zoneId) {
                let area: AreaModel = { floorId: "", zoneId: "", zoneName: "", zoneNumber: "", spaces: [] }
                setSelectedArea(area);
            }
            if (selectedFloor.floorId === floorToDelete?.floorId) {
                let floor: FloorModel = initialFloorData
                setSelectedFloor(floor);
            }
        }
    }

    const setCurrentFloorToAddArea = (isAdd: boolean, floorId: string) => {
        setIsAddArea(isAdd);
        setCurrentFloorId(floorId);
    }

    const editAreaOrFloor = () => {
        if (selectedFloor?.floorId !== "") {
            onFloorSelect(selectedFloor.floorId);
            setIsEditFloor(true);
            setCurrentFloorId(selectedFloor.floorId);
        }
        else if (selectedArea?.zoneId !== "") {
            onAreaSelect(selectedArea.zoneId);
            setIsEditArea(true);
            setCurrentFloorId(selectedArea.zoneId);
        }

    }

    const addRoom = () => {
        dispatch(setArea(selectedArea));
        navigate("/roomDetails")
    }

    const addFloor = (floorName: string, floorLevel: string) => {
        if (isEditFloor) {
            let floorToEdit = floors.find(f => f.floorId === selectedFloor.floorId);
            const FloorModelForEdit: FloorModelForUpdate = {
                floorId: selectedFloor.floorId,
                floorName: floorName,
                floorLevel: floorLevel,
                projectId: projectSliceData.projectData.projectData.projectId,
                dateCreatedOrModified: currentDate,
                userId: localStorage.getItem("userId")!
            }

            if (floorToEdit) {
                dispatch(UpdateFloor(FloorModelForEdit));
            }
            setIsEditFloor(false);
        }
        else {
            let floorToCreate: FloorModelForCreate = {
                projectId: projectSliceData.projectData.projectData.projectId,
                floorName: floorName,
                floorLevel: floorLevel,
                userId: localStorage.getItem("userId")!,
                dateCreatedOrModified: currentDate
            }
            dispatch(CreateFloor(floorToCreate));
            setIsAddFloor(false);
        }
    }

    const addArea = (areaCode: string, areaName: string) => {
        if (isEditArea) {
            let areaToEdit = areas.find(a => a.zoneId === selectedArea.zoneId);
            let areaModelForUpdate: AreaModelForUpdate = {
                zoneId: selectedArea.zoneId,
                floorId: selectedArea.floorId,
                zoneNumber: parseInt(areaCode),
                zoneName: areaName,
                userId: localStorage.getItem("userId")!,
                projectId: projectSliceData.projectData.projectData.projectId,
                dateCreatedOrModified: currentDate
            }

            if (areaToEdit) {
                dispatch(UpdateArea(areaModelForUpdate))
            }
            setIsEditArea(false);
        }
        else {
            let areaModelForCreate: AreaModelForCreate = {
                floorId: currentFloorId,
                zoneNumber: parseInt(areaCode),
                zoneName: areaName, userId: localStorage.getItem("userId")!,
                projectId: projectSliceData.projectData.projectData.projectId,
                dateCreatedOrModified: currentDate
            }
            dispatch(CreateArea(areaModelForCreate))
            setIsAddArea(false);
        }
    }

    const removeFloor = (floorId: string) => {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisFloor"));
        setIsDelete(true);
        setIdToDelete(floorId);
    }

    const removeArea = (areaId: string) => {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisArea"));
        setIsDelete(true);
        setIdToDelete(areaId);
    }

    const onFloorSelect = (floorId: string) => {
        let floor = floors.find(floor => floor.floorId === floorId);
        if (floor !== undefined) {
            setSelectedFloor(floor);
            dispatch(setFloor(floor));
            let area: AreaModel = { floorId: "", zoneId: "", zoneName: "", zoneNumber: "", spaces: [] }
            setSelectedArea(area);
            dispatch(setArea(area));
            let roomsToAdd: AreaRoomModel[] = []
            rooms.forEach((room) => {
                if (room.floorId === floorId) {
                    roomsToAdd.push(room);
                }
            })
            setRoomstoShow(roomsToAdd);
        }
    }

    const onAreaSelect = (areaId: string) => {
        let area = areas.find(area => area.zoneId === areaId);
        dispatch(resetIsEditRoom());
        if (area !== undefined) {
            setSelectedArea(area);
            dispatch(setArea(area));
            let floor: FloorModel = {
                floorId: "",
                floorName: "",
                floorLevel: "",
                level: 1,
                height: 1,
                heightPosition: 1,
                zones: [],
                spaces: [],
            }
            setSelectedFloor(floor);
            dispatch(setFloor(floor));
            let roomsToAdd: AreaRoomModel[] = []
            rooms.forEach((room) => {
                if (room.zoneId === areaId) {
                    roomsToAdd.push(room);
                }
            })
            setRoomstoShow(roomsToAdd);
        }
    }

    function BuildingInfo() {
        return (
            <div className="fixed ml-[160px] lg:ml-[150px] mt-[50px] lg:mt-[45px] z-[100]">
                <BuildingInfoPopup
                    length={buildingSliceData.buildingData.buildingData.length.toString()}
                    width={buildingSliceData.buildingData.buildingData.width.toString()}
                    buildingArea={buildingSliceData.buildingData.buildingData.buildingArea.toString()}
                    parameterB={buildingSliceData.buildingData.buildingData.parameterB.toString()}
                />
            </div>
        );
    }

    useEffect(() => {
        dispatch(resetRoom());
        dispatch(resetWalls());
        if (projectSliceData.projectData.projectData.hasBuilding) {
            dispatch(GetBuilding({
                projectId: projectSliceData.projectData.projectData.projectId,
                userId: localStorage.getItem("userId")!
            }))
        }
    }, [])

    useEffect(() => {
        if (projectSliceData.projectData.projectData.hasBuilding) {
            dispatch(GetBuilding({
                projectId: projectSliceData.projectData.projectData.projectId,
                userId: localStorage.getItem("userId")!
            }))
        }
    }, [projectSliceData.projectData.projectData.projectId])

    useEffect(() => {
        if (buildingSliceData.buildingData.buildingData.buildingId !== "") {
            dispatch(GetAllFloors(buildingSliceData.buildingData.buildingData.buildingId))
        }
        if (currentFloorId !== buildingStructureSliceData.buildingStructureData.areaData.floorId) {
            handleOpen(buildingStructureSliceData.buildingStructureData.areaData.floorId);
        }
    }, [buildingSliceData.buildingData.buildingData.buildingId, buildingStructureSliceData.buildingStructureData.areaRoomData, calculationsSliceData.statusValues])

    useEffect(() => {
        if (buildingStructureSliceData.buildingStructureData.allFloorsData) {
            setFloors([...buildingStructureSliceData.buildingStructureData.allFloorsData]);
            let areasToAdd: AreaModel[] = []
            let roomsToAdd: AreaRoomModel[] = []
            buildingStructureSliceData.buildingStructureData.allFloorsData.forEach((floor) => {
                floor.zones.forEach((zone) => {
                    const area: AreaModel = {
                        floorId: floor.floorId,
                        zoneId: zone.zoneId,
                        zoneNumber: zone.zoneNumber,
                        zoneName: zone.zoneName,
                        spaces: zone.spaces,
                    };
                    areasToAdd.push(area);
                });
                floor.spaces.forEach((space) => {
                    const room: AreaRoomModel = {
                        spaceId: space.spaceId,
                        zoneId: space.zoneId,
                        floorId: space.floorId,
                        spaceCode: space.spaceCode,
                        name: space.name,
                        temperature: space.temperature,
                        area: space.area,
                        transmissionLoss: space.transmissionLoss,
                        ventilationLoss: space.ventilationLoss,
                        standardHeatLoss: space.standardHeatLoss,
                        heatingInterruptionLoss: space.heatingInterruptionLoss,
                        totalLoss: space.totalLoss,
                        lossesBySquareMeter: space.lossesBySquareMeter,
                    };
                    roomsToAdd.push(room);
                });
            });
            setAreas(areasToAdd);
            setRooms(roomsToAdd);
        }
    }, [buildingStructureSliceData.buildingStructureData.allFloorsData])

    useEffect(() => {
        setSelectedFloor(buildingStructureSliceData.buildingStructureData.floorData);
        setSelectedArea(buildingStructureSliceData.buildingStructureData.areaData);
        if (currentFloorId === '') {
            handleOpen(buildingStructureSliceData.buildingStructureData.areaData.floorId);
            setCurrentFloorId(buildingStructureSliceData.buildingStructureData.areaData.floorId);
        }

        if (selectedFloor.floorId !== '') { onFloorSelect(selectedFloor.floorId); }
        else if (selectedArea.zoneId !== '') { onAreaSelect(selectedArea.zoneId); }
    }, [floors, areas])

    useEffect(() => {
        dispatch(resetStatusValues());
    }, [buildingStructureSliceData.buildingStructureData.floorData, buildingStructureSliceData.buildingStructureData.areaData])

    useEffect(() => {
        const roomToDelete = roomsToShow.find(x => x.spaceId === idToDelete)
        let indexToDelete;
        if (roomToDelete) {
            indexToDelete = roomsToShow.indexOf(roomToDelete)
            roomsToShow.splice(indexToDelete, 1)
        }
    }, [buildingStructureSliceData.buildingStructureData.areaRoomData])

    useEffect(() => {
        if (floorModelToDelete) {
            dispatch(DeleteFloor(floorModelToDelete));
        }
    }, [floorModelToDelete])

    useEffect(() => {
        if (areaModelToDelete) {
            dispatch(DeleteArea(areaModelToDelete));
        }
    }, [areaModelToDelete])

    useEffect(() => {
        if (areaRoomToDelete) {
            dispatch(DeleteRoom(areaRoomToDelete));
        }
    }, [areaRoomToDelete])

    useEffect(() => {
        if (buildingStructureSliceData.statusValues.statusCode === "") {
            return
        }
        if (buildingStructureSliceData.statusValues.statusCode === "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message));
            dispatch(GetAllFloors(buildingSliceData.buildingData.buildingData.buildingId));
            dispatch(resetStatusValues());
        }
        else if (buildingStructureSliceData.statusValues.statusCode !== "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message), true);
            dispatch(resetStatusValues());
        }
    }, [buildingStructureSliceData.statusValues])

    useEffect(() => {
        if (reportsSliceData.statusValues.statusCode === "") {
            return
        }
        if (reportsSliceData.statusValues.statusCode === "200" && reportsSliceData.statusValues.showNotification) {
            notify(t(reportsSliceData.statusValues.message));
            dispatch(resetStatusCode());
        }
        else if (reportsSliceData.statusValues.statusCode !== "200" && reportsSliceData.statusValues.showNotification) {
            notify(t(reportsSliceData.statusValues.message), true);
            dispatch(resetStatusCode());
        }
    }, [reportsSliceData.statusValues])

    useEffect(() => {
        if (calculationsSliceData.statusValues.statusCode === "") {
            return
        }
        if (calculationsSliceData.statusValues.statusCode === "200" && calculationsSliceData.statusValues.showNotification) {
            notify(t(calculationsSliceData.statusValues.message));
            dispatch(GetProject({ projectId: projectSliceData.projectData.projectData.projectId, userId: localStorage.getItem('userId')! }))
            dispatch(resetCalculationsStatusCode());
        }
        else if (calculationsSliceData.statusValues.statusCode !== "200" && calculationsSliceData.statusValues.showNotification) {
            notify(t(calculationsSliceData.statusValues.message), true);
            dispatch(resetCalculationsStatusCode());
        }
    }, [calculationsSliceData.statusValues])

    return (
        <div className="flex w-[calc(100vw-67px)] h-screenWithoutHeader">
            <div className={`${open ? "" : ""} bg-#343A47 min-w-[50px] max-w-[270px] w-[50px] lg:w-[270px] ml-[30px] my-[30px] rounded-[5px] font-inter text-#F5F5F5 tracking-wide lg:overflow-y-scroll`}>
                <div className="grid grid-cols-1 h-full place-content-start place-items-center p-4 lg:flex lg:justify-start lg:items-start lg:px-6">
                    <div className="block lg:hidden pb-2">
                        <IconButton id="openDrawerBtn" variant="text" size="sm" ripple={true} onClick={openDrawer} className="w-[7px] h-[16px] shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent">
                            <svg width="7" height="16" viewBox="0 0 7 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5">
                                <path d="M1 15L6 8L1 1" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </IconButton>
                    </div>
                    <div className="flex items-center justify-center w-fit h-fit pt-14 lg:pt-4">
                        <Typography className="h-fit w-[200px] text-#F5F5F5 uppercase text-sm font-normal leading-4 -rotate-90 lg:hidden pointer-events-none">{t("BuildingStructure")}</Typography>
                    </div>
                    <div className="hidden lg:flex lg:flex-col items-start">
                        <div className="flex flex-row items-center justify-center space-x-2">
                            <Typography className="h-fit w-fit text-#F5F5F5 uppercase text-sm font-normal leading-4 hidden lg:block pointer-events-none">{t("Building")}</Typography>
                            <IconButton id="buildingInfoBtn" variant="text" size="sm" ripple={true} className="w-[15px] h-[15px] shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent" onPointerLeave={() => openBuildingInfo(false)} onPointerEnter={() => openBuildingInfo(true)}>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                    <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.5 10.1023V7.50234M7.5 4.90234H7.5065" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                            </IconButton>
                        </div>
                        <div className="flex flex-row items-center justify-center pb-4">
                            <BuildingStructure floors={floors} openFloors={openFloors} areas={areas} selectedFloor={selectedFloor} handleOpen={handleOpen} onFloorSelect={onFloorSelect} removeFloor={removeFloor} selectedArea={selectedArea} onAreaSelect={onAreaSelect} removeArea={removeArea} setIsAddArea={setCurrentFloorToAddArea} setIsAddFloor={setIsAddFloor} />
                        </div>
                    </div>
                </div>
            </div>
            {buildingInfoOpen ? <BuildingInfo /> : <></>}
            <div className={`fixed top-[68px] bottom-0 ${open ? "z-[99] lg:hidden" : "hidden"} flex bg-transparent backdrop-blur w-full ml-[30px] my-[30px] rounded-[5px] font-inter text-#F5F5F5 tracking-wide`}>
                <div className="bg-#343A47 rounded-[5px] h-full w-[240px] p-5 flex flex-col justify-start items-start px-5 overflow-y-scroll">
                    <div className={`flex items-center justify-center space-x-2`}>
                        <IconButton id="closeDrawerBtn" variant="text" size="sm" ripple={true} onClick={closeDrawer} className="w-[7px] h-[16px] shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent">
                            <svg width="7" height="16" viewBox="0 0 7 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=" stroke-#F5F5F5">
                                <path d="M6 15L1 8L6 1" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </IconButton>
                        <Typography className="uppercase text-sm font-normal leading-4">{t("Building")}</Typography>
                        <IconButton id="buildingInfoBtn" variant="text" size="sm" ripple={true} className="w-[15px] h-[15px] shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent" onPointerLeave={() => openBuildingInfo(false)} onPointerEnter={() => openBuildingInfo(true)}>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.5 10.1023V7.50234M7.5 4.90234H7.5065" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            </svg>
                        </IconButton>
                    </div>
                    <BuildingStructure floors={floors} openFloors={openFloors} areas={areas} selectedFloor={selectedFloor} handleOpen={handleOpen} onFloorSelect={onFloorSelect} removeFloor={removeFloor} selectedArea={selectedArea} onAreaSelect={onAreaSelect} removeArea={removeArea} setIsAddArea={setCurrentFloorToAddArea} setIsAddFloor={setIsAddFloor} />
                </div>
                <div className="w-full h-full" onClick={() => setOpen(false)}></div>
            </div>
            <div className={`bg-#343A47 min-w-[1000px] max-w-full md:w-full mx-[30px] my-[30px] rounded-[5px] font-inter text-#F5F5F5 tracking-wide overflow-scroll`}>
                {
                    (selectedArea.zoneName || selectedFloor.floorName) ?
                        <>
                            <div className={`h-[70px] w-full flex items-center justify-start`}>
                                <Typography className="p-4 uppercase text-sm font-normal leading-4">{selectedArea.zoneName || selectedFloor.floorName}</Typography>
                                <IconButton id="editBtn" variant="text" size="sm" ripple={true} className="w-[16px] h-[16px] shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent" onClick={editAreaOrFloor}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                        <path d="M9.57403 2.71878L10.1919 2.10086C11.2157 1.07706 12.8757 1.07706 13.8995 2.10086C14.9233 3.12466 14.9233 4.78458 13.8995 5.80838L13.2815 6.4263M9.57403 2.71878C9.57403 2.71878 9.65127 4.03186 10.8099 5.19046C11.9685 6.34906 13.2815 6.4263 13.2815 6.4263M9.57403 2.71878L3.8932 8.39961C3.50843 8.78438 3.31604 8.97677 3.15058 9.1889C2.95541 9.43913 2.78807 9.70988 2.65155 9.99636C2.53581 10.2392 2.44977 10.4973 2.27769 11.0136L1.54852 13.2011M13.2815 6.4263L7.60072 12.1071C7.21594 12.4919 7.02356 12.6843 6.81143 12.8497C6.5612 13.0449 6.29045 13.2123 6.00397 13.3488C5.76111 13.4645 5.503 13.5506 4.98677 13.7226L2.79926 14.4518M2.79926 14.4518L2.26455 14.63C2.0105 14.7147 1.73042 14.6486 1.54107 14.4593C1.35172 14.2699 1.2856 13.9898 1.37028 13.7358L1.54852 13.2011M2.79926 14.4518L1.54852 13.2011" />
                                    </svg>
                                </IconButton>
                            </div>
                            <RoomsComponent rooms={roomsToShow} isCalculated={projectSliceData.projectData.projectData.isCalculated} editRoom={onEditRoomClick} deleteRoom={onDeleteRoomClick} />
                        </>
                        :
                        <div className="mt-[38px]">
                            <p className="text-center text-#F5F5F5 text-xl">{t("EmptyRoomList")}</p>
                        </div>
                }
                {
                    selectedArea.zoneName ?
                        < div className="pl-5 pt-3">
                            <Button id="addRoomBtn" onClick={addRoom} size="sm" ripple={false} className={`px-3 m-0 flex flex-row border-transparent justify-start items-center space-x-2 w-fit h-[20px] shadow-none hover:shadow-none rounded-[10px] bg-#785C34 normal-case text-#F5F5F5`}>
                                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 5C0.223858 5 -9.78512e-09 4.77614 -2.18557e-08 4.5C-3.39262e-08 4.22386 0.223858 4 0.5 4L8.5 4C8.77614 4 9 4.22386 9 4.5C9 4.77614 8.77614 5 8.5 5L0.5 5Z" fill="#F5F5F5" />
                                    <path d="M4 0.5C4 0.223858 4.22386 0 4.5 0C4.77614 0 5 0.223858 5 0.5L5 8.5C5 8.77614 4.77614 9 4.5 9C4.22386 9 4 8.77614 4 8.5L4 0.5Z" fill="#F5F5F5" />
                                </svg>
                                <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.53201 5.26381C1.844 4.66804 1 4.37016 1 4C1 3.62984 1.844 3.33196 3.53201 2.73619L5.9192 1.89365C7.60721 1.29788 8.45121 1 9.5 1C10.5488 1 11.3928 1.29788 13.0808 1.89365L15.468 2.73619C17.156 3.33196 18 3.62984 18 4C18 4.37016 17.156 4.66804 15.468 5.26381L13.0808 6.10635C11.3928 6.70212 10.5488 7 9.5 7C8.45121 7 7.60721 6.70212 5.9192 6.10635L3.53201 5.26381Z" stroke="#F5F5F5" />
                                </svg>
                                <Typography className="font-normal text-#F5F5F5">{t("Room")}</Typography>
                            </Button>
                        </div> : <></>
                }
            </div>
            {isEditFloor || isAddFloor ? <FloorPopup floorName={selectedFloor?.floorName} selectFloorLevel={selectedFloor?.floorLevel} addFloorClickFunction={addFloor} onCancelClickFunction={closeFloorAndAreaPopup} isEdit={isEditFloor} /> : <></>}
            {isEditArea || isAddArea ? <AreaPopup areaCode={selectedArea?.zoneNumber} areaName={selectedArea?.zoneName} addAreaClickFunction={addArea} onCancelClickFunction={closeFloorAndAreaPopup} isEdit={isEditArea} /> : <></>}
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} /> : <></>}
            <NotificationPopup />
            {buildingStructureSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(buildingStructureSliceData.statusValues.isLoading)}</div>}
        </div >
    );
}

export default BuildingStructurePage;