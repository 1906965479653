import { Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from '../elements/Dashboard/Pages/dashboard';
import LoginPage from "../elements/Auth/Pages/login";
import TroubleLoggingInPage from "../elements/Auth/Pages/troubleLoggingIn";
import CreateNewProjectPage from "../elements/Project/Pages/createNewProject";
import MaterialsPage from "../elements/Components/Material/Pages/materials";
import ProjectsPage from "../elements/Project/Pages/projects";
import CreateBuildingPage from "../elements/Building/Pages/building";
import CreateNewMaterialPage from "../elements/Components/Material/Pages/createNewMaterial";
import ComponentsPage from "../elements/Components/Component/Pages/components";
import BuildingStructurePage from '../elements/BuildingStructure/Pages/buildingStructure';
import RoomDetails from '../elements/BuildingStructure/Pages/roomDetails';
import { CompanyInformationPage } from '../elements/Settings/Pages/companyInformation';

export function AppRoutes() {
    return (
        <div className='ml-[64px]'>
            <Routes>
                <Route path="/" element={<Navigate replace to="/dashboard" />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/projects' element={<ProjectsPage />} />
                <Route path='/createNewProject' element={<CreateNewProjectPage />} />
                <Route path='/materials' element={<MaterialsPage />} />
                <Route path='/createNewMaterial' element={<CreateNewMaterialPage />} />
                <Route path='/createBuilding' element={<CreateBuildingPage />} />
                <Route path='/CreateComponents' element={<ComponentsPage />} />
                <Route path='/companyInformation' element={<CompanyInformationPage />} />
                <Route path='/buildingStructure' element={<BuildingStructurePage />} />
                <Route path='/roomDetails' element={<RoomDetails />} />
            </Routes>
        </div>
    );
}

export function LoggedOutRoutes() {
    return (
        <div className='ml-[64px]'>
            <Routes>
                <Route path='/*' element={<LoginPage />} />
                <Route path='/troubleLoggingIn' element={<TroubleLoggingInPage />} />
            </Routes>
        </div>
    );
}