import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { GetUpdateResultCompanyModel, CheckCompanyUserDataRequest, SettingsSliceData, AddCompanyModel } from "./settingsModels";
import { apiAddress } from "../../app/apiModel";

export const GetCompany = createAsyncThunk<ValueResponse<GetUpdateResultCompanyModel>, CheckCompanyUserDataRequest, { rejectValue: RejectedValue }>(
    'SettingsSlicer/GetCompany',
    async (checkCompanyUserDataRequest: CheckCompanyUserDataRequest, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetCompany?userId=' + checkCompanyUserDataRequest.userId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<GetUpdateResultCompanyModel> = {
                value: (await response.json()) as GetUpdateResultCompanyModel,
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const AddCompany = createAsyncThunk<ValueResponse<GetUpdateResultCompanyModel>, AddCompanyModel, { rejectValue: RejectedValue }>(
    'SettingsSlicer/AddCompany',
    async (addCompanyModel: AddCompanyModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'AddCompany', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(addCompanyModel)
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<GetUpdateResultCompanyModel> = {
                value: (await response.json()) as GetUpdateResultCompanyModel,
                status: response.status.toString(),
                message: 'Company Information is added successufully'
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateCompany = createAsyncThunk<ValueResponse<GetUpdateResultCompanyModel>, GetUpdateResultCompanyModel, { rejectValue: RejectedValue }>(
    'SettingsSlicer/UpdateCompany',
    async (getUpdateCompanyModel: GetUpdateResultCompanyModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateCompany', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(getUpdateCompanyModel)
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<GetUpdateResultCompanyModel> = {
                value: (await response.json()) as GetUpdateResultCompanyModel,
                status: response.status.toString(),
                message: 'Company Information is updated successufully'
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

export const initialGetUpdateResultCompanyModel: GetUpdateResultCompanyModel = {
    companyId: '',
    userId: '',
    name: '',
    street: '',
    country: '',
    city: '',
    postalCode: '',
    phoneNumber_1: '',
    phoneNumber_2: '',
    emailAddress_1: '',
    emailAddress_2: '',
    companyPicture: null,
}

export const initialAddCompanyModelData: AddCompanyModel = {
    userId: '',
    name: '',
    street: '',
    country: '',
    city: '',
    postalCode: '',
    phoneNumber_1: '',
    phoneNumber_2: '',
    emailAddress_1: '',
    emailAddress_2: '',
    companyPicture: null,
}

const initialSettingsSliceData: SettingsSliceData = {
    CompanyData: initialGetUpdateResultCompanyModel,
    statusValues: initialStatusValues
}

export const SettingsSlice = createSlice({
    name: "SettingsSlicer",
    initialState: {
        settingsSliceData: initialSettingsSliceData
    },
    reducers: {
        resetSettingsStatusValues: (state) => {
            state.settingsSliceData.statusValues.statusCode = '';
            state.settingsSliceData.statusValues.isLoading = false;
            state.settingsSliceData.statusValues.showNotification = false;
        },
        resetCompanyInformation: (state) => {
            state.settingsSliceData.CompanyData = initialGetUpdateResultCompanyModel;
        },

    },
    extraReducers(builder) {
        
        //CheckCompanyUser
        builder
            .addCase(GetCompany.fulfilled, (state, action) => {
                state.settingsSliceData.CompanyData = handleFulfilled(state.settingsSliceData.statusValues, action.payload);
                state.settingsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetCompany.rejected, (state, action) => {
                handleRejected(state.settingsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.settingsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetCompany.pending, (state) => {
                handlePending(state.settingsSliceData.statusValues);
            });
        builder
            .addCase(AddCompany.fulfilled, (state, action) => {
                state.settingsSliceData.CompanyData = handleFulfilled(state.settingsSliceData.statusValues, action.payload);
                state.settingsSliceData.statusValues.showNotification = true;
            })
            .addCase(AddCompany.rejected, (state, action) => {
                handleRejected(state.settingsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.settingsSliceData.statusValues.showNotification = true;
            })
            .addCase(AddCompany.pending, (state) => {
                handlePending(state.settingsSliceData.statusValues);
            });
        builder
            .addCase(UpdateCompany.fulfilled, (state, action) => {
                state.settingsSliceData.CompanyData = handleFulfilled(state.settingsSliceData.statusValues, action.payload);
                state.settingsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateCompany.rejected, (state, action) => {
                handleRejected(state.settingsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.settingsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateCompany.pending, (state) => {
                handlePending(state.settingsSliceData.statusValues);
            });
    },
});

export const { resetSettingsStatusValues, resetCompanyInformation } = SettingsSlice.actions;
export default SettingsSlice.reducer;