import { useTranslation } from "react-i18next";
import { useState } from "react";
import DeleteCard from "../../../../UIComponents/deleteCard";
import EditComponentWithLayeredStructure from "../Pages/editComponentWithLayeredStructure";
import EditComponentWithFixedValues from "../Pages/editComponentWithFixedValues";
import AddMaterialsCard from "../Pages/addMaterials";
import { ComponentType } from "../../../../app/enums";
import { formatDateToDateTimeString, formatNumber, getEnumValueByKey } from "../../../../app/common";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { AddMaterialToComponent, DeleteComponent } from "../componentsSlice";
import { ComponentModel, ComponentModelForDelete, MaterialInComponentModel, MaterialInComponentModelForCreate } from "../componentsModels";
import SaveTemplate from "../Pages/saveTemplate";

interface ComponentRowProps {
    component: ComponentModel;
    componentId: string;
    componentCode: string;
    componentName: string;
    componentType: string;
    internalSurfaceResistance?: number;
    externalSurfaceResistance?: number;
    thermalTransmittance: number;
    materials?: MaterialInComponentModel[];
}

function ComponentRow(props: ComponentRowProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [showMaterials, setShowMaterials] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [openAddMaterial, setOpenAddMaterial] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const currentDate = formatDateToDateTimeString(new Date());

    function handleDeleteComponentClick(): void {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisComponent"));
        setIsDelete(true);
    }

    function addMaterialToComponent(material: MaterialInComponentModelForCreate): void {
        dispatch(AddMaterialToComponent(material))
        setOpenAddMaterial(false)
    }

    function deleteSelected(): void {
        const currentDate = formatDateToDateTimeString(new Date());

        const componentForDelete: ComponentModelForDelete = {
            componentId: props.componentId,
            userId: localStorage.getItem("userId")!,
            projectId: projectId,
            dateDeleted: currentDate
        }
        dispatch(DeleteComponent(componentForDelete))
        setIsDelete(false)
    }

    return (
        <div className="text-#F5F5F5">
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 text-base font-medium pl-[35px] pr-[15px] py-[11px] h-[62px] border-b border-b-#F5F5F5">
                <p className="my-auto">{props.componentCode}</p>
                <p className="my-auto truncate">{props.componentName}</p>
                <p className="my-auto">{t(getEnumValueByKey(ComponentType, props.componentType))}</p>
                <p className="hidden md:block text-right my-auto">{props.externalSurfaceResistance}</p>
                <p className="hidden md:block text-right my-auto">{props.internalSurfaceResistance}</p>
                <p className="hidden md:block text-right my-auto">{formatNumber(props.thermalTransmittance, 3)}</p>
                {props.materials ?
                    <button id="addMaterialBtn" onClick={() => setOpenAddMaterial(true)} className="hidden lg:inline-flex col-span-2 w-fit h-10 px-6 bg-#FF9800 text-#272C35 uppercase font-semibold text-sm rounded-lg justify-center items-center m-auto">{t("AddMaterial")}</button>
                    : <div className="col-span-2" />}
                {props.materials ?
                    <svg id="seeMaterialsClick" onClick={() => setShowMaterials(!showMaterials)} className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                        <path d="M4.31176 7.39572C2.10392 6.50207 1 6.05524 1 5.5C1 4.94476 2.10392 4.49793 4.31176 3.60428L7.43411 2.34047C9.64195 1.44682 10.7459 1 12.1176 1C13.4894 1 14.5933 1.44682 16.8012 2.34047L19.9235 3.60428C22.1314 4.49793 23.2353 4.94476 23.2353 5.5C23.2353 6.05524 22.1314 6.50207 19.9235 7.39572L16.8012 8.65953C14.5933 9.55318 13.4894 10 12.1176 10C10.7459 10 9.64195 9.55318 7.43411 8.65953L4.31176 7.39572Z" />
                        <path d="M23.2353 10C23.2353 10 22.1314 11.0021 19.9235 11.8957L16.8012 13.1595C14.5933 14.0532 13.4894 14.5 12.1176 14.5C10.7459 14.5 9.64195 14.0532 7.43411 13.1595L4.31176 11.8957C2.10392 11.0021 1 10 1 10" />
                        <path d="M23.2353 14.5C23.2353 14.5 22.1314 15.5021 19.9235 16.3957L16.8012 17.6595C14.5933 18.5532 13.4894 19 12.1176 19C10.7459 19 9.64195 18.5532 7.43411 17.6595L4.31176 16.3957C2.10392 15.5021 1 14.5 1 14.5" />
                    </svg> : <div />}
                <svg id={props.materials ? "editComponentWithLayeredStructure" : "editComponentWithFixedValues"} onClick={() => setIsEdit(true)} className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M14.3601 4.07866L15.2869 3.15178C16.8226 1.61607 19.3125 1.61607 20.8482 3.15178C22.3839 4.68748 22.3839 7.17735 20.8482 8.71306L19.9213 9.63993M14.3601 4.07866C14.3601 4.07866 14.4759 6.04828 16.2138 7.78618C17.9517 9.52407 19.9213 9.63993 19.9213 9.63993M14.3601 4.07866L5.83882 12.5999C5.26166 13.1771 4.97308 13.4656 4.7249 13.7838C4.43213 14.1592 4.18114 14.5653 3.97634 14.995C3.80273 15.3593 3.67368 15.7465 3.41556 16.5208L2.32181 19.8021M19.9213 9.63993L11.4001 18.1612C10.8229 18.7383 10.5344 19.0269 10.2162 19.2751C9.84082 19.5679 9.43469 19.8189 9.00498 20.0237C8.6407 20.1973 8.25352 20.3263 7.47918 20.5844L4.19792 21.6782M4.19792 21.6782L3.39584 21.9456C3.01478 22.0726 2.59466 21.9734 2.31063 21.6894C2.0266 21.4053 1.92743 20.9852 2.05445 20.6042L2.32181 19.8021M4.19792 21.6782L2.32181 19.8021" />
                </svg>
                <svg id={props.materials ? "saveToTemplateWithLayeredStructure" : "saveToTemplateWithFixedValues"} onClick={() => setSaveTemplate(true)} className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 16.0909V11.0975C21 6.80891 21 4.6646 19.682 3.3323C18.364 2 16.2426 2 12 2C7.75736 2 5.63604 2 4.31802 3.3323C3 4.6646 3 6.80891 3 11.0975V16.0909C3 19.1875 3 20.7358 3.73411 21.4123C4.08421 21.735 4.52615 21.9377 4.99692 21.9915C5.98402 22.1045 7.13673 21.0849 9.44216 19.0458C10.4612 18.1445 10.9708 17.6938 11.5603 17.5751C11.8506 17.5166 12.1494 17.5166 12.4397 17.5751C13.0292 17.6938 13.5388 18.1445 14.5578 19.0458C16.8633 21.0849 18.016 22.1045 19.0031 21.9915C19.4739 21.9377 19.9158 21.735 20.2659 21.4123C21 20.7358 21 19.1875 21 16.0909Z" strokeWidth="1.5" />
                    <path d="M15 6H9" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <svg id={props.materials ? "deleteComponentWithLayeredStructure" : "deleteComponentWithFixedValues"} className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" onClick={handleDeleteComponentClick} width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            </div>
            {showMaterials &&
                <div className="bg-#414959">
                    <div className="pl-[35px] pr-[15px] py-[10px] grid grid-cols-3 font-medium border-b border-b-#F5F5F5">
                        <p className="my-auto">{t("Material")}</p>
                        <p className="my-auto text-left">{t("Thickness")}</p>
                    </div>
                    <div className="max-h-[126px] overflow-auto">
                        {props.materials!.map(material =>
                            <div key={material.materialId} className="pl-[35px] pr-[15px] py-[10px] grid grid-cols-3 font-normal border-b border-b-#F5F5F5">
                                <p className="my-auto">{material.name}</p>
                                <p className="my-auto text-left">{material.thickness} mm</p>
                            </div>
                        )}
                    </div>
                </div>
            }
            {isDelete && <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} />}
            {isEdit &&
                <div>
                    {props.materials ?
                        <EditComponentWithLayeredStructure onCancelClickFunction={() => setIsEdit(false)} onContinueClickFunction={() => setIsEdit(false)} component={{ componentId: props.componentId, projectId: projectId, userId: localStorage.getItem("userId")!, componentType: props.componentType, componentCode: props.componentCode, componentName: props.componentName, thermalTransmittance: props.thermalTransmittance, internalSurfaceResistance: props.internalSurfaceResistance, externalSurfaceResistance: props.externalSurfaceResistance, dateCreatedOrModified: currentDate }} />
                        : <EditComponentWithFixedValues onCancelClickFunction={() => setIsEdit(false)} onContinueClickFunction={() => setIsEdit(false)} component={{ componentId: props.componentId, projectId: projectId, userId: localStorage.getItem("userId")!, componentType: props.componentType, componentCode: props.componentCode, componentName: props.componentName, thermalTransmittance: props.thermalTransmittance, internalSurfaceResistance: props.internalSurfaceResistance, externalSurfaceResistance: props.externalSurfaceResistance, dateCreatedOrModified: currentDate }} />}
                </div>
            }
            {openAddMaterial && <AddMaterialsCard onCancelClickFunction={() => setOpenAddMaterial(false)} onAddMaterialClickFunction={(material) => addMaterialToComponent(material)} materials={props.materials!} componentId={props.componentId} component={props.component} />}
            {saveTemplate && <SaveTemplate onCancelClickFunction={() => setSaveTemplate(false)} setSaveTemplate={() => setSaveTemplate(false)} isComponent={true} item={{ itemId: props.componentId, itemName: props.componentName }} />}
        </div>
    );
}

export default ComponentRow;