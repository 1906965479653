import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ComponentModel, ComponentModelForDelete, ComponentModelForCreate, ComponentModelForUpdate, ComponentsData, ComponentsSliceData, MaterialInComponentModelForCreate, MaterialInComponentModelForDelete, OpeningModel, OpeningModelForCreate, OpeningModelForDelete, OpeningModelForUpdate, OpeningsData, ComponentCollectionModel, RemoveItemFromCollectionModel, RenameSaveItemCollectionModel, ComponentInCollectionModel, MaterialInCollectionModel, OpeningCollectionModel, OpeningInCollectionModel, ComponentModelForDeleteResponse, OpeningModelForDeleteResponse, MaterialInComponentModelForUpdate } from "./componentsModels";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, RemoveItemFromArray, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../../app/common";
import { apiAddress } from "../../../app/apiModel";

export const GetAllComponentsWithLayeredStructure = createAsyncThunk<ValueResponse<ComponentModel[]>, string, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/GetAllComponentsWithLayeredStructure',
    async (projectId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetComponentsWithLayerStructure?projectId=' + projectId, {
                method: "GET",
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel[]> = {
                value: (await response.json()) as ComponentModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateComponentWithLayeredStructure = createAsyncThunk<ValueResponse<ComponentModel>, ComponentModelForCreate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/CreateComponentWithLayeredStructure',
    async (componentWithLayeredStructure: ComponentModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateComponentWithLayerStructure', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(componentWithLayeredStructure),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Component created successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateComponentWithLayeredStructure = createAsyncThunk<ValueResponse<ComponentModel>, ComponentModelForUpdate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/UpdateComponentWithLayeredStructure',
    async (component: ComponentModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'UpdateComponentWithLayerStructure', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(component)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Component updated successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const AddMaterialToComponent = createAsyncThunk<ValueResponse<ComponentModel>, MaterialInComponentModelForCreate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/AddMaterialToComponent',
    async (material: MaterialInComponentModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'AddMaterialToComponent', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(material)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Material added to component",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to add material to component';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateMaterialInComponent = createAsyncThunk<ValueResponse<ComponentModel>, MaterialInComponentModelForUpdate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/UpdateMaterialInComponent',
    async (material: MaterialInComponentModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'UpdateMaterialInComponent', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(material)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "The component material has been updated",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to update material in component';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const RemoveMaterialFromComponent = createAsyncThunk<ValueResponse<ComponentModel>, MaterialInComponentModelForDelete, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/RemoveMaterialFromComponent',
    async (material: MaterialInComponentModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'RemoveMaterialFromComponent', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(material)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Material removed",
            };

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to remove material';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllComponentsWithFixedValues = createAsyncThunk<ValueResponse<ComponentModel[]>, string, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/GetAllComponentsWithFixedValues',
    async (projectId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetComponentsWithFixedValues?projectId=' + projectId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel[]> = {
                value: (await response.json()) as ComponentModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateComponentWithFixedValues = createAsyncThunk<ValueResponse<ComponentModel>, ComponentModelForCreate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/CreateComponentWithFixedValues',
    async (componentWithFixedValues: ComponentModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateComponentWithFixedValues', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(componentWithFixedValues),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Component created successfully",
            };
            return dataResponse;
        } catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }

    }
);

export const UpdateComponentWithFixedValues = createAsyncThunk<ValueResponse<ComponentModel>, ComponentModelForUpdate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/UpdateComponentWithFixedValues',
    async (component: ComponentModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'UpdateComponentWithFixedValues', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(component)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModel> = {
                value: (await response.json()) as ComponentModel,
                status: response.status.toString(),
                message: "Component updated successfully",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteComponent = createAsyncThunk<ValueResponse<ComponentModelForDeleteResponse>, ComponentModelForDelete, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/DeleteComponent',
    async (component: ComponentModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteComponent`, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(component)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentModelForDeleteResponse> = {
                value: (await response.json()) as ComponentModelForDeleteResponse,
                status: response.status.toString(),
                message: "Component deleted successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllOpenings = createAsyncThunk<ValueResponse<OpeningModel[]>, string, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/GetAllOpenings',
    async (projectId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllOpenings?projectId=' + projectId, {
                method: "GET",
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<OpeningModel[]> = {
                value: (await response.json()) as OpeningModel[],
                status: response.status.toString(),
                message: response.statusText
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateOpening = createAsyncThunk<ValueResponse<OpeningModel>, OpeningModelForCreate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/CreateOpening',
    async (opening: OpeningModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateOpening', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(opening),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<OpeningModel> = {
                value: (await response.json()) as OpeningModel,
                status: response.status.toString(),
                message: "Opening created successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateOpening = createAsyncThunk<ValueResponse<OpeningModel>, OpeningModelForUpdate, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/UpdateOpening',
    async (opening: OpeningModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set('Content-Type', 'application/json');
            const response = await fetch(apiAddress + 'UpdateOpening', {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(opening)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<OpeningModel> = {
                value: (await response.json()) as OpeningModel,
                status: response.status.toString(),
                message: "Opening updated successfully",
            };

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteOpening = createAsyncThunk<ValueResponse<OpeningModelForDeleteResponse>, OpeningModelForDelete, { rejectValue: RejectedValue }>(
    'ComponentsSlicer/DeleteOpening',
    async (opening: OpeningModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteOpening`, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(opening)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<OpeningModelForDeleteResponse> = {
                value: (await response.json()) as OpeningModelForDeleteResponse,
                status: response.status.toString(),
                message: "Opening deleted successfully",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteComponentCollection = createAsyncThunk<ValueResponse<string>, string, { rejectValue: RejectedValue }>(
    'ComponentSlicer/DeleteComponentCollection',
    async (collectionId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteComponentCollection?collectionId=` + collectionId, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(collectionId)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Template deleted successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const GetAllComponentCollections = createAsyncThunk<ValueResponse<ComponentCollectionModel>, string, { rejectValue: RejectedValue }>(
    'ComponentSlicer/GetAllComponentCollections',
    async (userId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `GetAllComponentCollections?userId=` + userId, {
                method: 'GET',
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<ComponentCollectionModel> = {
                value: (await response.json()) as ComponentCollectionModel,
                status: response.status.toString(),
                message: response.statusText
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const RemoveComponentFromCollection = createAsyncThunk<ValueResponse<string>, RemoveItemFromCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/RemoveComponentFromCollection',
    async (removeItemFromCollectionModel: RemoveItemFromCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `RemoveComponentFromCollection`, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(removeItemFromCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Component removed",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const RenameComponentCollection = createAsyncThunk<ValueResponse<string>, RenameSaveItemCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/RenameComponentCollection',
    async (renameSaveItemCollectionModel: RenameSaveItemCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `RenameComponentCollection`, {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(renameSaveItemCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Template updated",
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const SaveComponentsToCollection = createAsyncThunk<ValueResponse<string>, RenameSaveItemCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/SaveComponentsToCollection',
    async (renameSaveItemCollectionModel: RenameSaveItemCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `SaveComponentsToCollection`, {
                method: 'POST',
                headers: requestHeaders,
                body: JSON.stringify(renameSaveItemCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: renameSaveItemCollectionModel.entityIds.some(x => x !== undefined) ? 'Component added successfully to the template' : 'Template created successfully',
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const GetAllOpeningCollections = createAsyncThunk<ValueResponse<OpeningCollectionModel>, string, { rejectValue: RejectedValue }>(
    'ComponentSlicer/GetAllOpeningCollections',
    async (userId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `GetAllOpeningCollections?userId=` + userId, {
                method: 'GET',
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<OpeningCollectionModel> = {
                value: (await response.json()) as OpeningCollectionModel,
                status: response.status.toString(),
                message: response.statusText
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const RemoveOpeningFromCollection = createAsyncThunk<ValueResponse<string>, RemoveItemFromCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/RemoveOpeningFromCollection',
    async (removeItemFromCollectionModel: RemoveItemFromCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `RemoveOpeningFromCollection`, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(removeItemFromCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Opening removed",
            };

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const RenameOpeningCollection = createAsyncThunk<ValueResponse<string>, RenameSaveItemCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/RenameOpeningCollection',
    async (renameSaveItemCollectionModel: RenameSaveItemCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `RenameOpeningCollection`, {
                method: 'PUT',
                headers: requestHeaders,
                body: JSON.stringify(renameSaveItemCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Opening updated",
            };

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const DeleteOpeningCollection = createAsyncThunk<ValueResponse<string>, string, { rejectValue: RejectedValue }>(
    'ComponentSlicer/DeleteOpeningCollection',
    async (collectionId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteOpeningCollection?collectionId=` + collectionId, {
                method: 'DELETE',
                headers: requestHeaders,
                body: JSON.stringify(collectionId)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Template deleted successfully",
            };
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const SaveOpeningsToCollection = createAsyncThunk<ValueResponse<string>, RenameSaveItemCollectionModel, { rejectValue: RejectedValue }>(
    'ComponentSlicer/SaveOpeningsToCollection',
    async (renameSaveItemCollectionModel: RenameSaveItemCollectionModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `SaveOpeningsToCollection`, {
                method: 'POST',
                headers: requestHeaders,
                body: JSON.stringify(renameSaveItemCollectionModel)
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: renameSaveItemCollectionModel.entityIds.some(x => x !== undefined) ? 'Opening added successfully to the template'  : 'Template added successfully',
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        };
    }
)





const initialComponentWithLayeredStructureData: ComponentModel = {
    componentId: "",
    componentCode: "",
    componentName: "",
    componentType: "",
    thermalTransmittance: 0,
    totalThermalResistance: 0,
    thermalResistance: 0,
    thickness: 0,
    internalSurfaceResistance: 0,
    externalSurfaceResistance: 0,
    materials: []
}

const initialComponentWithFixedValuesData: ComponentModel = {
    componentId: "",
    componentCode: "",
    componentName: "",
    componentType: "",
    thermalTransmittance: 0,
    totalThermalResistance: 0,
    thermalResistance: 0,
    thickness: 0,
    internalSurfaceResistance: 0,
    externalSurfaceResistance: 0,
    materials: []
}

const initialOpeningData: OpeningModel = {
    openingId: "",
    openingName: "",
    openingCode: "",
    openingType: "",
    thermalTransmittance: 0
}

const initialComponentsData: ComponentsData = {
    componentWithLayeredStructureData: initialComponentWithLayeredStructureData,
    allComponentsWithLayeredStructureData: [],
    componentWithFixedValuesData: initialComponentWithFixedValuesData,
    allComponentsWithFixedValuesData: [],
    allComponentInCollectionData: [],
}

const initialOpeningsData: OpeningsData = {
    openingData: initialOpeningData,
    allOpeningsData: [],
    allOpeningInCollectionData: [],
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialComponentsSliceData: ComponentsSliceData = {
    componentsData: initialComponentsData,
    openingsData: initialOpeningsData,
    statusValues: initialStatusValues,
    statusValuesItemInCollection: false,
    statusValuesDeleteItemFromCollection: false,
    statusValuesDeleteComponentCollection: false,
    statusValuesDeleteOpeningCollection: false,
}

const initialMaterialInCollectionData: MaterialInCollectionModel = {
    materialInCollectionId: "",
    materialId: "",
    materialName: "",
    density: 0,
    thermalConductivity: 0,
    specificHeatCapacity: 0,
    thickness: 0,
}

const initialComponentInCollectionData: ComponentInCollectionModel = {
    componentInCollectionId: "",
    componentId: "",
    componentCode: "",
    componentName: "",
    componentType: "",
    thermalTransmittance: 0,
    totalThermalResistance: 0,
    thermalResistance: 0,
    thickness: 0,
    internalSurfaceResistance: 0,
    externalSurfaceResistance: 0,
    isLayerStructure: false,
    materials: [initialMaterialInCollectionData],
}

const initialOpeningInCollectionData: OpeningInCollectionModel = {
    openingInCollectionId: "",
    openingId: "",
    openingName: "",
    openingCode: "",
    openingType: "",
    thermalTransmittance: 0,
}

const initialOpeningCollectionData: OpeningCollectionModel = {
    collectionId: "",
    collectionName: "",
    openings: [initialOpeningInCollectionData],
}

export const initialRenameSaveItemCollectionData: RenameSaveItemCollectionModel = {
    userId: "",
    collectionName: "",
    collectionId: "",
    entityIds: [],
}

export const ComponentsSlice = createSlice({
    name: "ComponentsSlicer",
    initialState: {
        componentsSliceData: initialComponentsSliceData
    },
    reducers: {
        resetComponentsSlice: (state) => {
            state.componentsSliceData.statusValues.statusCode = '';
            state.componentsSliceData.statusValues.isLoading = false;
            state.componentsSliceData.statusValues.message = '';
            state.componentsSliceData.statusValues.showNotification = false;
            state.componentsSliceData.componentsData = initialComponentsData;
            state.componentsSliceData.openingsData = initialOpeningsData;
        },
        resetStatusCode: (state) => {
            state.componentsSliceData.statusValues.statusCode = '';
            state.componentsSliceData.statusValues.message = '';
            state.componentsSliceData.statusValues.showNotification = false;
        },
        resetSaveItemInCollection: (state) => {
            state.componentsSliceData.statusValuesItemInCollection = false;
        },
        resetDeleteItemFromCollection: (state) => {
            state.componentsSliceData.statusValuesDeleteItemFromCollection = false;
        },
        resetDeleteCollection: (state) => {
            state.componentsSliceData.statusValuesDeleteComponentCollection = false;
            state.componentsSliceData.statusValuesDeleteOpeningCollection = false;
        }
    },
    extraReducers(builder) {
        //GetAllComponentsWithLayeredStructure
        builder
            .addCase(GetAllComponentsWithLayeredStructure.fulfilled, (state, action) => {
                state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData = handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllComponentsWithLayeredStructure.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllComponentsWithLayeredStructure.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //CreateComponentWithLayeredStructure
        builder
            .addCase(CreateComponentWithLayeredStructure.fulfilled, (state, action) => {
                state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.push(handleFulfilled(state.componentsSliceData.statusValues, action.payload));
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateComponentWithLayeredStructure.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateComponentWithLayeredStructure.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //UpdateComponentWithLayeredStructure
        builder
            .addCase(UpdateComponentWithLayeredStructure.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfComponentToUpdate = state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.findIndex(x => x.componentId === action.payload.value.componentId)
                if (indexOfComponentToUpdate !== -1)
                    state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData[indexOfComponentToUpdate] = action.payload.value
            })
            .addCase(UpdateComponentWithLayeredStructure.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateComponentWithLayeredStructure.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //AddMaterialToComponent
        builder
            .addCase(AddMaterialToComponent.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfComponentToUpdate = state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.findIndex(x => x.componentId === action.payload.value.componentId)
                if (indexOfComponentToUpdate !== -1)
                    state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData[indexOfComponentToUpdate].materials = action.payload.value.materials
            })
            .addCase(AddMaterialToComponent.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(AddMaterialToComponent.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //UpdateMaterialInComponent
        builder
            .addCase(UpdateMaterialInComponent.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfComponentToUpdate = state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.findIndex(x => x.componentId === action.payload.value.componentId)
                if (indexOfComponentToUpdate !== -1)
                    state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData[indexOfComponentToUpdate].materials = action.payload.value.materials
            })
            .addCase(UpdateMaterialInComponent.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateMaterialInComponent.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //RemoveMaterialFromComponentRemoveMaterialFromComponent
        builder
            .addCase(RemoveMaterialFromComponent.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfComponentToUpdate = state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.findIndex(x => x.componentId === action.payload.value.componentId)
                if (indexOfComponentToUpdate !== -1)
                    state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData[indexOfComponentToUpdate].materials = action.payload.value.materials
            })
            .addCase(RemoveMaterialFromComponent.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(RemoveMaterialFromComponent.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //GetAllComponentsWithFixedValues
        builder
            .addCase(GetAllComponentsWithFixedValues.fulfilled, (state, action) => {
                state.componentsSliceData.componentsData.allComponentsWithFixedValuesData = handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllComponentsWithFixedValues.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllComponentsWithFixedValues.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //CreateComponentWithFixedValues
        builder
            .addCase(CreateComponentWithFixedValues.fulfilled, (state, action) => {
                state.componentsSliceData.componentsData.allComponentsWithFixedValuesData.push(handleFulfilled(state.componentsSliceData.statusValues, action.payload));
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateComponentWithFixedValues.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateComponentWithFixedValues.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //UpdateComponentWithFixedValues
        builder
            .addCase(UpdateComponentWithFixedValues.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfComponentToUpdate = state.componentsSliceData.componentsData.allComponentsWithFixedValuesData.findIndex(x => x.componentId === action.payload.value.componentId)
                if (indexOfComponentToUpdate !== -1)
                    state.componentsSliceData.componentsData.allComponentsWithFixedValuesData[indexOfComponentToUpdate] = action.payload.value
            })
            .addCase(UpdateComponentWithFixedValues.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateComponentWithFixedValues.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //DeleteComponent
        builder
            .addCase(DeleteComponent.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                let componentWithLayeredStructureToRemove: ComponentModel | undefined = state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData.find(component => component.componentId === action.payload.value.componentId)
                if (componentWithLayeredStructureToRemove !== undefined)
                    state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData = RemoveItemFromArray(state.componentsSliceData.componentsData.allComponentsWithLayeredStructureData, componentWithLayeredStructureToRemove)
                let componentWithFixedValuesToRemove: ComponentModel | undefined = state.componentsSliceData.componentsData.allComponentsWithFixedValuesData.find(component => component.componentId === action.payload.value.componentId)
                if (componentWithFixedValuesToRemove !== undefined)
                    state.componentsSliceData.componentsData.allComponentsWithFixedValuesData = RemoveItemFromArray(state.componentsSliceData.componentsData.allComponentsWithFixedValuesData, componentWithFixedValuesToRemove)
            })
            .addCase(DeleteComponent.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteComponent.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //GetAllOpenings
        builder
            .addCase(GetAllOpenings.fulfilled, (state, action) => {
                state.componentsSliceData.openingsData.allOpeningsData = handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllOpenings.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllOpenings.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //CreateOpening
        builder
            .addCase(CreateOpening.fulfilled, (state, action) => {
                state.componentsSliceData.openingsData.allOpeningsData.push(handleFulfilled(state.componentsSliceData.statusValues, action.payload));
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateOpening.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateOpening.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            });
        //UpdateOpening
        builder
            .addCase(UpdateOpening.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let indexOfOpeningToUpdate = state.componentsSliceData.openingsData.allOpeningsData.findIndex(x => x.openingId === action.payload.value.openingId)
                if (indexOfOpeningToUpdate !== -1)
                    state.componentsSliceData.openingsData.allOpeningsData[indexOfOpeningToUpdate] = action.payload.value
            })
            .addCase(UpdateOpening.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateOpening.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //DeleteOpening
        builder
            .addCase(DeleteOpening.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;

                let openingToRemove: OpeningModel | undefined = state.componentsSliceData.openingsData.allOpeningsData.find(opening => opening.openingId === action.payload.value.openingId)
                if (openingToRemove !== undefined)
                    state.componentsSliceData.openingsData.allOpeningsData = RemoveItemFromArray(state.componentsSliceData.openingsData.allOpeningsData, openingToRemove)
            })
            .addCase(DeleteOpening.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteOpening.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //DeleteComponentCollection
        builder
            .addCase(DeleteComponentCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesDeleteComponentCollection = true;
            })
            .addCase(DeleteComponentCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteComponentCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //GetAllComponentCollections
        builder
            .addCase(GetAllComponentCollections.fulfilled, (state, action) => {
                state.componentsSliceData.componentsData.allComponentInCollectionData = handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllComponentCollections.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetAllComponentCollections.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //RemoveComponentFromCollection
        builder
            .addCase(RemoveComponentFromCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesDeleteItemFromCollection = true;
            })
            .addCase(RemoveComponentFromCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(RemoveComponentFromCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //RenameComponentCollection
        builder
            .addCase(RenameComponentCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesItemInCollection = true;
            })
            .addCase(RenameComponentCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(RenameComponentCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //SaveComponentToCollection
        builder
            .addCase(SaveComponentsToCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesItemInCollection = true;
            })
            .addCase(SaveComponentsToCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(SaveComponentsToCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //GetAllOpeningCollections
        builder
            .addCase(GetAllOpeningCollections.fulfilled, (state, action) => {
                state.componentsSliceData.openingsData.allOpeningInCollectionData = handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllOpeningCollections.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetAllOpeningCollections.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //RemoveOpeningFromCollection
        builder
            .addCase(RemoveOpeningFromCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesDeleteItemFromCollection = true;
            })
            .addCase(RemoveOpeningFromCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(RemoveOpeningFromCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //RenameOpeningCollection
        builder
            .addCase(RenameOpeningCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesItemInCollection = true;
            })
            .addCase(RenameOpeningCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(RenameOpeningCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })

        //SaveOpeningsToCollection
        builder
            .addCase(SaveOpeningsToCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesItemInCollection = true;
            })
            .addCase(SaveOpeningsToCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(SaveOpeningsToCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
        //DeleteOpeningCollection
        builder
            .addCase(DeleteOpeningCollection.fulfilled, (state, action) => {
                handleFulfilled(state.componentsSliceData.statusValues, action.payload);
                state.componentsSliceData.statusValues.showNotification = true;
                state.componentsSliceData.statusValuesDeleteOpeningCollection = true;
            })
            .addCase(DeleteOpeningCollection.rejected, (state, action) => {
                handleRejected(state.componentsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.componentsSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteOpeningCollection.pending, (state) => {
                handlePending(state.componentsSliceData.statusValues);
            })
    }
})

export const { resetComponentsSlice, resetStatusCode, resetSaveItemInCollection, resetDeleteItemFromCollection, resetDeleteCollection } = ComponentsSlice.actions;
export default ComponentsSlice.reducer;