import { Button, Checkbox, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { RoomHeatingPopupProps } from "../roomDetailsModels";
import CustomDropdown from "../../../UIComponents/customDropdown";
import { BuildingMassValues } from "../../../app/enums";
import { getEnumValueByKey } from "../../../app/common";
import { initialHeatingInteruptionData } from "../buildingStructureSlice";
import { RoomHeatingInteruptionModelForUpdate } from "../buildingStructureModels";
import { buildingMassOptions } from "../../../app/optionModels";

const HeightPopup: React.FC<RoomHeatingPopupProps> = ({ openRoomHeatingPopup, handleRoomHeatingPopup, roomHeating, setRoomHeating }) => {
    const { t } = useTranslation();
    const [buildingMass, setBuildingMass] = useState("");
    const [checkManualDataEntry, setCheckManualEntry] = useState(false);
    const [checkInputs, setCheckInputs] = useState<boolean>(false);

    const [roomHeatingDraft, setRoomHeatingDraft] = useState<RoomHeatingInteruptionModelForUpdate>(initialHeatingInteruptionData);

    function handleInputChangeRoomHeating(event: React.ChangeEvent<HTMLInputElement>) {
        let { name, value } = event.target;

        let regexPattern;
        if (name === "effectiveBuildingMass_Cwirk") {
            regexPattern = /^\d*$/;
        } else {
            regexPattern = new RegExp(`^\\d*\\.?\\d{0,1}$`);
        }

        if (regexPattern.test(value)) {
            setRoomHeatingDraft({
                ...roomHeatingDraft,
                [name]: parseFloat(value),
            })
        }
    }

    function handleSaveChanges() {
        setCheckInputs(true)
        if ((roomHeatingDraft.airExchangeRateDuringLoweringPhase_nabs <= 0.5 && roomHeatingDraft.airExchangeRateDuringLoweringPhase_nabs >= 0.1) && (roomHeatingDraft.airExchangeRateDuringReheatingTime_nrh <= 0.5 && roomHeatingDraft.airExchangeRateDuringReheatingTime_nrh >= 0.1) && roomHeatingDraft.loweringPhase && roomHeatingDraft.reheatingTime) {
            setRoomHeating({ ...roomHeatingDraft })
            setCheckInputs(false)
            handleRoomHeatingPopup()
        }
    }

    useEffect(() => {
        setRoomHeatingDraft({ ...roomHeating })
    }, [roomHeating])

    useEffect(() => {
        setRoomHeatingDraft({
            ...roomHeatingDraft,
            buildingMass: buildingMass,
            effectiveBuildingMass_Cwirk: parseInt(getEnumValueByKey(BuildingMassValues, buildingMass)),
        })
    }, [buildingMass])

    return (
        <Dialog open={openRoomHeatingPopup} handler={handleRoomHeatingPopup} size="lg" className="flex font-inter bg-transparent shadow-none justify-center">
            <div className="bg-[#343A47] w-[1014px] h-[603px] rounded-md p-0 pt-[32px] pl-[32px] pb-[32px]">
                <div className="">
                    <DialogHeader className="p-0">
                        <Typography className="text-[#F5F5F5] uppercase font-inter mb-[29px] leading-none ">{t("roomHeatingInterruptionData")}</Typography>
                    </DialogHeader>
                    <DialogBody className="p-0 pb-[34px]">
                        <div className="flex items-center justify-start mb-[30px]">
                            <Typography className="text-[#F5F5F5] flex items-center w-[181px] mr-[70px] leading-none font-inter">{t("EffectiveBuildingMass")}</Typography>
                            <div>
                                <CustomDropdown dropdownId="effectiveBuildingMass" options={buildingMassOptions} previousSelectedOption={roomHeatingDraft.buildingMass} placeholder="" height={"h-10"} width={"w-[616px]"} onSelectClick={setBuildingMass} />
                            </div>
                        </div>
                        <div className="flex content-center justify-start mb-[35px] ">
                            <div className="flex content-center w-[181px] mr-[70px] ">
                                <Checkbox id="checkManualDataEntry" crossOrigin={""} ripple={false} onChange={() => { setCheckManualEntry(prevCheckManualDataEntry => !prevCheckManualDataEntry); }} className={`${checkManualDataEntry ? "" : "opacity-50"} w-[16px] h-[16px] hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"`}
                                    label={<Typography className={`${checkManualDataEntry ? "" : "opacity-50"} text-[#f5f5f5] font-inter  ml-[11px] leading-none`}>{t("ManualDataEntry")}</Typography>}
                                    containerProps={{ className: "p-0" }} />
                            </div>
                            <div className="flex items-center">
                                <input id="buildingMass" type="number" value={roomHeatingDraft.effectiveBuildingMass_Cwirk} name="effectiveBuildingMass_Cwirk" onChange={handleInputChangeRoomHeating} placeholder="0" pattern="^\d*(\.\d{0,2})?$" step={".01"} disabled={!checkManualDataEntry} className={`bg-#2E333E w-[616px] ${checkManualDataEntry ? "" : "opacity-50"} focus:outline-none placeholder:text-#545B6A text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner`} />
                                <Typography className={`${checkManualDataEntry ? "" : "opacity-50"} text-[#F5F5F5] font-inter text-[16px] w-[64px] p-0  ml-[8px] mr-[33px]`}>Wh/m<sup>3</sup>K</Typography>
                            </div>
                        </div>
                        <div className="h-[1px] bg-[#272C35] mb-[36px]" />
                        <div className="flex flex-row font-inter mb-[38px]">
                            <Typography className="text-[#F5F5F5] font-inter leading-none w-[181px] mr-[85px] ">{t("DurationAndAirChange")}</Typography>
                            <Typography className="text-[#F5F5F5] font-inter leading-none mr-[260px] ">{t("LoweringPhase")}</Typography>
                            <Typography className="text-[#F5F5F5] font-inter leading-none ">{t("HeatingTime")}</Typography>
                        </div>
                        <div className="flex flex-row  items-center mb-[18px]">
                            <Typography className="text-[#F5F5F5] font-inter w-[181px] mr-[70px] leading-none ">{t("DurationOfThePhase")}</Typography>
                            <div className="flex items-center mr-[101px]">
                                <input id="durationOfThePhaseLowering" type="number" value={roomHeatingDraft?.loweringPhase} name="loweringPhase" onChange={handleInputChangeRoomHeating} placeholder="0" pattern="^\d*(\.\d{0,2})?$" step={".01"} className={`${roomHeatingDraft?.loweringPhase?.toString() === "" && checkInputs ? "border border-[#EF5350]" : ""} focus:outline-none bg-#2E333E placeholder:text-#545B6A text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner w-[253px]`} />
                                <Typography className="text-[#F5F5F5] w-[10px] ml-[14px] font-inter ">h</Typography>
                            </div>
                            <div className="flex items-center">
                                <input id="durationOfThePhaseHeating" type="number" value={roomHeatingDraft.reheatingTime} name="reheatingTime" onChange={handleInputChangeRoomHeating} placeholder="0" pattern="^\d*(\.\d{0,2})?$" step={".01"} className={`${roomHeatingDraft?.reheatingTime?.toString() === "" && checkInputs ? "border border-[#EF5350]" : ""} focus:outline-none bg-#2E333E placeholder:text-#545B6A text-[#f5f5f5] disabled:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[238px]`} />
                                <Typography className="text-[#F5F5F5] w-[10px] ml-[14px] font-inter ">h</Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-[37px] ">
                            <Typography className="text-[#F5F5F5] text-[16px] w-[181px] mr-[70px] leading-none  font-inter">{t("AirExchangeRate")}</Typography>
                            <div className="flex items-center mr-[88px]">
                                <input id="airExchangeRateLowering" type="number" value={roomHeatingDraft.airExchangeRateDuringLoweringPhase_nabs} name="airExchangeRateDuringLoweringPhase_nabs" onChange={handleInputChangeRoomHeating} placeholder="0" className={`${roomHeatingDraft?.airExchangeRateDuringLoweringPhase_nabs?.toString() === "" || !(roomHeatingDraft?.airExchangeRateDuringLoweringPhase_nabs >= 0.1 && roomHeatingDraft?.airExchangeRateDuringLoweringPhase_nabs <= 0.5) && checkInputs ? "border border-[#EF5350]" : ""} focus:outline-none bg-#2E333E placeholder:text-#545B6A text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner w-[253px]`} />
                                <Typography className="text-[#F5F5F5] w-[23px] ml-[14px] font-inter">1/h</Typography>
                            </div>
                            <div className="flex items-center">
                                <input id="airExchangeRateHeating" type="number" value={roomHeatingDraft.airExchangeRateDuringReheatingTime_nrh} name="airExchangeRateDuringReheatingTime_nrh" onChange={handleInputChangeRoomHeating} placeholder="0" className={`${roomHeatingDraft?.airExchangeRateDuringReheatingTime_nrh?.toString() === "" || !(roomHeatingDraft?.airExchangeRateDuringReheatingTime_nrh >= 0.1 && roomHeatingDraft?.airExchangeRateDuringReheatingTime_nrh <= 0.5) && checkInputs ? "border border-[#EF5350]" : ""} focus:outline-none bg-#2E333E placeholder:text-#545B6A text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner w-[238px]`} />
                                <Typography className="text-[#F5F5F5] w-[23px] ml-[14px] font-inter">1/h</Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center ">
                            <Typography className="text-[#F5F5F5] w-[181px] mr-[70px] leading-none  font-inter">{t("TemperatureDrop")}</Typography>
                            <div className="flex items-center mr-[100px]">
                                <input id="temperatureDropLowering" type="number" value={roomHeatingDraft.temperatureDrop_trh} name="temperatureDrop_trh" onChange={handleInputChangeRoomHeating} placeholder="0" pattern="^\d*(\.\d{0,2})?$" step={".01"} className={`bg-#2E333E placeholder:text-#545B6A focus:outline-none text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner w-[253px]`} />
                                <Typography className="text-[#F5F5F5] w-[11px] ml-[14px] font-inter">K</Typography>
                            </div>
                            <div className="flex items-center">
                                <input id="temperatureDropHeating" type="number" value={roomHeatingDraft.reheatingFactor_RH} name="reheatingFactor_RH" onChange={handleInputChangeRoomHeating} placeholder="0" pattern="^\d*(\.\d{0,2})?$" step={".01"} className={`bg-#2E333E placeholder:text-#545B6A focus:outline-none text-[#f5f5f5] h-10 pl-[12px] rounded-[5px] shadow-inner w-[238px]`} />
                                <Typography className="text-[#F5F5F5] w-[40px] ml-[14px] font-inter">W/m<sup>2</sup></Typography>
                            </div>
                        </div>
                    </DialogBody>
                    <DialogFooter className="flex justify-start p-0">
                        <div className="flex flex-row ml-[693px]">
                            <Button id="roomHeatingSaveChangesBtn" onClick={handleSaveChanges} className="w-[174px]  h-10 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                {t("saveChanges")}
                            </Button>
                        </div>
                    </DialogFooter>
                </div>
            </div>

        </Dialog>
    );
}

export default HeightPopup;