import { Tabs, TabsHeader, TabsBody, Tab, TabPanel, Button, Typography, } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Geometry from "./geometryForm";
import Property from "./propertyForm";
import ThermalBridge from "./thermalBridgeForm";
import Geolocation from "./geolocationForm";
import HeatingInterruption from "./heatingInterruptionForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { BuildingModelForCreate, BuildingModelForUpdate, GeolocationDataInput, GeolocationModel, GeometryModel, GetBuildingModel, HeatingInterruptionModel, PropertyModel, ThermalBridgeModel } from "../buildingModels";
import { CreateBuilding, GetBuilding, UpdateBuilding, resetStatusValues } from "../buildingSlice";
import { formatDateToDateTimeString, getEnumKeyByValue } from "../../../app/common";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import { LoadingPage } from "../../../UIComponents/loading";
import { HeatingInterruptionType, ThermalBrigde } from "../../../app/enums";
import { useNavigate } from "react-router-dom";
import { GetProject } from "../../Project/projectSlice";
import { resetArea, resetFloor } from "../../BuildingStructure/buildingStructureSlice";

function CreateBuildingPage() {
    const { t } = useTranslation();
    const [isReady, setIsReady] = useState(false);
    const [activeTab, setActivetab] = useState("geometry");
    const [navigateTo, setNavigateTo] = useState("");
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const geometryRef = useRef<GeometryModel>({
        buildingName: "", length: 0, width: 0, bottomPlateThickness: 0, z: 0, buildingArea: 0, buildingPerimeter: 0, groundWaterDepth: 0,
    });
    const propertyRef = useRef<PropertyModel>({
        buildingType: "", groundType: "", buildingMass: "", airOpeningTightness: "", buildingCoverage: "",
    });
    const thermalBridgeRef = useRef<ThermalBridgeModel>({
        thermalBridge: "", thermalBridgeValue: 0,
    });
    const geolocationRef = useRef<GeolocationModel>({
        geolocationId: "", geolocationZipCode: "", geolocationName: "", region: "", climateRegion: "", regionCode: "", minElevation: 0, maxElevation: 0, minElevationTemperature: 0, outsideTemperature: 0, annualAverageTemperature: 0, elevation: 0, isEdit: false,
    });
    const heatingInterruptionRef = useRef<HeatingInterruptionModel>({
        loweringPhase: 0, reheatingTime: 0, airExchangeRateDuringLoweringPhase_nabs: 0, airExchangeRateDuringReheatingTime_nrh: 0, effectiveBuildingMass_Cwirk: 0, temperatureDrop_trh: 0, averageTemperature: 0, heatTransferCoefficient: 0, heatingInterruptionType: "", heatedBuildingFloorSpace: 0, heatedNetBuildingVolume: 0, reheatingFactor_RH: 0,
    });

    //Geometry form props
    const [buildingName, setBuildingName] = useState("");
    const [length, setLength] = useState("");
    const [width, setWidth] = useState("");
    const [bottomPlateThickness, setBottomPlateThickness] = useState("");
    const [parameterZ, setParameterZ] = useState("");
    const [buildingArea, setBuildingArea] = useState("");
    const [buildingPerimeter, setBuildingPerimeter] = useState("");
    const [groundWaterDepth, setGroundWaterDepth] = useState("");
    //Property form props
    const [buildingType, setBuildingType] = useState("");
    const [buildingMass, setBuildingMass] = useState("");
    const [buildingAirTightness, setBuildingAirTightness] = useState("");
    const [groundType, setGroundType] = useState("");
    const [buildingCoverage, setBuildingCoverage] = useState("");
    //Thermal bgridge form props
    const [thermalBridgeValue, setThermalBridgeValue] = useState("");
    //Geolocation form props

    const [geolocationDataInput, setGeolocationDataInput] = useState<GeolocationDataInput>({
        name: buildingSliceData.buildingData.buildingData.buildingId ? buildingSliceData.buildingData.buildingData.geolocationName : "",
        postalCode: buildingSliceData.buildingData.buildingData.buildingId ? buildingSliceData.buildingData.buildingData.geolocationZipCode : "",
        elevation: buildingSliceData.buildingData.buildingData.buildingId ? buildingSliceData.buildingData.buildingData.elevation : "",
        annualAverageTemperature: buildingSliceData.buildingData.buildingData.buildingId ? buildingSliceData.buildingData.buildingData.annualAverageTemperature : "",
        outsideTemperature: buildingSliceData.buildingData.buildingData.buildingId ? buildingSliceData.buildingData.buildingData.outsideTemperature : "",
    })
    //Heating interruption form props
    const [heatedBuildingFloorSpace, setHeatedBuildingFloorSpace] = useState("");
    const [heatedNetBuildingVolume, setHeatedNetBuildingVolume] = useState("");
    const [hasAdditionalHeatingCapacity, setHasAdditionalHeatingCapacity] = useState(false);
    const [acceptance, setAcceptance] = useState(false);
    const [roomByRoom, setRoomByRoom] = useState(false);
    const [global, setGlobal] = useState(false);
    const [transmissionHeatLossCoefficient, setTransmissionHeatLossCoefficient] = useState("");
    const [averageInternalTemperatureOfTheBuilding, setAverageInternalTemperatureOfTheBuilding] = useState("");
    const [effectiveBuildingMass, setEffectiveBuildingMass] = useState("");
    const [durationOfThePhaseLowering, setDurationOfThePhaseLowering] = useState("");
    const [durationOfThePhaseHeating, setDurationOfThePhaseHeating] = useState("");
    const [airExchangeRateLowering, setAirExchangeRateLowering] = useState("");
    const [airExchangeRateHeating, setAirExchangeRateHeating] = useState("");
    const [temperatureDropLowering, setTemperatureDropLowering] = useState("");

    const tabs = [
        {
            label: t("Geometry"),
            value: "geometry",
            desc: <Geometry isEdit={buildingSliceData.buildingData.isEdit} buildingNameOnChange={setBuildingName} lengthOnChange={setLength} widthOnChange={setWidth} bottomPlateThicknessOnChange={setBottomPlateThickness} parameterZOnChange={setParameterZ} buildingAreaOnChange={setBuildingArea} buildingPerimeterOnChange={setBuildingPerimeter} groundWaterDepthOnChange={setGroundWaterDepth} />
        },
        {
            label: t("Property"),
            value: "property",
            desc: <Property isEdit={buildingSliceData.buildingData.isEdit} buildingTypeOnChange={setBuildingType} buildingMassOnChange={setBuildingMass} buildingAirTightnessOnChange={setBuildingAirTightness} groundTypeOnChange={setGroundType} buildingCoverageOnChange={setBuildingCoverage} />,
        },
        {
            label: t("ThermalBridge"),
            value: "thermalBridge",
            desc: <ThermalBridge isEdit={buildingSliceData.buildingData.isEdit} thermalBridgeValueOnChange={setThermalBridgeValue} />,
        },
        {
            label: t("Geolocation"),
            value: "geolocation",
            desc: <Geolocation isEdit={buildingSliceData.buildingData.isEdit} setGeolocationDataInput={setGeolocationDataInput} geolocationDataInput={geolocationDataInput} />,
        },
        {
            label: t("HeatingInterruption") + " (" + t("Optional") + ")",
            value: "heatingInterruption",
            desc: <HeatingInterruption isEdit={buildingSliceData.buildingData.isEdit} buildingMass={buildingMass} setHasAdditionalHeatingCapacityOnChange={setHasAdditionalHeatingCapacity} setAcceptanceOnChange={setAcceptance} setRoomByRoomOnChange={setRoomByRoom} setGlobalOnChange={setGlobal} setTransmissionHeatLossCoefficientOnChange={setTransmissionHeatLossCoefficient} setAverageInternalTemperatureOfTheBuildingOnChange={setAverageInternalTemperatureOfTheBuilding} setEffectiveBuildingMassOnChange={setEffectiveBuildingMass} setDurationOfThePhaseLoweringOnChange={setDurationOfThePhaseLowering} setDurationOfThePhaseHeatingOnChange={setDurationOfThePhaseHeating} setAirExchangeRateLoweringOnChange={setAirExchangeRateLowering} setAirExchangeRateHeatingOnChange={setAirExchangeRateHeating} setTemperatureDropLoweringOnChange={setTemperatureDropLowering} />,
        },
    ];

    const createBuilding = () => {
        setBuildingData();
        const currentDate = formatDateToDateTimeString(new Date());
        const building: BuildingModelForCreate = {
            projectId: projectSliceData.projectData.projectData.projectId,
            geometry: geometryRef.current,
            property: propertyRef.current,
            thermalBridge: thermalBridgeRef.current,
            geolocation: geolocationRef.current,
            hasHeatingInterruption: hasAdditionalHeatingCapacity,
            heatingInterruptionData: heatingInterruptionRef.current,
            userId: localStorage.getItem("userId")!,
            dateCreatedOrModified: currentDate,
        }
        if (buildingSliceData.buildingData.isEdit) {
            const buildingToUpdate: BuildingModelForUpdate = {
                buildingModel: building,
            }
            dispatch(UpdateBuilding(buildingToUpdate));
        }
        else {
            dispatch(CreateBuilding(building));
        }
    }

    const setBuildingData = () => {
        //Geometry props
        geometryRef.current.buildingName = buildingName;
        geometryRef.current.length = parseFloat(length);
        geometryRef.current.width = parseFloat(width);
        geometryRef.current.bottomPlateThickness = parseFloat(bottomPlateThickness);
        geometryRef.current.z = parseFloat(parameterZ);
        geometryRef.current.buildingArea = parseFloat(buildingArea);
        geometryRef.current.buildingPerimeter = parseFloat(buildingPerimeter);
        geometryRef.current.groundWaterDepth = parseFloat(groundWaterDepth);
        //Property props
        propertyRef.current.buildingType = buildingType;
        propertyRef.current.groundType = groundType;
        propertyRef.current.buildingMass = buildingMass;
        propertyRef.current.airOpeningTightness = buildingAirTightness;
        propertyRef.current.buildingCoverage = buildingCoverage;
        //Thermal bgridge props
        if (thermalBridgeValue === ThermalBrigde.WITHOUT_MINIMIZING_HEAT_FLOW || thermalBridgeValue === ThermalBrigde.WITH_MINIMIZING_HEAT_FLOW) {
            thermalBridgeRef.current.thermalBridge = getEnumKeyByValue(ThermalBrigde, thermalBridgeValue);
            thermalBridgeRef.current.thermalBridgeValue = undefined;
        }
        else {
            thermalBridgeRef.current.thermalBridge = "";
            thermalBridgeRef.current.thermalBridgeValue = parseFloat(thermalBridgeValue);
        }
        //Geolocation props
        // geolocationRef.current.geolocationId = geolocationId;
        geolocationRef.current.geolocationName = geolocationDataInput.name;
        geolocationRef.current.geolocationZipCode = geolocationDataInput.postalCode;
        geolocationRef.current.elevation = parseFloat(geolocationDataInput.elevation);
        geolocationRef.current.annualAverageTemperature = parseFloat(geolocationDataInput.annualAverageTemperature);
        geolocationRef.current.outsideTemperature = parseFloat(geolocationDataInput.outsideTemperature);
        //Heating interruption props
        if (hasAdditionalHeatingCapacity) {
            heatingInterruptionRef.current.heatedBuildingFloorSpace = parseFloat(heatedBuildingFloorSpace);
            heatingInterruptionRef.current.heatedNetBuildingVolume = parseFloat(heatedNetBuildingVolume);
            if (acceptance) {
                heatingInterruptionRef.current.heatingInterruptionType = HeatingInterruptionType.MANUALLY;
                heatingInterruptionRef.current.temperatureDrop_trh = parseFloat(temperatureDropLowering);
            }
            else if (roomByRoom) {
                heatingInterruptionRef.current.heatingInterruptionType = HeatingInterruptionType.ROOMBYROOM;
                heatingInterruptionRef.current.temperatureDrop_trh = 0;
            }
            else if (global) {
                heatingInterruptionRef.current.heatingInterruptionType = HeatingInterruptionType.GLOBALLY;
                heatingInterruptionRef.current.temperatureDrop_trh = 0;
            }
            heatingInterruptionRef.current.heatTransferCoefficient = parseFloat(transmissionHeatLossCoefficient);
            heatingInterruptionRef.current.averageTemperature = parseFloat(averageInternalTemperatureOfTheBuilding);
            heatingInterruptionRef.current.effectiveBuildingMass_Cwirk = parseFloat(effectiveBuildingMass);
            heatingInterruptionRef.current.loweringPhase = parseFloat(durationOfThePhaseLowering);
            heatingInterruptionRef.current.reheatingTime = parseFloat(durationOfThePhaseHeating);
            heatingInterruptionRef.current.airExchangeRateDuringLoweringPhase_nabs = parseFloat(airExchangeRateLowering);
            heatingInterruptionRef.current.airExchangeRateDuringReheatingTime_nrh = parseFloat(airExchangeRateHeating);
        }
    }

    useEffect(() => {
        if (buildingName && length && width && bottomPlateThickness && parameterZ && buildingType && buildingMass && buildingAirTightness && groundType && buildingCoverage && thermalBridgeValue && geolocationDataInput.name && geolocationDataInput.postalCode && geolocationDataInput.elevation && geolocationDataInput.annualAverageTemperature && geolocationDataInput.outsideTemperature) {
            setIsReady(true);
        }
        else {
            setIsReady(false);
        }
    }, [buildingName, length, width, bottomPlateThickness, parameterZ, buildingType, buildingMass, buildingAirTightness, groundType, buildingCoverage, thermalBridgeValue, geolocationDataInput])

    useEffect(() => {
        dispatch(GetProject({ projectId: projectSliceData.projectData.projectData.projectId, userId: localStorage.getItem("userId")! }))
    }, [])

    useEffect(() => {
        setGeolocationDataInput({
            name: buildingSliceData.geolocation.geolocationName,
            postalCode: buildingSliceData.geolocation.geolocationZipCode,
            elevation: buildingSliceData.geolocation.elevation.toString(),
            annualAverageTemperature: buildingSliceData.geolocation.annualAverageTemperature.toString(),
            outsideTemperature: buildingSliceData.geolocation.outsideTemperature.toString(),
        })
    }, [buildingSliceData.geolocation])

    useEffect(() => {
        setGeolocationDataInput({
            name: buildingSliceData.buildingData.buildingData.geolocationName,
            postalCode: buildingSliceData.buildingData.buildingData.geolocationZipCode,
            elevation: buildingSliceData.buildingData.buildingData.elevation.toString(),
            annualAverageTemperature: buildingSliceData.buildingData.buildingData.annualAverageTemperature.toString(),
            outsideTemperature: buildingSliceData.buildingData.buildingData.outsideTemperature.toString(),
        })
    }, [buildingSliceData.buildingData.buildingData])

    useEffect(() => {
        dispatch(resetArea());
        dispatch(resetFloor());
        setIsReady(false);
        if (projectSliceData.projectData.projectData.hasBuilding) {
            const data: GetBuildingModel = {
                projectId: projectSliceData.projectData.projectData.projectId,
                userId: localStorage.getItem("userId")!
            }
            dispatch(GetBuilding(data));
            setNavigateTo("");
        }
        else {
            setNavigateTo("/buildingStructure");
        }
    }, [projectSliceData.projectData.projectData])

    useEffect(() => {
        if (buildingSliceData.statusValues.statusCode === "") {
            return
        }
        if (buildingSliceData.statusValues.statusCode === "200" && buildingSliceData.statusValues.showNotification) {
            notify(t(buildingSliceData.statusValues.message));
            dispatch(resetStatusValues());
            navigate(navigateTo);
            setNavigateTo("");
        }
        else if (buildingSliceData.statusValues.statusCode !== "200" && buildingSliceData.statusValues.showNotification) {
            notify(t(buildingSliceData.statusValues.message), true);
            dispatch(resetStatusValues());
        }
    }, [buildingSliceData.statusValues])

    return (
        <div className="bg-#343A47 w-[566px] md:w-[931px] lg:w-[1197px] h-[1393px] md:h-[1075px] m-[30px] rounded-[10px] font-inter text-#F5F5F5 tracking-wide overflow-hidden">
            <Tabs value={activeTab} className="w-[566px] md:w-[931px] lg:w-[1197px] overflow-visible">
                <TabsHeader className="bg-#3B4250 h-fit rounded-t-[10px] rounded-b-[0px]" indicatorProps={{ className: "bg-transparent shadow-none", }}>
                    <div className="flex flex-wrap pr-[30px]">
                        {tabs.map(({ label, value }, index) => (
                            <div key={value} className="flex flex-row justify-center items-center h-[40px]">
                                <Tab id={value + index} disabled={value === 'heatingInterruption' ? true : false} key={index} value={value} className={`w-auto px-[30px] text-#F5F5F5 text-[20px] leading-6 ${activeTab === value ? "font-semibold" : "font-light opacity-[80%]"} ${value === 'heatingInterruption' ? 'opacity-[22%]' : ''}`} onClick={() => setActivetab(value)}>{label}</Tab>
                                {index < tabs.length - 1 ?
                                    <div className="w-[2px] h-[36px] bg-#272C35"></div> : <></>
                                }
                            </div>))}
                    </div>
                </TabsHeader>
                <TabsBody className="">
                    {tabs.map(({ value, desc }, index) => (
                        <TabPanel key={index} value={value} className="p-0">{desc}</TabPanel>))}
                </TabsBody>
            </Tabs>
            <div className="flex flex-col items-end py-0 px-[80px] gap-5">
                <Button id="createSaveBuildingBtn" disabled={!isReady} className="w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D" onClick={createBuilding}>
                    {!buildingSliceData.buildingData.isEdit ? t("CreateBuilding") : t("SaveChanges")}
                </Button>
                <Typography className="text-[12px] text-#CFD8DC leading-[14px] font-normal">{"*" + t("ToCreateABuildingYouMustFillInAllTheFields")}</Typography>
            </div>
            <NotificationPopup />
            {buildingSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(buildingSliceData.statusValues.isLoading)}</div>}
        </div>
    );
}

export default CreateBuildingPage;
