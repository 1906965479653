import { useTranslation } from "react-i18next";
import RoomComponent from "./roomComponent";
import { AreaRoomModel } from "../buildingStructureModels";
import { Tooltip } from "@material-tailwind/react";

interface RoomsComponentProps {
    rooms: AreaRoomModel[];
    isCalculated: boolean;
    editRoom: (roomId: string) => void;
    deleteRoom: (roomId: string) => void;
}

function RoomsComponent(props: RoomsComponentProps) {
    const { t } = useTranslation();

    return (
        <div>
            <div className="bg-#3B4250 h-[32px] w-full px-[20px] py-[15px] grid grid-cols-12 space-x-2 text-#F5F5F5 text-sm lg:text-base place-content-center">
                <p className="text-left my-auto">{t("RoomNo")}</p>
                <p className="col-span-2 text-left my-auto">{t("Description")}</p>
                {/* DRAFT */}
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("Temperature")} placement="top"><p className="text-right my-auto">{t("Ti")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("Area")} placement="top"><p className="text-right my-auto">{t("Area")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("TransmissionLosses")} placement="top"><p className="text-right my-auto">{t("phiT")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("VentilationLosses")} placement="top"><p className="text-right my-auto">{t("phiV")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("StandardHeatingLoad")} placement="top"><p className="text-right my-auto">{t("phiNetto")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("InterruptionHeatingLoss")} placement="top"><p className="text-right my-auto">{t("phiRH")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("TotalHeatLoss")} placement="top"><p className="text-right my-auto">{t("phiHL")}</p></Tooltip>
                <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("LossesBySquareMeter")} placement="top"><p className="text-right my-auto">{t("phiNettoM2")}</p></Tooltip>
                <p className="text-center my-auto">{t("Delete")}</p>
            </div>
            {props.rooms.map(room => (
                <RoomComponent key={room.spaceId} isCalculated={props.isCalculated} room={room} onEditClick={() => props.editRoom(room.spaceId)} onDeleteClick={() => props.deleteRoom(room.spaceId)} />
            ))}
        </div>
    );
}

export default RoomsComponent;