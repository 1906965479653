import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthSliceData, LoginModel, LoginResponse, RefreshTokenModel, RefreshTokenResponse, RemeberMeModel } from "./AuthModels";
import { CalculationToolsAccessToken, CalculationToolsRefreshToken, ConvertDateToString, ErrorResponse, GetCurrentDate, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { apiAddress, centralizationApiAddress } from "../../app/apiModel";

export const Login = createAsyncThunk<ValueResponse<LoginResponse>, LoginModel, { rejectValue: RejectedValue }>(
    'AuthSlicer/Login',
    async (loginModel: LoginModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            requestHeaders.set("Authorization", "Basic " + btoa(loginModel.email + ":" + loginModel.password))

            const response = await fetch(apiAddress + "Login" , {
                method: "POST",
                headers: requestHeaders,
              });

            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }

            const logInResponse = (await response.json()) as LoginResponse;
            const dataResponse: ValueResponse<LoginResponse> = {
                value: logInResponse,
                status: response.status.toString(),
                message: response.statusText
            }
            localStorage.setItem(CalculationToolsAccessToken, logInResponse.accessToken);
            localStorage.setItem(CalculationToolsRefreshToken, logInResponse.refreshToken);
            localStorage.setItem("userId", dataResponse.value.userId);

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const RefreshAccessToken = createAsyncThunk<ValueResponse<RefreshTokenResponse>, void, { rejectValue: RejectedValue }>(
    'AuthSlicer/RefreshAccessToken',
    async (_, { rejectWithValue }) => {
        try {
            const refreshToken = localStorage.getItem(CalculationToolsRefreshToken);
            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set("Authorization", "Bearer " + refreshToken);
            const response = await fetch(centralizationApiAddress + 'api/auth/refresh', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(refreshToken),
            }
            );
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }

            const dataResponse: ValueResponse<RefreshTokenResponse> = {
                value: (await response.json()) as RefreshTokenResponse,
                status: response.status.toString(),
                message: response.statusText
            }
            let accessToken = (await response.json()) as string;
            localStorage.setItem(CalculationToolsAccessToken, accessToken);
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const getTokenFromSSO = createAsyncThunk<ValueResponse<LoginResponse>, void, { rejectValue: RejectedValue }>(
    "AuthSlicer/getTokenFromSSO",
    async (_, { rejectWithValue }) => {
        try {
            const accessToken = localStorage.getItem(CalculationToolsAccessToken);
            const response = await fetch(centralizationApiAddress + "api/auth/ssotoken", {
                headers: { Authorization: 'Bearer ' + accessToken, },
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const logInResponse: ValueResponse<LoginResponse> = {
                value: (await response.json()) as LoginResponse,
                status: response.status.toString(),
                message: response.statusText
            };
            localStorage.clear();
            localStorage.setItem(CalculationToolsAccessToken, logInResponse.value.accessToken);
            localStorage.setItem(CalculationToolsRefreshToken, logInResponse.value.refreshToken);
            localStorage.setItem("userId", logInResponse.value.userId);
            return logInResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

const initialLoginData: LoginResponse = {
    accessToken: "",
    refreshToken: "",
    tier: "",
    isValidLicense: false,
    userId: ""
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",

}

const initialAuthSliceData: AuthSliceData = {
    loginData: initialLoginData,
    statusValues: initialStatusValues
}

export const AuthSlice = createSlice({
    name: "AuthSlicer",
    initialState: {
        authSliceData: initialAuthSliceData,
        error: "",
        tokenRefreshed: ConvertDateToString(GetCurrentDate()),
        //shouldRefresh: false,
        shouldRedirectToLogin: false,
        repeatLastAction: false,
        isRefreshingToken: false,
    },
    reducers: {
        setShouldRefreshToken: (state) => {
            state.isRefreshingToken = true;
        },
        resetAuthSlice: (state) => {
            state.authSliceData.statusValues.statusCode = '';
            state.authSliceData.statusValues.isLoading = false;
            state.authSliceData.statusValues.message = '';
            state.authSliceData.statusValues.showNotification = false;
            state.authSliceData.loginData = initialLoginData;
            localStorage.clear();
            state.error = "";
            // state.tokenRefreshed = ConvertDateToString(GetCurrentDate());
            state.isRefreshingToken = false;
            state.shouldRedirectToLogin = false;
            state.repeatLastAction = false;
        },
        resetStatusCode: (state) => {
            state.authSliceData.statusValues.statusCode = '';
            state.authSliceData.statusValues.message = '';
            state.authSliceData.statusValues.showNotification = false;
        },
        setErrorMessage: (state, action) => {
            state.error = action.payload
        }
    },
    extraReducers(builder) {
        //Login
        builder
            .addCase(Login.fulfilled, (state, action) => {
                state.authSliceData.loginData = handleFulfilled(state.authSliceData.statusValues, action.payload);
                state.authSliceData.statusValues.showNotification = false;
            })
            .addCase(Login.rejected, (state, action) => {
                handleRejected(state.authSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.authSliceData.statusValues.showNotification = true;
            })
            .addCase(Login.pending, (state) => {
                handlePending(state.authSliceData.statusValues);
            });
        //RefreshAccessToken
        builder
            .addCase(RefreshAccessToken.fulfilled, (state, action) => {
                state.authSliceData.statusValues = handleFulfilled(state.authSliceData.statusValues, action.payload);
                state.authSliceData.statusValues.showNotification = false;
            })
            .addCase(RefreshAccessToken.rejected, (state, action) => {
                handleRejected(state.authSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.authSliceData.statusValues.showNotification = true;
            })
            .addCase(RefreshAccessToken.pending, (state) => {
                handlePending(state.authSliceData.statusValues);
            });
        //getTokenFromSSO
        builder
            .addCase(getTokenFromSSO.fulfilled, (state, action) => {
                state.authSliceData.loginData = handleFulfilled(state.authSliceData.statusValues, action.payload);
                state.authSliceData.statusValues.showNotification = false;
            })
            .addCase(getTokenFromSSO.rejected, (state, action) => {
                handleRejected(state.authSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.authSliceData.statusValues.showNotification = true;
            })
            .addCase(getTokenFromSSO.pending, (state) => {
                handlePending(state.authSliceData.statusValues);
            });
    }
})

export const { setShouldRefreshToken, resetAuthSlice, resetStatusCode, setErrorMessage } = AuthSlice.actions;
export default AuthSlice.reducer;