import { Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import CustomDropdown, { CustomDropdownMethods } from "../../../UIComponents/customDropdown";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Checkbox } from "@material-tailwind/react";
import RoomDetailsTable from "./roomDetailsTable";
import HeightPopup from "./heightPopup";
import RoomHeatingPopup from "./roomHeatingPopup";
import { useNavigate } from "react-router-dom";
import { formatDateToDateTimeString, handleInputChange, handleSpaceInInput } from "../../../app/common";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { AppDispatch, RootState } from "../../../app/store";
import { RoomHeatingInteruptionModelForUpdate, RoomModel, RoomModelForCreate, RoomModelForUpdate, WallHeightReq } from "../buildingStructureModels";
import { CreateRoom, GetAllWalls, UpdateHeatingInterruptionData, UpdateRoom, UpdateWallHeight, initialWallHeightReq, resetIsEditRoom, resetStatusValues } from "../buildingStructureSlice";
import { useDispatch } from "react-redux";
import { SpaceHeatingSurface } from "../../../app/enums";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import { selectBuildingTypes } from "../../../app/optionModels";

function RoomDetails() {
    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);
    const dispatch = useDispatch<AppDispatch>();
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
    const currentDate = formatDateToDateTimeString(new Date());

    const { t } = useTranslation();
    const [buildingType, setBuildingType] = useState("");
    const [openHeightPopup, setOpenHeightPopup] = useState<boolean>(false);
    const [openRoomHeatingPopup, setOpenRoomHeatingPopup] = useState<boolean>(false);
    const [checkInputs, setCheckInputs] = useState<boolean>(false);
    const [wallHeightReq, setWallHeightReq] = useState<WallHeightReq>(initialWallHeightReq);

    const navigate = useNavigate();
    let onFocusValue: string;

    const lengthRef = useRef<HTMLInputElement>(null);
    const widthRef = useRef<HTMLInputElement>(null);
    const heightRef = useRef<HTMLInputElement>(null);
    const areaRef = useRef<HTMLInputElement>(null);
    const volumeRef = useRef<HTMLInputElement>(null);

    const dropdownRef = useRef<CustomDropdownMethods>(null);

    const [roomHeating, setRoomHeating] = useState<RoomHeatingInteruptionModelForUpdate>();

    const [roomToEdit, setRoomToEdit] = useState<RoomModel>();

    const [roomHeatingDisabled, setRoomHeatingDisabled] = useState<boolean>(false);

    const [spaceHeatingSurface, setSpaceHeatingSurface] = useState<string | null>("");

    const [checkedHeatingInterruption, setCheckedHeatingInterruption] = useState<boolean>(buildingSliceData.buildingData.buildingData.hasHeatingInterruption)

    const [number, setNumber] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [length, setLength] = useState<string>("");
    const [height, setHeight] = useState<string>("");
    const [width, setWidth] = useState<string>("");
    const [area, setArea] = useState<string>("");
    const [volume, setVolume] = useState<string>("");
    const [ceilingThickness, setCeilingThickness] = useState<string>("");
    const [heightPosition, setHeightPosition] = useState<string>("");
    const [airExchangeRate, setAirExchangeRate] = useState<string>("");
    const [n50, setN50] = useState<string>("");
    const [temperature, setTemperature] = useState<string>("");
    const [heatingMethod, setHeatingMethod] = useState<SpaceHeatingSurface | null>();
    const [heatingInterruptionChanged, setHeatingInterruptionChanged] = useState<boolean>(false);

    let roomModelForCreate: RoomModelForCreate;
    let roomModelForUpdate: RoomModelForUpdate;

    const regEx: RegExp = /\.0+$/;

    function handleBackToRooms() {
        dispatch(resetIsEditRoom())
        navigate("/buildingStructure")
    }

    function handleAddRoom() {
        if (Number(height) > 5 && (spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setOpenHeightPopup(prevOpenHeightPopup => !prevOpenHeightPopup)
        }
        else {
            setCheckInputs(true);
            if (number !== "" && name !== "" && area !== "" && height !== "" && volume !== "" && airExchangeRate !== "" && temperature !== "" && buildingType !== "" && length !== "" && width !== "" && ceilingThickness !== "" && heightPosition !== "") {
                if (!roomToEdit) {
                    roomModelForCreate = {
                        zoneId: buildingStructureSliceData.buildingStructureData.areaData.zoneId,
                        spaceName: name,
                        spaceNumber: Number(number),
                        length: parseFloat(length),
                        width: parseFloat(width),
                        height: parseFloat(height),
                        temperature: parseFloat(temperature),
                        airExchangeRate: parseFloat(airExchangeRate),
                        n50Value: parseFloat(n50),
                        spaceType: buildingType,
                        spaceArea: parseFloat(area),
                        spaceVolume: parseFloat(volume),
                        spaceHeatingSurface: (parseFloat(height) >= 5 && !(spaceHeatingSurface === "" || spaceHeatingSurface === null) ? spaceHeatingSurface : null),
                        userId: localStorage.getItem("userId")!,
                        hasHeatingInterruption: true,
                        projectId: projectId,
                        dateCreatedOrModified: currentDate,
                        heightPosition: parseFloat(heightPosition),
                        ceilingThickness: parseFloat(ceilingThickness),
                    }
                    dispatch(CreateRoom(roomModelForCreate));
                }
                else {
                    roomModelForUpdate = {
                        spaceId: roomToEdit?.spaceId,
                        zoneId: roomToEdit?.zoneId,
                        spaceName: name,
                        spaceNumber: Number(number),
                        length: parseFloat(length),
                        width: parseFloat(width),
                        height: parseFloat(height),
                        temperature: parseFloat(temperature),
                        airExchangeRate: parseFloat(airExchangeRate),
                        n50Value: parseFloat(n50),
                        spaceType: buildingType,
                        spaceArea: parseFloat(area),
                        spaceVolume: parseFloat(volume),
                        spaceHeatingSurface: (parseFloat(height) >= 5 && !(spaceHeatingSurface === "" || spaceHeatingSurface === null) ? spaceHeatingSurface : null),
                        userId: localStorage.getItem("userId")!,
                        hasHeatingInterruption: checkedHeatingInterruption,
                        projectId: projectId,
                        dateCreatedOrModified: currentDate,
                        heightPosition: parseFloat(heightPosition),
                        ceilingThickness: parseFloat(ceilingThickness),
                    }
                    dispatch(UpdateWallHeight({ ...wallHeightReq, newSpaceHeight: Number(height) + Number(ceilingThickness), dateCreatedOrModified: currentDate }));
                    dispatch(UpdateRoom(roomModelForUpdate));
                    if (roomModelForUpdate.hasHeatingInterruption && roomHeating) {
                        dispatch(UpdateHeatingInterruptionData(roomHeating))
                    }
                }
                setCheckInputs(false);
            }
        }

    }

    function handleRoomHeatingDisabled() {
        if (roomToEdit) {
            if (buildingSliceData.buildingData.buildingData.hasHeatingInterruption) {
                setRoomHeatingDisabled(false);
            }
        } else {
            setRoomHeatingDisabled(true);
        }
    }

    function handleHeightPopup() {
        if (Number(height) > 5 && heatingMethod === undefined) {
            setOpenHeightPopup(prevOpenHeightPopup => !prevOpenHeightPopup)
        }
    }

    function handleRoomHeatingPopup() {
        setOpenRoomHeatingPopup(prevOpenRoomHeating => !prevOpenRoomHeating)
    }

    function handleSaveRow(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
    }

    function takeOnFocusValue() {
        let focusedElement = document.activeElement as HTMLInputElement
        onFocusValue = focusedElement.value;
    }

    function convertNumberToString(inputNumber: number, numberOfDecimals: number): string {
        let convertedNumber = inputNumber.toFixed(numberOfDecimals).toString().replace(regEx, '');
        return convertedNumber;
    }

    function handleCheckChange() {
        setCheckedHeatingInterruption(prevChecked => !prevChecked)
    }

    ///Calculations

    function areaByWidthLength(): number {
        const lengthNum = Number(length);
        const widthNum = Number(width);
        let areaNum: number = Number(area);

        areaNum = lengthNum * widthNum;

        return areaNum
    }

    function areaByVolumeHeight(): number {
        const volumeNum = Number(volume);
        const heightNum = Number(height);
        let areaNum = Number(area);

        areaNum = volumeNum / heightNum;

        return areaNum;
    }

    function volumeByAreaHeight(): number {
        const areaNum = Number(area);
        const heightNum = Number(height);
        let volumeNum: number = Number(volume);
        volumeNum = areaNum * heightNum;

        return volumeNum;
    }

    function heightByVolumeArea(): number {
        const areaNum = Number(area);
        const volumeNum = Number(volume);
        let heightNum: number = Number(height);
        heightNum = volumeNum / areaNum;

        handleHeightMoreThanValueOnCalculationChange(heightNum);

        return heightNum;
    }

    useEffect(() => {
        setWallHeightReq({ ...initialWallHeightReq, userId: localStorage.getItem("userId")!, projectId: projectId, spaceId: buildingStructureSliceData.buildingStructureData.roomData.spaceId, oldSpaceHeight: buildingStructureSliceData.buildingStructureData.roomData.roomHeight })
    }, [buildingStructureSliceData.buildingStructureData.roomData])

    useEffect(() => {
        if (length !== "" && width !== "" && height !== "") {
            setVolume(convertNumberToString(volumeByAreaHeight(), 2))
        }
    }, [area])

    ///Dimenstions handling
    function handleInputLengthWidth() {
        if (width !== "" && length !== "") {
            setArea(convertNumberToString(areaByWidthLength(), 2));
        }
    }

    function handleInputArea() {
        let lengthNum = Number(length);
        let widthNum = Number(width);
        let areaNum = Number(area)

        if (height !== "" && area !== "") {
            let voulumeToSet = convertNumberToString(volumeByAreaHeight(), 2);

            if (voulumeToSet !== volume) {
                setVolume(voulumeToSet);
            }
        }

        if (height === "" && area !== "" && volume !== "") {
            setHeight(convertNumberToString(heightByVolumeArea(), 1));
        }

        if (((areaNum !== widthNum * lengthNum) || (length === "" && width === "")) && area !== "") {
            lengthNum = Math.sqrt(areaNum);
            widthNum = Math.sqrt(areaNum);

            setWidth(convertNumberToString(widthNum, 1));
            setLength(convertNumberToString(lengthNum, 1));
        }
    }

    function handleInputHeight() {
        if (area !== "" && height !== "") {
            let volumeToSet = convertNumberToString(volumeByAreaHeight(), 1)

            if (volumeToSet !== volume && height !== onFocusValue) {
                setVolume(volumeToSet);
            }
        }

        if (volume !== "" && area === "" && height !== "") {
            let areaToSet = convertNumberToString(areaByVolumeHeight(), 1)

            if (areaToSet !== area && height !== onFocusValue) {
                setArea(areaToSet);
            }
        }
    }

    function handleInputVolume() {
        if (area !== "" && volume !== "") {
            let heightToSet = convertNumberToString(heightByVolumeArea(), 1)
            if (heightToSet !== height) {
                setHeight(heightToSet);
            }
        }

        if (area !== "" && height !== "" && volume === "") {
            let areaToSet = convertNumberToString(areaByVolumeHeight(), 2);

            if (areaToSet !== area && volume !== onFocusValue) {
                setArea(areaToSet);
            }
        }
    }

    function handleHeightMoreThanValueOnBlur() {
        if (Number(height) > 5 && (spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setOpenHeightPopup(true)
        } else if (Number(height) <= 5 && !(spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setSpaceHeatingSurface(null);
        }
    }

    function handleHeightMoreThanValueOnCalculationChange(height: number) {
        if (Number(height) > 5 && (spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setOpenHeightPopup(true)
        } else if (Number(height) <= 5 && !(spaceHeatingSurface === "" || spaceHeatingSurface === null)) {
            setSpaceHeatingSurface(null);
        }
    }

    useEffect(() => {
        setNumber("");
        setName("");
        setLength("");
        setHeight("");
        setWidth("");
        setArea("");
        setVolume("");
        setCeilingThickness("");
        setHeightPosition("");
        setAirExchangeRate("");
        setN50("");
        setTemperature("");
        if (!roomToEdit) {
            dropdownRef.current?.resetDropdown();
        }
        setHeatingInterruptionChanged(false);

    }, [buildingStructureSliceData.buildingStructureData.roomData])

    useEffect(() => {
        if (buildingStructureSliceData.isEditRoom) {
            setRoomToEdit(buildingStructureSliceData.buildingStructureData.roomData);
            if (buildingStructureSliceData.buildingStructureData.roomData.spaceId) {
                dispatch(GetAllWalls(buildingStructureSliceData.buildingStructureData.roomData.spaceId));
            }
        }
        setSpaceHeatingSurface(null);
    }, [buildingStructureSliceData.buildingStructureData.roomData])

    useEffect(() => {
        handleRoomHeatingDisabled()
        setCheckedHeatingInterruption(buildingSliceData.buildingData.buildingData.hasHeatingInterruption && roomToEdit?.hasHeatingInterruption ? true : false)

        if (roomToEdit) {
            setNumber(roomToEdit.spaceNumber?.toString());
            setName(roomToEdit.spaceName);
            setLength(roomToEdit.length?.toString());
            setHeight(roomToEdit.height?.toString());
            setWidth(roomToEdit.width?.toString());
            setArea(roomToEdit.area?.toString());
            setVolume(roomToEdit.volume?.toString());
            setCeilingThickness(roomToEdit.ceilingThickness.toString());
            setHeightPosition(roomToEdit.heightPosition.toString());
            setAirExchangeRate(roomToEdit.airExchangeRate?.toString());
            setN50(roomToEdit.n50Value?.toString());
            setTemperature(roomToEdit.temperature?.toString());
            setHeatingMethod(heatingMethod);
            setBuildingType(roomToEdit.spaceType);
            setSpaceHeatingSurface(roomToEdit.spaceHeatingSurface);
        }

        if (buildingSliceData.buildingData.buildingData.hasHeatingInterruption && roomToEdit?.hasHeatingInterruption) {
            setRoomHeating({
                zoneId: roomToEdit.zoneId,
                spaceId: roomToEdit.spaceId,
                userId: localStorage.getItem("userId")!,
                loweringPhase: roomToEdit.heatingInterruptionModel.loweringPhase,
                reheatingTime: roomToEdit.heatingInterruptionModel.reheatingTime,
                airExchangeRateDuringLoweringPhase_nabs: roomToEdit.heatingInterruptionModel.airExchangeRateDuringLoweringPhase_nabs,
                airExchangeRateDuringReheatingTime_nrh: roomToEdit.heatingInterruptionModel.airExchangeRateDuringReheatingTime_nrh,
                buildingMass: roomToEdit.heatingInterruptionModel.buildingMass,
                heatingInterruptionType: buildingSliceData.buildingData.buildingData.heatingInterruptionModel.heatingInterruptionType,
                effectiveBuildingMass_Cwirk: roomToEdit.heatingInterruptionModel.effectiveBuildingMass_Cwirk,
                temperatureDrop_trh: roomToEdit.heatingInterruptionModel.temperatureDrop_trh,
                reheatingFactor_RH: roomToEdit.heatingInterruptionModel.reheatingFactor_RH,
                hasHeatingInterruption: roomToEdit.hasHeatingInterruption,
                projectId: projectId
            })
        } else if (buildingSliceData.buildingData.buildingData.hasHeatingInterruption && !roomToEdit?.hasHeatingInterruption) {
            if (roomToEdit) {
                setRoomHeating({
                    zoneId: roomToEdit?.zoneId,
                    spaceId: roomToEdit?.spaceId,
                    userId: localStorage.getItem("userId")!,
                    loweringPhase: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.loweringPhase,
                    reheatingTime: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.reheatingTime,
                    airExchangeRateDuringLoweringPhase_nabs: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.airExchangeRateDuringLoweringPhase_nabs,
                    airExchangeRateDuringReheatingTime_nrh: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.airExchangeRateDuringReheatingTime_nrh,
                    buildingMass: buildingSliceData.buildingData.buildingData.buildingMass,
                    heatingInterruptionType: buildingSliceData.buildingData.buildingData.heatingInterruptionModel.heatingInterruptionType,
                    effectiveBuildingMass_Cwirk: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.effectiveBuildingMass_Cwirk,
                    temperatureDrop_trh: roomToEdit.heatingInterruptionModel.temperatureDrop_trh,
                    reheatingFactor_RH: buildingSliceData.buildingData.buildingData.heatingInterruptionModel?.reheatingFactor_RH,
                    hasHeatingInterruption: roomToEdit.hasHeatingInterruption,
                    projectId: projectId
                })
            }
        }
    }, [roomToEdit])

    useEffect(() => {
        if (buildingStructureSliceData.statusValues.statusCode === "") {
            return
        }
        if (buildingStructureSliceData.statusValues.statusCode === "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message));
            dispatch(resetStatusValues());
        }
        else if (buildingStructureSliceData.statusValues.statusCode !== "200" && buildingStructureSliceData.statusValues.showNotification) {
            notify(t(buildingStructureSliceData.statusValues.message), true);
            dispatch(resetStatusValues());
        }
    }, [buildingStructureSliceData.statusValues])

    useEffect(() => {
        if (Number(height) <= 5 && height !== "") {
            setSpaceHeatingSurface(null);
        }
    }, [height])

    return (
        <div className="flex flex-col bg-#343A47 rounded-md m-[30px] h-fit w-[calc(100vw-64px-63px)] sm:w-[700px] md:w-[900px] lg:w-[calc(100vw-64px-63px)] min-w-[700px] text-[#F5F5F5]">
            <div className="mb-[60px]">
                <div className="flex items-center h-[38px] border-b border-[#8D929B]">
                    <Button id="backToRoomsBtn" size="sm" onClick={handleBackToRooms} className="rounded-none flex w-[174px] justify-center items-center h-[100%] gap-[7px] text-[12px] font-thin text-[#F5F5F5]" variant="text">
                        <span>
                            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 1L1 5.5L5 10" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>{t("BackToRooms")}
                    </Button>
                    <div className="w-[1px] h-[30px] bg-[#8D929B]"></div>
                    {roomToEdit && <Typography color="inherit" variant="paragraph" className="text-[16px] font-thin ml-[30px]">{t("Room") + ":" + " " + `${roomToEdit.spaceCode}` + " " + `${roomToEdit.spaceName}`}</Typography>}
                </div>
                <Typography color="inherit" variant="h1" className="text-[16px] font-thin mt-[20px] mb-[20px] ml-[30px] uppercase">{t("AddRoomInformation")}</Typography>
                <div className="flex flex-row mx-[37px]">
                    <div className="w-[50%] pr-[54px] border-r border-[#8D929B] space-y-[15px]">
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Number") + ":"}</Typography>
                            <input id="inputNumber" autoComplete="off" type="text" placeholder="0" value={number} onChange={(e) => handleInputChange(e, setNumber, 0, 3)} className={`bg-#2E333E ${number === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                        <div className="flex items-center pb-[21px]">
                            <Typography className="w-[95px] min-w-[95px]">{t("Name") + ":"}</Typography>
                            <input id="inputName" autoComplete="off" type="text" placeholder="" value={name} onChange={(e) => handleSpaceInInput(e, setName)} className={`bg-#2E333E ${name === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Length") + ":"}</Typography>
                            <input id="inputLength" autoComplete="off" type="text" placeholder="0.0" ref={lengthRef} value={length} onChange={(e) => handleInputChange(e, setLength, 1)} onBlur={() => handleInputLengthWidth()} onFocus={takeOnFocusValue} className={`bg-#2E333E ${length === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="mr-[4px] ml-[12px]">m</Typography>
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Width") + ":"}</Typography>
                            <input id="inputWidth" autoComplete="off" type="text" placeholder="0.0" ref={widthRef} value={width} onChange={(e) => handleInputChange(e, setWidth, 1)} onBlur={() => handleInputLengthWidth()} onFocus={takeOnFocusValue} className={`bg-#2E333E ${width === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="mr-[4px] ml-[12px]">m</Typography>
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Area") + ":"}</Typography>
                            <input id="inputArea" autoComplete="off" type="text" placeholder="0.00" ref={areaRef} value={area} onChange={(e) => handleInputChange(e, setArea, 2)} onBlur={() => handleInputArea()} onFocus={takeOnFocusValue} className={`bg-#2E333E ${area === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="ml-[9px]">m<sup>2</sup></Typography>
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Height") + ":"}</Typography>
                            <input id="inputHeight" autoComplete="off" type="text" placeholder="0.0" value={height} onChange={(e) => { handleInputChange(e, setHeight, 1); }} ref={heightRef} onBlur={() => { handleInputHeight(); handleHeightMoreThanValueOnBlur(); }} onFocus={takeOnFocusValue} className={`bg-#2E333E ${height === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="mr-[4px] ml-[12px]">m</Typography>
                        </div>
                        <button className={`ml-[95px] ${Number(height) <= 5 ? "opacity-50" : ""}`} onClick={() => { setOpenHeightPopup(true) }} disabled={Number(height) <= 5 ? true : false}>Heating surface options...</button>
                        <div className="flex items-center">
                            <Typography className="w-[95px] min-w-[95px]">{t("Volume") + ":"}</Typography>
                            <input id="inputVolume" autoComplete="off" type="text" placeholder="0.00" ref={volumeRef} value={volume} onChange={(e) => handleInputChange(e, setVolume, 2)} onBlur={() => handleInputVolume()} onFocus={takeOnFocusValue} className={`bg-#2E333E ${volume === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="ml-[9px]">m<sup>3</sup></Typography>
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[200px] min-w-[95px]">{t("CeilingThickness") + ":"}</Typography>
                            <input id="ceilingThickness" autoComplete="off" type="text" placeholder="0.0" value={ceilingThickness} onChange={(e) => handleInputChange(e, setCeilingThickness, 1)} className={`bg-#2E333E ${ceilingThickness === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                    </div>
                    <div className="w-[50%] ml-[54px] space-y-[15px]">
                        <div className="flex items-center h-10">
                            <Typography className="w-[200px] min-w-[95px]">{t("HeightPosition") + ":"}</Typography>
                            <input id="heightPosition" autoComplete="off" type="text" placeholder="0.0" value={heightPosition} onChange={(e) => handleInputChange(e, setHeightPosition, 1)} className={`bg-#2E333E ${heightPosition === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[200px] min-w-[95px]">{t("AirExchangeRate") + ":"}</Typography>
                            <input id="inputAirExchangeRate" autoComplete="off" type="text" placeholder="0.0" value={airExchangeRate} onChange={(e) => handleInputChange(e, setAirExchangeRate, 1)} className={`bg-#2E333E ${airExchangeRate === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                        <div className="flex items-center pb-[24px] border-b border-[#8D929B]">
                            <Typography className="w-[200px] min-w-[95px]">n50:</Typography>
                            <input id="inputN50" autoComplete="off" type="text" placeholder="0.0" value={n50} onChange={(e) => handleInputChange(e, setN50, 1)} className={`bg-#2E333E ${n50 === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                        </div>
                        <div className="flex items-center pt-[10px]">
                            <Typography className="w-[200px] min-w-[90px]">{t("HeatedUnheated") + ":"}</Typography>
                            <CustomDropdown dropdownId="buildingTypesDropdown" isEmpty={checkInputs && buildingType === ""} ref={dropdownRef} options={selectBuildingTypes} previousSelectedOption={roomToEdit?.spaceType} placeholder={t("HeatedUnheated")} height={"h-10"} width={"w-full"} onSelectClick={setBuildingType} />
                        </div>
                        <div className="flex items-center">
                            <Typography className="w-[209px] min-w-[0px]">{t("Temperature") + ":"}</Typography>
                            <input id="inputTemperature" autoComplete="off" type="text" placeholder="0.0" value={temperature} onChange={(e) => handleInputChange(e, setTemperature, 1)} className={`bg-#2E333E ${temperature === "" && checkInputs ? "border border-[#EF5350]" : ""} placeholder:text-#545B6A h-10 pl-[12px] rounded-[5px] shadow-inner w-[100%] focus:outline-0`} />
                            <Typography className="ml-[9px]">&deg;C</Typography>
                        </div>
                        <div className="flex items-center pt-[5px]">
                            <Checkbox id="manuallyToggle" crossOrigin={""} ripple={false} checked={checkedHeatingInterruption} onChange={handleCheckChange} disabled={roomHeatingDisabled} className={`hover:before:opacity-0 checked:bg-transparent ${!roomHeatingDisabled ? "opacity-100" : "opacity-50"} border-#F5F5F5 checked:border-#F5F5F5`}
                                containerProps={{ className: "p-0" }} />
                            <button id="roomHeatingPopupBtn" className="flex items-center" disabled={roomHeatingDisabled || !checkedHeatingInterruption} onClick={handleRoomHeatingPopup}>
                                <svg className="ml-[25px] mr-[25px]" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity={`${checkedHeatingInterruption ? "1" : "0.5"}`}>
                                        <path d="M8 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V8C1 8.26522 1.10536 8.51957 1.29289 8.70711C1.48043 8.89464 1.73478 9 2 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V2C9 1.73478 8.89464 1.48043 8.70711 1.29289C8.51957 1.10536 8.26522 1 8 1ZM8 13H2C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14V20C1 20.2652 1.10536 20.5196 1.29289 20.7071C1.48043 20.8946 1.73478 21 2 21H8C8.26522 21 8.51957 20.8946 8.70711 20.7071C8.89464 20.5196 9 20.2652 9 20V14C9 13.7348 8.89464 13.4804 8.70711 13.2929C8.51957 13.1054 8.26522 13 8 13ZM20 1H14C13.7348 1 13.4804 1.10536 13.2929 1.29289C13.1054 1.48043 13 1.73478 13 2V8C13 8.26522 13.1054 8.51957 13.2929 8.70711C13.4804 8.89464 13.7348 9 14 9H20C20.2652 9 20.5196 8.89464 20.7071 8.70711C20.8946 8.51957 21 8.26522 21 8V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1Z" stroke="#F5F5F5" strokeWidth="1.5" strokeLinejoin="round" />
                                        <path d="M13 13H21M17 17H21M13 21H21" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </svg>
                                <Typography className={`text-[#f5f5f5] ${checkedHeatingInterruption ? "opacity-100" : "opacity-50"}`}>{t("RoomHeatingOperationData")}</Typography>
                            </button>
                        </div>
                        < Button id="AddRoomOrSaveChangesBtn" onClick={handleAddRoom} className="float-right w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                            {buildingStructureSliceData.isEditRoom ? t("SaveChanges") : t("AddRoom")}
                        </Button>
                    </div>
                </div>
            </div>
            <NotificationPopup />
            {roomToEdit && <RoomDetailsTable handleSave={handleSaveRow} />}
            <HeightPopup openHeightPopup={openHeightPopup} setOpenHeightPopup={setOpenHeightPopup} handleHeightPopup={handleHeightPopup} height={Number(height)} spaceHeatingSurface={spaceHeatingSurface} setSpaceHeatingSurface={setSpaceHeatingSurface} />
            <RoomHeatingPopup openRoomHeatingPopup={openRoomHeatingPopup} handleRoomHeatingPopup={handleRoomHeatingPopup} roomHeating={roomHeating} setRoomHeating={setRoomHeating} heatingInterruptionChanged={heatingInterruptionChanged} setHeatingInterruptionChanged={setHeatingInterruptionChanged} />
        </div>
    )
}

export default RoomDetails;