import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { apiAddress } from "../../app/apiModel";
import { ExcelReportModel, FileModel, ReportModel, ReportsData, ReportsSliceData } from "./reportsModels";

export const GetPdfReport = createAsyncThunk<ValueResponse<FileModel>, ReportModel, { rejectValue: RejectedValue }>(
    'ReportsSlicer/GetPdfReport',
    async (reportModel: ReportModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `GetPdfReport`, {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(reportModel)
            });
            if (!response.ok) {
                    return rejectWithValue(await HandleErrorsFromBack(response));
                };
            
            const dataResponse: ValueResponse<FileModel> = {
                value: (await response.json()) as FileModel,
                status: response.status.toString(),
                message: "The file is being downloaded"
            }
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        };
    }
);

export const GetExcelReport = createAsyncThunk<ValueResponse<FileModel>, ExcelReportModel, { rejectValue: RejectedValue }>(
    'ReportsSlicer/GetExcelReport',
    async (reportModel: ExcelReportModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `GetExcelReport`, {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(reportModel)
            });
            if (!response.ok) {
                    return rejectWithValue(await HandleErrorsFromBack(response));
                };
            const dataResponse: ValueResponse<FileModel> = {
                value: (await response.json()) as FileModel,
                status: response.status.toString(),
                message: "The file is being downloaded"
            };
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        };
    }
);

const initialFileData: FileModel = {
    fileName: "",
    document: ""
}

const initialReportsData: ReportsData = {
    file: initialFileData
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialReportsSliceData: ReportsSliceData = {
    reportsData: initialReportsData,
    statusValues: initialStatusValues,
}

export const ReportsSlice = createSlice({
    name: "ReportsSlicer",
    initialState: {
        reportsSliceData: initialReportsSliceData
    },
    reducers: {
        resetReportsSlice: (state) => {
            state.reportsSliceData.statusValues.statusCode = '';
            state.reportsSliceData.statusValues.isLoading = false;
            state.reportsSliceData.statusValues.message = '';
            state.reportsSliceData.statusValues.showNotification = false;
            state.reportsSliceData.reportsData = initialReportsData;
        },
        resetStatusCode: (state) => {
            state.reportsSliceData.statusValues.statusCode = '';
            state.reportsSliceData.statusValues.message = '';
            state.reportsSliceData.statusValues.showNotification = false;
        },
    },
    extraReducers(builder) {
        //GetPdfReport
        builder
            .addCase(GetPdfReport.fulfilled, (state, action) => {
                state.reportsSliceData.reportsData.file = handleFulfilled(state.reportsSliceData.statusValues, action.payload)
                state.reportsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetPdfReport.rejected, (state, action) => {
                handleRejected(state.reportsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.reportsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetPdfReport.pending, (state) => {
                handlePending(state.reportsSliceData.statusValues);
            });
        //GetExcelReport
        builder
            .addCase(GetExcelReport.fulfilled, (state, action) => {
                state.reportsSliceData.reportsData.file = handleFulfilled(state.reportsSliceData.statusValues, action.payload)
                state.reportsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetExcelReport.rejected, (state, action) => {
                handleRejected(state.reportsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.reportsSliceData.statusValues.showNotification = true;
            })
            .addCase(GetExcelReport.pending, (state) => {
                handlePending(state.reportsSliceData.statusValues);
            });
    }
})

export const { resetReportsSlice, resetStatusCode } = ReportsSlice.actions;
export default ReportsSlice.reducer;