import { Checkbox, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber, getEnumValueByKey, handleInputChange } from "../../../app/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { BuildingMassValues, HeatingInterruptionType } from "../../../app/enums";

interface HeatingInterruptionProps {
    isEdit: boolean,
    buildingMass: string,
    setHasAdditionalHeatingCapacityOnChange: (value: boolean) => void,
    setAcceptanceOnChange: (value: boolean) => void,
    setRoomByRoomOnChange: (value: boolean) => void,
    setGlobalOnChange: (value: boolean) => void,
    setTransmissionHeatLossCoefficientOnChange: (value: string) => void,
    setAverageInternalTemperatureOfTheBuildingOnChange: (value: string) => void,
    setEffectiveBuildingMassOnChange: (value: string) => void,
    setDurationOfThePhaseLoweringOnChange: (value: string) => void,
    setDurationOfThePhaseHeatingOnChange: (value: string) => void,
    setAirExchangeRateLoweringOnChange: (value: string) => void,
    setAirExchangeRateHeatingOnChange: (value: string) => void,
    setTemperatureDropLoweringOnChange: (value: string) => void,
}

function HeatingInterruption(props: HeatingInterruptionProps) {
    const { t } = useTranslation();
    const [heatedBuildingFloorSpace, setHeatedBuildingFloorSpace] = useState("");
    const [heatedNetBuildingVolume, setHeatedNetBuildingVolume] = useState("");
    const [hasAdditionalHeatingCapacity, setHasAdditionalHeatingCapacity] = useState(false);
    const [acceptance, setAcceptance] = useState(false);
    const [roomByRoom, setRoomByRoom] = useState(false);
    const [global, setGlobal] = useState(false);
    const [transmissionHeatLossCoefficient, setTransmissionHeatLossCoefficient] = useState("");
    const [averageInternalTemperatureOfTheBuilding, setAverageInternalTemperatureOfTheBuilding] = useState("");
    const [effectiveBuildingMass, setEffectiveBuildingMass] = useState("");
    const [durationOfThePhaseLowering, setDurationOfThePhaseLowering] = useState("");
    const [durationOfThePhaseHeating, setDurationOfThePhaseHeating] = useState("");
    const [airExchangeRateLowering, setAirExchangeRateLowering] = useState("");
    const [airExchangeRateLoweringError, setAirExchangeRateLoweringError] = useState(false);
    const [airExchangeRateHeating, setAirExchangeRateHeating] = useState("");
    const [airExchangeRateHeatingError, setAirExchangeRateHeatingError] = useState(false);
    const [temperatureDropLowering, setTemperatureDropLowering] = useState("");
    const [temperatureDropHeating, setTemperatureDropHeating] = useState("");
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData.buildingData.buildingData);

    const onAdditionalHeatingCapacityChange = () => {
        setHasAdditionalHeatingCapacity(!hasAdditionalHeatingCapacity);
        setAcceptance(!hasAdditionalHeatingCapacity);
        setRoomByRoom(false);
        setGlobal(false);
        setTransmissionHeatLossCoefficient("");
        setAverageInternalTemperatureOfTheBuilding("");
        setEffectiveBuildingMass("");
        if (!hasAdditionalHeatingCapacity) {
            setDurationOfThePhaseLowering("8");
            setDurationOfThePhaseHeating("2");
            setAirExchangeRateLowering("0.1");
            setAirExchangeRateHeating("0.1");
            setTemperatureDropLowering(buildingSliceData?.heatingInterruptionModel?.temperatureDrop_trh?.toString());
            onDurationOfThePhaseLoweringChange("8");
            onDurationOfThePhaseHeatingChange("2");
            onAirExchangeRateLoweringChange("0.1");
            onAirExchangeRateHeatingChange("0.1");
            onTemperatureDropLoweringChange(buildingSliceData?.heatingInterruptionModel?.temperatureDrop_trh?.toString());
        }
        else {
            setDurationOfThePhaseLowering("");
            setDurationOfThePhaseHeating("");
            setAirExchangeRateLowering("");
            setAirExchangeRateHeating("");
            setTemperatureDropLowering("");
            onDurationOfThePhaseLoweringChange("");
            onDurationOfThePhaseHeatingChange("");
            onAirExchangeRateLoweringChange("");
            onAirExchangeRateHeatingChange("");
            onTemperatureDropLoweringChange("");
            setAirExchangeRateHeatingError(false);
            setAirExchangeRateLoweringError(false);
        }

        if (hasAdditionalHeatingCapacity) {
            props.setHasAdditionalHeatingCapacityOnChange(false);
            props.setAcceptanceOnChange(false);
        }
        else {
            props.setHasAdditionalHeatingCapacityOnChange(true);
            props.setAcceptanceOnChange(true);
        }
        props.setRoomByRoomOnChange(false);
        props.setGlobalOnChange(false);
    }

    const onAcceptanceChange = () => {
        setAcceptance(true);
        setRoomByRoom(false);
        setGlobal(false);
        props.setAcceptanceOnChange(true);
        props.setRoomByRoomOnChange(false);
        props.setGlobalOnChange(false);
        props.setTransmissionHeatLossCoefficientOnChange("");
        props.setAverageInternalTemperatureOfTheBuildingOnChange("");
        setTransmissionHeatLossCoefficient("");
        setAverageInternalTemperatureOfTheBuilding("");
        props.setEffectiveBuildingMassOnChange("");
        setEffectiveBuildingMass("");
        setTemperatureDropLowering(buildingSliceData?.heatingInterruptionModel?.temperatureDrop_trh?.toString());
        onTemperatureDropLoweringChange(buildingSliceData?.heatingInterruptionModel?.temperatureDrop_trh?.toString());
    }

    const onRoomByRoomChange = () => {
        setAcceptance(false);
        setRoomByRoom(true);
        setGlobal(false);
        props.setAcceptanceOnChange(false);
        props.setRoomByRoomOnChange(true);
        props.setGlobalOnChange(false);
        props.setTransmissionHeatLossCoefficientOnChange("");
        props.setAverageInternalTemperatureOfTheBuildingOnChange("");
        setTransmissionHeatLossCoefficient("");
        setAverageInternalTemperatureOfTheBuilding("");
        props.setEffectiveBuildingMassOnChange("");
        setEffectiveBuildingMass("");
        setTemperatureDropLowering("");
    }

    const onGlobalChange = () => {
        setAcceptance(false);
        setRoomByRoom(false);
        setGlobal(true);
        props.setAcceptanceOnChange(false);
        props.setRoomByRoomOnChange(false);
        props.setGlobalOnChange(true);
        setTransmissionHeatLossCoefficient(formatNumber(buildingSliceData.heatingInterruptionModel?.heatTransferCoefficient, 3));
        setAverageInternalTemperatureOfTheBuilding(buildingSliceData?.heatingInterruptionModel?.averageTemperature?.toString());
        setEffectiveBuildingMass(getEnumValueByKey(BuildingMassValues, props.buildingMass));
        setTemperatureDropLowering("");
        props.setTransmissionHeatLossCoefficientOnChange(buildingSliceData?.heatingInterruptionModel?.heatTransferCoefficient?.toString());
        props.setAverageInternalTemperatureOfTheBuildingOnChange(buildingSliceData?.heatingInterruptionModel?.averageTemperature?.toString());
        props.setEffectiveBuildingMassOnChange(buildingSliceData?.heatingInterruptionModel?.effectiveBuildingMass_Cwirk?.toString());
    }

    const onTransmissionHeatLossCoefficientChange = () => {
        props.setTransmissionHeatLossCoefficientOnChange(transmissionHeatLossCoefficient);
    }

    const onAverageInternalTemperatureOfTheBuildingChange = () => {
        props.setAverageInternalTemperatureOfTheBuildingOnChange(averageInternalTemperatureOfTheBuilding);
    }

    const onEffectiveBuildingMassChange = () => {
        props.setEffectiveBuildingMassOnChange(effectiveBuildingMass);
    }

    const onDurationOfThePhaseLoweringChange = (value: string) => {
        props.setDurationOfThePhaseLoweringOnChange(value);
    }

    const onDurationOfThePhaseHeatingChange = (value: string) => {
        props.setDurationOfThePhaseHeatingOnChange(value);
    }

    const onAirExchangeRateLoweringChange = (value: string) => {
        let minValue = 0.1;
        let maxValue = 0.5;
        if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
            setAirExchangeRateLoweringError(false);
            props.setAirExchangeRateLoweringOnChange(value);
        }
        else {
            if (acceptance) {
                setAirExchangeRateLoweringError(true);
                props.setAirExchangeRateLoweringOnChange("");
            }
        }
    }

    const onAirExchangeRateHeatingChange = (value: string) => {
        let minValue = 0.1;
        let maxValue = 0.5;
        if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
            setAirExchangeRateHeatingError(false);
            props.setAirExchangeRateHeatingOnChange(value);
        }
        else {
            if (acceptance) {
                setAirExchangeRateHeatingError(true);
                props.setAirExchangeRateHeatingOnChange("");
            }
        }
    }

    const onTemperatureDropLoweringChange = (value: string) => {
        props.setTemperatureDropLoweringOnChange(value);
    }

    useEffect(() => {
        if (props.buildingMass !== "" && global) {
            let mass = getEnumValueByKey(BuildingMassValues, props.buildingMass);
            setEffectiveBuildingMass(mass);
            props.setEffectiveBuildingMassOnChange(mass);
        }
    }, [props.buildingMass])

    useEffect(() => {
        if (props.isEdit && buildingSliceData.heatingInterruptionModel !== null) {
            setHeatedBuildingFloorSpace(buildingSliceData.heatingInterruptionModel.heatedBuildingFloorSpace?.toString());
            setHeatedNetBuildingVolume(buildingSliceData.heatingInterruptionModel.heatedNetBuildingVolume?.toString());
            setHeatedBuildingFloorSpace(buildingSliceData.heatingInterruptionModel.heatedBuildingFloorSpace?.toString());
            setHeatedNetBuildingVolume(buildingSliceData.heatingInterruptionModel.heatedNetBuildingVolume?.toString());
            setHasAdditionalHeatingCapacity(buildingSliceData.hasHeatingInterruption);
            props.setHasAdditionalHeatingCapacityOnChange(buildingSliceData.hasHeatingInterruption);
            if (buildingSliceData.hasHeatingInterruption && buildingSliceData.heatingInterruptionModel.heatingInterruptionType === HeatingInterruptionType.MANUALLY) {
                setAcceptance(true);
                props.setAcceptanceOnChange(true);
                props.setEffectiveBuildingMassOnChange("");
                setEffectiveBuildingMass("");
                setTemperatureDropLowering(buildingSliceData.heatingInterruptionModel.temperatureDrop_trh?.toString());
                onTemperatureDropLoweringChange(buildingSliceData.heatingInterruptionModel.temperatureDrop_trh?.toString());
            }
            else if (buildingSliceData.hasHeatingInterruption && buildingSliceData.heatingInterruptionModel.heatingInterruptionType === HeatingInterruptionType.ROOMBYROOM) {
                setRoomByRoom(true);
                props.setRoomByRoomOnChange(true);
                props.setEffectiveBuildingMassOnChange("");
                setEffectiveBuildingMass("");
            }
            else if (buildingSliceData.hasHeatingInterruption && buildingSliceData.heatingInterruptionModel.heatingInterruptionType === HeatingInterruptionType.GLOBALLY) {
                setGlobal(true);
                props.setGlobalOnChange(true);
                setTransmissionHeatLossCoefficient(formatNumber(buildingSliceData.heatingInterruptionModel?.heatTransferCoefficient, 3));
                setAverageInternalTemperatureOfTheBuilding(buildingSliceData.heatingInterruptionModel.averageTemperature?.toString());
                setEffectiveBuildingMass(buildingSliceData.heatingInterruptionModel.effectiveBuildingMass_Cwirk?.toString());
            }
            setDurationOfThePhaseLowering(buildingSliceData.heatingInterruptionModel.loweringPhase?.toString());
            setDurationOfThePhaseHeating(buildingSliceData.heatingInterruptionModel.reheatingTime?.toString());
            setAirExchangeRateLowering(buildingSliceData.heatingInterruptionModel.airExchangeRateDuringLoweringPhase_nabs?.toString());
            setAirExchangeRateHeating(buildingSliceData.heatingInterruptionModel.airExchangeRateDuringReheatingTime_nrh?.toString());
            setTemperatureDropHeating(buildingSliceData.heatingInterruptionModel.reheatingFactor_RH?.toString());
            onTransmissionHeatLossCoefficientChange();
            onAverageInternalTemperatureOfTheBuildingChange();
            onEffectiveBuildingMassChange();
            onDurationOfThePhaseLoweringChange(buildingSliceData.heatingInterruptionModel.loweringPhase?.toString());
            onDurationOfThePhaseHeatingChange(buildingSliceData.heatingInterruptionModel.reheatingTime?.toString());
            onAirExchangeRateLoweringChange(buildingSliceData.heatingInterruptionModel.airExchangeRateDuringLoweringPhase_nabs?.toString());
            onAirExchangeRateHeatingChange(buildingSliceData.heatingInterruptionModel.airExchangeRateDuringReheatingTime_nrh?.toString());
        }
    }, [buildingSliceData])

    return (
        <div className="bg-transparent w-full h-fit flex items-center justify-start py-[30px] pl-[30px] pr-[10px] lg:pr-[30px]">
            <div className="space-y-[15px] text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] leading-6 w-full">
                <p className="text-#8D929B">{t("UsableSpace")}</p>
                <div className="flex items-center pr-[20px] lg:pr-[40px] text-#8D929B">
                    <p>{t("HeatedBuildingFloorSpace") + ":"}</p>
                    <input id="heatedBuildingFloorSpace" disabled={true} autoComplete="off" type="text" placeholder={"0.00"} value={heatedBuildingFloorSpace} onChange={(e) => handleInputChange(e, setHeatedBuildingFloorSpace, 2)} className="bg-#2E333E placeholder:text-#545B6A w-[330px] md:w-[553px] lg:w-[728px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m²"}</p>
                </div>
                <div className="flex items-center pr-[20px] lg:pr-[40px] text-#8D929B">
                    <p>{t("HeatedNetBuildingVolume") + ":"}</p>
                    <input id="heatedNetBuildingVolume" disabled={true} autoComplete="off" type="text" placeholder={"0.00"} value={heatedNetBuildingVolume} onChange={(e) => handleInputChange(e, setHeatedNetBuildingVolume, 2)} className="bg-#2E333E placeholder:text-#545B6A w-[330px] md:w-[553px] lg:w-[728px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"m³"}</p>
                </div>
                <div className="py-[10px]">
                    <div className="w-full h-[1px] bg-#272C35"></div>
                </div>
                <div>
                    <div className="ml-[30px]">
                        <Checkbox id="additionalHeatingCapacityToggle" disabled={true} crossOrigin={""} ripple={false} onChange={onAdditionalHeatingCapacityChange} className="hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={hasAdditionalHeatingCapacity}
                            label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal" >{t("AgreementOfTheAdditionalHeatingCapacity")}</Typography>}
                            containerProps={{ className: "-ml-2.5" }} />
                    </div>
                    <Checkbox id="acceptanceToggle" crossOrigin={""} ripple={false} disabled={!hasAdditionalHeatingCapacity} onChange={onAcceptanceChange} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={acceptance}
                        label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal" >{t("AcceptanceOfTheInternalTemperatureDropAfterTheLoweringPhase")}</Typography>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="roomByRoomToggle" crossOrigin={""} ripple={false} disabled={!hasAdditionalHeatingCapacity} onChange={onRoomByRoomChange} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={roomByRoom}
                        label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal" >{t("RoomByRoomCalculationOfTheInternalTemperatureDropAfterTheSinkingPhase")}</Typography>}
                        containerProps={{ className: "-ml-2.5" }} />
                    <Checkbox id="globalToggle" crossOrigin={""} ripple={false} disabled={!hasAdditionalHeatingCapacity} onChange={onGlobalChange} className="rounded-full hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5" checked={global}
                        label={<Typography variant="small" color="gray" className="flex items-center text-#F5F5F5 text-[16px] md:text-[18px] lg:text-[20px] font-normal" >{t("GlobalCalculationOfTheInternalTemperatureDrop")}</Typography>}
                        containerProps={{ className: "-ml-2.5" }} />
                </div>
                <div className="py-[10px]">
                    <div className="w-full h-[1px] bg-#272C35"></div>
                </div>
                <div className={`flex items-center pr-[20px] lg:pr-[40px] ${global ? "" : "text-#8D929B"}`}>
                    <p>{t("TransmissionHeatLossCoefficient") + ":"}</p>
                    <input id="transmissionHeatLossCoefficient" disabled={!global} autoComplete="off" type="text" placeholder={"0.000"} value={transmissionHeatLossCoefficient} onChange={(e) => handleInputChange(e, setTransmissionHeatLossCoefficient, 3)} onBlur={onTransmissionHeatLossCoefficientChange} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[270px] md:w-[412px] lg:w-[596px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"W/K"}</p>
                </div>
                <div className={`flex items-center pr-[20px] lg:pr-[40px] ${global ? "" : "text-#8D929B"}`}>
                    <p>{t("AverageInternalTemperatureOfTheBuilding") + ":"}</p>
                    <input id="averageInternalTemperatureOfTheBuilding" disabled={!global} autoComplete="off" type="text" placeholder={"0.0"} value={averageInternalTemperatureOfTheBuilding} onChange={(e) => handleInputChange(e, setAverageInternalTemperatureOfTheBuilding, 1)} onBlur={onAverageInternalTemperatureOfTheBuildingChange} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[366px] md:w-[426px] lg:w-[612px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"°C"}</p>
                </div>
                <div className={`flex items-center pr-[20px] lg:pr-[40px] ${global ? "" : "text-#8D929B"}`}>
                    <p>{t("EffectiveBuildingMass") + ":"}</p>
                    <input id="effectiveBuildingMaterial" disabled={!global} autoComplete="off" type="text" placeholder={"0"} value={effectiveBuildingMass} onChange={(e) => handleInputChange(e, setEffectiveBuildingMass, 0)} onBlur={onEffectiveBuildingMassChange} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[212px] md:w-[380px] lg:w-[560px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto" />
                    <p className="pl-[8px] md:pl-[12px]">{"Wh/m³K"}</p>
                </div>
                <div className="py-[10px]">
                    <div className="w-full h-[1px] bg-#272C35"></div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 items-center pr-[20px] lg:pr-0">
                    <div className="space-y-5">
                        <p className="h-10 text-#8D929B">{t("DurationAndAirChange")}</p>
                        <p className={`h-10 ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>{t("DurationOfThePhase")}:</p>
                        <p className={`h-10 ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>{t("AirExchangeRate")}:</p>
                        <p className={`h-10 ${acceptance ? "" : "text-#8D929B"}`}>{t("TemperatureDrop")}:</p>
                    </div>
                    <div className="space-y-5">
                        <p className="h-10 text-#8D929B">{t("LoweringPhase")}</p>
                        <div className={`flex flex-row items-center ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>
                            <input id="durationOfThePhaseLowering" disabled={!hasAdditionalHeatingCapacity} autoComplete="off" type="text" placeholder={"0.0"} value={durationOfThePhaseLowering} onChange={(e) => handleInputChange(e, setDurationOfThePhaseLowering, 1)} onBlur={(e) => onDurationOfThePhaseLoweringChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner" />
                            <p className="pl-[8px] md:pl-[12px]">{"h"}</p>
                        </div>
                        <div className={`flex flex-row items-center ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>
                            <input id="airExchangeRateLowering" disabled={!hasAdditionalHeatingCapacity} autoComplete="off" type="text" placeholder={"0.0 (0.1>0.5)"} value={airExchangeRateLowering} onChange={(e) => handleInputChange(e, setAirExchangeRateLowering, 1)} onBlur={(e) => onAirExchangeRateLoweringChange(e.target.value)} className={`${airExchangeRateLoweringError ? "border-[1px] border-#EF5350" : ""} bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner`} />
                            <p className="pl-[8px] md:pl-[12px]">{"1/h"}</p>
                        </div>
                        <div className={`flex flex-row items-center ${acceptance ? "" : "text-#8D929B"}`}>
                            <input id="temperatureDropLowering" disabled={!hasAdditionalHeatingCapacity || roomByRoom || global} autoComplete="off" type="text" placeholder={"0.0"} value={temperatureDropLowering} onChange={(e) => handleInputChange(e, setTemperatureDropLowering, 1)} onBlur={(e) => onTemperatureDropLoweringChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner" />
                            <p className="pl-[8px] md:pl-[12px]">{"K"}</p>
                        </div>
                    </div>
                    <div className="pt-10 space-y-5 md:hidden">
                        <p className="h-10"></p>
                        <p className="h-10">{t("DurationOfThePhase")}:</p>
                        <p className="h-10">{t("AirExchangeRate")}:</p>
                        <p className="h-10">{t("TemperatureDrop")}:</p>
                    </div>
                    <div className="pt-10 md:pt-0 space-y-5">
                        <p className="h-10 text-#8D929B">{t("HeatingTime")}</p>
                        <div className={`flex flex-row items-center ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>
                            <input id="durationOfThePhaseHeating" disabled={!hasAdditionalHeatingCapacity} autoComplete="off" type="text" placeholder={"0.0"} value={durationOfThePhaseHeating} onChange={(e) => handleInputChange(e, setDurationOfThePhaseHeating, 1)} onBlur={(e) => onDurationOfThePhaseHeatingChange(e.target.value)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner" />
                            <p className="pl-[8px] md:pl-[12px]">{"h"}</p>
                        </div>
                        <div className={`flex flex-row items-center ${hasAdditionalHeatingCapacity ? "" : "text-#8D929B"}`}>
                            <input id="airExchangeRateHeating" disabled={!hasAdditionalHeatingCapacity} autoComplete="off" type="text" placeholder={"0.0 (0.1>0.5)"} value={airExchangeRateHeating} onChange={(e) => handleInputChange(e, setAirExchangeRateHeating, 1)} onBlur={(e) => onAirExchangeRateHeatingChange(e.target.value)} className={`${airExchangeRateHeatingError ? "border-[1px] border-#EF5350" : ""} bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner`} />
                            <p className="pl-[8px] md:pl-[12px]">{"1/h"}</p>
                        </div>
                        <div className="flex flex-row items-center">
                            <input id="temperatureDropHeating" disabled={true} autoComplete="off" type="text" placeholder={"0.0"} value={temperatureDropHeating} onChange={(e) => handleInputChange(e, setTemperatureDropHeating, 1)} className="bg-#2E333E placeholder:text-#545B6A outline-none w-[238px] h-10 pl-[12px] rounded-[5px] shadow-inner" />
                            <p className="pl-[8px] md:pl-[12px] text-#8D929B">{"W/m²"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeatingInterruption;