import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CalculationModel, CalculationResponse, CalculationsData, CalculationsSliceData } from "./calculationsModels";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { apiAddress } from "../../app/apiModel";

export const Calculate = createAsyncThunk<ValueResponse<CalculationResponse>, CalculationModel, { rejectValue: RejectedValue }>(
    'CalculationsSlicer/Calculate',
    async (calculationModel: CalculationModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set("Content-Type", "application/json");
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + "Calculate", {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(calculationModel),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            };
            const dataResponse: ValueResponse<CalculationResponse> = {
                value: (await response.json()) as CalculationResponse,
                status: response.status.toString(),
                message: "Calculation is done",
            };

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to create project';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

const initialCalculationData: CalculationModel = {
    projectId: "",
    userId: "",
    dateCalculated: "",
}

const initialCalculationResponse: CalculationResponse = {
    isCalculated: false,
}

const initialCalculationsData: CalculationsData = {
    calculation: initialCalculationData
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialCalculationsSliceData: CalculationsSliceData = {
    calculationsData: initialCalculationsData,
    calculationResponse: initialCalculationResponse,
    statusValues: initialStatusValues
}

export const CalculationsSlice = createSlice({
    name: "CalculationsSlicer",
    initialState: {
        calculationsSliceData: initialCalculationsSliceData
    },
    reducers: {
        resetCalculationsSlice: (state) => {
            state.calculationsSliceData.statusValues.statusCode = '';
            state.calculationsSliceData.statusValues.isLoading = false;
            state.calculationsSliceData.statusValues.message = '';
            state.calculationsSliceData.statusValues.showNotification = false;
            state.calculationsSliceData.calculationResponse.isCalculated = false;
            state.calculationsSliceData.calculationsData = initialCalculationsData;
        },
        resetCalculationsStatusCode: (state) => {
            state.calculationsSliceData.statusValues.statusCode = '';
            state.calculationsSliceData.statusValues.message = '';
            state.calculationsSliceData.statusValues.showNotification = false;
        },
    },
    extraReducers(builder) {
        //Calculate
        builder
            .addCase(Calculate.fulfilled, (state, action) => {
                state.calculationsSliceData.calculationResponse = handleFulfilled(state.calculationsSliceData.statusValues, action.payload)
                state.calculationsSliceData.calculationResponse.isCalculated = true;
                state.calculationsSliceData.statusValues.showNotification = true;
            })
            .addCase(Calculate.rejected, (state, action) => {
                handleRejected(state.calculationsSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.calculationsSliceData.statusValues.showNotification = true;
            })
            .addCase(Calculate.pending, (state) => {
                handlePending(state.calculationsSliceData.statusValues);
            });
    }
})

export const { resetCalculationsSlice, resetCalculationsStatusCode } = CalculationsSlice.actions;
export default CalculationsSlice.reducer;