import { configureStore, ThunkAction, Action, combineReducers, Middleware } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ProjectSlice from '../elements/Project/projectSlice';
import MaterialSlice from "../elements/Components/Material/materialSlice";
import BuildingSlice from '../elements/Building/buildingSlice';
import ComponentsSlice from "../elements/Components/Component/componentsSlice";
import BuildingStructureSlice from '../elements/BuildingStructure/buildingStructureSlice';
import AuthSlice from "../elements/Auth/AuthSlice";
import CalculationsSlice from "../elements/Dashboard/calculationsSlice";
import ReportsSlice from "../elements/Dashboard/reportsSlice";
import SettingsSlice from '../elements/Settings/settingsSlice';
import { tokenExpirationMiddleware } from '../tokenExpirationMiddleware';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: []
};

const reducer = combineReducers({
    ProjectSlice: ProjectSlice,
    BuildingSlice: BuildingSlice,
    MaterialSlice: MaterialSlice,
    ComponentsSlice: ComponentsSlice,
    BuildingStructureSlice: BuildingStructureSlice,
    AuthSlice: AuthSlice,
    CalculationsSlice: CalculationsSlice,
    ReportsSlice: ReportsSlice,
    SettingsSlice: SettingsSlice,
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware()

        const sliceMiddleware: Middleware = (middlewareAPI) => (next) => (action) => {
            if (action.type.endsWith('pending')) {
                tokenExpirationMiddleware(middlewareAPI)(next)(action);
            }
            else {
                return next(action);
            }
        };

        return defaultMiddleware.concat(sliceMiddleware);
    },
    devTools: false
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
