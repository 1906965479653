import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, RemoveItemFromArray, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { AreaModel, AreaModelForCreate, AreaModelForDelete, AreaModelForUpdate, AreaRoomModel, BuildingModel, BuildingStructureSliceData, BuildingStructureSliceDataModel, FloorModel, FloorModelForCreate, FloorModelForDelete, FloorModelForUpdate, OpeningInWallModel, OpeningInWallModelForCreate, OpeningInWallModelForDelete, OpeningInWallModelForUpdate, RoomHeatingInteruptionModelForUpdate, RoomModel, RoomModelForCreate, RoomModelForDelete, RoomModelForUpdate, RoomsByHeatingModel, WallHeightReq, WallModel, WallModelForCreate, WallModelForDelete, WallModelForUpdate, initialSpaceHeatingInteruptionModel } from "./buildingStructureModels";
import { HeatingInterruptionModel } from "../Building/buildingModels";


//#region Floors

export const GetAllFloors = createAsyncThunk<ValueResponse<FloorModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllFloors',
    async (buildingId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllFloors?buildingId=' + buildingId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                    return rejectWithValue(await HandleErrorsFromBack(response));
                }
            const dataResponse: ValueResponse<FloorModel[]> = {
                value: (await response.json()) as FloorModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        } 
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetFloor = createAsyncThunk<ValueResponse<FloorModel>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetFloor',
    async (floorId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetFloor?floorId=' + floorId, {
                method: "GET",
                headers: requestHeaders,
            })
        if (!response.ok) {
                    return rejectWithValue(await HandleErrorsFromBack(response));
                }
        const dataResponse: ValueResponse<FloorModel> = {
            value: (await response.json()) as FloorModel,
            status: response.status.toString(),
            message: response.statusText
        }

        return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateFloor = createAsyncThunk<ValueResponse<FloorModel>, FloorModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/CreateFloor',
    async (floorModelForCreate: FloorModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateFloor', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(floorModelForCreate),
        })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<FloorModel> = {
                value: (await response.json()) as FloorModel,
                status: response.status.toString(),
                message: "Floor created successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateFloor = createAsyncThunk<ValueResponse<FloorModel>, FloorModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateFloor',
    async (floorModelForUpdate: FloorModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateFloor', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(floorModelForUpdate),
        })
            if (!response.ok) {
                    return rejectWithValue(await HandleErrorsFromBack(response));
                }
            const dataResponse: ValueResponse<FloorModel> = {
                value: (await response.json()) as FloorModel,
                status: response.status.toString(),
                message: "Floor updated successfully",
            }

            return dataResponse
        }
        
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteFloor = createAsyncThunk<ValueResponse<FloorModel>, FloorModelForDelete, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/DeleteFloor',
    async (floorModelForDelete: FloorModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'RemoveFloor', {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(floorModelForDelete),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<FloorModel> = {
                value: (await response.json()) as FloorModel,
                status: response.status.toString(),
                message: "Floor deleted successfully",
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

//#endregion

//#region Areas

export const GetAllAreas = createAsyncThunk<ValueResponse<AreaModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllAreas',
    async (floorId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllZones?floorId=' + floorId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaModel[]> = {
                value: (await response.json()) as AreaModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetArea = createAsyncThunk<ValueResponse<AreaModel>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetArea',
    async (zoneId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetZone?zoneId=' + zoneId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaModel> = {
                value: (await response.json()) as AreaModel,
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateArea = createAsyncThunk<ValueResponse<AreaModel>, AreaModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/CreateArea',
    async (areaModelForCreate: AreaModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateZone', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(areaModelForCreate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaModel> = {
                value: (await response.json()) as AreaModel,
                status: response.status.toString(),
                message: "Area created successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateArea = createAsyncThunk<ValueResponse<AreaModel>, AreaModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateArea',
    async (areaModelForCreate: AreaModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateZone', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(areaModelForCreate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaModel> = {
                value: (await response.json()) as AreaModel,
                status: response.status.toString(),
                message: "Area updated successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteArea = createAsyncThunk<ValueResponse<AreaModel>, AreaModelForDelete, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/DeletaArea',
    async (areaModelForDelete: AreaModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'DeleteZone', {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(areaModelForDelete)
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaModel> = {
                value: (await response.json()) as AreaModel,
                status: response.status.toString(),
                message: "Area deleted successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

//#endregion

//#region Rooms

export const GetAllRoomsInArea = createAsyncThunk<ValueResponse<RoomModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllRoomsInArea',
    async (areaId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllSpacesInZone?zoneId=' + areaId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel[]> = {
                value: (await response.json()) as RoomModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllRoomsOnFloor = createAsyncThunk<ValueResponse<RoomModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllRoomsOnFloor',
    async (floorId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllSpacesOnFloor?floorId=' + floorId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel[]> = {
                value: (await response.json()) as RoomModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllRoomsByHeating = createAsyncThunk<ValueResponse<RoomModel[]>, RoomsByHeatingModel, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllRoomsByHeating',
    async (roomsByHeating: RoomsByHeatingModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + `GetAllHeatedOrUnheatedSpaces?projectId=${roomsByHeating.projectId}&spaceId=${roomsByHeating.spaceId}&spaceType=${roomsByHeating.spaceType}`, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel[]> = {
                value: (await response.json()) as RoomModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetRoom = createAsyncThunk<ValueResponse<RoomModel>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetRoom',
    async (spaceId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetSpace?spaceId=' + spaceId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel> = {
                value: (await response.json()) as RoomModel,
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateRoom = createAsyncThunk<ValueResponse<RoomModel>, RoomModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/CreateRoom',
    async (roomModelForCreate: RoomModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateSpace', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(roomModelForCreate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel> = {
                value: (await response.json()) as RoomModel,
                status: response.status.toString(),
                message: "Room created successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateRoom = createAsyncThunk<ValueResponse<RoomModel>, RoomModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateRoom',
    async (roomModelForUpdate: RoomModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateSpace', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(roomModelForUpdate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<RoomModel> = {
                value: (await response.json()) as RoomModel,
                status: response.status.toString(),
                message: "Room updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteRoom = createAsyncThunk<ValueResponse<AreaRoomModel>, RoomModelForDelete, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/DeleteRoom',
    async (roomModelForDelete: RoomModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'DeleteSpace?spaceId=' + roomModelForDelete.spaceId + '&userId=' + roomModelForDelete.userId, {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(roomModelForDelete),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<AreaRoomModel> = {
                value: (await response.json()) as AreaRoomModel,
                status: response.status.toString(),
                message: "Room deleted successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateHeatingInterruptionData = createAsyncThunk<ValueResponse<HeatingInterruptionModel>, RoomHeatingInteruptionModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateHeatingInterruptionData',
    async (roomHeatingInteruptionModelForUpdate: RoomHeatingInteruptionModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateHeatingInterruptionData', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(roomHeatingInteruptionModelForUpdate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<HeatingInterruptionModel> = {
                value: (await response.json()) as HeatingInterruptionModel,
                status: response.status.toString(),
                message: "Heating interruption data updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

//#endregion

//#region Walls

export const GetAllWalls = createAsyncThunk<ValueResponse<WallModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllWalls',
    async (roomId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllWalls?spaceId=' + roomId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<WallModel[]> = {
                value: (await response.json()) as WallModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateWall = createAsyncThunk<ValueResponse<WallModel>, WallModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/CreateWall',
    async (wallModelForCreate: WallModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateWall', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(wallModelForCreate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<WallModel> = {
                value: (await response.json()) as WallModel,
                status: response.status.toString(),
                message: "Wall created successfully",
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateWall = createAsyncThunk<ValueResponse<WallModel>, WallModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateWall',
    async (wallModelForUpdate: WallModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateWall', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(wallModelForUpdate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<WallModel> = {
                value: (await response.json()) as WallModel,
                status: response.status.toString(),
                message: "Wall updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteWall = createAsyncThunk<ValueResponse<string>, WallModelForDelete, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/DeleteWall',
    async (wallModelForDelete: WallModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteWall?pairId=${wallModelForDelete.pairId}&Id=${wallModelForDelete.userId}`, {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(wallModelForDelete),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Wall deleted successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

//#endregion

//#region OpeningsInWall

export const GetAllOpeningsInWall = createAsyncThunk<ValueResponse<OpeningInWallModel[]>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetAllOpeningsInWall',
    async (wallId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetAllOpeningsInWall?wallId=' + wallId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<OpeningInWallModel[]> = {
                value: (await response.json()) as OpeningInWallModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetOpeningInWall = createAsyncThunk<ValueResponse<OpeningInWallModel>, string, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/GetOpeningInWall',
    async (openingInWallId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            const response = await fetch(apiAddress + 'GetOpeningInWall?openingInWallId=' + openingInWallId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<OpeningInWallModel> = {
                value: (await response.json()) as OpeningInWallModel,
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateOpeningInWall = createAsyncThunk<ValueResponse<OpeningInWallModel>, OpeningInWallModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/CreateOpeningInWall',
    async (openingInWallModelForCreate: OpeningInWallModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateOpeningInWall', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(openingInWallModelForCreate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<OpeningInWallModel> = {
                value: (await response.json()) as OpeningInWallModel,
                status: response.status.toString(),
                message: "Opening in wall created successfully",
            }
            return dataResponse
        }
         catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateOpeningInWall = createAsyncThunk<ValueResponse<OpeningInWallModel>, OpeningInWallModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateOpeningInWall',
    async (openingInWallModelForUpdate: OpeningInWallModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateOpeningInWall', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(openingInWallModelForUpdate),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<OpeningInWallModel> = {
                value: (await response.json()) as OpeningInWallModel,
                status: response.status.toString(),
                message: "Opening in wall updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteOpeningInWall = createAsyncThunk<ValueResponse<string>, OpeningInWallModelForDelete, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/DeleteOpeningInWall',
    async (openingInWallModelForDelete: OpeningInWallModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `DeleteOpeningInWall`, {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(openingInWallModelForDelete),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Opening in wall deleted successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateWallHeight = createAsyncThunk<ValueResponse<WallModel>, WallHeightReq, { rejectValue: RejectedValue }>(
    'BuildingStructureSlice/UpdateWallHeight',
    async (wallHeightReq: WallHeightReq, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `UpdateWallHeight`, {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(wallHeightReq),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<WallModel> = {
                value: (await response.json()) as WallModel,
                status: response.status.toString(),
                message: "Walls have been updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

//#endregion

export const initialFloorData: FloorModel = {
    floorId: "",
    floorName: "",
    floorLevel: "",
    level: 1,
    height: 1,
    heightPosition: 1,
    zones: [],
    spaces: [],
};

export const initialAllFloorsData: FloorModel[] = [];

export const initialAreaData: AreaModel = {
    floorId: "",
    zoneId: "",
    zoneNumber: "",
    zoneName: "",
    spaces: [],
};

export const initialAllAreasData: AreaModel[] = [];

const initialAreaRoomData: AreaRoomModel = {
    spaceId: "",
    zoneId: "",
    floorId: "",
    spaceCode: "",
    name: "",
    temperature: 0,
    area: 0,
    transmissionLoss: 0,
    ventilationLoss: 0,
    standardHeatLoss: 0,
    heatingInterruptionLoss: 0,
    totalLoss: 0,
    lossesBySquareMeter: 0,
};

export const initialAllAreaRoomsData: AreaRoomModel[] = [];

export const initialRoomData: RoomModel = {
    spaceId: "",
    spaceCode: "",
    zoneId: "",
    spaceName: "",
    spaceNumber: 0,
    length: 0,
    width: 0,
    height: 0,
    area: 0,
    volume: 0,
    n50Value: 0,
    temperature: 0,
    roomHeight: 0,
    airExchangeRate: 0,
    spaceType: "",
    spaceHeatingSurface: "",
    hasHeatingInterruption: false,
    heatingInterruptionModel: initialSpaceHeatingInteruptionModel,
    walls: [],
    ceilingThickness: 0,
    heightPosition: 0
};

export const initialAllRoomsData: RoomModel[] = [];

const initialBuildingData: BuildingModel = {
    buildingId: "",
    buildingName: "",
    length: 0,
    width: 0,
    buildingArea: 0,
    buildingPerimeter: 0,
    bottomPlateThickness: 0,
    parameterB: 0,
    annualAverageTemperature: 0,
    outsideTemperature: 0,
    geolocationName: "",
    geolocationZipCode: "",
    elevation: 0,
    z: 0,
    groundWaterDepth: 0,
    thermalBridgeValue: 0,
    hasHeatingInterruption: true,
    buildingCoverage: "",
    airOpeningTightness: "",
    buildingType: "",
    groundType: "",
    buildingMass: "",
    thermalBridge: "",
};

export const initialHeatingInteruptionData: RoomHeatingInteruptionModelForUpdate = {
    zoneId: "",
    spaceId: "",
    userId: "",
    loweringPhase: 0,
    reheatingTime: 0,
    airExchangeRateDuringLoweringPhase_nabs: 0,
    airExchangeRateDuringReheatingTime_nrh: 0,
    buildingMass: "",
    effectiveBuildingMass_Cwirk: 0,
    temperatureDrop_trh: 0,
    reheatingFactor_RH: 0,
    heatingInterruptionType: "",
    hasHeatingInterruption: false,
    projectId: ""
}

const initialWallData: WallModel = {
    wallId: "",
    pairId: "",
    componentId: "",
    componentCode: "",
    name: "",
    orientation: "",
    environmentType: "",
    length: 0,
    widthHeight: 0,
    area: 0,
    usefulArea: 0,
    temperature: 0,
    bu: 0,
    thermalBridge: 0,
    thermalTransmittance: 0,
    correctedUValue: 0,
    heatTransferCoefficient: 0,
    transmissionLoss: 0,
    dateCreated: "",
    openings: [],
    nextSpaceId: "",
    unheatedSpaceProperty: "",
    correctionFactorForTheGround: ""
};

const initialAllWallsData: WallModel[] = [];

const initialOpeningInWall: OpeningInWallModel = {
    openingInWallId: "",
    openingInWallPairId: "",
    wallId: "",
    openingId: "",
    name: "",
    openingCode: "",
    thermalTransmittance: 0,
    area: 0,
    orientation: "",
    environmentType: "",
    width: 0,
    height: 0,
    temperature: 0,
    bu: 0,
    thermalBridge: 0,
    correctedUValue: 0,
    heatTransferCoefficient: 0,
    transmissionLoss: 0,
    number: 0
};

const initialAllOpeningsInWallData: OpeningInWallModel[] = [];

const initialBuildingStructureData: BuildingStructureSliceDataModel = {
    floorData: initialFloorData,
    allFloorsData: initialAllFloorsData,
    areaData: initialAreaData,
    allAreasData: initialAllAreasData,
    areaRoomData: initialAreaRoomData,
    allAreaRoomsData: initialAllAreaRoomsData,
    roomData: initialRoomData,
    allRoomsDataInArea: initialAllRoomsData,
    allRoomsDataOnFloor: initialAllRoomsData,
    buildingData: initialBuildingData,
    wallData: initialWallData,
    allWallsData: initialAllWallsData,
    openingInWallData: initialOpeningInWall,
    allOpeningsInWall: initialAllOpeningsInWallData,
    activeFloorId: "",
    allRoomsByHeatingData: initialAllRoomsData,

}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialBuildingStructureSliceData: BuildingStructureSliceData = {
    buildingStructureData: initialBuildingStructureData,
    statusValues: initialStatusValues,
    isEditRoom: false
}

export const initialWallHeightReq: WallHeightReq = {
    projectId: '',
    userId: '',
    spaceId: '',
    newSpaceHeight: 0,
    oldSpaceHeight: 0,
    dateCreatedOrModified: '',
}

export const BuildingStructureSlice = createSlice({
    name: "BuildingStructureSlice",
    initialState: {
        buildingStructureSlice: initialBuildingStructureSliceData
    },
    reducers: {
        setFloor: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.floorData = action.payload;
        },
        setArea: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.areaData = action.payload;
        },
        setAreaRoom: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.areaRoomData = action.payload;
        },
        setRoom: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.roomData = action.payload;
        },
        setWall: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.wallData = action.payload;
        },
        setOpeningInWall: (state, action) => {
            state.buildingStructureSlice.buildingStructureData.openingInWallData = action.payload;
        },
        resetFloor: (state) => {
            state.buildingStructureSlice.buildingStructureData.floorData = initialFloorData;
        },
        resetArea: (state) => {
            state.buildingStructureSlice.buildingStructureData.areaData = initialAreaData;
        },
        resetAreaRoom: (state) => {
            state.buildingStructureSlice.buildingStructureData.areaRoomData = initialAreaRoomData;
        },
        resetRoom: (state) => {
            state.buildingStructureSlice.buildingStructureData.roomData = initialRoomData;
        },
        resetWall: (state) => {
            state.buildingStructureSlice.buildingStructureData.wallData = initialWallData;
        },
        resetWalls: (state) => {
            state.buildingStructureSlice.buildingStructureData.allWallsData = initialAllWallsData;
        },
        resetOpeningInWall: (state) => {
            state.buildingStructureSlice.buildingStructureData.openingInWallData = initialOpeningInWall;
        },
        resetBuildingStructureSlice: (state) => {
            state.buildingStructureSlice.statusValues.statusCode = '';
            state.buildingStructureSlice.statusValues.isLoading = false;
            state.buildingStructureSlice.statusValues.message = '';
            state.buildingStructureSlice.buildingStructureData = initialBuildingStructureData;
        },
        resetStatusValues: (state) => {
            state.buildingStructureSlice.statusValues.statusCode = '';
            state.buildingStructureSlice.statusValues.isLoading = false;
            state.buildingStructureSlice.statusValues.showNotification = false;
        },
        resetIsEditRoom: (state) => {
            state.buildingStructureSlice.isEditRoom = false;
        },
        setStatusCodeToNone: (state) => {
            state.buildingStructureSlice.statusValues.statusCode = 'none';
        },
    },
    extraReducers(builder) {

        //FLOORS
        //Create floor
        builder
            .addCase(CreateFloor.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.floorData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateFloor.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateFloor.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update floor
        builder
            .addCase(UpdateFloor.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.floorData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateFloor.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateFloor.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //DeleteFloor
        builder
            .addCase(DeleteFloor.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.floorData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteFloor.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteFloor.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //GetAllFloors
        builder
            .addCase(GetAllFloors.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allFloorsData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllFloors.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllFloors.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get Floor
        builder
            .addCase(GetFloor.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.floorData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetFloor.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetFloor.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //AREAS
        //Create area
        builder
            .addCase(CreateArea.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.areaData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateArea.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateArea.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update area
        builder
            .addCase(UpdateArea.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.areaData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateArea.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateArea.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Delete area - to be implemented
        builder
            .addCase(DeleteArea.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.areaData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteArea.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteArea.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get All Areas
        builder
            .addCase(GetAllAreas.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allAreasData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllAreas.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllAreas.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get Area
        builder
            .addCase(GetArea.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.areaData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetArea.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetArea.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //ROOMS
        //Create room
        builder
            .addCase(CreateRoom.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.roomData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateRoom.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateRoom.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update room
        builder
            .addCase(UpdateRoom.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.roomData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateRoom.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateRoom.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update Heating Interruption Data
        builder
            .addCase(UpdateHeatingInterruptionData.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.roomData.heatingInterruptionModel = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                //state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateHeatingInterruptionData.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateHeatingInterruptionData.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Delete room
        builder
            .addCase(DeleteRoom.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.areaRoomData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteRoom.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteRoom.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get all rooms in area
        builder
            .addCase(GetAllRoomsInArea.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allRoomsDataInArea = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllRoomsInArea.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllRoomsInArea.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get all rooms of floor
        builder
            .addCase(GetAllRoomsOnFloor.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allRoomsDataOnFloor = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllRoomsOnFloor.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllRoomsOnFloor.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get all rooms by heating
        builder
            .addCase(GetAllRoomsByHeating.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allRoomsByHeatingData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllRoomsByHeating.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllRoomsByHeating.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get room
        builder
            .addCase(GetRoom.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.roomData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.isEditRoom = true;
            })
            .addCase(GetRoom.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetRoom.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });

        //WALLS
        //Create Wall
        builder
            .addCase(CreateWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.wallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update Wall
        builder
            .addCase(UpdateWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.wallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Delete Wall
        builder
            .addCase(DeleteWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.wallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;

                let wallToRemove: WallModel | undefined = state.buildingStructureSlice.buildingStructureData.allWallsData.find(wall => wall.pairId === action.payload.value)
                if (wallToRemove !== undefined)
                    state.buildingStructureSlice.buildingStructureData.allWallsData = RemoveItemFromArray(state.buildingStructureSlice.buildingStructureData.allWallsData, wallToRemove)
            })
            .addCase(DeleteWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get All Walls
        builder
            .addCase(GetAllWalls.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allWallsData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllWalls.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllWalls.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Opening in Walls
        //Get All Walls
        builder
            .addCase(GetAllOpeningsInWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allOpeningsInWall = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetAllOpeningsInWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetAllOpeningsInWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Get All Walls
        builder
            .addCase(GetOpeningInWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.openingInWallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(GetOpeningInWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(GetOpeningInWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Create Opening in Wall
        builder
            .addCase(CreateOpeningInWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.openingInWallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateOpeningInWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(CreateOpeningInWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update Opening in Wall
        builder
            .addCase(UpdateOpeningInWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.openingInWallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateOpeningInWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(UpdateOpeningInWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Delete Opening in Wall
        builder
            .addCase(DeleteOpeningInWall.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.openingInWallData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteOpeningInWall.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingStructureSlice.statusValues.showNotification = true;
            })
            .addCase(DeleteOpeningInWall.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
        //Update Wall Heights
        builder
            .addCase(UpdateWallHeight.fulfilled, (state, action) => {
                state.buildingStructureSlice.buildingStructureData.allWallsData = handleFulfilled(state.buildingStructureSlice.statusValues, action.payload);
            })
            .addCase(UpdateWallHeight.rejected, (state, action) => {
                handleRejected(state.buildingStructureSlice.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
            })
            .addCase(UpdateWallHeight.pending, (state) => {
                handlePending(state.buildingStructureSlice.statusValues);
            });
    }
});

export const { setFloor, setArea, setAreaRoom, setOpeningInWall, setRoom, setWall, resetArea, resetFloor, resetAreaRoom, resetRoom, resetOpeningInWall, resetWall, resetWalls, resetBuildingStructureSlice, resetStatusValues, setStatusCodeToNone, resetIsEditRoom } = BuildingStructureSlice.actions;
export default BuildingStructureSlice.reducer;