import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { CalculationToolsAccessToken } from '../app/common';
import { loginRequest } from '../authConfig';
import { AppDispatch } from '../app/store';
import { getTokenFromSSO, setErrorMessage } from '../elements/Auth/AuthSlice';

const defaulthError = 'The app is trying to access a service (CentralServices-WebShopBackend)that your organization lacks a service principal for. Contact your IT Admin to review the configuration of your service subscriptions or consent to the application in order to create the required Service Principal.'

const SignInButton: React.FC = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const dispatch = useDispatch<AppDispatch>();
    const setSliceForLogInData = async () => {
        dispatch(getTokenFromSSO())
    };
    const setErrorMessageForSSO = async (errorMessage: any) => {
        dispatch(setErrorMessage(errorMessage))
    }

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).then(() => {
                getAccessToken();
            }).catch(e => {
                setErrorMessageForSSO(e)
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).then(() => {
                getAccessToken();
            }).catch(e => {
                setErrorMessageForSSO(e)
                console.error(e);
            });
        }
    };

    const getAccessToken = async () => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            try {
                const response = await instance.acquireTokenSilent(request);
                if (!response.accessToken) {
                    setErrorMessageForSSO(defaulthError)
                }
                localStorage.setItem(CalculationToolsAccessToken, response.accessToken);
                setSliceForLogInData();
                return response.accessToken;
            } catch (error) {
                // Handle error, you might need to use acquireTokenPopup or acquireTokenRedirect
                console.error(error);
                setErrorMessageForSSO(error)
                return null;
            }
        }
        return null;
    };

    return (
        <div className="flex place-content-center mt-[25px] cursor-pointer" onClick={() => handleLogin("popup")}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 2H11.4737V11.4737H2V2ZM11.4737 22H2V12.5263H11.4737V22ZM22 2V11.4737H12.5263V2H22ZM22 22H12.5263V12.5263H22V22Z" fill="#FF9800" />
            </svg>
            <p className="text-#FF9800 pl-2">{t("LogInWithMicrosoft")}</p>
        </div>
    );
};

export default SignInButton;
