import { useTranslation } from "react-i18next";
import { ProjectModel } from "../projectModels";
import ProjectsComponent from "./projectsComponent";
import { useEffect, useState } from "react";
import { GetAllProjects, GetNumberOfProjects, resetStatusValues } from "../projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { LoadingPage } from "../../../UIComponents/loading";
import { resetArea, resetFloor } from "../../BuildingStructure/buildingStructureSlice";

function ProjectsPage() {
    const { t } = useTranslation();
    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const userId = localStorage.getItem("userId")!;
    const dispatch = useDispatch<AppDispatch>();
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const firstPage = 1
    const [filteredList, setFilteredList] = useState<boolean>(false);

    useEffect(() => {
        dispatch(resetStatusValues());
        dispatch(resetArea());
        dispatch(resetFloor());
        dispatch(GetAllProjects({ userId: userId!, page: firstPage.toString() }));
        dispatch(GetNumberOfProjects(userId));
    }, [])

    useEffect(() => {
        setProjects(projectSliceData.projectData.allProjectsData);
    }, [projectSliceData.projectData.allProjectsData])

    useEffect(() => {
        if (projectSliceData.statusValues.statusCode === "") {
            return
        }
        if (projectSliceData.statusValues.statusCode === "200" && projectSliceData.statusValues.showNotification) {
            dispatch(GetAllProjects({ userId: userId!, page: firstPage.toString() }));
        }
    }, [projectSliceData.statusValues])

    return (
        <div className="w-screenWithoutSidebar">
            {projects.length > 0 || (projects.length === 0 && filteredList) ? <ProjectsComponent projects={projects} setFilteredList={setFilteredList} /> :
                <div className="w-[610px] sm:w-screenWithoutSidebar mt-[38px]">
                    <p className="text-center text-#F5F5F5 text-xl">{t("EmptyProjectList")}</p>
                </div>}
            {projectSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(projectSliceData.statusValues.isLoading)}</div>}
        </div>
    );
}

export default ProjectsPage;
