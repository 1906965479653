import { useTranslation } from "react-i18next";
import ComponentWithFixedValues from "./componentWithFixedValues";
import ComponentWithLayeredStructure from "./componentWithLayeredStructure";
import Opening from "./opening";
import { useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Tooltip } from "@material-tailwind/react";
import ComponentRow from "../ComponentRow/componentRow";
import OpeningRow from "../ComponentRow/openingRow";
import { ComponentModel, OpeningModel } from "../componentsModels";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { GetAllComponentsWithFixedValues, GetAllComponentsWithLayeredStructure, GetAllOpenings, resetComponentsSlice } from "../componentsSlice";
import { NotificationPopup } from "../../../../UIComponents/notification";
import { resetArea, resetFloor } from "../../../BuildingStructure/buildingStructureSlice";

function ComponentsPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [activeTab, setActivetab] = useState("componentsWithLayeredStructure");
    const [openComponentWithLayeredStructure, setOpenComponentWithLayeredStructure] = useState(false)
    const [openComponentWithFixedValues, setOpenComponentWithFixedValues] = useState(false)
    const [openOpenings, setOpenOpenings] = useState(false)
    const componentsSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);
    const [componentsWithLayeredStructure, setComponentsWithLayeredStructure] = useState<ComponentModel[]>([])
    const [componentsWithFixedValues, setComponentsWithFixedValues] = useState<ComponentModel[]>([])
    const [openings, setOpenings] = useState<OpeningModel[]>([])
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    useEffect(() => {
        dispatch(resetArea());
        dispatch(resetFloor());
        dispatch(resetComponentsSlice());
        dispatch(GetAllComponentsWithLayeredStructure(projectId));
        dispatch(GetAllComponentsWithFixedValues(projectId));
        dispatch(GetAllOpenings(projectId))
    }, [])

    useEffect(() => {
        setComponentsWithLayeredStructure(componentsSliceData.componentsData.allComponentsWithLayeredStructureData)
    }, [componentsSliceData.componentsData.allComponentsWithLayeredStructureData])

    useEffect(() => {
        setComponentsWithFixedValues(componentsSliceData.componentsData.allComponentsWithFixedValuesData)
    }, [componentsSliceData.componentsData.allComponentsWithFixedValuesData])

    useEffect(() => {
        setOpenings(componentsSliceData.openingsData.allOpeningsData)
    }, [componentsSliceData.openingsData.allOpeningsData])

    const data = [
        {
            label: t("ComponentsWithLayeredStructure"),
            value: "componentsWithLayeredStructure",
            desc: <ComponentWithLayeredStructure />,
        },
        {
            label: t("ComponentsWithFixedValues"),
            value: "componentsWithFixedValues",
            desc: <ComponentWithFixedValues />,
        },
        {
            label: t("Openings"),
            value: "openings",
            desc: <Opening />,
        },
    ];

    return (
        <div>
            <div className="bg-#343A47 w-[570px] sm:w-[calc(100vw-70px-20px)] md:w-[calc(100vw-70px-60px)] h-fit sm:min-h-[calc(100vh-110px)] md:min-h-[calc(100vh-150px)] m-[10px] md:m-[30px] rounded-[10px] font-inter text-#F5F5F5 tracking-wide overflow-hidden">
                <Tabs value={activeTab} className="w-[570px] sm:w-[calc(100vw-70px-20px)] md:w-[calc(100vw-70px-60px)]">
                    <TabsHeader className="bg-#3B4250 h-fit rounded-t-[10px] rounded-b-[0px]" indicatorProps={{ className: "bg-transparent shadow-none", }}>
                        <div className="flex flex-wrap pr-[30px]">
                            {data.map(({ label, value }, index) => (
                                <div key={value} className="h-[40px] flex flex-row justify-center items-center">
                                    <Tab id={value + index} key={index} value={value} className={`w-auto px-[30px] text-#F5F5F5 text-[20px] leading-6 ${activeTab === value ? "font-semibold" : "font-light opacity-[80%]"}`} onClick={() => setActivetab(value)}>{label}</Tab>
                                    {index < data.length - 1 ?
                                        <div className="w-[2px] h-[35px] bg-#272C35"></div> : <></>
                                    }
                                </div>))}
                        </div>
                    </TabsHeader>

                    <TabsBody className="overflow-visible">
                        {data.map(({ value, desc }, index) => (
                            <TabPanel key={index} value={value} className="p-0">{desc}</TabPanel>))}
                    </TabsBody>
                </Tabs>
                <div className="h-px bg-#F5F5F5 md:mx-[30px] mt-[25px] md:mt-[50px]" />

                <Accordion open={openComponentWithLayeredStructure} className="px-[15px] md:px-[40px] mt-[20px] z-10">
                    <AccordionHeader onClick={() => setOpenComponentWithLayeredStructure(!openComponentWithLayeredStructure)} className="w-fit border-none outline-none p-0 cursor-pointer">
                        <p className="text-#F5F5F5 text-lg xl:text-xl font-normal mr-[15px]">{t("ComponentsWithLayeredStructure")}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" transform={openComponentWithLayeredStructure ? "rotate(180)" : ""}>
                            <path d="M19 9L12 15L5 9" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </AccordionHeader>

                    <AccordionBody>
                        {/* Header */}
                        <div className="bg-#414959 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 text-base font-medium text-#F5F5F5 pl-[35px] pr-[15px] py-[7px] border-b border-b-#F5F5F5">
                            <p className="my-auto">{t("Code")}</p>
                            <p className="my-auto">{t("Name")}</p>
                            <p className="my-auto">{t("Type")}</p>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("RseFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("Rse")}</p></Tooltip>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("RsiFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("Rsi")}</p></Tooltip>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("UFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("U")}</p></Tooltip>
                            <div className="col-span-2" />
                            <p className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800">{t("SeeMaterials")}</p>
                            <p className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800">{t("Edit")}</p>
                            <p className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800 ml-[26px]">{t("SaveToTemplate")}</p>
                            <p className="hidden lg:block m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800">{t("Delete")}</p>
                        </div>
                        {/* Rows */}
                        <div className="max-h-[315px] overflow-auto">
                            {componentsWithLayeredStructure.map(component =>
                                <ComponentRow key={component.componentId} component={component} componentId={component.componentId} componentCode={component.componentCode} componentName={component.componentName} componentType={component.componentType} externalSurfaceResistance={component.externalSurfaceResistance} internalSurfaceResistance={component.internalSurfaceResistance} thermalTransmittance={component.thermalTransmittance} materials={component.materials} />
                            )}
                        </div>
                    </AccordionBody>
                </Accordion>
                <Accordion open={openComponentWithFixedValues} className="px-[15px] md:px-[40px] mt-[20px]">
                    <AccordionHeader onClick={() => setOpenComponentWithFixedValues(!openComponentWithFixedValues)} className="w-fit border-none outline-none p-0 cursor-pointer">
                        <p className="text-#F5F5F5 text-lg xl:text-xl font-normal mr-[15px]">{t("ComponentsWithFixedValues")}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" transform={openComponentWithFixedValues ? "rotate(180)" : ""}>
                            <path d="M19 9L12 15L5 9" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </AccordionHeader>

                    <AccordionBody>
                        {/* Header */}
                        <div className="bg-#414959 text-#F5F5F5 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-12 text-base font-medium pl-[35px] pr-[15px] py-[7px] border-b border-b-#F5F5F5">
                            <p className="">{t("Code")}</p>
                            <p className="my-auto">{t("Name")}</p>
                            <p className="my-auto">{t("Type")}</p>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("RseFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("Rse")}</p></Tooltip>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("RsiFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("Rsi")}</p></Tooltip>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("UFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("U")}</p></Tooltip>
                            <div className="col-span-3" />
                            <p className="hidden lg:block m-auto">{t("Edit")}</p>
                            <p className="hidden lg:block m-auto">{t("SaveToTemplate")}</p>
                            <p className="hidden lg:block m-auto">{t("Delete")}</p>
                        </div>
                        {/* Rows */}
                        <div className="max-h-[315px] overflow-auto">
                            {componentsWithFixedValues.map(component =>
                                <ComponentRow key={component.componentId} component={component} componentId={component.componentId} componentCode={component.componentCode} componentName={component.componentName} componentType={component.componentType} externalSurfaceResistance={component.externalSurfaceResistance} internalSurfaceResistance={component.internalSurfaceResistance} thermalTransmittance={component.thermalTransmittance} materials={undefined} />
                            )}
                        </div>
                    </AccordionBody>
                </Accordion>
                <Accordion open={openOpenings} className="px-[15px] md:px-[40px] mt-[20px] mb-[30px]">
                    <AccordionHeader onClick={() => setOpenOpenings(!openOpenings)} className="w-fit border-none outline-none p-0 cursor-pointer">
                        <p className="text-#F5F5F5 text-lg xl:text-xl font-normal mr-[15px]">{t("Openings")}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" transform={openOpenings ? "rotate(180)" : ""}>
                            <path d="M19 9L12 15L5 9" stroke="#F5F5F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </AccordionHeader>

                    <AccordionBody>
                        {/* Header */}
                        <div className="bg-#414959 grid text-#F5F5F5 lg:grid-cols-12 text-base font-medium pl-[35px] pr-[15px] py-[7px] border-b border-b-#F5F5F5">
                            <p className="">{t("Code")}</p>
                            <p className="my-auto">{t("Name")}</p>
                            <p className="my-auto">{t("Type")}</p>
                            <Tooltip className="rounded-[8px] bg-#2E333E text-#FFFFFF text-[12px] leading-[16px] font-[600] px-2 py-1 m-0" content={t("UFull")} placement="top"><p className="hidden md:block text-right my-auto">{t("U")}</p></Tooltip>
                            <div className="col-span-5" />
                            <p className="hidden lg:block m-auto">{t("Edit")}</p>
                            <p className="hidden lg:block m-auto">{t("SaveToTemplate")}</p>
                            <p className="hidden lg:block m-auto">{t("Delete")}</p>
                        </div>
                        {/* Rows */}
                        <div className="max-h-[315px] overflow-auto">
                            {openings.map(opening =>
                                <OpeningRow key={opening.openingId} openingId={opening.openingId} name={opening.openingName} openingCode={opening.openingCode} openingType={opening.openingType} thermalTransmittance={opening.thermalTransmittance} />)}
                        </div>
                    </AccordionBody>
                </Accordion>
            </div >
            <NotificationPopup />
        </div>
    );
}

export default ComponentsPage;
