import { useEffect, useState } from "react";
import { WallModel, WallModelForDelete } from "../buildingStructureModels";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { DeleteWall } from "../buildingStructureSlice";
import { formatDateToDateTimeString, formatNumber, getEnumValueByKey } from "../../../app/common";
import { EnvironmentType } from "../../../app/enums";
import NewRow from "./newRow";
import OpeningInWall from "./openingInWallRow";
import DeleteCard from "../../../UIComponents/deleteCard";
import { useTranslation } from "react-i18next";

interface WallInRoomProps {
    spaceId: string
    wall: WallModel
}

function WallInRoom({ spaceId, wall }: WallInRoomProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isAddOpeningRowOpen, setIsAddOpeningRowOpen] = useState(false)
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const buildingStructureSliceData = useSelector((state: RootState) => state.BuildingStructureSlice.buildingStructureSlice);
    const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);

    function onEditWallClick() {
        setIsEdit(!isEdit);
    }

    function handleDelete() {
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisWall"));
        setIsDelete(true);
    }

    function deleteWall() {
        const currentDate = formatDateToDateTimeString(new Date());

        const wallForDelete: WallModelForDelete = {
            userId: localStorage.getItem("userId")!,
            pairId: wall.pairId,
            projectId: projectId,
            dateDeleted: currentDate
        }
        dispatch(DeleteWall(wallForDelete));
        setIsDelete(false);
    }

    useEffect(() => {
        setIsEdit(false)
        setIsAddOpeningRowOpen(false);
    }, [buildingStructureSliceData.buildingStructureData.wallData, buildingStructureSliceData.buildingStructureData.openingInWallData])

    return (
        <div className="min-w-fit">
            {isEdit ? <NewRow isWallEdit spaceId={spaceId} wallData={wall} onDoubleClick={onEditWallClick} /> :
                <div id={"wall-" + wall.wallId} className="bg-#676D7A flex text-xs xl:text-lg font-inter justify-between border-b border-b-#3B4250 h-[45px]" onDoubleClick={onEditWallClick}>
                    <p className="flex items-center justify-left pl-4 min-w-[120px] xl:min-w-[170px]">{wall.name}</p>
                    <p className="flex items-center justify-center min-w-[120px] xl:min-w-[146px]">{wall.orientation}</p>
                    <p className="flex items-center justify-center min-w-[170px] xl:min-w-[210px]">{t(getEnumValueByKey(EnvironmentType, wall.environmentType))}</p>
                    <p className="flex items-center justify-center min-w-[85px] xl:min-w-[99px]">{formatNumber(wall.length, 2)}</p>
                    <p className="flex items-center justify-center min-w-[135px] xl:min-w-[158px]">{formatNumber(wall.widthHeight, 2)}</p>
                    <p className="flex items-center justify-center min-w-[92px] xl:min-w-[113px]">1</p>
                    <p className="flex items-center justify-center min-w-[95px] xl:min-w-[115px]">{formatNumber(wall.area, 2)}</p>
                    <p className="flex items-center justify-center min-w-[119px] xl:min-w-[142px]">{formatNumber(wall.usefulArea, 2)}</p>
                    <p className="flex items-center justify-center min-w-[128px] xl:min-w-[153px]">{formatNumber(wall.temperature, 1)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(wall.bu, 2)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(wall.thermalTransmittance, 3)}</p>
                    <p className="flex items-center justify-center min-w-[146px] xl:min-w-[176px]">{formatNumber(wall.thermalBridge, 3)}</p>
                    <p className="flex items-center justify-center min-w-[124px] xl:min-w-[148px]">{formatNumber(wall.correctedUValue, 3)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(wall.heatTransferCoefficient, 2)}</p>
                    <p className="flex items-center justify-center min-w-[70px] xl:min-w-[80px]">{formatNumber(wall.transmissionLoss, 0)}</p>
                    <p className="flex items-center justify-center font-light text-center min-w-[146px] xl:min-w-[220px]">
                        <button id="createOpeningBtn" onClick={() => setIsAddOpeningRowOpen(!isAddOpeningRowOpen)}>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                <path d="M11.3755 19.2495C14.9237 19.2431 16.7817 19.156 17.9691 17.9686C19.2505 16.6872 19.2505 14.6248 19.2505 10.5C19.2505 6.37521 19.2505 4.31282 17.9691 3.03141C16.6877 1.75 14.6253 1.75 10.5005 1.75C6.37568 1.75 4.31329 1.75 3.03188 3.03141C1.84448 4.21881 1.75737 6.07682 1.75098 9.625" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M9.625 11.375L2.625 18.375M2.625 18.375H7.875M2.625 18.375V13.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </p>
                    <p className="flex items-center justify-center font-light text-center min-w-[75px] xl:min-w-[85px]">
                        <button id="deleteBtn" className="mx-auto" onClick={handleDelete}>
                            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#F5F5F5 hover:stroke-#FF9800">
                                <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </button>
                    </p>
                </div>
            }
            {wall.openings.length > 0 &&
                wall.openings.map((opening) => (
                    <OpeningInWall spaceId={spaceId} opening={opening} key={opening.openingInWallId} />
                ))}
            {isAddOpeningRowOpen && <NewRow opening spaceId={spaceId} wallEnvironment={wall.environmentType} wallOrientation={wall.orientation} wallPairId={wall.pairId} />}
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteWall} /> : <></>}
        </div>
    );

}
export default WallInRoom;