import { Dialog } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { useEffect, useState } from "react";
import { CreateProjectAddress, GetAllProjectAddresses, RemoveProjectAddress, UpdateProjectAddress } from "../projectSlice";
import { ProjectAddressModel, ProjectAddressModelForCreate, ProjectAddressModelForUpdate } from "../projectModels";
import DeleteCard from "../../../UIComponents/deleteCard";
import { handleInputChange, handleSpaceInInput } from "../../../app/common";

interface ProjectAddressProps {
    onCancelClickFunction: () => void,
}

function ProjectAdress(props: ProjectAddressProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false);
    const [selectedProjectAddressId, setSelectedProjectAddressId] = useState("");
    const [hoveredProjectAddressId, setHoveredProjectAddressId] = useState("");
    const [projectAddressIdToDelete, setProjectAddressIdToDelete] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const [groupName, setGroupName] = useState("");
    const [name, setName] = useState("");
    const [department, setDepartment] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [phoneNumber1, setPhoneNumber1] = useState("");
    const [phoneNumber2, setPhoneNumber2] = useState("");
    const [fax, setFax] = useState("");
    const [email1, setEmail1] = useState("");
    const [email2, setEmail2] = useState("");
    const [webSite, setWebSite] = useState("");
    const [groupNameErrorStatus, setGroupNameErrorStatus] = useState(false);
    const [nameErrorStatus, setNameErrorStatus] = useState(false);
    const [projectAddresses, setProjectAddresses] = useState<ProjectAddressModel[]>([]);

    function onProjectAddressSelect(projectAddressId: string): void {
        if (projectAddressId !== "" && selectedProjectAddressId !== "" && projectAddressId === selectedProjectAddressId) {
            setSelectedProjectAddressId("");
            setIsEdit(false);
            setHoveredProjectAddressId("");
            resetForm();
            return;
        }
        if (projectAddressId) {
            setSelectedProjectAddressId(projectAddressId);
            setIsEdit(true);
            setHoveredProjectAddressId(projectAddressId);
        }
        const projectAddress = projectAddresses.filter(pa => pa.projectAddressId === projectAddressId).find(pa => pa);
        if (projectAddress) {
            setGroupName(projectAddress.groupName);
            setName(projectAddress.name);
            setDepartment(projectAddress.department);
            setCountry(projectAddress.country);
            setPostalCode(projectAddress.postalCode);
            setCity(projectAddress.city);
            setStreet(projectAddress.street);
            setStreetNumber(projectAddress.streetNumber);
            setPhoneNumber1(projectAddress.phoneNumber1);
            setPhoneNumber2(projectAddress.phoneNumber2);
            setFax(projectAddress.fax);
            setEmail1(projectAddress.email1);
            setEmail2(projectAddress.email2);
            setWebSite(projectAddress.webSite);
        }
    }

    const deleteSelected = () => {
        dispatch(RemoveProjectAddress(projectAddressIdToDelete));
    }

    function handleDeleteClick(projectAddressId: string): void {
        setProjectAddressIdToDelete(projectAddressId);
        setDeleteMessage(t("AreYouSureYouWantToDeleteThisGroup"));
        setIsDelete(true);
    }

    function handleCancelClick(): void {
        props.onCancelClickFunction();
        handleDialog();
    }

    function handleSaveClick(): void {
        if (groupName === "" || name === "") {
            if (groupName === "") { setGroupNameErrorStatus(true); }
            if (name === "") { setNameErrorStatus(true); }
            return;
        }
        if (isEdit) {
            const projectAddressToUpdate: ProjectAddressModelForUpdate = {
                projectAddressId: selectedProjectAddressId,
                projectId: projectSliceData.projectData.projectData.projectId,
                groupName: groupName,
                name: name,
                department: department,
                country: country,
                postalCode: postalCode,
                city: city,
                street: street,
                streetNumber: streetNumber,
                phoneNumber1: phoneNumber1,
                phoneNumber2: phoneNumber2,
                fax: fax,
                email1: email1,
                email2: email2,
                webSite: webSite,
            }
            dispatch(UpdateProjectAddress(projectAddressToUpdate));
        }
        else {
            const projectAddressToCreate: ProjectAddressModelForCreate = {
                projectId: projectSliceData.projectData.projectData.projectId,
                groupName: groupName,
                name: name,
                department: department,
                country: country,
                postalCode: postalCode,
                city: city,
                street: street,
                streetNumber: streetNumber,
                phoneNumber1: phoneNumber1,
                phoneNumber2: phoneNumber2,
                fax: fax,
                email1: email1,
                email2: email2,
                webSite: webSite,
            }
            dispatch(CreateProjectAddress(projectAddressToCreate));
        }
    }

    // const onGroupNameChange = (groupName: string) => {
    //     if (groupName !== "") { setGroupNameErrorStatus(false); }
    //     setGroupName(groupName);
    // }

    // const onNameChange = (name: string) => {
    //     if (name !== "") { setNameErrorStatus(false); }
    //     setName(name);
    // }

    const resetForm = () => {
        setGroupNameErrorStatus(false);
        setNameErrorStatus(false);
        setGroupName("");
        setName("");
        setDepartment("");
        setCountry("");
        setPostalCode("");
        setCity("");
        setStreet("");
        setStreetNumber("");
        setPhoneNumber1("");
        setPhoneNumber2("");
        setFax("");
        setEmail1("");
        setEmail2("");
        setWebSite("");
    }

    useEffect(() => {
        dispatch(GetAllProjectAddresses(projectSliceData.projectData.projectData.projectId));
    }, [])

    useEffect(() => {
        const projectAddresses = projectSliceData.projectData.allProjectAddressesData.map(address => address);
        setProjectAddresses(projectAddresses);
    }, [projectSliceData.projectData.allProjectAddressesData])

    useEffect(() => {
        if (projectSliceData.statusValues.statusCode === "200" && projectSliceData.statusValues.showNotification) {
            dispatch(GetAllProjectAddresses(projectSliceData.projectData.projectData.projectId));
            if (!isEdit) {
                resetForm();
            }
            if (selectedProjectAddressId === projectAddressIdToDelete) {
                setIsDelete(false);
                setIsEdit(false);
                resetForm();
                setProjectAddressIdToDelete("");
                setSelectedProjectAddressId("");
            }
            else {
                setIsDelete(false);
            }
        }
    }, [projectSliceData.statusValues])

    return (
        <Dialog open={openDialog} size="lg" handler={() => { props.onCancelClickFunction(); handleDialog(); }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="justify-center bg-transparent shadow-none outline-none">
            <div className="text-#F5F5F5 bg-#3B4250 p-[30px] rounded-[5px] w-[700px] md:w-[900px] xl:w-[1346px]">
                <div className="flex flex-row justify-between mb-10">
                    <div className="w-3/5 xl:w-1/2">
                        <p className="">{t("CreateANewStakeholder")}</p>
                    </div>
                    <div className="w-2/5 xl:w-1/2 pl-[60px]">
                        <p className="">{t("Stakeholders")}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-between h-fit">
                    <div className="w-3/5 xl:w-1/2 pr-[30px]">
                        <div className="space-y-[10px] text-base">
                            {/* {LeftSide()} */}
                            <div className="flex items-center justify-between">
                                <p>{t("StakeholderName")}:<span className="text-#EF5350 pl-1">*</span></p>
                                <input id="stakeholderName" type="text" autoComplete="off" placeholder={t("StakeholderName")} value={groupName} onChange={(e) => handleSpaceInInput(e, setGroupName)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0 ${groupNameErrorStatus && groupName.length === 0 ? "border-[1px] border-#EF5350" : ""}`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("OrganizationName")}:<span className="text-#EF5350 pl-1">*</span></p>
                                <input id="organizationName" type="text" autoComplete="off" placeholder={t("OrganizationName")} value={name} onChange={(e) => handleSpaceInInput(e, setName)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0 ${nameErrorStatus && name.length === 0 ? "border-[1px] border-#EF5350" : ""}`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Department")}:</p>
                                <input id="department" type="text" autoComplete="off" placeholder={t("Department")} value={department} onChange={(e) => setDepartment(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Country")}:</p>
                                <input id="country" type="text" autoComplete="off" placeholder={t("Country")} value={country} onChange={(e) => setCountry(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("PostalCode")}:</p>
                                <input id="postalCode" type="text" autoComplete="off" placeholder={t("PostalCode")} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("City")}:</p>
                                <input id="city" type="text" autoComplete="off" placeholder={t("City")} value={city} onChange={(e) => setCity(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Street")}:</p>
                                <input id="street" type="text" autoComplete="off" placeholder={t("Street")} value={street} onChange={(e) => setStreet(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("StreetNumber")}:</p>
                                <input id="streetNumber" type="text" autoComplete="off" placeholder={t("StreetNumber")} value={streetNumber} onChange={(e) => setStreetNumber(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("PhoneNumber1")}:</p>
                                <input id="phoneNumber1" type="text" autoComplete="off" placeholder={t("PhoneNumber1")} value={phoneNumber1} onChange={(e) => handleInputChange(e, setPhoneNumber1, 0)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("PhoneNumber2")}:</p>
                                <input id="phoneNumber2" type="text" autoComplete="off" placeholder={t("PhoneNumber2")} value={phoneNumber2} onChange={(e) => handleInputChange(e, setPhoneNumber2, 0)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Fax")}:</p>
                                <input id="fax" type="text" autoComplete="off" placeholder={t("Fax")} value={fax} onChange={(e) => handleInputChange(e, setFax, 0)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Email1")}:</p>
                                <input id="email1" type="text" autoComplete="off" placeholder={t("Email1")} value={email1} onChange={(e) => setEmail1(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("Email2")}:</p>
                                <input id="email2" type="text" autoComplete="off" placeholder={t("Email2")} value={email2} onChange={(e) => setEmail2(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                            <div className="flex items-center justify-between">
                                <p>{t("WebSite")}:</p>
                                <input id="webSite" type="text" autoComplete="off" placeholder={t("WebSite")} value={webSite} onChange={(e) => setWebSite(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A outline-none w-[200px] md:w-[300px] xl:w-[400px] h-[30px] pl-[12px] rounded-[5px] shadow-inner ml-0`} />
                            </div>
                        </div>
                    </div>
                    <div className="w-2/5 xl:w-1/2 pl-[25px] border-l-[1px] border-#D9D9D9">
                        {/* {RightSide()} */}
                        <div className="space-y-[10px] overflow-auto max-h-[550px]">
                            {projectAddresses.map((projectAddress) => (
                                <div key={projectAddress.projectAddressId} id={"StakeholderInfo" + projectAddress.projectAddressId} onClick={() => onProjectAddressSelect(projectAddress.projectAddressId)} onPointerEnter={() => setHoveredProjectAddressId(projectAddress.projectAddressId)} onPointerLeave={() => setHoveredProjectAddressId('')} className={`flex flex-row items-center justify-between pl-[35px] pr-[20px] gap-2 h-[30px] hover:bg-#D79F451A cursor-pointer rounded ${(selectedProjectAddressId === projectAddress.projectAddressId) ? "bg-#D79F451A" : ""}`}>
                                    <p className="truncate">{projectAddress.groupName}</p>
                                    <button id="deleteStakeholderBtns" onClick={(e) => { e.stopPropagation(); handleDeleteClick(projectAddress.projectAddressId); }} className={`${hoveredProjectAddressId === projectAddress.projectAddressId || selectedProjectAddressId === projectAddress.projectAddressId ? 'block' : 'hidden'}`}>
                                        <svg className="m-auto cursor-pointer stroke-#F5F5F5 hover:stroke-#FF9800" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.67065 3C7.08249 1.83481 8.19373 1 9.49995 1C10.8062 1 11.9174 1.83481 12.3292 3" strokeWidth="1.5" strokeLinecap="round" />
                                            <path d="M18.0001 5H1" strokeWidth="1.5" strokeLinecap="round" />
                                            <path d="M16.3334 7.5L15.8735 14.3991C15.6965 17.054 15.608 18.3815 14.743 19.1907C13.878 20 12.5476 20 9.88676 20H9.1134C6.4526 20 5.1222 20 4.25719 19.1907C3.39218 18.3815 3.30368 17.054 3.12669 14.3991L2.66675 7.5" strokeWidth="1.5" strokeLinecap="round" />
                                            <path d="M7 10L7.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                            <path d="M12 10L11.5 15" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex items-center mt-[30px] space-x-[30px]">
                    <button id="saveBtn" onClick={handleSaveClick} className="w-fit h-[30px] lg:h-[40px] px-4 lg:px-6 bg-#FF9800 text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{selectedProjectAddressId ? t("Save") : t("Create")}</button>
                    <button id="cancelBtn" onClick={handleCancelClick} className="w-fit h-[30px] lg:h-[40px] px-4 lg:px-6 bg-#FF9800 text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</button>
                </div>
            </div>
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => { setIsDelete(false); setProjectAddressIdToDelete(""); }} onContinueClickFunction={deleteSelected} /> : <></>}
        </Dialog>
    );
}

export default ProjectAdress;