import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../../../UIComponents/customDropdown";
import { MaterialModel, MaterialModelForUpdate } from "../materialModels";
import { materialTypeOptions } from "../../../../app/optionModels";
import { handleInputChange } from "../../../../app/common";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { UpdateMaterial, resetStatusCode } from "../materialSlice";
import { notify } from "../../../../UIComponents/notification";
import { LoadingPage } from "../../../../UIComponents/loading";

interface EditMaterialProps {
    material: MaterialModel
    onCancelClick: () => void;
    onUpdateMaterialClick: () => void;
}

function EditMaterial(props: EditMaterialProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [materialName, setMaterialName] = useState("");
    const [thermalConductivity, setThermalConductivity] = useState("");
    const [specificHeatCapacity, setSpecificHeatCapacity] = useState("");
    const [density, setDensity] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const materialSliceData = useSelector((state: RootState) => state.MaterialSlice.materialSliceData);

    useEffect(() => {
        setMaterialName(props.material.name);
        setThermalConductivity(props.material.thermalConductivity.toString());
        setSpecificHeatCapacity(props.material.specificHeatCapacity.toString());
        setDensity(props.material.density.toString());
        setSelectedCategory(props.material.materialType);
    }, [])

    function handleEditClick(): void {
        if (materialName && thermalConductivity && specificHeatCapacity && density && selectedCategory) {
            const materialForUpdate: MaterialModelForUpdate = {
                materialId: props.material.materialId,
                name: materialName,
                materialType: selectedCategory,
                thermalConductivity: parseFloat(thermalConductivity),
                specificHeatCapacity: parseFloat(specificHeatCapacity),
                density: parseFloat(density)
            }
            dispatch(UpdateMaterial(materialForUpdate))
        }
    }

    useEffect(() => {
        if (materialName === "" || thermalConductivity === "" || parseFloat(thermalConductivity) === 0 || specificHeatCapacity === "" || parseFloat(specificHeatCapacity) === 0 || density === "" || parseFloat(density) === 0 || selectedCategory === "" ||
            (materialName === props.material.name && parseFloat(thermalConductivity) === props.material.thermalConductivity && parseFloat(specificHeatCapacity) === props.material.specificHeatCapacity && parseFloat(density) === props.material.density && selectedCategory === props.material.materialType)) {
            setIsButtonDisabled(true)
        }
        else {
            setIsButtonDisabled(false)
        }
    }, [materialName, thermalConductivity, specificHeatCapacity, density, selectedCategory])

    useEffect(() => {
        if (materialSliceData.statusValues.statusCode === "") {
            return;
        }
        if (materialSliceData.statusValues.statusCode === "200") {
            props.onUpdateMaterialClick()
        }
        else {
            notify(t(materialSliceData.statusValues.message), true)
            dispatch(resetStatusCode());
        }

    }, [materialSliceData.statusValues])

    return (
        <div className="bg-#3B4250 p-[20px] w-[531px] md:w-[829px] lg:w-[899px] xl:w-[904px] m-auto">
            <div className={`w-full ${materialSliceData.statusValues.isLoading && "blur-5"}`}>
                <p className="text-#F5F5F5 lg:text-lg xl:text-xl">{t("EditMaterial")}</p>
                <div className="space-y-[25px] mt-[45px] text-#FFFFFF lg:text-lg xl:text-xl">
                    <div className="flex items-center">
                        <p>{t("MaterialName")}</p>
                        <input id="materialName" type="text" autoComplete="off" placeholder={t("MaterialName")} value={materialName} onChange={(e) => setMaterialName(e.target.value)} className={`bg-#2E333E placeholder:text-#545B6A w-[339px] md:w-[635px] lg:w-[702px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto`} />
                    </div>
                    <div className="flex items-center">
                        <p>{t("ThermalConductivity")}</p>
                        <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                            <input id="thermalConductivity" type="number" autoComplete="off" placeholder={"0.000"} value={thermalConductivity} onChange={(e) => { handleInputChange(e, setThermalConductivity, 3) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                            <p className="w-[48px] md:w-[59px]">W/mK</p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <p>{t("SpecificHeatCapacity")}</p>
                        <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                            <input id="specificHeatCapacity" type="number" autoComplete="off" placeholder={"0"} value={specificHeatCapacity} onChange={(e) => { handleInputChange(e, setSpecificHeatCapacity, 0) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                            <p className="w-[48px] md:w-[59px]">J/K</p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <p>{t("Density")}</p>
                        <div className="flex items-center gap-2 lg:gap-[19px] ml-auto">
                            <input id="density" type="number" autoComplete="off" placeholder={"0"} value={density} onChange={(e) => { handleInputChange(e, setDensity, 0) }} className={`bg-#2E333E placeholder:text-#545B6A w-[283px] md:w-[569px] lg:w-[624px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                            <p className="w-[48px] md:w-[59px]">kg/m</p>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <p>{t("Category")}</p>
                        <CustomDropdown dropdownId="categoryDropdown" previousSelectedOption={props.material.materialType} options={materialTypeOptions} placeholder={t("SelectCategory")} height={"h-10"} width={"w-[339px] md:w-[635px] lg:w-[702px]"} onSelectClick={setSelectedCategory} />
                    </div>
                    <div className="flex w-fit ml-auto gap-[25px]">
                        <button id="cancelBtn" onClick={props.onCancelClick} className="w-fit h-10 px-6 bg-#FF9800 text-#272C35 uppercase font-semibold text-sm rounded-lg">{t("Cancel")}</button>
                        <button id="saveChangesBtn" disabled={isButtonDisabled} onClick={handleEditClick} className="w-fit h-10 px-6 bg-#FF9800 text-#272C35 disabled:opacity-50 uppercase font-semibold text-sm rounded-lg ">{t("SaveChanges")}</button>
                    </div>
                </div>
            </div>
            {materialSliceData.statusValues.isLoading && <div className="absolute top-1/2 left-1/2">{LoadingPage(materialSliceData.statusValues.isLoading)}</div>}
        </div>
    );
}

export default EditMaterial;