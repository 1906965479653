import Header from './elements/Dashboard/Pages/header';
import Sidebar from './elements/Dashboard/Pages/sidebar';
import { AppRoutes, LoggedOutRoutes } from './app/routes';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./app/store";
import { useEffect } from 'react';
import { CloseProject } from './elements/Project/projectSlice';
import { ProjectUserModel } from './elements/Project/projectModels';

function App() {
  const isValidLicense = useSelector((state: RootState) => state.AuthSlice.authSliceData.loginData.isValidLicense);
  const userId = useSelector((state: RootState) => state.AuthSlice.authSliceData.loginData.userId);
  const projectId = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData.projectId);
  const dispatch = useDispatch<AppDispatch>();

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     const projectToClose: ProjectUserModel = {
  //       projectId: projectId,
  //       userId: userId,
  //     }
  //     dispatch(CloseProject(projectToClose));
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div className="bg-#414959 max-h-[100vh] min-w-[660px] h-[100vh] overflow-scroll font-inter">
      {isValidLicense ?
        <div>
          <Header />
          <div className="bg-#414959 flex min-w-[660px]">
            <Sidebar />
            <AppRoutes />
          </div>
        </div>
        :
        <div className="bg-#414959 h-[100vh] min-w-[512px]">
          <LoggedOutRoutes />
        </div>}
    </div>
  );
}

export default App;
