import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAllGeolocations, GetGeolocation, GetNumberOfGeolocation, SearchGeolocations, resetIsEditGeolocations, setIsEditGeolocation } from "../buildingSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { GeolocationModel, GeolocationModelForCalculate } from "../buildingModels";
import { Button, IconButton, Tooltip } from "@material-tailwind/react";
import LeftArrow from "../../../Images/ArrowLeft.png";
import RightArrow from "../../../Images/ArrowRight.png";

interface GeolocationProps {
    onGeolocationSelect: (data: GeolocationModel) => void
}

function GeolocationPopup(props: GeolocationProps) {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("");
    const [geolocations, setGeolocations] = useState<GeolocationModel[]>([]);
    const buildingSliceData = useSelector((state: RootState) => state.BuildingSlice.buildingSliceData);
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData);
    const dispatch = useDispatch<AppDispatch>();
    let geolocationsPerPage: number = 50;
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const startIndexRef = useRef<number>(1);
    const endIndexRef = useRef<number>(1);
    const pagesCountRef = useRef<number>(1);
    const [elevationErrorStatus, setElevationErrorStatus] = useState(false);
    const [elevation, setElevation] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [selectedGeolocationId, setSelectedGeolocationId] = useState("");

    const onGeolocationClick = (geolocation: GeolocationModel) => {
        if (geolocation.geolocationId === selectedGeolocationId) return;
        setSelectedGeolocationId(geolocation.geolocationId);
        setElevation("");
        setElevationErrorStatus(false);
        dispatch(resetIsEditGeolocations());
        dispatch(setIsEditGeolocation(geolocation.geolocationId));
    }

    const onGeolocationConfirm = (geolocation: GeolocationModel) => {

        if (parseFloat(elevation) < geolocation.minElevation || parseFloat(elevation) > geolocation.maxElevation || elevation.length === 0) {
            setElevationErrorStatus(true);
            return;
        }
        dispatch(resetIsEditGeolocations());
        const geolocationToGet: GeolocationModelForCalculate = {
            geolocationId: geolocation.geolocationId,
            projectId: projectSliceData.projectData.projectId,
            userId: localStorage.getItem("userId")!,
            elevation: parseFloat(elevation)
        }
        dispatch(GetGeolocation(geolocationToGet));
        props.onGeolocationSelect(geolocation);
    }

    const getItemProps = (index: number) =>
    ({
        className:
            activePage === index
                ? "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#FFFFFF bg-#4E4E4E shadow-none hover:shadow-none"
                : "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#4E4E4E bg-#FFFFFF shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent",
        variant: activePage === index ? "filled" : "text",
        onClick: () => select(index),
    } as any);

    const select = (step: number) => {
        setActivePage(step);

        startIndexRef.current = (step - 1) * geolocationsPerPage;
        endIndexRef.current = startIndexRef.current + geolocationsPerPage;

        dispatch(GetAllGeolocations(step));
    };

    const searchGeolocations = (value: string) => {
        setSearchValue(value);
        if (value === '') {
            resetGeolocations();
        }
    }

    const getGeolocations = () => {
        dispatch(SearchGeolocations(searchValue));
    }

    const resetGeolocations = () => {
        dispatch(SearchGeolocations(""));
        setSearchValue("");
    }

    function CreatePages() {
        const pagesToCreate = [];

        for (let i = 1; i <= pagesCountRef.current; i++) {
            pagesToCreate.push(
                <IconButton key={i} {...getItemProps(i)}>
                    {i}
                </IconButton>
            );
        }

        return (
            <div className="flex justify-center items-center gap-2">
                {pagesToCreate}
            </div>
        );
    }

    function PagesReload() {
        pagesCountRef.current = buildingSliceData.numberOfGeolocations.geolocationNumber <= geolocationsPerPage ? 1 : Math.ceil(buildingSliceData.numberOfGeolocations.geolocationNumber / geolocationsPerPage);
    }

    function nextPage() {
        dispatch(GetAllGeolocations(activePage + 1));
        setActivePage(prevActivePage => prevActivePage + 1);
    }

    function previousPage() {
        dispatch(GetAllGeolocations(activePage - 1));
        setActivePage(prevActivePage => prevActivePage - 1);
    }

    useEffect(() => {
        dispatch(GetNumberOfGeolocation());
        dispatch(GetAllGeolocations(activePage));
    }, [])

    useEffect(() => {
        setGeolocations(buildingSliceData.geolocationData);
        setNumberOfPages(Math.ceil(buildingSliceData.numberOfGeolocations.geolocationNumber / geolocationsPerPage));
        PagesReload();
    }, [buildingSliceData.geolocationData, buildingSliceData.numberOfGeolocations])

    return (
        <div className="bg-#2E333E w-[933px] lg:w-[1324px] h-[608px] rounded-[5px] flex items-start justify-start py-[22px] px-[20px]">
            <div className="space-y-[15px] text-#F5F5F5 text-[20px] leading-6 w-full">
                <div className="flex items-center justify-between">
                    <p>{t("Geolocation")}</p>
                    <div className="flex items-center justify-end space-x-5">
                        <input id="searchGeolocations" type="text" placeholder={t("Search")} value={searchValue} onChange={(e) => searchGeolocations(e.target.value)} className="bg-#343A47 placeholder:text-#545B6A outline-none w-[300px] h-10 text-[16px] pl-[12px] pr-[32px] rounded-[5px] shadow-inner" />
                        {searchValue && <button id="clearSearchBtn" className="absolute translate-x-[-110px] w-fit h-5 px-1 bg-transparent rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none" onClick={resetGeolocations}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.252823 10.5349C0.091857 10.6958 0.0014286 10.9141 0.0014286 11.1417C0.0014286 11.3694 0.091857 11.5877 0.252823 11.7486C0.413788 11.9096 0.632104 12 0.859743 12C1.08738 12 1.3057 11.9096 1.46666 11.7486L6 7.21412L10.5348 11.7472C10.6957 11.9081 10.914 11.9986 11.1417 11.9986C11.3693 11.9986 11.5876 11.9081 11.7486 11.7472C11.9096 11.5862 12 11.3679 12 11.1403C12 10.9127 11.9096 10.6944 11.7486 10.5334L7.21384 6.00036L11.7472 1.46586C11.9081 1.30491 11.9986 1.0866 11.9986 0.858978C11.9986 0.631352 11.9081 0.413049 11.7472 0.252094C11.5862 0.0911379 11.3679 0.000713864 11.1403 0.000713861C10.9126 0.000713859 10.6943 0.0911379 10.5333 0.252094L6 4.78659L1.46524 0.251379C1.30427 0.0904238 1.08595 -3.79225e-09 0.858315 0C0.630676 3.79225e-09 0.41236 0.0904238 0.251395 0.251379C0.0904303 0.412335 9.53674e-07 0.630638 9.53674e-07 0.858263C9.53674e-07 1.08589 0.0904303 1.30419 0.251395 1.46515L4.78616 6.00036L0.252823 10.5349Z" fill="#F5F5F5" />
                            </svg>
                        </button>}
                        <button id="searchGeolocationsBtn" className="w-fit h-10 px-3 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D" onClick={getGeolocations}>
                            <span>{t("Search")}</span>
                        </button>
                    </div>
                </div>
                <div className="w-[874px] lg:w-[1262px]">
                    <div className={`w-full flex flex-row text-#F5F5F5 text-base ${geolocations.length > 7 ? "pr-[5px]" : ""}`}>
                        <p className="w-[118px] lg:w-[164px] h-[50px] flex justify-start items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-4">{t("Name")}</p>
                        <p className="w-[116px] lg:w-[136px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("PostalCode")}</p>
                        <p className="w-[90px] lg:w-[138px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("ClimateRegion")}</p>
                        <p className="w-[84px] lg:w-[126px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("RegionCode")}</p>
                        <p className="w-[96px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("MinElevation")}</p>
                        <p className="w-[96px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("MaxElevation")}</p>
                        <p className="w-[130px] lg:w-[230px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{t("MinElevationTemperature")}</p>
                        <p className="w-[144px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-b-[1px] border-#F5F5F5 px-2">{t("Elevation")}</p>
                    </div>
                    <div className="overflow-auto max-h-[370px]">
                        {geolocations.length > 0 && geolocations.map(geolocation => (
                            <div id={"Geolocation-" + geolocation.geolocationId} key={geolocation.geolocationId} className={`w-full flex flex-row text-#F5F5F5 text-base hover:bg-#D79F451A ${geolocation.isEdit ? "bg-#D79F451A" : ""} cursor-pointer`} onClick={() => onGeolocationClick(geolocation)}>
                                <div className="w-[118px] lg:w-[164px] h-[50px] flex justify-start items-center border-r-[1px] border-b-[1px] border-#F5F5F5">
                                    <Tooltip key={"geolocationFullName"} className="rounded-[8px] bg-#414959 text-#FFFFFF font-[600] px-2 py-1 m-0 z-[10000]" content={geolocation.geolocationName} placement={"top"}>
                                        <p className="mx-4 truncate">{geolocation.geolocationName}</p>
                                    </Tooltip>
                                </div>
                                <p className="w-[116px] lg:w-[136px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.geolocationZipCode}</p>
                                <p className="w-[90px] lg:w-[138px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.climateRegion}</p>
                                <p className="w-[84px] lg:w-[126px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.regionCode}</p>
                                <p className="w-[96px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.minElevation}</p>
                                <p className="w-[96px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.maxElevation}</p>
                                <p className="w-[130px] lg:w-[230px] h-[50px] flex justify-center items-center text-center border-r-[1px] border-b-[1px] border-#F5F5F5 px-2">{geolocation.minElevationTemperature}</p>
                                <Tooltip key={"elevationNotification"} className="rounded-[8px] bg-#414959 text-#FFFFFF font-[600] px-2 py-1 m-0 z-[10000]" content={t("InsertElevationBetween") + " " + geolocation.minElevation + " - " + geolocation.maxElevation + ""} placement={"top"}>
                                    <p className="w-[144px] lg:w-[156px] h-[50px] flex justify-center items-center text-center border-b-[1px] px-2 border-#F5F5F5">
                                        {!geolocation.isEdit ? "" :
                                            <>
                                                <input id="elevationInputField" type="number" value={elevation} onChange={(e) => setElevation(e.target.value)} className={`${elevationErrorStatus ? "border-[1px] border-#EF5350" : ""} w-[88px] lg:w-[116px] h-[33px] bg-#2E333E flex justify-center items-center text-center outline-none`} />
                                                <button id="confirmElevation" className="w-fit h-10 ml-2 bg-transparent rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none" onClick={() => onGeolocationConfirm(geolocation)}>
                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="10.5" cy="10.5" r="10.5" fill="#7EB608" />
                                                        <path d="M14.6355 7.94036C14.7306 8.04048 14.7817 8.17391 14.7775 8.31132C14.7733 8.44873 14.7142 8.57887 14.6131 8.67312L9.07842 13.8397C8.96559 13.945 8.81566 14.0023 8.66071 13.9995C8.50575 13.9966 8.35807 13.9338 8.24925 13.8245L6.37876 11.9443C6.32923 11.896 6.28987 11.8386 6.26299 11.7752C6.2361 11.7117 6.22223 11.6437 6.22217 11.5749C6.22211 11.5061 6.23587 11.438 6.26264 11.3745C6.28941 11.3111 6.32866 11.2535 6.37811 11.2052C6.42756 11.157 6.48622 11.1189 6.55066 11.0933C6.6151 11.0677 6.68405 11.055 6.75348 11.056C6.82292 11.0571 6.89145 11.0718 6.95509 11.0993C7.01873 11.1268 7.0762 11.1666 7.12417 11.2163L8.68338 12.7835L13.8956 7.91824C13.9967 7.82403 14.1314 7.77343 14.2702 7.77758C14.4089 7.78173 14.5403 7.84028 14.6355 7.94036Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </>
                                        }
                                    </p>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                </div>
                {
                    geolocations.length < 50 || buildingSliceData.numberOfGeolocations.geolocationNumber < 50 || buildingSliceData.statusValues.isLoading ? <></> :
                        <div className="flex justify-between items-center mt-[40px] xl:2xl:mt-[60px]">
                            <Button id="leftArrowBtn" variant="text" className="flex items-center text-#F5F5F5 normal-case font-normal text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => previousPage()} disabled={activePage === 1}>
                                <img src={LeftArrow} className="h-[24px] w-[24px]" alt=""></img>
                                {t("Previous")}
                            </Button>
                            <Button id="rightArrowBtn" variant="text" className="flex items-center text-#F5F5F5 normal-case font-normal text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => nextPage()} disabled={activePage === numberOfPages}>
                                {t("Next")}
                                <img src={RightArrow} className="h-[24px] w-[24px]" alt=""></img>
                            </Button>
                        </div>
                }
            </div>
        </div>
    );
}

export default GeolocationPopup;