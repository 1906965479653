import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { BuildingData, BuildingModel, BuildingModelForCreate, BuildingModelForDelete, BuildingModelForUpdate, BuildingSliceData, GeolocationModel, GeolocationModelForCalculate, GeometryModel, GetBuildingModel, HeatingInterruptionModel, HeatingInterruptionModelForCreate, NumberOfGeolocations, PropertyModel, ThermalBridgeModel } from "./buildingModels";

export const CreateBuilding = createAsyncThunk<ValueResponse<BuildingModel>, BuildingModelForCreate, { rejectValue: RejectedValue }>(
    'BuildingSlicer/CreateBuilding',
    async (building: BuildingModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateBuilding', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify({
                    projectId: building.projectId,
                    buildingName: building.geometry.buildingName,
                    length: building.geometry.length,
                    width: building.geometry.width,
                    bottomPlateThickness: building.geometry.bottomPlateThickness,
                    z: building.geometry.z,
                    buildingArea: building.geometry.buildingArea === 0 ? null : building.geometry.buildingArea,
                    buildingPerimeter: building.geometry.buildingPerimeter === 0 ? null : building.geometry.buildingPerimeter,
                    groundWaterDepth: building.geometry.groundWaterDepth === 0 ? null : building.geometry.groundWaterDepth,
                    buildingType: building.property.buildingType,
                    groundType: building.property.groundType,
                    buildingMass: building.property.buildingMass,
                    airOpeningTightness: building.property.airOpeningTightness,
                    buildingCoverage: building.property.buildingCoverage,
                    thermalBridge: building.thermalBridge.thermalBridge,
                    thermalBridgeValue: building.thermalBridge.thermalBridgeValue === 0 ? null : building.thermalBridge.thermalBridgeValue,
                    geolocationName: building.geolocation.geolocationName,
                    geolocationZipCode: building.geolocation.geolocationZipCode,
                    elevation: building.geolocation.elevation === 0 ? null : building.geolocation.elevation,
                    annualAverageTemperature: building.geolocation.annualAverageTemperature === 0 ? 0 : building.geolocation.annualAverageTemperature,
                    outsideTemperature: building.geolocation.outsideTemperature === 0 ? null : building.geolocation.outsideTemperature,
                    hasHeatingInterruption: building.hasHeatingInterruption,
                    heatingInterruptionType: building.heatingInterruptionData.heatingInterruptionType,
                    heatTransferCoefficient: building.heatingInterruptionData.heatTransferCoefficient === 0 ? null : building.heatingInterruptionData.heatTransferCoefficient,
                    averageTemperature: building.heatingInterruptionData.averageTemperature === 0 ? null : building.heatingInterruptionData.averageTemperature,
                    effectiveBuildingMass_Cwirk: building.heatingInterruptionData.effectiveBuildingMass_Cwirk === 0 ? null : building.heatingInterruptionData.effectiveBuildingMass_Cwirk,
                    loweringPhase: building.heatingInterruptionData.loweringPhase,
                    reheatingTime: building.heatingInterruptionData.reheatingTime,
                    airExchangeRateDuringLoweringPhase_nabs: building.heatingInterruptionData.airExchangeRateDuringLoweringPhase_nabs,
                    airExchangeRateDuringReheatingTime_nrh: building.heatingInterruptionData.airExchangeRateDuringReheatingTime_nrh,
                    temperatureDrop_trh: building.heatingInterruptionData.temperatureDrop_trh === 0 ? null : building.heatingInterruptionData.temperatureDrop_trh,
                    userId: building.userId,
                    dateCreatedOrModified: building.dateCreatedOrModified,
                }),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<BuildingModel> = {
                value: (await response.json()) as BuildingModel,
                status: response.status.toString(),
                message: "Building created successfully",
            }
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateBuilding = createAsyncThunk<ValueResponse<BuildingModelForUpdate>, BuildingModelForUpdate, { rejectValue: RejectedValue }>(
    'BuildingSlicer/UpdateBuilding',
    async (building: BuildingModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateBuilding', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify({
                    projectId: building.buildingModel.projectId,
                    buildingName: building.buildingModel.geometry.buildingName,
                    length: building.buildingModel.geometry.length,
                    width: building.buildingModel.geometry.width,
                    bottomPlateThickness: building.buildingModel.geometry.bottomPlateThickness,
                    z: building.buildingModel.geometry.z,
                    buildingArea: building.buildingModel.geometry.buildingArea === 0 ? null : building.buildingModel.geometry.buildingArea,
                    buildingPerimeter: building.buildingModel.geometry.buildingPerimeter === 0 ? null : building.buildingModel.geometry.buildingPerimeter,
                    groundWaterDepth: building.buildingModel.geometry.groundWaterDepth === 0 ? null : building.buildingModel.geometry.groundWaterDepth,
                    buildingType: building.buildingModel.property.buildingType,
                    groundType: building.buildingModel.property.groundType,
                    buildingMass: building.buildingModel.property.buildingMass,
                    airOpeningTightness: building.buildingModel.property.airOpeningTightness,
                    buildingCoverage: building.buildingModel.property.buildingCoverage,
                    thermalBridge: building.buildingModel.thermalBridge.thermalBridge,
                    thermalBridgeValue: building.buildingModel.thermalBridge.thermalBridgeValue === 0 ? null : building.buildingModel.thermalBridge.thermalBridgeValue,
                    geolocationName: building.buildingModel.geolocation.geolocationName,
                    geolocationZipCode: building.buildingModel.geolocation.geolocationZipCode,
                    elevation: building.buildingModel.geolocation.elevation === 0 ? null : building.buildingModel.geolocation.elevation,
                    annualAverageTemperature: building.buildingModel.geolocation.annualAverageTemperature === 0 ? 0 : building.buildingModel.geolocation.annualAverageTemperature,
                    outsideTemperature: building.buildingModel.geolocation.outsideTemperature === 0 ? null : building.buildingModel.geolocation.outsideTemperature,
                    hasHeatingInterruption: building.buildingModel.hasHeatingInterruption,
                    heatingInterruptionType: building.buildingModel.heatingInterruptionData.heatingInterruptionType,
                    heatTransferCoefficient: building.buildingModel.heatingInterruptionData.heatTransferCoefficient === 0 ? null : building.buildingModel.heatingInterruptionData.heatTransferCoefficient,
                    averageTemperature: building.buildingModel.heatingInterruptionData.averageTemperature === 0 ? null : building.buildingModel.heatingInterruptionData.averageTemperature,
                    effectiveBuildingMass_Cwirk: building.buildingModel.heatingInterruptionData.effectiveBuildingMass_Cwirk === 0 ? null : building.buildingModel.heatingInterruptionData.effectiveBuildingMass_Cwirk,
                    loweringPhase: building.buildingModel.heatingInterruptionData.loweringPhase,
                    reheatingTime: building.buildingModel.heatingInterruptionData.reheatingTime,
                    airExchangeRateDuringLoweringPhase_nabs: building.buildingModel.heatingInterruptionData.airExchangeRateDuringLoweringPhase_nabs,
                    airExchangeRateDuringReheatingTime_nrh: building.buildingModel.heatingInterruptionData.airExchangeRateDuringReheatingTime_nrh,
                    temperatureDrop_trh: building.buildingModel.heatingInterruptionData.temperatureDrop_trh === 0 ? null : building.buildingModel.heatingInterruptionData.temperatureDrop_trh,
                    userId: building.buildingModel.userId,
                    dateCreatedOrModified: building.buildingModel.dateCreatedOrModified,
                }),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<BuildingModelForUpdate> = {
                value: (await response.json()) as BuildingModelForUpdate,
                status: response.status.toString(),
                message: "Building updated successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetBuilding = createAsyncThunk<ValueResponse<BuildingModel>, GetBuildingModel, { rejectValue: RejectedValue }>(
    'BuildingSlicer/GetBuilding',
    async (getBuildingModel: GetBuildingModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + `GetBuilding?projectId=${getBuildingModel.projectId}&userId=${getBuildingModel.userId}`, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<BuildingModel> = {
                value: (await response.json()) as BuildingModel,
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteBuilding = createAsyncThunk<ValueResponse<string>, BuildingModelForDelete, { rejectValue: RejectedValue }>( //pass BuildingModelForDelete, not only projectId
    'BuildingSlicer/DeleteBuilding',
    async (buildingForDelete: BuildingModelForDelete, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'DeleteBuilding', {
                method: "DELETE",
                headers: requestHeaders,
                body: JSON.stringify(buildingForDelete),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Building deleted successfully",
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetGeolocation = createAsyncThunk<ValueResponse<GeolocationModel>, GeolocationModelForCalculate, { rejectValue: RejectedValue }>(
    'BuildingSlicer/GetGeolocation',
    async (geolocationToGet: GeolocationModelForCalculate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetGeolocation', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(geolocationToGet),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<GeolocationModel> = {
                value: (await response.json()) as GeolocationModel,
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllGeolocations = createAsyncThunk<ValueResponse<GeolocationModel[]>, number, { rejectValue: RejectedValue }>(
    'BuildingSlicer/GetAllGeolocations',
    async (page: number, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetAllGeolocations?page=' + page, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<GeolocationModel[]> = {
                value: (await response.json()) as GeolocationModel[],
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetNumberOfGeolocation = createAsyncThunk<ValueResponse<number>, void, { rejectValue: RejectedValue }>(
    'BuildingSlicer/GetNumberOfGeolocation',
    async (_, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetNumberOfGeolocation', {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<number> = {
                value: (await response.json()) as number,
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const SearchGeolocations = createAsyncThunk<ValueResponse<number>, string, { rejectValue: RejectedValue }>(
    'BuildingSlicer/SearchGeolocations',
    async (searchValue: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'SearchGeolocations?search=' + searchValue, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<number> = {
                value: (await response.json()) as number,
                status: response.status.toString(),
                message: response.statusText
            }

            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

const initialHeatingInterruptionData: HeatingInterruptionModel = {
    loweringPhase: 0,
    reheatingTime: 0,
    airExchangeRateDuringLoweringPhase_nabs: 0,
    airExchangeRateDuringReheatingTime_nrh: 0,
    effectiveBuildingMass_Cwirk: 0,
    temperatureDrop_trh: 0,
    averageTemperature: 0,
    heatTransferCoefficient: 0,
    heatingInterruptionType: "",
    heatedBuildingFloorSpace: 0,
    heatedNetBuildingVolume: 0,
    reheatingFactor_RH: 0,
}

const initialHeatingInterruptionDataForCreate: HeatingInterruptionModelForCreate = {
    heatingInterruptionType: "",
    heatTransferCoefficient: 0,
    averageTemperature: 0,
    effectiveBuildingMass_Cwirk: 0,
    loweringPhase: 0,
    reheatingTime: 0,
    airExchangeRateDuringLoweringPhase_nabs: 0,
    airExchangeRateDuringReheatingTime_nrh: 0,
    temperatureDrop_trh: 0,
}

const initialGeometry: GeometryModel = {
    buildingName: "",
    length: 0,
    width: 0,
    bottomPlateThickness: 0,
    z: 0,
    buildingArea: 0,
    buildingPerimeter: 0,
    groundWaterDepth: 0,
}

const initialProperty: PropertyModel = {
    buildingType: "",
    groundType: "",
    buildingMass: "",
    airOpeningTightness: "",
    buildingCoverage: "",
}

const initialThermalBridge: ThermalBridgeModel = {
    thermalBridge: "",
    thermalBridgeValue: 0,
}

const initialGeolocation: GeolocationModel = {
    geolocationId: "",
    geolocationZipCode: "",
    geolocationName: "",
    region: "",
    climateRegion: "",
    regionCode: "",
    minElevation: 0,
    maxElevation: 0,
    minElevationTemperature: 0,
    outsideTemperature: 0,
    annualAverageTemperature: 0,
    elevation: 0,
    isEdit: false,
}

const initialGeolocations: GeolocationModel[] = []

const initialBuildingData: BuildingModel = {
    buildingId: "",
    buildingName: "",
    length: 0,
    width: 0,
    bottomPlateThickness: 0,
    parameterB: 0,
    z: 0,
    buildingArea: 0,
    buildingPerimeter: 0,
    groundWaterDepth: 0,
    buildingType: "",
    groundType: "",
    buildingMass: "",
    airOpeningTightness: "",
    buildingCoverage: "",
    thermalBridge: "",
    thermalBridgeValue: 0,
    hasHeatingInterruption: false,
    heatingInterruptionModel: initialHeatingInterruptionData,
    geolocationId: "",
    geolocationName: "",
    geolocationZipCode: "",
    elevation: "",
    annualAverageTemperature: "",
    outsideTemperature: "",
}

const initialBuildingDataForCreate: BuildingModelForCreate = {
    projectId: "",
    geometry: initialGeometry,
    property: initialProperty,
    thermalBridge: initialThermalBridge,
    geolocation: initialGeolocation,
    hasHeatingInterruption: false,
    heatingInterruptionData: initialHeatingInterruptionDataForCreate,
    userId: "",
    dateCreatedOrModified: "",
}

const initialBuildingDataForUpdate: BuildingModelForUpdate = {
    buildingModel: initialBuildingDataForCreate,
}

const initialData: BuildingData = {
    isEdit: false,
    buildingData: initialBuildingData,
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialGeoloaction: GeolocationModel = {
    geolocationId: "",
    geolocationZipCode: "",
    geolocationName: "",
    region: "",
    climateRegion: "",
    regionCode: "",
    minElevation: 0,
    maxElevation: 0,
    minElevationTemperature: 0,
    outsideTemperature: 0,
    annualAverageTemperature: 0,
    elevation: 0,
    isEdit: undefined,
}

const initialNumberOfGeolocations: NumberOfGeolocations = {
    geolocationNumber: 0
}

const initialBuildingSliceData: BuildingSliceData = {
    geolocationData: initialGeolocations,
    geolocation: initialGeoloaction,
    numberOfGeolocations: initialNumberOfGeolocations,
    buildingData: initialData,
    buildingDataForCreate: initialBuildingDataForCreate,
    buildingDataForUpdate: initialBuildingDataForUpdate,
    statusValues: initialStatusValues
}

export const BuildingSlice = createSlice({
    name: "BuildingSlicer",
    initialState: {
        buildingSliceData: initialBuildingSliceData
    },
    reducers: {
        setIsEditGeolocation: (state, action) => {
            state.buildingSliceData.geolocationData.find(g => g.geolocationId === action.payload)!.isEdit = true;
        },
        resetIsEditGeolocations: (state) => {
            state.buildingSliceData.geolocationData.map(g => g.isEdit = false);
        },
        setBuilding: (state, action) => {
            state.buildingSliceData.buildingDataForCreate = action.payload;
        },
        resetBuilding: (state) => {
            state.buildingSliceData.buildingData.buildingData = initialBuildingData;
            state.buildingSliceData.buildingData.isEdit = false;
        },
        resetBuildingSlice: (state) => {
            state.buildingSliceData.statusValues.statusCode = '';
            state.buildingSliceData.statusValues.isLoading = false;
            state.buildingSliceData.statusValues.message = '';
            state.buildingSliceData.statusValues.showNotification = false;
            state.buildingSliceData.geolocationData = initialGeolocations;
            state.buildingSliceData.buildingData = initialData;
        },
        resetStatusValues: (state) => {
            state.buildingSliceData.statusValues.statusCode = '';
            state.buildingSliceData.statusValues.showNotification = false;
        },
        setStatusCodeToNone: (state) => {
            state.buildingSliceData.statusValues.statusCode = 'none';
        },
    },
    extraReducers(builder) {
        //CreateBuilding
        builder
            .addCase(CreateBuilding.fulfilled, (state, action) => {
                state.buildingSliceData.buildingData.buildingData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = true;

            })
            .addCase(CreateBuilding.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateBuilding.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //UpdateBuilding
        builder
            .addCase(UpdateBuilding.fulfilled, (state, action) => {
                state.buildingSliceData.buildingData.buildingData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateBuilding.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateBuilding.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //GetBuilding
        builder
            .addCase(GetBuilding.fulfilled, (state, action) => {
                state.buildingSliceData.buildingData.buildingData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.buildingData.isEdit = true;
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetBuilding.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetBuilding.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //DeleteBuilding
        builder
            .addCase(DeleteBuilding.fulfilled, (state, action) => {
                state.buildingSliceData.buildingData.buildingData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteBuilding.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteBuilding.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //GetAllGeolocations
        builder
            .addCase(GetAllGeolocations.fulfilled, (state, action) => {
                state.buildingSliceData.geolocationData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllGeolocations.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllGeolocations.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //GetNumberOfGeolocation
        builder
            .addCase(GetNumberOfGeolocation.fulfilled, (state, action) => {
                state.buildingSliceData.numberOfGeolocations = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetNumberOfGeolocation.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetNumberOfGeolocation.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        //SearchGeolocations
        builder
            .addCase(SearchGeolocations.fulfilled, (state, action) => {
                state.buildingSliceData.geolocationData = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(SearchGeolocations.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(SearchGeolocations.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });
        builder
            .addCase(GetGeolocation.fulfilled, (state, action) => {
                state.buildingSliceData.geolocation = handleFulfilled(state.buildingSliceData.statusValues, action.payload);
                state.buildingSliceData.statusValues.showNotification = false;
            })
            .addCase(GetGeolocation.rejected, (state, action) => {
                handleRejected(state.buildingSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.buildingSliceData.statusValues.showNotification = true;
            })
            .addCase(GetGeolocation.pending, (state) => {
                handlePending(state.buildingSliceData.statusValues);
            });

    },
});

export const { setIsEditGeolocation, resetIsEditGeolocations, setBuilding, resetBuilding, resetBuildingSlice, resetStatusValues, setStatusCodeToNone } = BuildingSlice.actions;
export default BuildingSlice.reducer;
