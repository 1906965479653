import { useTranslation } from "react-i18next";
import { ProjectModel, ProjectUserModel } from "../projectModels";
import ProjectComponent from "./projectComponent";
import DeleteCard from "../../../UIComponents/deleteCard";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { CloseProject, DeleteProject, GetAllProjects, GetProject, SearchProjectByName, resetProject, resetStatusValues, setProject } from "../projectSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { resetBuildingSlice } from "../../Building/buildingSlice";
import { NotificationPopup, notify } from "../../../UIComponents/notification";
import { Button } from "@material-tailwind/react";
import LeftArrow from "../../../Images/ArrowLeft.png";
import RightArrow from "../../../Images/ArrowRight.png";

interface ProjectsComponentProps {
    projects: ProjectModel[],
    setFilteredList: Dispatch<SetStateAction<boolean>>,
}

function ProjectsComponent(props: ProjectsComponentProps) {
    const { t } = useTranslation();
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedProject, setSelectedProject] = useState("");
    const projectSliceData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData);
    const [searchValue, setSearchValue] = useState("");
    const numberOfProjects = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.numberOfProjects);
    const projectsPerPage: number = 20;
    const numberOfPages: number = Math.ceil(numberOfProjects.projectNumber / projectsPerPage);
    const userId = localStorage.getItem("userId")!;

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const [activePage, setActivePage] = useState<number>(1);

    function nextPage() {
        setActivePage(prevActivePage => prevActivePage + 1);
    }

    function previousPage() {
        setActivePage(prevActivePage => prevActivePage - 1);
    }

    useEffect(() => {
        dispatch(GetAllProjects({ userId: userId!, page: activePage.toString() }));
    }, [activePage])

    function handleSelectClick(event: React.MouseEvent<HTMLDivElement>, projectId: string): void {
        localStorage.setItem('projectId', projectId);
        if (projectSliceData.projectData.projectData.projectId !== '' && projectSliceData.projectData.projectData.projectId !== projectId) {
            handleCloseProject();
        }
        else {
            handleOpenProject(projectId);
        }
    };

    function handleCloseProject(): void {
        const projectToClose: ProjectUserModel = {
            projectId: projectSliceData.projectData.projectData.projectId,
            userId: localStorage.getItem("userId")!,
        }
        dispatch(CloseProject(projectToClose));
    };

    function handleOpenProject(projectId: string): void {
        setIsEdit(false);
        setSelectedProject(projectId);
        const project = props.projects.find(p => p.projectId === projectId);
        if (project) {
            const projectToOpen: ProjectUserModel = {
                projectId: project.projectId,
                userId: localStorage.getItem("userId")!
            }
            dispatch(resetBuildingSlice());
            dispatch(GetProject(projectToOpen));
        }
    };

    function handleEditClick(projectId: string): void {
        setIsEdit(true);
        setSelectedProject(projectId);
        const projectToOpen: ProjectUserModel = {
            projectId: projectId,
            userId: localStorage.getItem("userId")!
        }
        dispatch(resetBuildingSlice());
        dispatch(GetProject(projectToOpen));
    };

    function handleDeleteClick(projectId: string): void {
        setSelectedProject(projectId);
        setIsDelete(true);
    }

    const deleteSelected = () => {
        const projectForDelete: ProjectUserModel = {
            projectId: selectedProject,
            userId: localStorage.getItem("userId")!
        }
        dispatch(resetProject());
        dispatch(DeleteProject(projectForDelete));
        setIsDelete(false);
    }

    function handleSearch() {
        dispatch(SearchProjectByName({ userId: userId, search: searchValue }))
        searchValue !== "" ? props.setFilteredList(true) : props.setFilteredList(false);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    useEffect(() => {
        if (projectSliceData.projectData.isClosed) {
            handleOpenProject(localStorage.getItem('projectId')!);
        }
        localStorage.removeItem('projectId');
    }, [projectSliceData.projectData.isClosed])

    useEffect(() => {
        if (projectSliceData.projectData.isReady) {
            if (isEdit) {
                setSelectedProject(projectSliceData.projectData.projectData.projectId);
                navigate("/createNewProject");
            }
            else if (projectSliceData.projectData.projectData.hasBuilding) {
                navigate("/buildingStructure");
            }
            else {
                navigate("/createBuilding");
            }
            setIsEdit(false);
        }
    }, [projectSliceData.projectData.isReady])

    useEffect(() => {
        if (projectSliceData.statusValues.statusCode === "") {
            return
        }
        else if (projectSliceData.statusValues.statusCode === "200" && projectSliceData.statusValues.showNotification) {
            notify(t(projectSliceData.statusValues.message))
            dispatch(resetStatusValues());
            dispatch(resetProject());
        }
        else if (projectSliceData.statusValues.statusCode !== "200" && projectSliceData.statusValues.showNotification) {
            notify(t(projectSliceData.statusValues.message), true)
            dispatch(resetStatusValues());
        }
    }, [projectSliceData.statusValues])

    return (
        <div className="h-screen">
            <div className="mx-[30px]">
                <div className="relative mt-[30px]">
                    <input id="searchProjects" type="text" autoComplete="off" placeholder={t("Search")} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={handleKeyDown} className="relative bg-#2E333E placeholder:text-#545B6A text-#F5F5F5 w-[321px] md:w-[473px] h-10 pl-[12px] rounded-[5px] outline-none shadow-inner ml-auto" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute translate-x-[280px] md:translate-x-[435px] -translate-y-[32px]" onClick={() => handleSearch()}>
                        <circle cx="11.5" cy="11.5" r="9.5" stroke="white" strokeWidth="1.5" />
                        <path d="M18.5 18.5L22 22" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </div>
                <div className="my-[15px] px-[20px] py-[15px] grid grid-cols-7 md:grid-cols-8 xl:grid-cols-8 text-#F5F5F5 text-sm md:text-base rounded-[10px] place-content-center">
                    <p className="col-span-3 my-auto pr-2">{t("Project")}</p>
                    <p className="my-auto px-2">{t("Status")}</p>
                    <p className="my-auto px-2 hidden md:block">{t("DateCreated")}</p>
                    <p className="my-auto px-2">{t("LastEdited")}</p>
                    <p className="text-center my-auto">{t("Edit")}</p>
                    <p className="text-center my-auto">{t("Delete")}</p>
                </div>
                <div className="w-full space-y-[15px] overflow-auto max-h-[calc(100vh-290px)]">
                    {props.projects.map(project => (
                        <ProjectComponent key={project.projectId} project={project.projectCode + "_" + project.projectName + " (" + project.standard + ")"} status={project.status} dateCreated={project.dateCreated} lastEdited={project.dateModified} isSelected={selectedProject === project.projectId} onSeleckClick={(e) => handleSelectClick(e, project.projectId)} onEditClick={() => handleEditClick(project.projectId)} onDeleteClick={() => handleDeleteClick(project.projectId)} />
                    ))}
                </div>
            </div>
            {isDelete ? <DeleteCard title={t("AreYouSureYouWantToDeleteThisProject")} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteSelected} /> : <></>}
            <NotificationPopup />
            {location.pathname === '/projects' &&
                <div className="flex mx-[27px] fixed justify-between bottom-6 w-[calc(100%-128px)]">
                    <Button id="leftArrowBtn" variant="text" className="flex items-center text-#F5F5F5 normal-case font-normal text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => previousPage()} disabled={activePage === 1}>
                        <img src={LeftArrow} className="h-[24px] w-[24px]" alt=""></img>
                        {t("Previous")}
                    </Button>
                    <Button id="rightArrowBtn" variant="text" className="flex items-center text-#F5F5F5 normal-case font-normal text-[16px] shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => nextPage()} disabled={activePage === numberOfPages}>
                        {t("Next")}
                        <img src={RightArrow} className="h-[24px] w-[24px]" alt=""></img>
                    </Button>
                </div>
            }
        </div>
    );
}

export default ProjectsComponent;