import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { handleInputChange, handleSpaceInInput } from "../../../app/common";

interface AreaProps {
    areaCode: string,
    areaName: string,
    addAreaClickFunction: (areaCode: string, areaName: string) => void,
    onCancelClickFunction: () => void,
    isEdit: boolean,
}

function AreaPopup(props: AreaProps) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDialog = () => setOpenDialog(!openDialog);
    const [areaCode, setAreaCode] = useState("");
    const [areaName, setAreaName] = useState("");
    const [areaCodeErrorStatus, setAreaCodeErrorStatus] = useState(false);
    const [areaNameErrorStatus, setAreaNameErrorStatus] = useState(false);

    function onAddClick() {
        if (areaCode === "") { setAreaCodeErrorStatus(true); } else { setAreaCodeErrorStatus(false); }
        if (areaName === "") { setAreaNameErrorStatus(true); } else { setAreaNameErrorStatus(false); }

        if (areaCode && areaName) {
            props.addAreaClickFunction(areaCode, areaName);
            handleDialog();
        }
    }

    function onCancelClick(): void {
        props.onCancelClickFunction();
        handleDialog();
    }

    useEffect(() => {
        if (props.isEdit) {
            setAreaCode(props.areaCode);
            setAreaName(props.areaName);
        }
    }, [])

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex justify-center bg-transparent shadow-none outline-none">
            <div className="flex flex-col justify-center space-y-3 bg-#343A47 w-[521px] md:w-[711px] h-[229px] rounded-[5px] text-[16px] font-normal font-inter text-#F5F5F5 px-6">
                <div className="flex justify-start items-center uppercase">
                    <p>{t("Area")}</p>
                </div>
                <div className="flex items-center gap-3">
                    <p>{t("Code")}</p>
                    <input id="areaCodeInput" type="text" autoComplete="off" placeholder={t("Code")} value={areaCode} onChange={(e) => handleInputChange(e, setAreaCode, 0)} maxLength={3} className={`${areaCodeErrorStatus ? "border-[1px] border-#EF5350" : ""} bg-#2E333E placeholder:text-#545B6A outline-none w-[396px] md:w-[604px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center gap-3">
                    <p>{t("Name")}</p>
                    <input id="areaNameInput" type="text" autoComplete="off" placeholder={t("Name")} value={areaName} onChange={(e) => handleSpaceInInput(e, setAreaName)} className={`${areaNameErrorStatus ? "border-[1px] border-#EF5350" : ""} bg-#2E333E placeholder:text-#545B6A outline-none w-[396px] md:w-[604px] h-10 pl-[12px] rounded-[5px] shadow-inner ml-auto`} />
                </div>
                <div className="flex items-center space-x-[30px] my-[15px] md:my-[30px]">
                    <Button id="cancelBtn" onClick={() => onCancelClick()} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg ml-auto">{t("Cancel")}</Button>
                    <Button id="confirmationBtn" disabled={false} onClick={() => onAddClick()} className="bg-#FF9800 w-fit h-10 px-6 justify-center items-center inline-flex rounded-lg text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                        {props.isEdit ? t("SaveArea") : t("AddArea")}
                    </Button>
                </div>
            </div>
        </Dialog >
    );
}

export default AreaPopup;
