import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-tailwind/react";
import { useState } from "react";

interface DeleteCardProps {
    title: string,
    text: string,
    isDelete: boolean,
    onCancelClickFunction: () => void,
    onContinueClickFunction: () => void,
}

function DeleteCard(props: DeleteCardProps) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(true);
    const handleDeleteDialog = () => setOpenDialog(!openDialog);

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDeleteDialog() }} animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex justify-center bg-transparent shadow-none outline-none">
            <div className="flex flex-col justify-between items-center bg-#3B4250 w-[388px] h-[249px] rounded-[5px] font-inter text-#F5F5F5">
                <div className="flex flex-row justify-end h-[32px] w-full rounded-t-[5px] bg-#2E333E border-none">
                    <Button id="closeBtn" size="sm" onClick={() => { props.onCancelClickFunction(); handleDeleteDialog() }} className="flex justify-center items-center w-[44px] h-[30px] bg-transparent hover:bg-transparent shadow-none hover:shadow-none hover:stroke-#F5F5F5">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 1.007L8.993 0L5 3.993L1.007 0L0 1.007L3.993 5L0 8.993L1.007 10L5 6.007L8.993 10L10 8.993L6.007 5L10 1.007Z" strokeWidth="0.7" fill="#8D929B" />
                        </svg>
                    </Button>
                </div>
                {
                    props.isDelete ?
                        <div className="px-[20%] text-xl leading-6 font-normal text-center pt-[10px]">{props.title}</div>
                        :
                        <>
                            <div className="px-[10%] text-xl leading-6 font-normal text-center pt-[10px]">{props.title}</div>
                            <div className="px-[20%] text-sm leading-4 font-light text-center">{props.text}</div>
                        </>
                }
                <div className="flex flex-row justify-center items-center gap-5 pb-[38px]">
                    <Button id="cancelBtn" size="sm" className={`w-fit h-10 px-6 rounded-[5px] normal-case text-base leading-6 font-semibold bg-transparent hover:bg-transparent shadow-none hover:shadow-none text-#F5F5F5 hover:text-#FF9800`} onClick={() => { props.onCancelClickFunction(); handleDeleteDialog() }}>
                        <span>{t("Cancel")}</span>
                    </Button>
                    <Button id="continueBtn" size="sm" className={`w-fit h-10 px-6 rounded-[5px] normal-case text-base leading-6 font-semibold shadow-none hover:shadow-none text-#F5F5F5 ${props.isDelete ? "bg-#EF5350 hover:bg-#E93F3C" : "bg-#66BB6A hover:bg-#3EAD43"}`} onClick={props.onContinueClickFunction}>
                        <span>{t("Continue")}</span>
                    </Button>
                </div >
            </div >
        </Dialog >

    );
}

export default DeleteCard;
