import { Button, Typography } from "@material-tailwind/react";
import CalculationToolsLogo from "../../../Images/CalculationToolsLogo.png"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { Calculate } from "../calculationsSlice";
import { CalculationModel } from "../calculationsModels";
import SaveFile from "./saveFileDialog";
import { formatDateToDateTimeString } from "../../../app/common";

function Header() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [isProjectTab, setProjectTab] = useState(false);
    const [isMaterialTab, setMaterialTab] = useState(false);
    const [isRoomTab, setRoomTab] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const projectData = useSelector((state: RootState) => state.ProjectSlice.projectSliceData.projectData.projectData);
    const dispatch = useDispatch<AppDispatch>();
    const [openSave, setOpenSave] = useState(false)

    function onHomePage(): void {
        navigate("/dashboard");
    }
    function handleCreateNewProjectClick(): void {
        navigate("/createNewProject");
    }
    function handleCreateNewMaterialClick(): void {
        navigate("/createNewMaterial");
    }

    useEffect(() => {
        if (location.pathname === "/projects" || location.pathname === "/createNewProject" || location.pathname === "/dashboard") {
            setProjectTab(true)
            setMaterialTab(false)
            setRoomTab(false)
            location.pathname === "/createNewProject" ? setButtonDisabled(true) : setButtonDisabled(false)
        }
        else if (location.pathname === "/materials" || location.pathname === "/createNewMaterial") {
            setMaterialTab(true)
            setRoomTab(false)
            setProjectTab(false)
            location.pathname === "/createNewMaterial" ? setButtonDisabled(true) : setButtonDisabled(false)
        }
        else if (location.pathname === "/buildingStructure") {
            setRoomTab(true)
            setProjectTab(false)
            setMaterialTab(false)
        }
        else {
            setProjectTab(false)
            setMaterialTab(false)
            setRoomTab(false)
        }
    }, [location])

    function handleCalculateClick(): void {
        const currentDate = formatDateToDateTimeString(new Date());

        const calculationModel: CalculationModel = {
            projectId: projectData.projectId,
            userId: localStorage.getItem("userId")!,
            dateCalculated: currentDate
        }
        dispatch(Calculate(calculationModel))
    }

    return (
        <div className="sticky top-0 z-50 max-h-[70px] w-[100%] bg-#343A47 font-inter shadow-header">
            <div className="max-h-[70px] max-w-full rounded-none bg-#343A47 border-none shadow-none">
                <div className="flex items-center">
                    <Typography as="a" className="cursor-pointer flex flex-row px-[14px] py-[16px] left-0" onClick={onHomePage}>
                        <img id="logo" src={CalculationToolsLogo} alt="Logo" className="w-[36px] h-[36px]" />
                    </Typography>
                    {
                        projectData !== null && projectData.projectId !== "" ?
                            <div className="flex items-center">
                                < Typography className="flex flex-row px-[14px] py-[16px] left-0 text-#FFFFFF text-[16px]">{t("ProjectName") + ": " + projectData.projectName}</Typography>
                                <Typography className="flex flex-row px-[14px] py-[16px] left-0 text-#FFFFFF text-[16px]">{t("Standard") + ": " + projectData.standard}</Typography>
                            </div> : <></>
                    }
                    <div className="flex flex-row items-center gap-[26px] pr-[27px] ml-auto">
                        {isProjectTab &&
                            < Button id="createANewProjectBtn" disabled={buttonDisabled} onClick={handleCreateNewProjectClick} className="w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                {t("CreateANewProject")}
                            </Button>
                        }
                        {
                            isRoomTab &&
                            <>
                                <Button id="saveFileBtn" onClick={() => setOpenSave(true)} className="w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                    {t("SaveFile")}
                                </Button>
                                <Button id="calculateBtn" onClick={handleCalculateClick} className="w-fit h-10 px-6 bg-#FF9800 rounded-lg justify-center items-center inline-flex text-#272C35 text-sm font-semibold uppercase leading-[24px] shadow-none hover:shadow-none hover:bg-#FBC02D">
                                    {t("Calculate")}
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
            {openSave && <SaveFile onCancelClickFunction={() => setOpenSave(false)} />}
        </div >
    );
}

export default Header;
