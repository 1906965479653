import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse, HandleErrorsFromBack, RejectedValue, StatusValue, ValueResponse, extractErrorMessageFromException, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { GetAllProjectsModel, NumberOfProjects, ProjectAddressModel, ProjectAddressModelForCreate, ProjectAddressModelForUpdate, ProjectData, ProjectModel, ProjectModelForCreate, ProjectModelForUpdate, ProjectSliceData, ProjectUserModel, SearchProjectModel } from "./projectModels";

export const CreateProject = createAsyncThunk<ValueResponse<ProjectModel>, ProjectModelForCreate, { rejectValue: RejectedValue }>(
    'ProjectSlicer/CreateProject',
    async (project: ProjectModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateProject', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(project),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel> = {
                value: (await response.json()) as ProjectModel,
                status: response.status.toString(),
                message: "Project created successfully",
            };

            return dataResponse;
        } catch (error: any) {
            const errorMessage = error.message || 'Error in application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateProject = createAsyncThunk<ValueResponse<ProjectModel>, ProjectModelForUpdate, { rejectValue: RejectedValue }>(
    'ProjectSlicer/UpdateProject',
    async (project: ProjectModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateProject', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(project),
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel> = {
                value: (await response.json()) as ProjectModel,
                status: response.status.toString(),
                message: "Project updated successfully"
            }

            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to update project';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const DeleteProject = createAsyncThunk<ValueResponse<string>, ProjectUserModel, { rejectValue: RejectedValue }>(
    'ProjectSlicer/DeleteProject',
    async (projectUser: ProjectUserModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'DeleteProject?projectId=' + projectUser.projectId + '&userId=' + projectUser.userId, {
                method: "DELETE",
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Project deleted successfully"
            }
            return dataResponse;
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to update project';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CloseProject = createAsyncThunk<ValueResponse<string>, ProjectUserModel, { rejectValue: RejectedValue }>(
    'ProjectSlicer/CloseProject',
    async (projectUser: ProjectUserModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CloseProject?projectId=' + projectUser.projectId + '&userId=' + projectUser.userId, {
                method: "POST",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<string> = {
                value: (await response.json()) as string,
                status: response.status.toString(),
                message: "Project closed successfully",
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetProject = createAsyncThunk<ValueResponse<ProjectModel>, ProjectUserModel, { rejectValue: RejectedValue }>(
    'ProjectSlicer/GetProject',
    async (projectUser: ProjectUserModel, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("calculationToolsTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        requestHeaders.set("Content-Type", "application/json");

        let dateOpened = new Date();
        const year = dateOpened.getFullYear();
        const month = ('0' + (dateOpened.getMonth() + 1)).slice(-2);
        const day = ('0' + dateOpened.getDate()).slice(-2);
        const hours = ('0' + dateOpened.getHours()).slice(-2);
        const minutes = ('0' + dateOpened.getMinutes()).slice(-2);
        const seconds = ('0' + dateOpened.getSeconds()).slice(-2);

        const formattedString = `${year}-${month}-${day}%20${hours}%3A${minutes}%3A${seconds}`;

        try {
            const response = await fetch(apiAddress + 'GetProject?projectId=' + projectUser.projectId + '&userId=' + projectUser.userId + '&dateOpened=' + formattedString, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel> = {
                value: (await response.json()) as ProjectModel,
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllProjects = createAsyncThunk<ValueResponse<ProjectModel[]>, GetAllProjectsModel, { rejectValue: RejectedValue }>(
    'ProjectSlicer/GetAllProjectsAlphabeticaly',
    async (getAllProjectsModel: GetAllProjectsModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetAllProjectsAlphabeticaly?userId=' + getAllProjectsModel.userId + '&page=' + getAllProjectsModel.page, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel[]> = {
                value: (await response.json()) as ProjectModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetLastModifiedProjects = createAsyncThunk<ValueResponse<ProjectModel[]>, string, { rejectValue: RejectedValue }>(
    'ProjectSlicer/GetAllProjectsLastModified',
    async (userId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetAllProjectsLastModified?userId=' + userId + '&limit=15', {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel[]> = {
                value: (await response.json()) as ProjectModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetNumberOfProjects = createAsyncThunk<ValueResponse<NumberOfProjects>, string, { rejectValue: RejectedValue }>(
    'ProjectSlicer/GetNumberOfProjects',
    async (userId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetNumberOfProjects?userId=' + userId, {
                method: "GET",
                headers: requestHeaders,
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<NumberOfProjects> = {
                value: (await response.json()) as NumberOfProjects,
                status: response.status.toString(),
                message: response.statusText,
            };

            return dataResponse;
        } catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
)

export const SearchProjectByName = createAsyncThunk<ValueResponse<ProjectModel[]>, SearchProjectModel, { rejectValue: RejectedValue }>(
    'ProjectSlicer/SearchProjectByName',
    async (searchProjectModel: SearchProjectModel, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'SearchProjectByName?userId=' + searchProjectModel.userId + '&search=' + searchProjectModel.search, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectModel[]> = {
                value: (await response.json()) as ProjectModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const CreateProjectAddress = createAsyncThunk<ValueResponse<ProjectAddressModel>, ProjectAddressModelForCreate, { rejectValue: RejectedValue }>(
    'ProjectSlicer/CreateProjectAddress',
    async (projectAddress: ProjectAddressModelForCreate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'CreateProjectAddress', {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify(projectAddress),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectAddressModel> = {
                value: (await response.json()) as ProjectAddressModel,
                status: response.status.toString(),
                message: "Stakeholder information added successfully",
            };

            return dataResponse;
        } catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const UpdateProjectAddress = createAsyncThunk<ValueResponse<ProjectAddressModel>, ProjectAddressModelForUpdate, { rejectValue: RejectedValue }>(
    'ProjectSlicer/UpdateProjectAddress',
    async (projectAddress: ProjectAddressModelForUpdate, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'UpdateProjectAddress', {
                method: "PUT",
                headers: requestHeaders,
                body: JSON.stringify(projectAddress),
            });
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectAddressModel> = {
                value: (await response.json()) as ProjectAddressModel,
                status: response.status.toString(),
                message: "Stakeholder information updated successfully",
            };

            return dataResponse;
        } catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const RemoveProjectAddress = createAsyncThunk<ValueResponse<ProjectAddressModel[]>, string, { rejectValue: RejectedValue }>(
    'ProjectSlicer/RemoveProjectAddress',
    async (projectAddressId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'RemoveProjectAddress?projectAddressId=' + projectAddressId, {
                method: "DELETE",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectAddressModel[]> = {
                value: (await response.json()) as ProjectAddressModel[],
                status: response.status.toString(),
                message: "Stakeholder information removed successfully",

            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Failed to remove project address';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

export const GetAllProjectAddresses = createAsyncThunk<ValueResponse<ProjectAddressModel[]>, string, { rejectValue: RejectedValue }>(
    'ProjectSlicer/GetAllProjectAddresses',
    async (projectId: string, { rejectWithValue }) => {
        try {
            const requestHeaders: HeadersInit = new Headers();
            const accessToken = localStorage.getItem("calculationToolsTAccessToken");
            requestHeaders.set("Authorization", "Bearer " + accessToken);
            requestHeaders.set("Content-Type", "application/json");
            const response = await fetch(apiAddress + 'GetAllProjectAddresses?projectId=' + projectId, {
                method: "GET",
                headers: requestHeaders,
            })
            if (!response.ok) {
                return rejectWithValue(await HandleErrorsFromBack(response));
            }
            const dataResponse: ValueResponse<ProjectAddressModel[]> = {
                value: (await response.json()) as ProjectAddressModel[],
                status: response.status.toString(),
                message: response.statusText
            }
            return dataResponse
        }
        catch (error: any) {
            const errorMessage = error.message || 'Error in Application';
            return rejectWithValue({ message: errorMessage, status: "500" });
        }
    }
);

const initialProjectData: ProjectModel = {
    projectId: "",
    standard: "",
    projectCode: "",
    projectName: "",
    status: false,
    dateCreated: "",
    dateModified: "",
    directoryId: "",
    hasBuilding: false,
    isCalculated: false,
}

const initialProjectAddressData: ProjectAddressModel = {
    projectAddressId: "",
    projectId: "",
    groupName: "",
    name: "",
    department: "",
    country: "",
    postalCode: "",
    city: "",
    street: "",
    streetNumber: "",
    phoneNumber1: "",
    phoneNumber2: "",
    fax: "",
    email1: "",
    email2: "",
    webSite: "",
}

const initialAllProjectsData: ProjectModel[] = []

const initialAllProjectAddressesData: ProjectAddressModel[] = []

const initialData: ProjectData = {
    projectData: initialProjectData,
    allProjectsData: initialAllProjectsData,
    lastOpenedProjectsData: initialAllProjectsData,
    projectAddressData: initialProjectAddressData,
    allProjectAddressesData: initialAllProjectAddressesData,
    isReady: false,
    isEdit: false,
    isClosed: false,
    numberOfProjects: { projectNumber: 0 },
}

const initialStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    showNotification: false,
    message: "",
}

const initialProjectSliceData: ProjectSliceData = {
    projectData: initialData,
    statusValues: initialStatusValues
}

export const ProjectSlice = createSlice({
    name: "ProjectSlicer",
    initialState: {
        projectSliceData: initialProjectSliceData
    },
    reducers: {
        setProject: (state, action) => {
            state.projectSliceData.projectData.projectData = action.payload;
        },
        resetProject: (state) => {
            state.projectSliceData.projectData.projectData = initialProjectData;
        },
        resetProjectSlice: (state) => {
            state.projectSliceData.statusValues.statusCode = '';
            state.projectSliceData.statusValues.isLoading = false;
            state.projectSliceData.statusValues.message = '';
            state.projectSliceData.statusValues.showNotification = false;
            state.projectSliceData.projectData = initialData;
        },
        resetStatusValues: (state) => {
            state.projectSliceData.statusValues.statusCode = '';
            state.projectSliceData.statusValues.showNotification = false;
            state.projectSliceData.projectData.isReady = false;
            state.projectSliceData.projectData.isEdit = false;
        },
        setStatusCodeToNone: (state) => {
            state.projectSliceData.statusValues.statusCode = 'none';
        },
    },
    extraReducers(builder) {
        //Create project
        builder
            .addCase(CreateProject.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateProject.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateProject.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //Update project
        builder
            .addCase(UpdateProject.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateProject.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateProject.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //GetProject
        builder
            .addCase(GetProject.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.projectData.isReady = true;
                state.projectSliceData.projectData.isEdit = true;
                state.projectSliceData.projectData.isClosed = false;
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetProject.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetProject.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //DeleteProject
        builder
            .addCase(DeleteProject.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.projectData.isReady = false;
                state.projectSliceData.projectData.isEdit = false;
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteProject.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(DeleteProject.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //CloseProject
        builder
            .addCase(CloseProject.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.projectData.projectData = initialProjectData;
                state.projectSliceData.projectData.isClosed = true;
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(CloseProject.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(CloseProject.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //GetAllProjects
        builder
            .addCase(GetAllProjects.fulfilled, (state, action) => {
                state.projectSliceData.projectData.allProjectsData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllProjects.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllProjects.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //GetLastModifiedProjects
        builder
            .addCase(GetLastModifiedProjects.fulfilled, (state, action) => {
                state.projectSliceData.projectData.lastOpenedProjectsData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetLastModifiedProjects.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetLastModifiedProjects.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //GetNumberOfProjects
        builder
            .addCase(GetNumberOfProjects.fulfilled, (state, action) => {
                state.projectSliceData.projectData.numberOfProjects = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetNumberOfProjects.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetNumberOfProjects.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //SearchProjectByName
        builder
            .addCase(SearchProjectByName.fulfilled, (state, action) => {
                state.projectSliceData.projectData.allProjectsData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(SearchProjectByName.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(SearchProjectByName.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //CreateProjectAddress
        builder
            .addCase(CreateProjectAddress.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectAddressData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateProjectAddress.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(CreateProjectAddress.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //UpdateProjectAddress
        builder
            .addCase(UpdateProjectAddress.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectAddressData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateProjectAddress.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(UpdateProjectAddress.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //RemoveProjectAddress
        builder
            .addCase(RemoveProjectAddress.fulfilled, (state, action) => {
                state.projectSliceData.projectData.projectAddressData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(RemoveProjectAddress.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = true;
            })
            .addCase(RemoveProjectAddress.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
        //GetAllProjectAddresses
        builder
            .addCase(GetAllProjectAddresses.fulfilled, (state, action) => {
                state.projectSliceData.projectData.allProjectAddressesData = handleFulfilled(state.projectSliceData.statusValues, action.payload);
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllProjectAddresses.rejected, (state, action) => {
                handleRejected(state.projectSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: "-1" })
                state.projectSliceData.statusValues.showNotification = false;
            })
            .addCase(GetAllProjectAddresses.pending, (state) => {
                handlePending(state.projectSliceData.statusValues);
            });
    },
});

export const { setProject, resetProject, resetProjectSlice, resetStatusValues, setStatusCodeToNone } = ProjectSlice.actions;
export default ProjectSlice.reducer;
