import { Dialog, Radio, Typography } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { DeleteComponentCollection, DeleteOpeningCollection, GetAllComponentCollections, GetAllOpeningCollections, RemoveComponentFromCollection, RemoveOpeningFromCollection, RenameComponentCollection, RenameOpeningCollection, SaveComponentsToCollection, SaveOpeningsToCollection, initialRenameSaveItemCollectionData, resetDeleteCollection, resetDeleteItemFromCollection, resetSaveItemInCollection } from "../componentsSlice";
import { ComponentCollectionModel, OpeningCollectionModel, RenameSaveItemCollectionModel } from "../componentsModels";
import DeleteCard from "../../../../UIComponents/deleteCard";
import { handleSpaceInInput, handleSpaceInInputForObjects } from "../../../../app/common";

interface SaveTemplateProps {
    setSaveTemplate: () => void,
    isComponent: boolean,
    item: { itemId: string, itemName: string }
    onCancelClickFunction: () => void,
}

interface updateOnCheck {
    templateId: string,
    templateName: string
}

export interface ItemModel {
    name: string,
    id: string,
}


function SaveTemplate(props: SaveTemplateProps) {
    //const [openDialog] = useState(true);
    const [newTemplate, setNewTemplate] = useState<RenameSaveItemCollectionModel>(initialRenameSaveItemCollectionData);
    const [inputMode, setInputMode] = useState(false)
    const [allComponentCollections, setAllComponentCollections] = useState<ComponentCollectionModel[]>([]);
    const [allOpeningCollections, setAllOpeningCollections] = useState<OpeningCollectionModel[]>([]);
    const [openDialog, setOpenDialog] = useState(true);
    const handleDeleteDialog = () => setOpenDialog(!openDialog);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const componentSliceData = useSelector((state: RootState) => state.ComponentsSlice.componentsSliceData);
    const [renameSaveItemCollectionModel, setRenameSaveItemCollectionModel] = useState<RenameSaveItemCollectionModel>(initialRenameSaveItemCollectionData);
    const [selectedTemplate, setSelectedTemplate] = useState<ComponentCollectionModel | OpeningCollectionModel>();
    const [renameTemplate, setRenameTemplate] = useState<boolean>(false);
    const [deletedItemId, setDeletedItemId] = useState<string>('');
    const userId: string = localStorage.getItem("userId")!;
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const deleteMessage: string = t("AreYouSureYouWantToDeleteTemplate")
    const [itemIdToDelete, setItemIdToDelete] = useState<string>('');
    const [inputError, setInputError] = useState<boolean>(false);
    const [renameCollectionName, setRenameCollectionName] = useState<string>(renameSaveItemCollectionModel.collectionName);
    const radioRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, userId: userId, collectionId: null });
        setNewTemplate({ ...newTemplate, userId: userId, collectionId: null });
    }, [])

    useEffect(() => {
        if (props.isComponent) {
            dispatch(GetAllComponentCollections(userId));
        } else {
            dispatch(GetAllOpeningCollections(userId));
        }
        dispatch(resetSaveItemInCollection());
        dispatch(resetDeleteItemFromCollection());
        dispatch(resetDeleteCollection());
    }, [componentSliceData.statusValuesItemInCollection, componentSliceData.statusValuesDeleteItemFromCollection, componentSliceData.statusValuesDeleteComponentCollection, componentSliceData.statusValuesDeleteOpeningCollection]);

    useEffect(() => {
        if (selectedTemplate) {
            if ('openings' in selectedTemplate) {
                let selectedOpening = selectedTemplate as OpeningCollectionModel;
                let indexToDelete = selectedOpening.openings.findIndex(x => x.openingId === deletedItemId);
                if (indexToDelete !== -1) {
                    let newOpenings = [...selectedOpening.openings];
                    newOpenings.splice(indexToDelete, 1);
                    setSelectedTemplate({ ...selectedOpening, openings: newOpenings });
                }
            } else {
                let selectedComponent = selectedTemplate as ComponentCollectionModel;
                let indexToDelete = selectedComponent.components.findIndex(x => x.componentId === deletedItemId);
                if (indexToDelete !== -1) {
                    let newComponents = [...selectedComponent.components];
                    newComponents.splice(indexToDelete, 1);
                    setSelectedTemplate({ ...selectedComponent, components: newComponents });
                }
            }
        }
    }, [componentSliceData.statusValuesDeleteItemFromCollection, componentSliceData.statusValuesDeleteComponentCollection, componentSliceData.statusValuesDeleteOpeningCollection]);

    useEffect(() => {
        if (props.isComponent) {
            setAllComponentCollections(componentSliceData.componentsData.allComponentInCollectionData)
        } else {
            setAllOpeningCollections(componentSliceData.openingsData.allOpeningInCollectionData);
        }
    }, [componentSliceData.componentsData.allComponentInCollectionData, componentSliceData.openingsData.allOpeningInCollectionData]);

    useEffect(() => {
        let collectionNameTemp = undefined;
        if (props.isComponent) {
            collectionNameTemp = allComponentCollections.find(x => x.collectionId === selectedTemplate?.collectionId)?.collectionName;
        } else {
            collectionNameTemp = allOpeningCollections.find(x => x.collectionId === selectedTemplate?.collectionId)?.collectionName;
        }
        if (collectionNameTemp) {
            setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, collectionName: collectionNameTemp });
        }
    }, [allComponentCollections, allOpeningCollections])

    function handleSaveTemplate() {
        setInputError(true)
        if (newTemplate.collectionName !== "") {
            props.isComponent ? dispatch(SaveComponentsToCollection(newTemplate)) : dispatch(SaveOpeningsToCollection(newTemplate));
            setNewTemplate({ ...newTemplate, collectionName: '' });
            setInputMode(false);
            setInputError(false)
        }
    }

    function selectItemType(item: OpeningCollectionModel | ComponentCollectionModel): ItemModel[] {
        let collectionModel: OpeningCollectionModel | ComponentCollectionModel;
        let itemsResult: ItemModel[] = [];

        if ('openings' in item) {
            collectionModel = item as OpeningCollectionModel;
            itemsResult = collectionModel.openings.map(opening => ({
                name: opening.openingName,
                id: opening.openingId
            }));
            return itemsResult;
        } else {
            collectionModel = item as ComponentCollectionModel;
            itemsResult = collectionModel.components.map(component => ({
                name: component.componentName,
                id: component.componentId
            }));

            return itemsResult;
        }
    }

    function handleTemplateCheck(updateOnCheck: updateOnCheck) {
        setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, collectionId: updateOnCheck.templateId, collectionName: updateOnCheck.templateName })
    }

    function handleSaveButton() {
        if (renameSaveItemCollectionModel.collectionId && renameSaveItemCollectionModel.collectionName) {
            let modelForDispatch: RenameSaveItemCollectionModel = { ...renameSaveItemCollectionModel, userId: userId, entityIds: [...renameSaveItemCollectionModel.entityIds, props.item.itemId] }
            props.isComponent ? dispatch(SaveComponentsToCollection(modelForDispatch)) : dispatch(SaveOpeningsToCollection(modelForDispatch));
            setRenameSaveItemCollectionModel(initialRenameSaveItemCollectionData);
        }
    }

    function handleRenameTemplate() {
        setInputError(true)
        if (selectedTemplate?.collectionId && renameCollectionName !== "") {
            props.isComponent ?
                dispatch(RenameComponentCollection({ ...renameSaveItemCollectionModel, collectionName: renameCollectionName, userId: userId, collectionId: selectedTemplate?.collectionId, entityIds: [] })) :
                dispatch(RenameOpeningCollection({ ...renameSaveItemCollectionModel, collectionName: renameCollectionName, userId: userId, collectionId: selectedTemplate?.collectionId, entityIds: [] }));
            setRenameTemplate(false);
            setInputError(false);
        }
    }

    function handleDeleteFromCollection(item: ItemModel) {
        if (selectedTemplate) {
            if (props.isComponent) {
                dispatch(RemoveComponentFromCollection({ entityInCollectionId: findEntityInCollectionId(item), collectionId: selectedTemplate.collectionId }))
            } else {
                dispatch(RemoveOpeningFromCollection({ entityInCollectionId: findEntityInCollectionId(item), collectionId: selectedTemplate.collectionId }))
            }
            setDeletedItemId(item.id)
        }
    }

    function filterOpeningCollectionsCurrent(items: OpeningCollectionModel[]) {
        if (items !== undefined && items.length > 0) {
            let collection = items as OpeningCollectionModel[];
            let filteredCollection = collection.filter(x => x.openings.some(y => y.openingName === props.item.itemName) === true)
            return filteredCollection as OpeningCollectionModel[];
        }
        return [];
    }

    function filterComponentCollectionsCurrent(items: ComponentCollectionModel[]) {
        if (items !== undefined && items.length > 0) {
            let collection = items as ComponentCollectionModel[];
            let filteredCollection = collection.filter(x => x.components.some(y => y.componentName === props.item.itemName) === true)
            return filteredCollection as ComponentCollectionModel[];
        }
        return [];
    }

    function filterCollectionsRest(items: OpeningCollectionModel[] | ComponentCollectionModel[]) {
        if (items !== undefined && items.length > 0) {
            if ('openings' in items[0]) {
                let collection = items as OpeningCollectionModel[];
                if (collection.length > 0) {
                    let filteredCollection = collection.filter(x => x.openings.some(y => y.openingName === props.item.itemName) === false)
                    return filteredCollection;
                }
                return [];

            } else {
                let collection = items as ComponentCollectionModel[];
                if (collection.length > 0) {
                    let filteredCollection = collection.filter(x => x.components.some(y => y.componentName === props.item.itemName) === false)
                    return filteredCollection;
                }
                return [];
            }
        }
        return [];
    }

    function findEntityInCollectionId(item: ItemModel): string {
        if (props.isComponent && selectedTemplate?.collectionId) {
            let component = allComponentCollections.find(x => x.collectionId === selectedTemplate?.collectionId)?.components.find(x => x.componentId === item.id)?.componentId;
            if (component) {
                return component
            } else {
                return '';
            }
        } else if (!props.isComponent && selectedTemplate?.collectionId) {
            let opening = allOpeningCollections.find(x => x.collectionId === selectedTemplate?.collectionId)?.openings.find(x => x.openingId === item.id)?.openingId;
            if (opening) {
                return opening
            } else {
                return '';
            }
        }
        return ''
    }

    function deleteItemCollection() {
        props.isComponent ? dispatch(DeleteComponentCollection(itemIdToDelete)) : dispatch(DeleteOpeningCollection(itemIdToDelete));
        setIsDelete(false);
    }

    function handleRenameButton() {
        setRenameTemplate(prev => !prev);
        setRenameCollectionName(renameSaveItemCollectionModel.collectionName)
    }

    function uncheckRadio() {
        if (radioRef.current) {
            radioRef.current.checked = false;
        }
    }

    return (
        <Dialog open={openDialog} handler={() => { props.onCancelClickFunction(); handleDeleteDialog() }} size="xl" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }} className="flex justify-center bg-transparent shadow-none outline-none">
            <div className="flex flex-col text-#F5F5F5 bg-#3B4250 justify-between p-[20px] h-[515px] w-[939px] md:w-[939px] lg:w-[939px] xl:w-[939px] md:h-[515px] lg:h-[515px] xl:h-[515px]">
                <div className="">
                    <Typography className="lg:text-lg xl:text-xl ml-[11px] leading-none">{t("Templates")}</Typography>
                    <div className=" max-h-[345px] overflow-auto">
                        <div id="currentTemplates" className="">
                            {<div className="border-b-[1px] border-[#E9E8E7] flex mt-[20px] pb-1 pr-[17px] justify-between text-#F5F5F5">
                                {!selectedTemplate?.collectionId ?
                                    <Typography className={`text-[14px]  font-light ml-[11px]`}>{t("CurrentTemplates")}</Typography>
                                    :
                                    <div className="flex items-center ml-[11px]">
                                        {renameTemplate ?
                                            <div className="flex items-center">
                                                <input name="collectionName" id="templateNameInput" type="text" value={renameCollectionName} onChange={(e) => handleSpaceInInput(e, setRenameCollectionName)} className={`${inputError && renameCollectionName === "" ? "border-[1px] border-#EF5350" : ""} bg-#2E333E ml-[11px] placeholder:text-#545B6A w-[258px] md:w-[545px] lg:w-[198px] h-[33px] pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                                                <div className="flex ml-[14px]">
                                                    <button id="renameTemplateBtn" onClick={() => (handleRenameTemplate())}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                            <circle cx="10.5" cy="10.5" r="10.5" fill="#7EB608" />
                                                            <path d="M14.6355 7.94036C14.7306 8.04048 14.7817 8.17391 14.7775 8.31132C14.7733 8.44873 14.7142 8.57887 14.6131 8.67312L9.07842 13.8397C8.96559 13.945 8.81566 14.0023 8.66071 13.9995C8.50575 13.9966 8.35807 13.9338 8.24925 13.8245L6.37876 11.9443C6.32923 11.896 6.28987 11.8386 6.26299 11.7752C6.2361 11.7117 6.22223 11.6437 6.22217 11.5749C6.22211 11.5061 6.23587 11.438 6.26264 11.3745C6.28941 11.3111 6.32866 11.2535 6.37811 11.2052C6.42756 11.157 6.48622 11.1189 6.55066 11.0933C6.6151 11.0677 6.68405 11.055 6.75348 11.056C6.82292 11.0571 6.89145 11.0718 6.95509 11.0993C7.01873 11.1268 7.0762 11.1666 7.12417 11.2163L8.68338 12.7835L13.8956 7.91824C13.9967 7.82403 14.1314 7.77343 14.2702 7.77758C14.4089 7.78173 14.5403 7.84028 14.6355 7.94036Z" fill="white" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <button id="closeInputBtn" className="w-[14px] h-[14px] ml-3" onClick={() => { setRenameTemplate(false); setInputError(false) }} >
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13 1L1 13M1 1L13 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>
                                            :
                                            <div className="flex">
                                                <Typography className="text-[14px] font-light">{renameSaveItemCollectionModel?.collectionName}</Typography>
                                                <button id="renameTemplateInput" className="stroke-#F5F5F5 ml-[10px] hover:stroke-#FF9800" onClick={handleRenameButton}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M9.57354 2.71976L10.1915 2.10184C11.2153 1.07803 12.8752 1.07803 13.899 2.10184C14.9228 3.12564 14.9228 4.78555 13.899 5.80935L13.2811 6.42727M9.57354 2.71976C9.57354 2.71976 9.65078 4.03284 10.8094 5.19143C11.968 6.35003 13.2811 6.42727 13.2811 6.42727M9.57354 2.71976L3.89271 8.40058C3.50794 8.78536 3.31555 8.97775 3.15009 9.18987C2.95492 9.44011 2.78759 9.71086 2.65106 9.99733C2.53532 10.2402 2.44928 10.4983 2.2772 11.0145L1.54803 13.202M13.2811 6.42727L7.60023 12.1081C7.21546 12.4929 7.02307 12.6853 6.81094 12.8507C6.56071 13.0459 6.28996 13.2132 6.00348 13.3498C5.76063 13.4655 5.50251 13.5515 4.98628 13.7236L2.79878 14.4528M2.79878 14.4528L2.26406 14.631C2.01002 14.7157 1.72993 14.6496 1.54058 14.4602C1.35123 14.2709 1.28511 13.9908 1.3698 13.7368L1.54803 13.202M2.79878 14.4528L1.54803 13.202" />
                                                    </svg>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                }
                                <div
                                    className="flex">
                                    <Typography>{t("DeleteTheComponentFromTheTemplate")}</Typography>
                                </div>
                            </div>}
                            <div className="max-h-[293px] overflow-auto">{
                                !selectedTemplate?.collectionId ?
                                    (props.isComponent ? filterComponentCollectionsCurrent(allComponentCollections).map((item) => (
                                        <div className="flex border-b-[0.5px] border-[#E9E8E7] pl-[11px] pr-[31px] py-[14px] content-center">
                                            <button id="selectComponentBtn" disabled={inputMode} onClick={() => { setSelectedTemplate(item); setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, userId: userId, collectionId: item.collectionId, collectionName: item.collectionName }) }}>
                                                <Typography className={`$ text-[#f5f5f5] font-inter ${inputMode && 'opacity-50'} h-[16px] ml-[11px] leading-none`}>{item.collectionName}</Typography>
                                            </button>
                                            <div className="flex ml-auto">
                                                <button id="deleteTemplateBtn" className={`hover:fill-#FF9800 ${inputMode && 'opacity-50'} fill-#F5F5F5`} disabled={inputMode} onClick={() => { setItemIdToDelete(item.collectionId); setIsDelete(true) }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.87307 1.50001H9.12737C9.27163 1.49992 9.39731 1.49984 9.51599 1.51879C9.98485 1.59366 10.3906 1.8861 10.6099 2.30723C10.6654 2.41382 10.7051 2.53307 10.7506 2.66996L10.825 2.89323C10.8376 2.93102 10.8412 2.94172 10.8443 2.95014C10.961 3.27289 11.2637 3.49106 11.6068 3.49976C11.6158 3.49999 11.6269 3.50003 11.6669 3.50003H13.6669C13.943 3.50003 14.1669 3.72388 14.1669 4.00003C14.1669 4.27617 13.943 4.50003 13.6669 4.50003H2.3335C2.05735 4.50003 1.8335 4.27617 1.8335 4.00003C1.8335 3.72388 2.05735 3.50003 2.3335 3.50003H4.33355C4.37358 3.50003 4.38463 3.49999 4.39365 3.49976C4.73675 3.49106 5.03943 3.2729 5.15618 2.95016C5.15925 2.94168 5.16278 2.93121 5.17544 2.89323L5.24984 2.66998C5.29537 2.5331 5.33505 2.41382 5.39055 2.30723C5.60985 1.8861 6.01558 1.59366 6.48445 1.51879C6.60313 1.49984 6.72881 1.49992 6.87307 1.50001ZM6.0056 3.50003C6.03994 3.43268 6.07037 3.36269 6.09655 3.29032C6.1045 3.26834 6.11229 3.24495 6.12231 3.21489L6.18885 3.01528C6.24963 2.83294 6.26362 2.79575 6.2775 2.76909C6.3506 2.62872 6.48585 2.53124 6.64213 2.50628C6.67182 2.50154 6.71152 2.50003 6.90373 2.50003H9.09671C9.28892 2.50003 9.32862 2.50154 9.3583 2.50628C9.51459 2.53124 9.64984 2.62872 9.72293 2.76909C9.73682 2.79575 9.75081 2.83294 9.81159 3.01528L9.87809 3.21477L9.9039 3.29033C9.93007 3.3627 9.9605 3.43268 9.99484 3.50003H6.0056Z" />
                                                        <path d="M3.94356 5.63343C3.92519 5.3579 3.68693 5.14943 3.4114 5.1678C3.13587 5.18617 2.9274 5.42442 2.94577 5.69995L3.25474 10.3344C3.31173 11.1896 3.35777 11.8804 3.46576 12.4224C3.57802 12.986 3.76897 13.4567 4.16336 13.8256C4.55776 14.1946 5.04013 14.3538 5.60989 14.4284C6.15791 14.5 6.85019 14.5 7.70723 14.5H8.29316C9.1502 14.5 9.84253 14.5 10.3905 14.4284C10.9603 14.3538 11.4427 14.1946 11.8371 13.8256C12.2315 13.4567 12.4224 12.986 12.5347 12.4224C12.6427 11.8804 12.6887 11.1896 12.7457 10.3345L13.0547 5.69995C13.073 5.42442 12.8646 5.18617 12.589 5.1678C12.3135 5.14943 12.0753 5.3579 12.0569 5.63343L11.7503 10.2328C11.6904 11.1314 11.6477 11.7566 11.554 12.227C11.4631 12.6833 11.3362 12.9249 11.1539 13.0954C10.9716 13.2659 10.7222 13.3765 10.2608 13.4368C9.78524 13.499 9.15857 13.5 8.25801 13.5H7.74243C6.84187 13.5 6.2152 13.499 5.7396 13.4368C5.27826 13.3765 5.02882 13.2659 4.84655 13.0954C4.66427 12.9249 4.53738 12.6833 4.44648 12.227C4.35277 11.7566 4.31009 11.1314 4.25018 10.2328L3.94356 5.63343Z" />
                                                        <path d="M6.28381 6.83584C6.55858 6.80836 6.8036 7.00883 6.83108 7.28361L7.16441 10.6169C7.19189 10.8917 6.99142 11.1367 6.71664 11.1642C6.44187 11.1917 6.19685 10.9912 6.16937 10.7164L5.83604 7.38311C5.80856 7.10834 6.00903 6.86332 6.28381 6.83584Z" />
                                                        <path d="M9.71664 6.83584C9.99142 6.86332 10.1919 7.10834 10.1644 7.38311L9.83108 10.7164C9.8036 10.9912 9.55858 11.1917 9.28381 11.1642C9.00903 11.1367 8.80856 10.8917 8.83604 10.6169L9.16937 7.28361C9.19685 7.00883 9.44187 6.80836 9.71664 6.83584Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                    )
                                        : filterOpeningCollectionsCurrent(allOpeningCollections).map((item) => (
                                            <div className="flex border-b-[0.5px] border-[#E9E8E7] pl-[11px] pr-[31px] py-[16px] content-center">
                                                <button id="selectOpeningBtn" disabled={inputMode} onClick={() => { setSelectedTemplate(item); setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, userId: userId, collectionId: item.collectionId, collectionName: item.collectionName }) }}>
                                                    <Typography className={`$ text-[#f5f5f5] font-inter ${inputMode && 'opacity-50'} h-[16px] ml-[11px] leading-none`}>{item.collectionName}</Typography>
                                                </button>
                                                <div className="flex ml-auto">
                                                    <button id="deleteOpeningFromCollectionBtn" className={`hover:fill-#FF9800 ${inputMode && 'opacity-50'} fill-#F5F5F5`} disabled={inputMode} onClick={() => { setItemIdToDelete(item.collectionId); setIsDelete(true) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.87307 1.50001H9.12737C9.27163 1.49992 9.39731 1.49984 9.51599 1.51879C9.98485 1.59366 10.3906 1.8861 10.6099 2.30723C10.6654 2.41382 10.7051 2.53307 10.7506 2.66996L10.825 2.89323C10.8376 2.93102 10.8412 2.94172 10.8443 2.95014C10.961 3.27289 11.2637 3.49106 11.6068 3.49976C11.6158 3.49999 11.6269 3.50003 11.6669 3.50003H13.6669C13.943 3.50003 14.1669 3.72388 14.1669 4.00003C14.1669 4.27617 13.943 4.50003 13.6669 4.50003H2.3335C2.05735 4.50003 1.8335 4.27617 1.8335 4.00003C1.8335 3.72388 2.05735 3.50003 2.3335 3.50003H4.33355C4.37358 3.50003 4.38463 3.49999 4.39365 3.49976C4.73675 3.49106 5.03943 3.2729 5.15618 2.95016C5.15925 2.94168 5.16278 2.93121 5.17544 2.89323L5.24984 2.66998C5.29537 2.5331 5.33505 2.41382 5.39055 2.30723C5.60985 1.8861 6.01558 1.59366 6.48445 1.51879C6.60313 1.49984 6.72881 1.49992 6.87307 1.50001ZM6.0056 3.50003C6.03994 3.43268 6.07037 3.36269 6.09655 3.29032C6.1045 3.26834 6.11229 3.24495 6.12231 3.21489L6.18885 3.01528C6.24963 2.83294 6.26362 2.79575 6.2775 2.76909C6.3506 2.62872 6.48585 2.53124 6.64213 2.50628C6.67182 2.50154 6.71152 2.50003 6.90373 2.50003H9.09671C9.28892 2.50003 9.32862 2.50154 9.3583 2.50628C9.51459 2.53124 9.64984 2.62872 9.72293 2.76909C9.73682 2.79575 9.75081 2.83294 9.81159 3.01528L9.87809 3.21477L9.9039 3.29033C9.93007 3.3627 9.9605 3.43268 9.99484 3.50003H6.0056Z" />
                                                            <path d="M3.94356 5.63343C3.92519 5.3579 3.68693 5.14943 3.4114 5.1678C3.13587 5.18617 2.9274 5.42442 2.94577 5.69995L3.25474 10.3344C3.31173 11.1896 3.35777 11.8804 3.46576 12.4224C3.57802 12.986 3.76897 13.4567 4.16336 13.8256C4.55776 14.1946 5.04013 14.3538 5.60989 14.4284C6.15791 14.5 6.85019 14.5 7.70723 14.5H8.29316C9.1502 14.5 9.84253 14.5 10.3905 14.4284C10.9603 14.3538 11.4427 14.1946 11.8371 13.8256C12.2315 13.4567 12.4224 12.986 12.5347 12.4224C12.6427 11.8804 12.6887 11.1896 12.7457 10.3345L13.0547 5.69995C13.073 5.42442 12.8646 5.18617 12.589 5.1678C12.3135 5.14943 12.0753 5.3579 12.0569 5.63343L11.7503 10.2328C11.6904 11.1314 11.6477 11.7566 11.554 12.227C11.4631 12.6833 11.3362 12.9249 11.1539 13.0954C10.9716 13.2659 10.7222 13.3765 10.2608 13.4368C9.78524 13.499 9.15857 13.5 8.25801 13.5H7.74243C6.84187 13.5 6.2152 13.499 5.7396 13.4368C5.27826 13.3765 5.02882 13.2659 4.84655 13.0954C4.66427 12.9249 4.53738 12.6833 4.44648 12.227C4.35277 11.7566 4.31009 11.1314 4.25018 10.2328L3.94356 5.63343Z" />
                                                            <path d="M6.28381 6.83584C6.55858 6.80836 6.8036 7.00883 6.83108 7.28361L7.16441 10.6169C7.19189 10.8917 6.99142 11.1367 6.71664 11.1642C6.44187 11.1917 6.19685 10.9912 6.16937 10.7164L5.83604 7.38311C5.80856 7.10834 6.00903 6.86332 6.28381 6.83584Z" />
                                                            <path d="M9.71664 6.83584C9.99142 6.86332 10.1919 7.10834 10.1644 7.38311L9.83108 10.7164C9.8036 10.9912 9.55858 11.1917 9.28381 11.1642C9.00903 11.1367 8.80856 10.8917 8.83604 10.6169L9.16937 7.28361C9.19685 7.00883 9.44187 6.80836 9.71664 6.83584Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) :
                                    (selectedTemplate.collectionId ? (selectItemType(selectedTemplate).map(item => (
                                        <div className="flex border-b-[0.5px] border-[#E9E8E7] pl-[11px] pr-[31px] py-[16px]">
                                            <Typography>{item.name}</Typography>
                                            <div className="flex ml-auto">
                                                <button id="deleteComponentFromTemplate" className="hover:fill-#FF9800 fill-#F5F5F5" onClick={() => handleDeleteFromCollection(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.87307 1.50001H9.12737C9.27163 1.49992 9.39731 1.49984 9.51599 1.51879C9.98485 1.59366 10.3906 1.8861 10.6099 2.30723C10.6654 2.41382 10.7051 2.53307 10.7506 2.66996L10.825 2.89323C10.8376 2.93102 10.8412 2.94172 10.8443 2.95014C10.961 3.27289 11.2637 3.49106 11.6068 3.49976C11.6158 3.49999 11.6269 3.50003 11.6669 3.50003H13.6669C13.943 3.50003 14.1669 3.72388 14.1669 4.00003C14.1669 4.27617 13.943 4.50003 13.6669 4.50003H2.3335C2.05735 4.50003 1.8335 4.27617 1.8335 4.00003C1.8335 3.72388 2.05735 3.50003 2.3335 3.50003H4.33355C4.37358 3.50003 4.38463 3.49999 4.39365 3.49976C4.73675 3.49106 5.03943 3.2729 5.15618 2.95016C5.15925 2.94168 5.16278 2.93121 5.17544 2.89323L5.24984 2.66998C5.29537 2.5331 5.33505 2.41382 5.39055 2.30723C5.60985 1.8861 6.01558 1.59366 6.48445 1.51879C6.60313 1.49984 6.72881 1.49992 6.87307 1.50001ZM6.0056 3.50003C6.03994 3.43268 6.07037 3.36269 6.09655 3.29032C6.1045 3.26834 6.11229 3.24495 6.12231 3.21489L6.18885 3.01528C6.24963 2.83294 6.26362 2.79575 6.2775 2.76909C6.3506 2.62872 6.48585 2.53124 6.64213 2.50628C6.67182 2.50154 6.71152 2.50003 6.90373 2.50003H9.09671C9.28892 2.50003 9.32862 2.50154 9.3583 2.50628C9.51459 2.53124 9.64984 2.62872 9.72293 2.76909C9.73682 2.79575 9.75081 2.83294 9.81159 3.01528L9.87809 3.21477L9.9039 3.29033C9.93007 3.3627 9.9605 3.43268 9.99484 3.50003H6.0056Z" />
                                                        <path d="M3.94356 5.63343C3.92519 5.3579 3.68693 5.14943 3.4114 5.1678C3.13587 5.18617 2.9274 5.42442 2.94577 5.69995L3.25474 10.3344C3.31173 11.1896 3.35777 11.8804 3.46576 12.4224C3.57802 12.986 3.76897 13.4567 4.16336 13.8256C4.55776 14.1946 5.04013 14.3538 5.60989 14.4284C6.15791 14.5 6.85019 14.5 7.70723 14.5H8.29316C9.1502 14.5 9.84253 14.5 10.3905 14.4284C10.9603 14.3538 11.4427 14.1946 11.8371 13.8256C12.2315 13.4567 12.4224 12.986 12.5347 12.4224C12.6427 11.8804 12.6887 11.1896 12.7457 10.3345L13.0547 5.69995C13.073 5.42442 12.8646 5.18617 12.589 5.1678C12.3135 5.14943 12.0753 5.3579 12.0569 5.63343L11.7503 10.2328C11.6904 11.1314 11.6477 11.7566 11.554 12.227C11.4631 12.6833 11.3362 12.9249 11.1539 13.0954C10.9716 13.2659 10.7222 13.3765 10.2608 13.4368C9.78524 13.499 9.15857 13.5 8.25801 13.5H7.74243C6.84187 13.5 6.2152 13.499 5.7396 13.4368C5.27826 13.3765 5.02882 13.2659 4.84655 13.0954C4.66427 12.9249 4.53738 12.6833 4.44648 12.227C4.35277 11.7566 4.31009 11.1314 4.25018 10.2328L3.94356 5.63343Z" />
                                                        <path d="M6.28381 6.83584C6.55858 6.80836 6.8036 7.00883 6.83108 7.28361L7.16441 10.6169C7.19189 10.8917 6.99142 11.1367 6.71664 11.1642C6.44187 11.1917 6.19685 10.9912 6.16937 10.7164L5.83604 7.38311C5.80856 7.10834 6.00903 6.86332 6.28381 6.83584Z" />
                                                        <path d="M9.71664 6.83584C9.99142 6.86332 10.1919 7.10834 10.1644 7.38311L9.83108 10.7164C9.8036 10.9912 9.55858 11.1917 9.28381 11.1642C9.00903 11.1367 8.80856 10.8917 8.83604 10.6169L9.16937 7.28361C9.19685 7.00883 9.44187 6.80836 9.71664 6.83584Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))) : "")
                            }</div>
                        </div>
                        <div id="restOfTemplates">
                            {!selectedTemplate && <div className="flex justify-between pr-[17px] border-b mt-[20px] pb-[4px]">
                                <Typography className="text-[14px] font-light ml-[11px]">{t("SelectATemplateToAddTo")} </Typography>
                                <Typography>{t("DeleteTemplate")}</Typography>
                            </div>}

                            {(!selectedTemplate?.collectionId && (props.isComponent ? filterCollectionsRest(allComponentCollections) : filterCollectionsRest(allOpeningCollections)).map((item) => (
                                <div className="flex border-b-[0.5px] border-[#E9E8E7] pl-[11px] pr-[31px] py-[16px] content-center">
                                    <Radio id="checkTemplateRadioBtn" key={item.collectionId} name="templateToCheck" ref={radioRef} disabled={inputMode} crossOrigin={""} defaultChecked={false} ripple={false} onClick={() => { handleTemplateCheck({ templateId: item.collectionId, templateName: item.collectionName }); uncheckRadio() }} icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 10 8" fill="none">
                                            <path d="M9.80418 0.213594C9.86625 0.28113 9.9155 0.361359 9.9491 0.449687C9.9827 0.538015 10 0.632706 10 0.728336C10 0.823967 9.9827 0.918658 9.9491 1.00699C9.9155 1.09531 9.86625 1.17554 9.80418 1.24308L3.8054 7.78641C3.74349 7.85411 3.66993 7.90783 3.58896 7.94448C3.50798 7.98113 3.42117 8 3.3335 8C3.24583 8 3.15901 7.98113 3.07804 7.94448C2.99706 7.90783 2.92351 7.85411 2.86159 7.78641L0.195469 4.87826C0.0703122 4.74174 0 4.55658 0 4.36352C0 4.17045 0.0703122 3.98529 0.195469 3.84878C0.320626 3.71226 0.490375 3.63556 0.667373 3.63556C0.844371 3.63556 1.01412 3.71226 1.13928 3.84878L3.3335 6.24363L8.86037 0.213594C8.92229 0.145888 8.99584 0.0921704 9.07682 0.0555184C9.1578 0.0188664 9.24461 0 9.33228 0C9.41995 0 9.50676 0.0188664 9.58774 0.0555184C9.66871 0.0921704 9.74227 0.145888 9.80418 0.213594Z" fill="#F5F5F5" />
                                        </svg>}
                                        className={`w-[16px] h-[16px] hover:before:opacity-0 checked:bg-transparent border-#F5F5F5 checked:border-#F5F5F5"`}
                                        containerProps={{ className: "p-0" }}
                                    />
                                    <button id="selectTemplateBtn" disabled={inputMode} onClick={() => { setSelectedTemplate(item); setRenameSaveItemCollectionModel({ ...renameSaveItemCollectionModel, userId: userId, collectionId: item.collectionId, collectionName: item.collectionName }); }}>
                                        <Typography className={`text-[#f5f5f5] font-inter h-[16px] ml-[11px] leading-none ${inputMode && 'opacity-50'}`}>{item.collectionName}</Typography>
                                    </button>
                                    <div className="flex ml-auto">
                                        <button id="deleteTemplateBtn" className={`hover:fill-#FF9800 ${inputMode && 'opacity-50'} fill-#F5F5F5`} disabled={inputMode} onClick={() => { setItemIdToDelete(item.collectionId); setIsDelete(true) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6.87307 1.50001H9.12737C9.27163 1.49992 9.39731 1.49984 9.51599 1.51879C9.98485 1.59366 10.3906 1.8861 10.6099 2.30723C10.6654 2.41382 10.7051 2.53307 10.7506 2.66996L10.825 2.89323C10.8376 2.93102 10.8412 2.94172 10.8443 2.95014C10.961 3.27289 11.2637 3.49106 11.6068 3.49976C11.6158 3.49999 11.6269 3.50003 11.6669 3.50003H13.6669C13.943 3.50003 14.1669 3.72388 14.1669 4.00003C14.1669 4.27617 13.943 4.50003 13.6669 4.50003H2.3335C2.05735 4.50003 1.8335 4.27617 1.8335 4.00003C1.8335 3.72388 2.05735 3.50003 2.3335 3.50003H4.33355C4.37358 3.50003 4.38463 3.49999 4.39365 3.49976C4.73675 3.49106 5.03943 3.2729 5.15618 2.95016C5.15925 2.94168 5.16278 2.93121 5.17544 2.89323L5.24984 2.66998C5.29537 2.5331 5.33505 2.41382 5.39055 2.30723C5.60985 1.8861 6.01558 1.59366 6.48445 1.51879C6.60313 1.49984 6.72881 1.49992 6.87307 1.50001ZM6.0056 3.50003C6.03994 3.43268 6.07037 3.36269 6.09655 3.29032C6.1045 3.26834 6.11229 3.24495 6.12231 3.21489L6.18885 3.01528C6.24963 2.83294 6.26362 2.79575 6.2775 2.76909C6.3506 2.62872 6.48585 2.53124 6.64213 2.50628C6.67182 2.50154 6.71152 2.50003 6.90373 2.50003H9.09671C9.28892 2.50003 9.32862 2.50154 9.3583 2.50628C9.51459 2.53124 9.64984 2.62872 9.72293 2.76909C9.73682 2.79575 9.75081 2.83294 9.81159 3.01528L9.87809 3.21477L9.9039 3.29033C9.93007 3.3627 9.9605 3.43268 9.99484 3.50003H6.0056Z" />
                                                <path d="M3.94356 5.63343C3.92519 5.3579 3.68693 5.14943 3.4114 5.1678C3.13587 5.18617 2.9274 5.42442 2.94577 5.69995L3.25474 10.3344C3.31173 11.1896 3.35777 11.8804 3.46576 12.4224C3.57802 12.986 3.76897 13.4567 4.16336 13.8256C4.55776 14.1946 5.04013 14.3538 5.60989 14.4284C6.15791 14.5 6.85019 14.5 7.70723 14.5H8.29316C9.1502 14.5 9.84253 14.5 10.3905 14.4284C10.9603 14.3538 11.4427 14.1946 11.8371 13.8256C12.2315 13.4567 12.4224 12.986 12.5347 12.4224C12.6427 11.8804 12.6887 11.1896 12.7457 10.3345L13.0547 5.69995C13.073 5.42442 12.8646 5.18617 12.589 5.1678C12.3135 5.14943 12.0753 5.3579 12.0569 5.63343L11.7503 10.2328C11.6904 11.1314 11.6477 11.7566 11.554 12.227C11.4631 12.6833 11.3362 12.9249 11.1539 13.0954C10.9716 13.2659 10.7222 13.3765 10.2608 13.4368C9.78524 13.499 9.15857 13.5 8.25801 13.5H7.74243C6.84187 13.5 6.2152 13.499 5.7396 13.4368C5.27826 13.3765 5.02882 13.2659 4.84655 13.0954C4.66427 12.9249 4.53738 12.6833 4.44648 12.227C4.35277 11.7566 4.31009 11.1314 4.25018 10.2328L3.94356 5.63343Z" />
                                                <path d="M6.28381 6.83584C6.55858 6.80836 6.8036 7.00883 6.83108 7.28361L7.16441 10.6169C7.19189 10.8917 6.99142 11.1367 6.71664 11.1642C6.44187 11.1917 6.19685 10.9912 6.16937 10.7164L5.83604 7.38311C5.80856 7.10834 6.00903 6.86332 6.28381 6.83584Z" />
                                                <path d="M9.71664 6.83584C9.99142 6.86332 10.1919 7.10834 10.1644 7.38311L9.83108 10.7164C9.8036 10.9912 9.55858 11.1917 9.28381 11.1642C9.00903 11.1367 8.80856 10.8917 8.83604 10.6169L9.16937 7.28361C9.19685 7.00883 9.44187 6.80836 9.71664 6.83584Z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            )
                            ))}
                        </div>
                    </div>
                    <div id="addingTemplate">
                        {inputMode ?
                            <div className="flex items-center border-b-[0.5px] border-[#E9E8E7] pl-[11px] pr-[28px] py-[7px]">
                                <div className="flex content-center">
                                    <input id="addNewTemplateInput" name="collectionName" type="text" placeholder={"Enter template name..."} autoComplete="off" value={newTemplate.collectionName} onChange={(e) => handleSpaceInInputForObjects(e, newTemplate, setNewTemplate)} className={`${inputError && newTemplate.collectionName === "" ? "border-[1px] border-#EF5350" : ""} bg-#2E333E ml-[11px] placeholder:text-#545B6A w-[258px] md:w-[545px] lg:w-[198px] h-[33px] pl-[12px] rounded-[5px] outline-none shadow-inner`} />
                                </div>
                                <div className="flex items-center ml-[14px] gap-[14px]">
                                    <button id="addTemplateBtn" onClick={() => (handleSaveTemplate())}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <circle cx="10.5" cy="10.5" r="10.5" fill="#3EAD43" className="hover:fill-#7EB608" />
                                            <path d="M14.6355 7.94036C14.7306 8.04048 14.7817 8.17391 14.7775 8.31132C14.7733 8.44873 14.7142 8.57887 14.6131 8.67312L9.07842 13.8397C8.96559 13.945 8.81566 14.0023 8.66071 13.9995C8.50575 13.9966 8.35807 13.9338 8.24925 13.8245L6.37876 11.9443C6.32923 11.896 6.28987 11.8386 6.26299 11.7752C6.2361 11.7117 6.22223 11.6437 6.22217 11.5749C6.22211 11.5061 6.23587 11.438 6.26264 11.3745C6.28941 11.3111 6.32866 11.2535 6.37811 11.2052C6.42756 11.157 6.48622 11.1189 6.55066 11.0933C6.6151 11.0677 6.68405 11.055 6.75348 11.056C6.82292 11.0571 6.89145 11.0718 6.95509 11.0993C7.01873 11.1268 7.0762 11.1666 7.12417 11.2163L8.68338 12.7835L13.8956 7.91824C13.9967 7.82403 14.1314 7.77343 14.2702 7.77758C14.4089 7.78173 14.5403 7.84028 14.6355 7.94036Z" fill="white" />
                                        </svg>
                                    </button>
                                    <button id="closeInputBtn" className="w-[14px] h-[14px]" onClick={() => { setInputMode(false); setNewTemplate({ ...newTemplate, collectionName: '' }); setInputError(false) }} >
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 1L1 13M1 1L13 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div> : ""}
                        {!selectedTemplate?.collectionId && !inputMode ?
                            <button id="addIconBtn" className="flex ml-[10px] content-center stroke-#F5F5F5 hover:stroke-#FF9800 hover:text-[#FF9800] items-center my-[13px]" onClick={() => setInputMode(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="my-auto mr-[10px]" width="19" height="19" viewBox="0 0 21 21" fill="none">
                                    <circle cx="10.5" cy="10.5" r="9.5" strokeWidth="1.2" />
                                    <path d="M13.3499 10.5014L10.4999 10.5014M10.4999 10.5014L7.6499 10.5014M10.4999 10.5014L10.4999 7.65137M10.4999 10.5014L10.4999 13.3514" strokeWidth="1.2" strokeLinecap="round" />
                                </svg>
                                <Typography className="text-[16px] leading-none">{t("AddANewTemplate")}</Typography>
                            </button> : ""}
                    </div>
                </div>
                <div id="buttonsComponent" className="flex ml-auto mt-auto w-fit gap-[25px]">
                    <button id="closePopupBtn" onClick={selectedTemplate?.collectionId ? (() => { setSelectedTemplate(undefined); setRenameSaveItemCollectionModel(initialRenameSaveItemCollectionData) }) : props.setSaveTemplate} className="w-fit h-10 px-6 bg-#FF9800 hover:bg-#FBC02D text-#272C35 uppercase font-semibold text-sm rounded-lg">{selectedTemplate?.collectionId ? `${t("BackToTemplates")}` : `${t('Cancel')}`}</button>
                    {!selectedTemplate && <button id="addItemToTemplateBtn" onClick={handleSaveButton} disabled={inputMode || (renameSaveItemCollectionModel.collectionId ? false : true)} className={`w-fit h-10 px-6 bg-#FF9800 enabled:hover:bg-#FBC02D ${inputMode && 'opacity-50'} ${!renameSaveItemCollectionModel.collectionId && 'opacity-50'} text-#272C35 uppercase font-semibold text-sm rounded-lg`}>{t("AddToTemplate")}</button>}
                </div>
            </div>
            {isDelete ? <DeleteCard title={deleteMessage} text="" isDelete={true} onCancelClickFunction={() => setIsDelete(false)} onContinueClickFunction={deleteItemCollection} /> : <></>}
        </Dialog >
    )
};

export default SaveTemplate;